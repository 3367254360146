import React, { useState } from "react"
import { connect } from "react-redux"

// Reactstrap
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { useWallet } from "../../libs/useMetaMaskWallet"
import MetamaskLogo from "../../assets/images/metamask.png"
import { setLogin, setNetwork } from "../../store/common/commonActions"
import { formatBalance, formatAddress } from "../../utils"

const ETHERSCAN_ADDRESSES = {
    1: "https://etherscan.io/",
    2: "",
    3: "https://ropsten.etherscan.io/",
    4: "https://rinkeby.etherscan.io/",
    5: "https://goerli.etherscan.io/",
    8: "",
    42: "https://kovan.etherscan.io/",
    66: "https://www.oklink.com/en/okc",
    100: "",
    137: "https://polygonscan.com/",
    209: "https://explorer.idexo.io",
    324: "https://explorer.zksync.io",
    1337: "",
    5777: "",
    42161: "https://arbiscan.io/",
    42170: "https://nova.arbiscan.io/",
    43114: "https://avascan.info/"
}

const WalletInfo = (props) => {
    const wallet = useWallet()
    const { setLogin, setNetwork } = props

    const [openDetailsModal, setOpenDetailsModal] = useState(false)

    const openEtherscan = () => {
        window.open(ETHERSCAN_ADDRESSES[wallet.ethereum.networkVersion] + "address/" + wallet.account)
    }

    const disconnectWallet = () => {
        setLogin(false)
        setNetwork("")
        wallet.reset()
    }

    return (
        <React.Fragment>
            {wallet && wallet.account && (
                <div className="wallet-info-header">
                    <div className="wallet-info-header__balance">
                        <i className="bx bx-wallet" /> {formatBalance(wallet.balance, wallet)}
                    </div>
                    <div className="wallet-info-header__addr" onClick={() => setOpenDetailsModal(true)}>
                        {formatAddress(wallet.account)}
                    </div>
                </div>
            )}
            <Modal isOpen={openDetailsModal} fade={false} toggle={() => setOpenDetailsModal(!openDetailsModal)} className="wallet-info-modal">
                <ModalHeader toggle={() => setOpenDetailsModal(!openDetailsModal)}>My Wallet</ModalHeader>
                <ModalBody>
                    <div className="wallet-info-modal__container">
                        <label className="status-label">Connected with MetaMask</label>
                        <div className="info-box">
                            <img src={MetamaskLogo} alt="metamask" /> <span>{formatAddress(wallet.account)}</span>
                        </div>
                        <div className="button-box">
                            <div className="button__item" onClick={() => navigator.clipboard.writeText(wallet.account)}>
                                <i className="bx bx-copy font-size-18 align-middle mr-1" /> <span>Copy Address</span>
                            </div>
                            <div className="button__item" onClick={openEtherscan}>
                                <i className="bx bx-link-external font-size-18 align-middle mr-1" /> <span>View on Etherscan</span>
                            </div>
                            <div className="button__item" onClick={disconnectWallet}>
                                <i className="bx bx-exit font-size-18 align-middle mr-1" /> <span>Disconnect</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

const mapDispatchToProps = {
    setLogin,
    setNetwork
}

export default connect(null, mapDispatchToProps)(WalletInfo)
