import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardTitle, FormGroup, Label, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import * as yup from "yup"

import LockIcon from "../../assets/icons/lock-icon.png"
import { toBase64, allFormsValid, isValidImage, compressFile } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./SubdomainCard.scss"

const SubdomainCard = React.memo(
    ({
        module, // module from projects table, NOT nftmarketing table!
        headerTitle,
        fieldLabel,
        subdomainData, // original data from backend (nftmarketing table item)
        subdomain,
        setSubdomain,
        subdomain_file, // string- logo base64
        setSubdomainFile,
        paid,
        showSpinner,
        buttonId,
        handleSubmit
    }) => {
        const [url, setUrl] = useState(null)
        const [status, setStatus] = useState(null)
        var intervalId = null

        const [alertMessage, setAlertMessage] = useState("")
        const [alertColor, setAlertColor] = useState("") // "success" = green, "danger" = red

        const checkCloudfront = async () => {
            const res = await fetch(`https://cloudfront.idexo.io/?subdomain=${subdomain}`, { method: "GET" }) // fetches ALL subdomains for all users
            const response = await res.json()

            // if response status is "Deployed" that means insertion time is greater than 2 hours
            if (response?.data?.Status === "Not Deployed") {
                // draft
                setStatus("Not Deployed")
            } else {
                setStatus(response?.data?.Status) // "InProgress" or "Deployed"
                if (response.data.Status === "Deployed" && intervalId) {
                    clearInterval(intervalId)
                    intervalId = null
                }
            }
        }

        const updateSubdomainLogo = async () => {
            try {
                let finalUri = "https://nftmarketing.idexo.io/"
                if (localStorage.user_tenants && localStorage.selected_tenant) {
                    const user_tenants = JSON.parse(localStorage.user_tenants)
                    if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
                }

                const res = await fetch(finalUri, {
                    method: "POST",
                    headers: { token: localStorage.jwtToken },
                    body: JSON.stringify({
                        action: "updateSubdomainLogo", // this action creates or updates a module
                        subdomain: subdomain,
                        logo: subdomain_file
                    })
                })
                const response = await res.json()
                console.log("updateSubdomainLogo", response)

                if (response.error || response.message === "Internal server error" || response?.data.error) {
                    setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                    setAlertColor("danger")
                }
            } catch (err) {
                console.log(err)
            }
        }

        const submit = (e) => {
            // don't let user change change subdomain after deploying
            if (module?.subdomain?.S !== subdomain && (status === "Deployed" || status === "InProgress")) {
                setAlertMessage("You cannot change the subdomain name if the creation status is InProgress or Deployed")
                setAlertColor("danger")
                return
            }

            // if domain is deployed, let user change logo
            if (status === "Deployed" && subdomain_file) {
                updateSubdomainLogo()
            }

            let newModule = { M: module }
            newModule.M["subdomain"] = { S: subdomain }
            if (subdomain_file) newModule.M["subdomain_file"] = { S: subdomain_file }
            const btnId = e.subdomain ? 8 : 7 // 8 == submit button, 7 == save button

            // if user clicks "SUBMIT"
            if (e.subdomain && !intervalId) {
                intervalId = setInterval(checkCloudfront, 10000)
            }
            if (headerTitle === "NFT Verification Site Subdomain") {
                handleSubmit(newModule, {}, btnId, e.subdomain, false, subdomainData?.inserted?.N)
            } else {
                // if user clicks "SAVE" or "SUBMIT"
                handleSubmit(newModule, btnId, e.subdomain)
            }
        }

        const handleAcceptedFiles = async (files) => {
            setAlertMessage("")
            const fileType = files[0].type
            if (!isValidImage(fileType)) {
                setAlertMessage("You must select a jpeg or png file!")
                setAlertColor("danger")
                return
            }

            // if file size >= 400kb, compress file (so we meet dynamo 400kb limit)
            if (files[0].size >= 400000) {
                files = [await compressFile(files[0])]
            }

            const base64Files = await Promise.all(
                files.map((file) => {
                    return toBase64(file)
                })
            )

            setSubdomainFile(base64Files[0].base64 || "")
        }

        useEffect(() => {
            setUrl(`https://${subdomain}.idexo.com`)
        }, [subdomain])

        // runs once after component mounts
        useEffect(() => {
            if (!status || status === "InProgress") {
                checkCloudfront()
            }

            // eslint-disable-next-line
        }, [status])

        return (
            <Card className={"site-subdomain-card" + (headerTitle === "NFT Verification Site Subdomain" ? " nft-verification" : "")}>
                <CardBody>
                    <CardTitle className="h3">{headerTitle}</CardTitle>
                    <div className="gray-box">
                        {paid > 0 ? (
                            <AvForm
                                className="nft-verification-form"
                                enableReinitialize
                                initialValues={{ subdomain: subdomain }}
                                validationSchema={yup.object().shape({
                                    subdomain: yup.string().required("This field is required.")
                                })}
                                onSubmit={submit}
                            >
                                <FormGroup className="mb-4">
                                    <AvField
                                        name="subdomain"
                                        label={fieldLabel}
                                        placeholder="Enter Custom Subdomain"
                                        type="text"
                                        className="form-control"
                                        id="subdomain"
                                        required
                                        disabled={showSpinner}
                                        onChange={(e) => setSubdomain(e.target.value)}
                                    />
                                    {subdomain && (
                                        <a href={url} target="_blank" rel="noreferrer">
                                            {url}
                                        </a>
                                    )}
                                    &nbsp;&nbsp;
                                    <Label htmlFor="exampleFile" className="form-label">
                                        {/* empty or "Not Deployed" if draft -> "InProgress" -> "Deployed" */}
                                        {status}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="exampleFile" className="form-label">
                                        <span>Upload A Logo</span>
                                        <i
                                            className="bx bx-info-circle"
                                            title="This logo is what will appear in your subdomains login page and header navigation bar"
                                        />
                                    </Label>
                                    {/* TO DO: (low priority)- this upload div should be its own component for reuse/DRY */}
                                    <div className={"photo-upload" + (showSpinner ? " disable" : "")}>
                                        <Dropzone onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)} maxFiles={1} disabled={showSpinner}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="d-flex flex-column align-items-center h-100">
                                                    <div className="photo-select needsclick" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {subdomain_file ? (
                                                            <div className="img-preview-container">
                                                                <img
                                                                    className="selected-image"
                                                                    alt="user data"
                                                                    src={subdomain_file !== " " ? `data:image/jpeg;base64,${subdomain_file}` : ""}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="photo-select needsclick">
                                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                </FormGroup>
                                <div>
                                    {alertMessage && (
                                        <Alert color={alertColor} toggle={() => setAlertMessage("")} isOpen={!!alertMessage}>
                                            {alertMessage}
                                        </Alert>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        color="primary"
                                        outline
                                        className="waves-effect w-sm"
                                        disabled={showSpinner || !allFormsValid([subdomain]) || status === "InProgress"}
                                        type="button"
                                        title="Save a draft of your subdomain name and logo. This will not create the website"
                                        onClick={submit}
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 7} txtTrue={"SAVING"} txtFalse={"SAVE"} />
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="waves-effect w-xl"
                                        disabled={showSpinner || !allFormsValid([subdomain]) || status === "Deployed" || status === "InProgress"}
                                        type="submit"
                                        title="Submitting will create the subdomain website. Once submitting, you cannot change the subdomain name"
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 8} txtTrue={"SUBMITTING"} txtFalse={"SUBMIT"} />
                                    </Button>
                                </div>
                            </AvForm>
                        ) : (
                            <div className="lock">
                                <img src={LockIcon} alt="Lock" />
                                <p>You need to upgrade to a paid plan to access the {headerTitle}</p>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        )
    }
)

export default SubdomainCard
