import React, { Component } from "react"
import { Button, Alert, Card, CardBody } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { Link } from "react-router-dom"
import { Logo } from "./Fragments"
import password from "../common/password"
import SpinnerWrap from "../Components/SpinnerWrap"

const apiUrl = "https://register.idexo.io/"

class Invite extends Component {
    constructor(props) {
        super(props)
        this.state = { email: "", password: "", action: "", tenant_id: "" }
        this.registerUser = this.registerUser.bind(this)
        this.acceptInvite = this.acceptInvite.bind(this)
    }

    registerUser(event) {
        let message = password.verify(event)
        if (message) return this.setState({ alertMessage: message, alertColor: "danger", showSpinner: false })
        this.setState({ alertMessage: "", alertColor: "danger", showSpinner: true })
        return fetch(apiUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                action: "new",
                email: event.email,
                password: event.password,
                tenant_id: this.state.tenant_id
            }),
            json: true
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    this.setState({ alertMessage: "Error: " + data.error, alertColor: "danger", showSpinner: false })
                } else {
                    this.setState({
                        alertMessage: "Password change request submitted. Check your email for further instructions.",
                        alertColor: "success",
                        showSpinner: false
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    acceptInvite(event) {
        this.setState({ alertMessage: "", alertColor: "danger", showSpinner: true })
        return fetch(apiUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                action: "invite",
                email: event.email,
                password: "*", // To accept an invitation, the user who already has an account, must send his password??
                tenant_id: this.state.tenant_id
            }),
            json: true
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    this.setState({ alertMessage: "Error: " + data.error, alertColor: "danger", showSpinner: false })
                } else {
                    this.setState({
                        alertMessage: "Accept invite submitted.",
                        alertColor: "success",
                        showSpinner: false
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        let s = window.location.search
        let sClean = s.replace("?", "")
        let params = sClean.split("&")

        for (let p = 0; p < params.length; p++) {
            if (params[p].includes("email=")) {
                this.setState({ email: params[p].replace("email=", "") })
            }
            if (params[p].includes("action=")) {
                this.setState({ action: params[p].replace("action=", "") })
            }
            if (params[p].includes("tenant_id=")) {
                this.setState({ tenant_id: params[p].replace("tenant_id=", "") })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages">
                    <Card>
                        <CardBody>
                            <Logo></Logo>
                            <AvForm
                                className="form needs-validation"
                                enableReinitialize
                                initialValues={{ email: this.state.email, password: "" }}
                                onSubmit={this.state.action === "invite" ? this.acceptInvite : this.registerUser}
                                validationSchema={this.state.action === "invite" ? null : password.schema}
                                autoComplete="off"
                            >
                                {this.state.alertMessage ? <Alert color={this.state.alertColor}>{this.state.alertMessage}</Alert> : null}

                                <div className="form-item">
                                    <AvField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        className="form-control"
                                        placeholder="Email"
                                        type="email"
                                        required
                                        autoComplete="off"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>
                                {this.state.action === "invite" ? null : (
                                    <>
                                        <div className="form-item">
                                            <AvField
                                                name="password"
                                                label="New Password"
                                                type="password"
                                                required
                                                placeholder="New Password"
                                                autoComplete="new-password"
                                                disabled={this.state.showSpinner}
                                            />
                                        </div>
                                        <div className="form-item">
                                            <AvField
                                                name="confirmpassword"
                                                label="Confirm Password"
                                                type="password"
                                                required
                                                placeholder="Confirm Password"
                                                autoComplete="new-password"
                                                disabled={this.state.showSpinner}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="form-button d-grid">
                                    <Button
                                        className="btn btn-primary btn-block waves-effect waves-light text-uppercase"
                                        type="submit"
                                        color="primary"
                                        disabled={this.state.showSpinner}
                                    >
                                        <SpinnerWrap
                                            showSpinner={this.state.showSpinner}
                                            txtTrue={"Submitting Request"}
                                            txtFalse={this.state.action === "invite" ? "Accept Invite" : "Submit Request"}
                                        />
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <div className="more-link text-center text-uppercase">
                        Already have an account ?
                        <Link to="/login" className="mx-3 fw-medium">
                            Login
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Invite
