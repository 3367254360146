import React, { useState, useEffect, useCallback } from "react"
import ido from "idexo-sdk"
import jwt from "jsonwebtoken"
import { Button, Card, CardBody, CardTitle, Table, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Input, Label } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import * as yup from "yup"
import { Player } from "video-react"

import {
    getImageInserted,
    formatAddress,
    capitalizeFirstLetter,
    everyNftHasImage,
    allNftsMintedOrPreminted,
    networkIcons,
    chainURLs
} from "../../utils"
import { useToast } from "../../hooks/toast"
import Tooltip from "../../Components/Tooltip"
import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import ConfirmSubmitModal from "../ConfirmSubmitModal/ConfirmSubmitModal"
import EditNFTModal from "../EditNFTModal/EditNFTModal"
import BulkUploadModal from "../BulkUploadModal/BulkUploadModal"
import OkDialog from "../OkDialog/OkDialog"
import BreadCrumb from "../BreadCrumb"
import "./NFTCollection.scss"

const apiUrl = "https://nftmarketing.idexo.io/"

const collectionLockedMessage = "Collection is locked, you cannot add or edit NFTs"

const NFTCollectionPage = ({
    project,
    module,
    clickProjects,
    clickProject,
    clickCancel,
    campaignData,
    showNFTCollectionOnly,
    setCampaignData,
    ...rest
}) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const toast = useToast()
    const [showLoading, setShowLoading] = useState(false) // for fetching nft images table loading animation
    const [alertMessage, setAlertMessage] = useState("") // for images table card if error fetching nft images
    const [NFTs, setNFTs] = useState([]) // array of objects (each object referring to an nft image that has already been uploaded)

    const [okAlertMessage, setOkAlertMessage] = useState("") // string- if user tries to add more nfts than cap, or collection cap is locked
    const [showNFTRowColor, setShowNFTRowColor] = useState(false) // nft table row changes color on mouse hover (but not if mouse over buttons)
    const [sumRandomnessWeights, setSumRandomnessWeights] = useState(null) // sum of all NFT's randomness_weight
    const [isCollectionLocked, setIsCollectionLocked] = useState(campaignData?.is_collection_locked?.BOOL || false) // snake case because key in dynamo

    const [showEditNFTModal, setShowEditNFTModal] = useState(false) // also for ADD NFT Modal
    const [showDeleteNFTModal, setShowDeleteNFTModal] = useState(false)
    const [showMintNFTModal, setShowMintNFTModal] = useState(false)
    const [showPreMintNFTModal, setShowPreMintNFTModal] = useState(false)
    const [showPreMintAllNFTModal, setShowPreMintAllNFTModal] = useState(false)
    const [showLockCollectionModal, setShowLockCollectionModal] = useState(false)
    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false)

    // MINT NFT Modal
    const [useOperatorAddress, setUseOperatorAddress] = useState(true)
    const [addressToMintTo, setAddressToMintTo] = useState("")

    // EDIT/MINT/PREMINT/DELETE Modals
    const [nft, setNft] = useState(null) // {} refers to nft table row we clicked / nft we are about to premint/mint/delete

    // Image Modal (on clicking nft image in table row)
    const [selectedImage, setSelectedImage] = useState(null) // object

    // ADD/EDIT/MINT/PREMINT/PREMINTALL/DELETE Modals
    const [showConfirmLoading, setShowConfirmLoading] = useState(false) // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
    const [disableModalButtons, setDisableModalButtons] = useState(false) // for all modals
    const [modalAlertMessage, setModalAlertMessage] = useState("") // for mint and add/edit modals
    const [alertColor, setAlertColor] = useState("")

    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`Address ${formatAddress(address)} is copied to clipboard.`)
    }

    const handleClickDeleteNFT = async () => {
        setShowConfirmLoading(true)

        try {
            // remove user_id from s3 item imgKey
            const prefix = nft.imgKey.split("/").slice(1).join("/") // part of key in item in s3 bucket to delete
            // console.log("prefix", prefix) // "module-1234/nftcollection-1234/nft-1234"

            let finalUri = apiUrl

            const response = await fetch(finalUri, {
                method: "POST",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "x-api-key": decoded.api_key,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    action: "deleteNFT",
                    prefix
                })
            })
            const res = await response.json()

            if (res.error || res?.data?.error) {
                setModalAlertMessage(res.error || res?.data?.error)
                setShowConfirmLoading(false)
                setAlertColor("danger")
                return
            }

            // if succesful deletion, close modal in 3 seconds
            setModalAlertMessage("Successfully Deleted the NFT! Returning to collection details...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setTimeout(() => {
                setShowDeleteNFTModal(false)
                resetModalAlertData()
            }, 3000)
            fetchNFTs()
        } catch (err) {
            console.log(err)
            setModalAlertMessage(`Error: ` + String(err))
            setAlertColor("danger")
        }
        setShowConfirmLoading(false)
    }

    const handleClickUseOperator = () => {
        setUseOperatorAddress((prev) => !prev) // toggle false/true on click
        setAddressToMintTo("") // clear addressToMintTo if user clicks checkbox useOperator
    }

    // TO DO: backend validation
    const handleClickLockCollection = async () => {
        // TO DO: should require that number of premints = cap and warn if that’s not the case
        setShowConfirmLoading(true)
        try {
            let finalUri = apiUrl

            const response = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: "lockCollection",
                    is_collection_locked: true,
                    inserted: campaignData.inserted.N // inserted time of campaign/nft collection- used to find/update item in nftmarketing table
                })
            })
            const res = await response.json()
            console.log("lockCollection", res)

            if (res.error || res?.data?.error) {
                setModalAlertMessage(res.error || res?.data?.error)
                setShowConfirmLoading(false)
                setAlertColor("danger")
                return
            }
            // if succesful deletion, close modal in 3 seconds
            setModalAlertMessage("Successfully Locked Collection! Returning to collection details...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setTimeout(() => {
                setShowLockCollectionModal(false)
                resetModalAlertData()
                setIsCollectionLocked(true)
                setCampaignData(res.data.Attributes) // adds the key/value .is_collection_locked to object
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
    }

    const uploadImageToS3 = async (url, img) => {
        try {
            // PUT request: upload file directly to s3 bucket using presigned URL
            const s3Response = await fetch(url, {
                method: "PUT",
                body: img // image object (blob)
            })
            // valid s3Response === {type:'cors', url: 'https://nftmarketing-738087076124.s3.us-east-1.ama…15af9d2e9&X-Amz-SignedHeaders=host&x-id=PutObject', redirected: false, status: 200, ok: true, …}
            console.log("s3Response", s3Response)

            // problem saving image to s3
            if (!s3Response.ok) {
                setModalAlertMessage(`Error: ${s3Response.statusText}`)
                setAlertColor("danger")
            } else {
                setModalAlertMessage("Successfully saved NFT!")
                setAlertColor("success")
                setDisableModalButtons(true)

                setTimeout(() => {
                    setShowEditNFTModal(false)
                    resetModalAlertData()
                }, 3000)
                fetchNFTs()
            }
        } catch (err) {
            console.log(err)
            setModalAlertMessage(`Error: ${String(err)}`)
            setAlertColor("danger")
        }
    }

    // ADDING or UPDATING an nft (if it hasn't already been preminted or minted)
    // TO DO: backend validation preventing updating if nft preminted/minted
    const handleSaveNFT = async (e, { name, description, randomness_weight, img, nftInserted, newAttributes, nftContentType }, action) => {
        // NOTE* img == JS obj (blob image)
        try {
            setShowConfirmLoading(true)
            setModalAlertMessage("")

            // if inserted time exists, we're overwriting existing s3 bucket item
            let inserted = nftInserted ? nftInserted : new Date().getTime() // milliseconds since 1 January 1970 UTC

            let user_id = decoded.user_id

            const Key = `${user_id}/module-${module.inserted.N}/nftcollection-${campaignData.inserted.N}/nft-${inserted}`
            // console.log(Key) //=> '12345-abcdefg/module-1646679079459/nftcollection-1646758022854/nft-1647626430874'

            const s3Params = {
                Key: Key,
                Metadata: {
                    name,
                    description,
                    randomness_weight, // all values must be string types, or else we'll get a 403 error!
                    attributes: JSON.stringify(newAttributes), // ex. '[ {trait_type: "color", value: "gold"}, {trait_type:"animal", value:"dog"} ]'
                    content_type: nftContentType, // ex. "image/jpg", "image/gif", "video/mp4"
                    file_name: img.name // ex. "canada.jpg"
                }
                // NOTE* S3 is storing file as a binary/octet-stream
            }

            // backend- for now just gets s3 upload url

            let finalUri = apiUrl

            const res = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: action, // string- ex. "saveUserNFT" (updates image + metadata) or "updateNFT" (update metadata only)
                    s3Params: s3Params, // sent to server to get upload url
                    Key: s3Params.Key,
                    Metadata: s3Params.Metadata,
                    // TO DO: find out if we need any of the data below to mint NFT
                    project_name: project.project_name.S,
                    project_inserted: project.inserted.N,
                    module_inserted: module.inserted.N,
                    campaign_inserted: campaignData.inserted.N // used to find/update item in nftmarketing table
                })
            })
            const response = await res.json()
            console.log("handleSaveNFT", response) //=> { error:null, data:null, code:200, url:{message,error,code,data:""}

            // problem getting signed url or updating s3 item metadata
            if (response.error || response.data?.error || response?.url?.error) {
                setModalAlertMessage(`Error: ${response.error || response.data?.error || response?.url?.error}`)
                setAlertColor("danger")
            } else if (action === "saveUserNFT") {
                const uploadUrl = JSON.parse(response.url.data)?.url
                await uploadImageToS3(uploadUrl, img)

                // updateNFT only changes s3 item metadata ex. name/description... (not image)
            } else if (action === "updateNFT") {
                // TO DO: this process could be abstracted for DRY
                setModalAlertMessage("Successfully saved NFT Image!")
                setAlertColor("success")
                setDisableModalButtons(true)

                setTimeout(() => {
                    setShowEditNFTModal(false)
                    resetModalAlertData()
                }, 3000)
                fetchNFTs()
            }
        } catch (err) {
            console.log(err)
            setModalAlertMessage(`Error: ` + String(err))
            setAlertColor("danger")
        }
        setShowConfirmLoading(false)
    }

    const preMintAll = async () => {
        // show error if 1) collection contract not deployed, 2) entire collection already preminted, or 3) minted, 4) no nfts
        if (!isValidPremintAll(true)) return
        setShowConfirmLoading(true)
        try {
            const preMintableNFTs = NFTs.filter((nft) => nft.imgMetaData.tx_status === undefined)

            let finalUri = apiUrl

            const response = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: "preMintNFTBatch",
                    chain: campaignData.chain.S.toLowerCase(),
                    nfts: preMintableNFTs,
                    metadataStorage: campaignData?.metadata_storage?.S || "arweave"
                })
            })
            const res = await response.json()
            console.log("preMintAll", res)

            if (res.error || res?.data?.error || res.code !== 200) {
                processMintNFTErrors(res.error || res?.data?.error || res.message)
                setShowConfirmLoading(false)
                return
            }
            // if succesful preminting all, close modal in 3 seconds
            setModalAlertMessage("Successfully Pre Minted all NFTs! Returning to collection details...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setTimeout(() => {
                setShowPreMintAllNFTModal(false)
                resetModalAlertData()
            }, 3000)
            fetchNFTs() // this is so we can update our local state & UI
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
    }

    const preMintNFT = async () => {
        // exit if collection not deployed or user has not uploaded image
        if (!isValidMintNFT(true)) return
        setShowConfirmLoading(true)

        try {
            let nftAttributes = "[]"
            if (nft?.imgMetaData?.attributes) nftAttributes = nft.imgMetaData.attributes // string- '[{"trait_type":"color", "value":"blue"}, {"trait_type":"location", "value":"italy"}]'
            // NOTE* attributes datatype in arweave will be JSON array of objects (with same shape/key naming convention as opensea)

            let s3Key = nft.imgKey.split("/") // remove user_id from imgKey
            s3Key.shift()
            s3Key = s3Key.join("/")
            nft.imgMetaData.storage = campaignData?.metadata_storage?.S || "arweave" // add the key "storage" to the s3 image metadata

            let finalUri = apiUrl

            const response = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: "preMintNFT",
                    chain: campaignData.chain.S.toLowerCase(),
                    nftName: nft.imgMetaData.name,
                    nftDescription: nft.imgMetaData.description,
                    contentType: nft.imgMetaData.content_type, // ex. "image/jpeg"
                    attributes: nftAttributes,
                    Metadata: nft.imgMetaData,
                    s3Key: s3Key,
                    metadataStorage: campaignData?.metadata_storage?.S || "arweave"
                })
            })
            const res = await response.json()
            console.log("preMintNFT", res)

            if (res.error || res?.data?.error || res.code !== 200) {
                processMintNFTErrors(res.error || res?.data?.error || res.message)
                setShowConfirmLoading(false)
                return
            }

            // if succesful minting, close modal in 3 seconds
            setModalAlertMessage("Successfully Pre Minted the NFT! Returning to collection details...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setTimeout(() => {
                setShowPreMintNFTModal(false)
                resetModalAlertData()
            }, 3000)
            fetchNFTs() // this is so we can update our local state & UI
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
    }

    const determineMintMethodName = () => {
        // if nft has been preminted
        if (nft.imgMetaData.metadata_uri && nft.imgMetaData.tx_status === "premint") {
            if (campaignData.contract_type.S === "soulbound") {
                return `mintSBT ${campaignData.chain.S.toLowerCase()}`
            } else {
                return `mintNFT" ${campaignData.chain.S.toLowerCase()}`
            }
        } else {
            // NOTE* it might be confusing for user whether they should purchase the method credit name 'NFTs_mintNFT' or 'Multi_mintNFTWithImage'
            if (campaignData.contract_type.S === "soulbound") {
                return `SBTs_mintSBTWithImage_${campaignData.chain.S.toLowerCase()}`
            } else {
                return `Multi_mintNFTWithImage_${campaignData.chain.S.toLowerCase()}`
            }
        }
    }

    // NOTE we are not using the idexo-sdk Multi.mintNFTWithImage because the method does not take in an s3Key as the image parameter
    const mintNFTWithImage = async () => {
        let nftAttributes = []
        if (nft?.imgMetaData?.attributes) nftAttributes = JSON.parse(nft.imgMetaData.attributes) // object. ex [{"trait_type":"color","value":"green"}]

        const image = "S3KEY/" + nft.imgKey // prepending 'S3KEY' so backend sdk knows the string is an S3key and not a base64 string
        const options = {
            metadataStorage: campaignData?.metadata_storage?.S || "arweave"
        }

        let api_key = decoded.api_key

        return await fetch(chainURLs[campaignData.chain.S.toLowerCase()], {
            method: "POST",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "x-api-key": api_key },
            body: JSON.stringify({
                contractAddress: campaignData.address.S.toLowerCase(), // lowercase in dynamo
                mintToAddress: addressToMintTo.toLowerCase(), // will be empty string if user selects "use operator address"
                image: image, // string- s3 key
                contentType: nft?.imgMetaData?.content_type || "image/jpeg",
                nftName: nft.imgMetaData.name,
                nftDescription: nft.imgMetaData.description,
                attributes: nftAttributes, // ex. [{"trait_type":"color","value":"green"}]
                transactionType: campaignData.contract_type.S === "soulbound" ? "mintSBTWithImage" : "mintNFTWithImage",
                options
            })
        })
    }

    const mintNFT = async () => {
        // exit if collection not deployed, user has not uploaded image, or user not entered addressToMintTo
        if (!isValidMintNFT()) return
        setShowConfirmLoading(true)

        try {
            const contractAddress = campaignData.address.S // address of createCollection contract deployed
            let api_key = decoded.api_key

            let res

            // if nft has been preminted, mint nft
            if (nft.imgMetaData.metadata_uri && nft.imgMetaData.tx_status === "premint") {
                if (campaignData.contract_type.S === "soulbound") {
                    res = await ido.SBTs.mintSBT(
                        api_key,
                        campaignData.chain.S.toLowerCase(),
                        contractAddress.toLowerCase(),
                        addressToMintTo.toLowerCase(), // will be empty string if user selects "use operator address"
                        nft.imgMetaData.metadata_uri // string- arweave link to json metadata
                    )
                } else {
                    res = await ido.NFTs.mintNFT(
                        api_key,
                        campaignData.chain.S.toLowerCase(),
                        contractAddress.toLowerCase(),
                        addressToMintTo.toLowerCase(), // will be empty string if user selects "use operator address"
                        nft.imgMetaData.metadata_uri // string- arweave link to json metadata
                    )
                }

                // nft has not been preminted, so this custom method premints and mints
            } else {
                // use ustom fetch bypassing idexo-sdk and POST directly to lambda
                res = await mintNFTWithImage()
                res = await res.json()
                // FOR TESTING
                // TO DO:
                // - test invalid contractAddress & invalid addressToMintTo & other inputs
                // - backend- finish useOperator function
                // - test useOperator mintTo address
                // - test different chains other than fantom
                // ----------
                // 1) bad apiKey    enough method credits-  error 403-  "Forbidden"
                // const res = await ido.Multi.mintNFTWithImage("potato_API", campaignData.chain.S.toLowerCase(), contractAddress.toLowerCase(), addressToMintTo.toLowerCase(), image, nft.imgMetaData.name, nft.imgMetaData.description, {}, true, contentType)
                //
                // 2) good apiKey   no method credits-      error 400-  "Invalid Amount"-  No Method Credits
                // const res = await ido.Multi.mintNFTWithImage(apiKey, campaignData.chain.S.toLowerCase(), contractAddress.toLowerCase(), addressToMintTo.toLowerCase(), image, nft.imgMetaData.name, nft.imgMetaData.description, {}, true, contentType)
                //
                // 3) good apiKey   enough method credits-  error 400-  "Invalid Amount"-  No Method Credits
                // const res = await ido.Multi.mintNFTWithImage(apiKey, campaignData.chain.S.toLowerCase(), contractAddress.toLowerCase(), addressToMintTo.toLowerCase(), image, nft.imgMetaData.name, nft.imgMetaData.description, {}, true, contentType)
                // ----------
            }
            console.log("mintNFT", res)

            if (res.error || res?.data?.error || res.message === "Internal server error" || res?.data?.message === "Forbidden") {
                processMintNFTErrors(res.error || res?.data?.error || res.message || res?.data?.message)
                setShowConfirmLoading(false)
                return
            }

            // if succesful minting, close modal in 3 seconds & update nft metadata params in s3
            // TO DO: (low priority)- remove nested res.data.data in response from backend / standardize response shape
            await updateS3Params(res.data.data || res.data) // res.data.data (res from sdk call), res.data (res from direct lambda call)

            setModalAlertMessage("Successfully Minted NFT! Returning to collection details...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setTimeout(() => {
                setShowMintNFTModal(false)
                resetModalAlertData()
            }, 3000)
            fetchNFTs() // this is so we can update our local state & UI
        } catch (err) {
            console.log(err)
            processMintNFTErrors(err)
        }

        setShowConfirmLoading(false)
    }

    // adds pending nft to Nft table & update s3 item metadata
    const updateS3Params = async (txData) => {
        let inserted = getImageInserted(nft.imgKey)

        let user_id = decoded.user_id
        if (localStorage.user_tenants && localStorage.selected_tenant) {
            const user_tenants = JSON.parse(localStorage.user_tenants)
            if (user_tenants.length > 0) user_id = localStorage.selected_tenant
        }

        const Key = `${user_id}/module-${module.inserted.N}/nftcollection-${campaignData.inserted.N}/nft-${inserted}`
        // console.log(Key) //=> '12345-abcdefg/module-1646679079459/nftcollection-1646758022854/nft-1647626430874'

        const s3Params = {
            Key: Key,
            Metadata: {
                name: nft?.imgMetaData?.name,
                description: nft?.imgMetaData?.description,
                randomness_weight: nft?.imgMetaData?.randomness_weight, // all values must be string types, or else we'll get a 403 error!
                chain: campaignData?.chain.S,
                tx_id: txData.tx_id,
                tx_hash: txData.tx_hash,
                tx_status: String(txData.tx_status), // "false", "done"
                step: "pending", // "draft" -> "premint" -> "pending" -> "done"
                mint_to_address: addressToMintTo.toLowerCase() || txData.sender, // sender == idexo if user selects "use operator" mint to address
                contract_address: campaignData.address.S, // address of createCollection contract deployed
                attributes: nft?.imgMetaData?.attributes, // stringified array of objects
                metadata_uri: txData.metadataUri || nft?.imgMetaData?.metadata_uri,
                content_type: nft?.imgMetaData?.content_type
            }
        }

        let finalUri = apiUrl
        if (localStorage.user_tenants && localStorage.selected_tenant) {
            const user_tenants = JSON.parse(localStorage.user_tenants)
            if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
        }

        // adds pending nft to Nft table & update s3 item metadata
        const res = await fetch(finalUri, {
            method: "POST",
            headers: { token: localStorage.jwtToken, "Content-Type": "application/json" },
            body: JSON.stringify({
                action: "mintUserNFT",
                s3Params: s3Params,
                Metadata: s3Params.Metadata
            })
        })
        const response = await res.json()
        console.log("updateS3Params", response)

        // // "update" s3 metadata (adds tx_id, tx_hash, ...)
        // response.data = await S3Helpers.updateItemMetaData(awsAccountId, body.s3Params.Key, body.Metadata)
        // let imgBlob = await base64ToFile(nft, nft.imgMetaData.name) // convert string to blob
        // const uploadUrl = JSON.parse(response.url.data)?.url
        // await uploadImageToS3(uploadUrl, imgBlob)
    }

    // update s3 item metadata
    const updateS3Metadata = async (item) => {
        const Key = item.imgKey
        // console.log(Key) //=> '12345-abcdefg/module-1646679079459/nftcollection-1646758022854/nft-1647626430874'

        const s3Params = {
            Key: Key,
            Metadata: item.imgMetaData
        }

        let finalUri = apiUrl
        if (localStorage.user_tenants && localStorage.selected_tenant) {
            const user_tenants = JSON.parse(localStorage.user_tenants)
            if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
        }

        // update s3 item metadata
        const res = await fetch(finalUri, {
            method: "POST",
            headers: { token: localStorage.jwtToken, "Content-Type": "application/json" },
            body: JSON.stringify({
                action: "updateNFT",
                Key: Key,
                s3Params: s3Params,
                Metadata: s3Params.Metadata
            })
        })
        return await res.json()
    }

    // if preMint=true, that means we've clicked the preMintNFT button
    // if preMint=false, that means we've clicked the mintNFT button
    const isValidMintNFT = (preMint = false) => {
        let errorMessage
        // show error if user hasn't deployed nft collection
        if (campaignData.source.S === "draft" || campaignData.source.S === "pending") {
            errorMessage = "You must deploy the NFT collection contract first!"

            // show error if user hasn't uploaded image for NFT
        } else if (nft?.contentLength === 0) {
            errorMessage = "You must upload an image for this NFT!"

            // show error if cap has been exceeded & collection is NOT capped
        } else if (sumRandomnessWeights > Number(campaignData.cap.S) && campaignData.cap.S !== "-1") {
            errorMessage = "Cap exceeded! The sum of all NFT's randomness weights cannot be > collection cap"

            // show error if we clicked mintNFT && user hasn't filled out addressToMintTo form
        } else if (!preMint && addressToMintTo === "" && !useOperatorAddress) {
            errorMessage = "You must enter a Mint To Address or Use Operator Address"
        }
        if (!errorMessage) return true
        setModalAlertMessage(errorMessage)
        setAlertColor("danger")
        return false
    }

    const isValidPremintAll = () => {
        let errorMessage
        if (campaignData.source.S === "draft" || campaignData.source.S === "pending") {
            errorMessage = "You must deploy the NFT collection contract first"
        } else if (NFTs.length === 0) {
            errorMessage = "You have no nfts to premint"
        } else if (!everyNftHasImage(NFTs)) {
            errorMessage = "All nfts must have images uploaded before preminting all"
        } else if (allNftsMintedOrPreminted(NFTs)) {
            errorMessage = "You have no more nfts to premint"
        }
        if (!errorMessage) return true
        setModalAlertMessage(errorMessage)
        setAlertColor("danger")
        return false
    }

    const processMintNFTErrors = (err) => {
        let errorMessage = String(err)
        if (err?.response?.data?.data?.error_message) {
            errorMessage = err?.response?.data?.data?.error_message // "Invalid contract type" error 500
        } else if (err?.response?.data?.error) {
            errorMessage = "Not enough credits for this method!" // err.response.data.code === 500
        } else if (err?.response?.data?.message === "Forbidden") {
            errorMessage = "Invalid API Key"
        }
        setAlertColor("danger")
        setModalAlertMessage(errorMessage)
    }

    // TO DO: unit test
    const isCollectionCapMet = () => {
        if (Number(campaignData.cap.S) === -1) return false // -1 === collection uncapped
        if (NFTs.length >= Number(campaignData.cap.S)) return true // exit if # of unique nfts >= collection cap

        // need to factor in weights of nfts, in addition to # of unique nfts
        // exit if new sumRandomnessWeights > collection cap
        return sumRandomnessWeights + 1 > Number(campaignData.cap.S) // 1 is minimum randomness_weight of adding new nft
    }

    const handleClickAddNFT = (e) => {
        if (isCollectionLocked) {
            setOkAlertMessage(collectionLockedMessage)
        } else if (isCollectionCapMet()) {
            setOkAlertMessage(
                "The cap for this collection has been met! If possible, delete an NFT or lower the randomnmess weight on an NFT to add another NFT"
            )
        } else {
            setShowEditNFTModal(true)
        }
    }

    const resetModalAlertData = () => {
        setDisableModalButtons(false)
        setModalAlertMessage("")
        setAlertColor("")
        setNft(null)
    }

    const checkPendingMint = async (tx_id) => {
        let finalUri = apiUrl + "?action=checkpending"
        let additionalParams = `&txid=${tx_id}`
        const response = await fetch(finalUri + String(additionalParams), {
            method: "GET",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
        })
        return await response.json()
    }

    const fetchNFTs = useCallback(async () => {
        try {
            setShowLoading(true)

            // in order to get an s3 image, we need to send to back end: 1) user_id, 2) module inserted, 3) nftcollection inserted
            let additionalParams = `&moduleinserted=${module.inserted.N}&nftcollection=${campaignData.inserted.N}`
            let finalUri = apiUrl + "?action=getusernfts"

            const res = await fetch(finalUri + String(additionalParams), {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
            })
            const response = await res.json()
            console.log("fetchNFTs", response) //=> {code:200, error:null, data:[{imgURL: "string", imgMetaData:{} }, {}, ...]}
            // response.data = array of objects (each referring to an NFT)

            for (let nft = 0; nft < response.data.length; nft++) {
                if (response.data[nft]?.imgMetaData?.tx_status === "false") {
                    // console.log("Run check transaction status")
                    const pendingResponse = await checkPendingMint(response.data[nft]?.imgMetaData?.tx_id)
                    // console.log("pendingResponse", pendingResponse)

                    if (!pendingResponse?.error && pendingResponse?.data?.tx_status?.S === "true" && pendingResponse?.data?.step?.S === "done") {
                        // console.log("Transaction is confirmed")
                        response.data[nft].imgMetaData.tx_status = "true"
                        response.data[nft].imgMetaData.step = "done"

                        // update s3 Metaadata
                        const updateResponse = await updateS3Metadata(response.data[nft])
                        console.log("updateResponse", updateResponse)
                    }
                }
            }

            // server ok, but no images
            if (response.error === "No images found") {
                setShowLoading(false)
                setNFTs([])
                return

                // problem from server fetching images
            } else if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                setAlertMessage("Error: " + (response.error || response?.data?.error))
                setNFTs(null)

                // successfully fetched nft data
            } else {
                setNFTs(response.data) // array of objects
            }
        } catch (err) {
            console.log(err)
            setAlertMessage("Error: " + String(err))
        }

        setShowLoading(false)
    }, [campaignData.inserted.N, module.inserted.N])

    // runs after everytime NFTs changes
    useEffect(() => {
        let sumWeights = 0
        // loop through all NFTs and Sum randomness weights and up weights
        for (let i = 0; i < NFTs?.length; i++) {
            let nftItem = NFTs[i]
            sumWeights += Number(nftItem.imgMetaData.randomness_weight)
        }
        setSumRandomnessWeights(sumWeights)
    }, [NFTs])

    // runs once after initial mount
    useEffect(() => {
        fetchNFTs()
        // FOR TESTING
        // const apiKey = decoded.api_key
        // const from = "1641081600000" // jan1 2022
        // const to = "1658244508532" // jul19 11:28am
        // await getTransactions(apiKey, "fantom", from, to)
    }, [fetchNFTs])

    // for table row
    const renderAttributes = (attributes) => {
        // attributes === stringified array of objects. [{"trait_type": "color", "value": "blue"}, {"trait_type": "location", "value": "italy"}]
        if (attributes === undefined) return null
        attributes = JSON.parse(attributes)
        return (
            <div className="attributes-inner">
                {attributes.map((attribute, idx) => {
                    return (
                        <div key={idx}>
                            {attribute.trait_type} : {attribute.value}
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderNFTs = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"NFTs"} />
        }

        if (NFTs === null || NFTs === undefined) {
            return <LoadingCard errorTxt={"NFTs"} alertMessage={alertMessage} />
        }

        if (NFTs.length === 0) {
            // NOTE* UploadFile is a Dropzone Wrapper. Currently we can not drop files into browser to upload
            // return <UploadFile files={uploadFiles} handleChange={setUploadFiles} handleSaveNFT={handleSaveNFT} />
            return <LoadingCard emptyTxt={"NFTs"} icon="bx-image-add" onClick={() => setShowEditNFTModal(true)} className="add-nft" />
        }

        return (
            <div className="overflow-auto">
                <Table className="nfts-table align-middle mb-0 table-nowrap">
                    <thead>
                        <tr>
                            <th scope="col" className="nft-name">
                                Name
                            </th>
                            <th scope="col" className="nft-description">
                                Description
                            </th>
                            <th scope="col">Image</th>
                            <th scope="col" className="nft-randomness">
                                Randomness
                            </th>
                            <th scope="col" className="nft-attributes">
                                Attributes
                            </th>
                            <th scope="col" className="nft-premint"></th>
                            <th scope="col" className="nft-status"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {NFTs?.slice().map((nft, idx) => {
                            let nftStatus // undefined, "preminted", "false", "pending", or "done"
                            nft.imgMetaData?.tx_status === "true"
                                ? (nftStatus = "done")
                                : nft.imgMetaData?.tx_status === "false"
                                  ? (nftStatus = "pending")
                                  : (nftStatus = nft.imgMetaData?.tx_status) // NOTE* "false" === "pending"

                            return (
                                <tr
                                    key={idx}
                                    className={"nft-row" + (showNFTRowColor ? " show-background" : "")}
                                    onClick={(e) => {
                                        if (isCollectionLocked) {
                                            setOkAlertMessage(collectionLockedMessage)
                                            return
                                        }
                                        setNft(nft)
                                        setShowEditNFTModal(true)
                                    }}
                                    onMouseEnter={() => setShowNFTRowColor(true)}
                                    onMouseLeave={() => setShowNFTRowColor(false)}
                                >
                                    <td className="name">
                                        <span className="name-val">{nft.imgMetaData.name}</span>
                                        <div className="mobile-ui-description">{nft.imgMetaData.description}</div>
                                    </td>
                                    <td className="description-row">{nft.imgMetaData.description}</td>
                                    <td className="image">
                                        {/* TO DO: show loading animation for nft images/videos */}
                                        {/* if user hasn't uploaded an image, show default upload image icon */}
                                        {/* {nft.imgData === "" || nft.imgData === "W29iamVjdCBPYmplY3Rd" ? ( // "W29iamVjdCBPYmplY3Rd" ~= null. OLD way of getting image (from lambda) */}
                                        {nft.contentLength === 0 ? ( // contentLength = byte size of img body
                                            <div className="empty-photo">
                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {nft?.imgMetaData?.content_type === "video/mp4" ||
                                                nft?.imgMetaData?.content_type === "video/quicktime" ? (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedImage(nft)
                                                        }}
                                                        onMouseEnter={() => setShowNFTRowColor(false)}
                                                        onMouseLeave={() => setShowNFTRowColor(true)}
                                                    >
                                                        <Player
                                                            src={`${JSON.parse(nft?.imgURL || "").url}`}
                                                            playsInline
                                                            controls={false}
                                                            autoPlay={true}
                                                            loop={true}
                                                            className="nft-table-video-preview"
                                                        />
                                                    </span>
                                                ) : (
                                                    <img
                                                        alt="imgdata"
                                                        // NEW WAY- get image directly from s3 with presigned url
                                                        src={`${JSON.parse(nft?.imgURL || "").url}`}
                                                        // OLD WAY- get image using fetch
                                                        // src={`data:image/jpeg;base64,${nft.imgData}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedImage(nft)
                                                        }}
                                                        onMouseEnter={() => setShowNFTRowColor(false)}
                                                        onMouseLeave={() => setShowNFTRowColor(true)}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className="randomness">{nft.imgMetaData.randomness_weight}</td>
                                    <td className="attributes">{renderAttributes(nft?.imgMetaData?.attributes)}</td>
                                    <td className="premint-row">
                                        {nftStatus === "premint" ? (
                                            <span className="nft-pending-or-minted">PREMINTED</span>
                                        ) : (
                                            <Button
                                                color="secondary"
                                                outline
                                                className={"premint-nft-btn waves-effect waves-light " + nftStatus}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setNft(nft)
                                                    setShowPreMintNFTModal(true)
                                                }}
                                                onMouseEnter={() => setShowNFTRowColor(false)}
                                                onMouseLeave={() => setShowNFTRowColor(true)}
                                            >
                                                PREMINT
                                            </Button>
                                        )}
                                    </td>
                                    <td className="status">
                                        {nftStatus === "pending" || nftStatus === "done" ? (
                                            <span className="nft-pending-or-minted">{nftStatus === "pending" ? "PENDING" : "MINTED"}</span>
                                        ) : (
                                            <React.Fragment>
                                                <Button
                                                    color="secondary"
                                                    outline
                                                    className={"mint-nft-btn waves-effect waves-light " + nftStatus}
                                                    onClick={(e) => {
                                                        e.stopPropagation() // prevents event from bubbling up- stops parent click handler from being run (prevents edit nft modal from opening)
                                                        setNft(nft)
                                                        setShowMintNFTModal(true)
                                                        setAddressToMintTo("") // reset form data on open
                                                        setUseOperatorAddress(true) // default true
                                                    }}
                                                    onMouseEnter={() => setShowNFTRowColor(false)}
                                                    onMouseLeave={() => setShowNFTRowColor(true)}
                                                >
                                                    MINT NFT
                                                </Button>
                                                <i
                                                    className={"bx bx-trash delete-nft " + (nftStatus === "premint" ? "hide" : "")}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (isCollectionLocked) return setOkAlertMessage(collectionLockedMessage)
                                                        setNft(nft)
                                                        setShowDeleteNFTModal(true)
                                                    }}
                                                    onMouseEnter={() => setShowNFTRowColor(false)}
                                                    onMouseLeave={() => setShowNFTRowColor(true)}
                                                ></i>
                                            </React.Fragment>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className="nft-collection-page">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, `NFT ${showNFTCollectionOnly ? "Collection" : "Marketing"}`, "Collection Details"]}
                links={["projects", "projects", "projects"]}
                linkFunctions={[clickProjects, clickProject, clickCancel]}
            />
            <div className="nft-marketing-row back-to-projects">
                <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn">
                    Back to Module
                </Button>
            </div>

            <Card>
                <CardBody>
                    <CardTitle className="h3">NFT Collection Details: {campaignData.collection_name.S}</CardTitle>

                    <div className="collection-summary">
                        <div className="chain-symbol-wrap">
                            <div className="collection-chain item">
                                <img src={networkIcons[campaignData?.chain.S]} alt={campaignData?.chain.S} className="nftcollection-chain-logo" />
                                <div className="chain-right">
                                    <div className="collection-label">CHAIN</div>
                                    <div className="value">{campaignData.chain.S}</div>
                                </div>
                            </div>
                            <div className="collection-symbol item">
                                <div className="collection-label">SYMBOL</div>
                                <div className="value">{campaignData.symbol.S}</div>
                            </div>
                        </div>

                        <div className="description-cap-wrap">
                            <div className="collection-description item">
                                <div className="collection-label">DESCRIPTION</div>
                                <div className="value">{campaignData.description.S}</div>
                            </div>
                            <div className="collection-cap item">
                                <div className="collection-label">CAP</div>
                                <div className="value">{campaignData?.cap?.S === "-1" ? "none" : campaignData?.cap?.S}</div>
                            </div>
                        </div>
                    </div>

                    <div className="contract-info contract-and-storage-type-wrap">
                        <div className="collection-type">
                            {/* Ex. "standard", "royalty", "soulbound", ... */}
                            <span className="collection-label">Contract Type:</span>
                            <span className="collection-value type">{campaignData.contract_type.S}</span>
                        </div>
                        <div>
                            {/* Ex. "arweave", "filecoin" */}
                            <span className="collection-label">Metadata Storage:</span>
                            <span className="collection-value type">{campaignData?.metadata_storage?.S || "Arweave"}</span>
                        </div>
                    </div>

                    <div className="contract-info collection-status">
                        <span className="collection-label">Contract Status:</span>
                        <span className="collection-value step">{campaignData.source.S}</span>
                    </div>

                    <div className="contract-info collection-contract-address">
                        <span className="collection-label" title="">
                            Contract Address:
                        </span>

                        <span className="collection-value address">
                            {campaignData?.address?.S ? (
                                <React.Fragment>
                                    <span className="contract-address">{formatAddress(campaignData?.address?.S?.toLowerCase())}</span>
                                    <Tooltip title="Copy contract address to clipboard" placement="bottom">
                                        <i className="bx bx-copy font-size-20" onClick={(e) => handleCopyClick(e, campaignData?.address?.S)} />
                                    </Tooltip>
                                </React.Fragment>
                            ) : (
                                <span className="not-deployed">Collection Not Deployed</span>
                            )}
                        </span>
                    </div>

                    {campaignData?.address?.S && (
                        <div className="contract-info collection-owner">
                            <span className="collection-label" title="">
                                Owner Address:
                            </span>
                            <span className="collection-value address">
                                <span className="contract-address">{formatAddress(campaignData?.contract_owner?.S?.toLowerCase())}</span>
                                <Tooltip title="Copy owner address to clipboard" placement="bottom">
                                    <i className="bx bx-copy font-size-20" onClick={(e) => handleCopyClick(e, campaignData?.contract_owner?.S)} />
                                </Tooltip>
                            </span>
                        </div>
                    )}

                    <div className="bulk-nft-row">
                        {isCollectionLocked ? (
                            <Button color="primary" disabled outline className="mb-3">
                                Collection Locked for Premint
                            </Button>
                        ) : (
                            <Button color="primary" className="mb-3" outline onClick={() => setShowLockCollectionModal(true)}>
                                Lock Collection for Premint
                            </Button>
                        )}

                        <Button color="primary" outline className="mb-3 bulk-upload-btn" onClick={() => setShowBulkUploadModal(true)}>
                            BULK UPLOAD
                        </Button>
                    </div>
                    <div className="add-nft-row">
                        <Button color="primary" outline className="premint-all-btn" onClick={() => setShowPreMintAllNFTModal(true)}>
                            PREMINT ALL
                        </Button>
                        {/* <UploadFile
                            files={uploadFiles}
                            handleChange={setUploadFiles}
                            buttonTxt={"ADD NFT"}
                            handleSaveNFT={handleSaveNFT}
                            setShowSaveNFTLoading={setShowSaveNFTLoading}
                            modalAlertMessage={modalAlertMessage}
                            alertColor={alertColor}
                        /> */}
                        {/* <Button color="primary" onClick={() => setShowEditNFTModal(true)}> */}
                        <Button color="primary" onClick={handleClickAddNFT}>
                            ADD NFT
                        </Button>
                    </div>

                    <div className="collection-label nfts">NFTS</div>

                    {renderNFTs()}
                </CardBody>
            </Card>

            {/* SELECTED IMAGE EXPANDED MODAL */}
            {selectedImage && (
                <Modal
                    className="enlarge-image-modal"
                    isOpen={selectedImage}
                    onClose={() => setSelectedImage(null)}
                    fade={true}
                    centered={true}
                    toggle={() => setSelectedImage(null)}
                >
                    <ModalHeader toggle={() => setSelectedImage(null)}>NFT Image</ModalHeader>
                    <ModalBody>
                        <React.Fragment>
                            {selectedImage?.imgMetaData?.content_type === "video/mp4" ||
                            selectedImage?.imgMetaData?.content_type === "video/quicktime" ? (
                                <Player
                                    src={`${JSON.parse(selectedImage?.imgURL || "").url}`}
                                    playsInline
                                    controls={false}
                                    autoPlay={true}
                                    loop={true}
                                    className="enlarge-nft-video-preview"
                                />
                            ) : (
                                // NOTE* src url expires in 5min
                                <img alt="imgdata" src={`${JSON.parse(selectedImage?.imgURL || "").url}`} className="enlarge-image-modal-img" />
                            )}
                        </React.Fragment>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={() => setSelectedImage(null)} className="btn-light waves-effect ok-btn">
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            )}

            {/* MINT NFT MODAL */}
            {showMintNFTModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showMintNFTModal}
                    setShowConfirmSubmitModal={setShowMintNFTModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    handleClickConfirm={mintNFT}
                    modalTitle={"Mint NFT"}
                    modalText={"Minting an NFT will use 1 method credit. Are you sure you want to mint this NFT?"}
                    confirmButtonText={"MINT NFT"}
                    confirmButtonLoading={"MINTING"}
                    handleCloseModal={() => setNft(null)}
                >
                    <div className="mint-nft-modal-body">
                        <div className="nft-name-confirm-submit mb-3">
                            NFT Name: <span>{nft.imgMetaData.name}</span>
                        </div>
                        <div className="nft-name-confirm-submit mb-3">
                            Method Credit Name: <span>{determineMintMethodName()}</span>
                        </div>
                        <AvForm
                            initialValues={{
                                "mint-to-address": addressToMintTo
                            }}
                            enableReinitialize
                            validationSchema={yup.object().shape({
                                "mint-to-address": yup.string().required("This field is required.")
                            })}
                        >
                            <FormGroup className="mb-2 mintto-address-wrap">
                                <AvField
                                    name="mint-to-address"
                                    label="Mint To Address"
                                    placeholder={useOperatorAddress ? "Not required if using operator address" : "Enter Mint To Address"}
                                    type="text"
                                    className="form-control"
                                    id="nft-mintto-address"
                                    value={addressToMintTo}
                                    required={!useOperatorAddress}
                                    disabled={showConfirmLoading || disableModalButtons || useOperatorAddress}
                                    onChange={(e) => setAddressToMintTo(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup check inline className="mb-2 use-operator-checkbox">
                                <Input
                                    name="Use Operator Address"
                                    type="checkbox"
                                    id="use-operator-address"
                                    checked={useOperatorAddress}
                                    disabled={showConfirmLoading || disableModalButtons}
                                    onChange={handleClickUseOperator}
                                />
                                <Label check for="use-operator-address">
                                    Use Operator Address
                                </Label>
                            </FormGroup>
                        </AvForm>
                    </div>
                </ConfirmSubmitModal>
            )}

            {/* PREMINT MODAL */}
            {showPreMintNFTModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showPreMintNFTModal}
                    setShowConfirmSubmitModal={setShowPreMintNFTModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    handleClickConfirm={preMintNFT}
                    modalTitle={"Pre Mint NFT"}
                    modalText={
                        "Pre Minting an NFT is free and uploads the image and metadata to " +
                        (capitalizeFirstLetter(campaignData?.metadata_storage?.S) || "Arweave")
                    }
                    confirmButtonText={"PRE MINT"}
                    confirmButtonLoading={"PREMINTING"}
                    handleCloseModal={() => setNft(null)}
                >
                    <div className="mt-2 nft-name-confirm-submit">
                        NFT Name: <span>{nft.imgMetaData.name}</span>
                    </div>
                </ConfirmSubmitModal>
            )}

            {/* PREMINT ALL MODAL */}
            {showPreMintAllNFTModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showPreMintAllNFTModal}
                    setShowConfirmSubmitModal={setShowPreMintAllNFTModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    handleClickConfirm={preMintAll}
                    modalTitle={"Pre Mint All NFTs"}
                    modalText={"Pre Minting NFTs are free and uploads the images and metadata to Arweave"}
                    confirmButtonText={"PRE MINT ALL"}
                    confirmButtonLoading={"PREMINTING"}
                />
            )}

            {/* ADD OR EDIT NFT MODAL */}
            {showEditNFTModal && (
                <EditNFTModal
                    showConfirmSubmitModal={showEditNFTModal}
                    setShowConfirmSubmitModal={setShowEditNFTModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    nft={nft}
                    setNft={setNft}
                    handleSubmit={handleSaveNFT}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    disableAllButtons={disableModalButtons}
                    campaignData={campaignData}
                    sumRandomnessWeights={sumRandomnessWeights} // sum of all NFT's randomness_weight
                    storage={campaignData?.metadata_storage?.S || "Arweave"}
                />
            )}

            {/* BULK UPLOAD NFTS MODAL */}
            {showBulkUploadModal && (
                <BulkUploadModal
                    showBulkUploadModal={showBulkUploadModal}
                    setShowBulkUploadModal={setShowBulkUploadModal}
                    module={module}
                    campaignData={campaignData}
                    NFTs={NFTs}
                    fetchNFTs={fetchNFTs}
                />
            )}

            {/* DELETE NFT MODAL (only if nft has not been preminted or minted) */}
            {showDeleteNFTModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showDeleteNFTModal}
                    setShowConfirmSubmitModal={setShowDeleteNFTModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    handleClickConfirm={handleClickDeleteNFT}
                    modalTitle={"Delete Draft NFT"}
                    modalText={"Are you sure you want to delete this NFT draft? (Note* once you premint or mint an NFT, the NFT cannot be deleted)"}
                    confirmButtonText={"DELETE NFT"}
                    confirmButtonLoading={"DELETING"}
                    handleCloseModal={() => setNft(null)}
                >
                    <div className="mt-2 nft-name-confirm-submit">
                        NFT Name: <span>{nft.imgMetaData.name}</span>
                    </div>
                </ConfirmSubmitModal>
            )}

            {/* LOCK COLLECTION MODAL */}
            {showLockCollectionModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showLockCollectionModal}
                    setShowConfirmSubmitModal={setShowLockCollectionModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete/Lock modals
                    handleClickConfirm={handleClickLockCollection}
                    modalTitle={"Lock Collection for Premint"}
                    modalText={"Are you sure you want to lock this NFT collection for premint? Locking prevents editing/adding any NFTS"}
                    confirmButtonText={"LOCK COLLECTION"}
                    confirmButtonLoading={"LOCKING"}
                ></ConfirmSubmitModal>
            )}

            <OkDialog
                open={!!okAlertMessage}
                message={okAlertMessage}
                okClick={() => {
                    setOkAlertMessage("")
                }}
            />
        </div>
    )
}

export default NFTCollectionPage
