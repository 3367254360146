import React, { Component } from "react"
import { Button, Alert, Card, CardBody } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { Link } from "react-router-dom"
import { Logo } from "./Fragments"
import password from "../common/password"
import SpinnerWrap from "../Components/SpinnerWrap"

const apiUrl = "https://register.idexo.io/"

class Register extends Component {
    constructor(props) {
        super(props)
        this.email = ""
        this.referral = ""
        const params = window.location.search.replace("?", "").split("&")
        const email = params.filter((x) => x.startsWith("email="))
        if (email.length === 1) this.email = email[0].replace("email=", "")
        const referral = params.filter((x) => x.startsWith("referral="))
        if (referral.length === 1) this.referral = referral[0].replace("referral=", "")
        this.state = { email: this.email, password: "" }
        this.registerUser = this.registerUser.bind(this)
    }

    registerUser(event) {
        let message = password.verify(event)
        if (message) return this.setState({ alertMessage: message, alertColor: "danger", showSpinner: false })
        this.setState({ alertMessage: "", alertColor: "danger", showSpinner: true })
        return fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: event.email,
                password: event.password,
                referral: event.referral,
                type: "new"
            }),
            json: true
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    this.setState({ alertMessage: "Error: " + data.error, alertColor: "danger", showSpinner: false })
                } else {
                    this.setState({
                        alertMessage: "Registration submitted. Check your email for further instructions.",
                        alertColor: "success",
                        showSpinner: false
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages">
                    <Card>
                        <CardBody>
                            <Logo></Logo>
                            <AvForm
                                className="form needs-validation"
                                initialValues={{ email: this.email, password: "", referral: this.referral }}
                                onSubmit={this.registerUser}
                                validationSchema={password.schema}
                                autoComplete="off"
                            >
                                {this.state.alertMessage ? <Alert color={this.state.alertColor}>{this.state.alertMessage}</Alert> : null}

                                <div className="form-item">
                                    <AvField
                                        name="email"
                                        label="Email"
                                        className="form-control"
                                        placeholder="Email"
                                        type="email"
                                        required
                                        autoComplete="new-password"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>

                                <div className="form-item">
                                    <AvField
                                        name="password"
                                        label="Password"
                                        type="password"
                                        required
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>

                                <div className="form-item">
                                    <AvField
                                        name="confirmpassword"
                                        label="Confirm Password"
                                        type="password"
                                        required
                                        placeholder="Confirm Password"
                                        autoComplete="off"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>

                                <div className="form-item">
                                    <AvField
                                        name="referral"
                                        label="Referral Code"
                                        type="text"
                                        placeholder="Referral Code"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>

                                <div className="form-button d-grid">
                                    <Button
                                        className="btn btn-primary btn-block waves-effect waves-light text-uppercase"
                                        type="submit"
                                        color="primary"
                                        disabled={this.state.showSpinner}
                                    >
                                        <SpinnerWrap showSpinner={this.state.showSpinner} txtTrue={"Registering"} txtFalse={"Register"} />
                                    </Button>
                                </div>

                                <div className="form-link text-center">
                                    <Link to="#" className="text-primary text-uppercase">
                                        Terms of Use
                                    </Link>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <div className="more-link text-center text-uppercase">
                        Already have an account ?
                        <Link to="/login" className=" mx-3 fw-medium text-primary">
                            Login
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Register
