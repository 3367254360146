import React, { useEffect, useState } from "react"
import { ModalBody, Table, Input, Alert } from "reactstrap"
import { getParsedFunctions } from "../../abi/abiParser"
import { useConstructor } from "../../hooks/useConstructor"

const MethodsTab = (props) => {
    const {
        abi = "[]"
        // methodsList = [] // list from super's parsing method
    } = props

    const [allMethods, setAllMethods] = useState([]) // all methods
    const [selectedMethods, setSelectedMethods] = useState(allMethods)
    const [searchKeyword, setSearchKeyword] = useState("")
    const [errorMessage, setErrorMessage] = useState("") // error if can't parse ABI

    // custom hook- this emulates a constructor function from classical components
    // callback in useConstructor params is executed only ONCE, before initial render
    useConstructor(() => {
        try {
            setAllMethods(getParsedFunctions(JSON.parse(abi))) // setAllMethods- github actions will error out if we don't use all variables/functions
        } catch (er) {
            setErrorMessage("There was an error parsing your ABI:" + String(er))
        }
    })

    useEffect(() => {
        setSelectedMethods(() => {
            // return allMethods.filter((method) => method?.name?.toLowerCase().includes(searchKeyword.toLowerCase())) // searches only method name and excludes constructor
            return allMethods.filter((method) => method?.nameAndInputs?.toLowerCase().includes(searchKeyword.toLowerCase())) // searches all methods and their inputs
        })
    }, [allMethods, searchKeyword])

    const renderMethodsTable = () => {
        return (
            <Table className="methods-table align-middle mb-0">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        {/* <th scope="col">Inputs</th> */}
                        <th scope="col">Output</th>
                        <th scope="col" className="th-read-write">
                            Read/Write
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {selectedMethods?.slice().map((method, idx) => {
                        return (
                            <tr key={idx} className="methods-row">
                                <td className="name">
                                    {method.type === "constructor"
                                        ? "constructor(" + method.inputs.map((input) => input.internalType).join(",") + ")"
                                        : method.inputs.length === 0
                                          ? method.name
                                          : method.nameAndInputs}
                                </td>
                                {/* <td className="inputs">
                                    {method?.inputs?.map((input) => (
                                        <div>{input.type}</div>
                                    ))}
                                </td> */}
                                <td className="outputs">
                                    {method?.outputs?.map((output) => (
                                        <div key={"output" + idx}>{output.type}</div>
                                    ))}
                                </td>
                                <td className="read-write">{method.stateMutability === "view" ? "read" : "write"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    return (
        <ModalBody className="overflow-auto">
            {/* <FormGroup className="mb-3">
                <MethodsSelect
                    label="Select Method"
                    id="method-select"
                    name="method-select"
                    placeholder="Select Method"
                    methods={methodsList}
                    selectedMethods={selectedMethods}
                    errorMessage="Required!"
                    required
                    // disabled={showSpinner}
                    handleChange={(value) => setSelectedMethods(value)}
                />
            </FormGroup> */}
            <Input
                type="search"
                placeholder="Search Methods..."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="search-methods"
            />
            {renderMethodsTable()}
            <Alert color={"danger"} toggle={() => setErrorMessage("")} isOpen={!!errorMessage} className="methods-tab-alert">
                {errorMessage}
            </Alert>
        </ModalBody>
    )
}

export default MethodsTab
