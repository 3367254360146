import React, { Component } from "react"
import { Button } from "reactstrap"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import "./okDialog.scss"

class OkDialog extends Component {
    constructor(props) {
        localStorage.setItem("lastActive", new Date().getTime())
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="ok-dialog-wrap">
                    <Dialog open={this.props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="btn-light btn-outline-secondary" onClick={this.props.okClick}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </React.Fragment>
        )
    }
}

export default OkDialog
