import React from "react"
import { Card, CardBody, Spinner, Alert } from "reactstrap"
import "./loadingCard.scss"

// This component is used for 1) LOADING animation, 2) a resource (ex. smart contract) is EMPTY, or 3) to show ERRORS after fetching
// This component is used in Projects, ProjectView, Contracts, and Addresses Pages
const LoadingCard = ({ loadingTxt, errorTxt, emptyTxt, alertMessage, backgroundColor = "grey", icon, onClick, className }) => {
    return (
        <Card className={`loading-wrap ${backgroundColor} ${className}`} onClick={onClick}>
            <CardBody className="loading-card-body">
                {/* only display spinner if loadingTxt exists/passed from props */}
                {loadingTxt && <Spinner className="spinner-animation" />}

                {errorTxt && <i className="bx bx-error"></i>}

                {emptyTxt && <i className={"bx " + icon}></i>}

                <div className="card-txt">
                    {loadingTxt && <div>Loading {loadingTxt}...</div>}

                    {errorTxt && (
                        <React.Fragment>
                            <div>There was an error retrieving your {errorTxt}</div>
                            <Alert color="danger" id="loading-card-alert">
                                {alertMessage}
                            </Alert>
                        </React.Fragment>
                    )}

                    {emptyTxt && (
                        <React.Fragment>
                            <div>You have no {emptyTxt}</div>
                            <div>Add one to get started!</div>
                        </React.Fragment>
                    )}
                </div>
            </CardBody>
        </Card>
    )
}

export default LoadingCard
