import React, { useState } from "react"
import { Collapse, Button } from "reactstrap"
import "./CollapseDropdownItem.scss"

const CollapseDropdownItem = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    return (
        <div className="collapse-dropdown-item">
            <div className="item-summary-wrap" onClick={toggle}>
                <div className="item-summary" onClick={toggle}>
                    {item.question}
                </div>
                <Button onClick={toggle} className="collapse-dropdown-btn">
                    {isOpen ? <i className="bx bxs-down-arrow"></i> : <i className="bx bxs-up-arrow"></i>}
                </Button>
            </div>
            <Collapse isOpen={isOpen} className="item-details">
                {item.answer}
            </Collapse>
        </div>
    )
}

export default CollapseDropdownItem
