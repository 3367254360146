import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardTitle } from "reactstrap"
import idexoLogoLargeWhite from "../assets/images/Logo light.png"
// import videoThumbnail from "../assets/images/videothumbnail 1.png"
// import PricingDesign16 from "../Components/Pricing/PricingDesign16"
import Logo from "../Layouts/Logo"
import PricingDesign15 from "../Components/Pricing/PricingDesign15"
import CollapseDropdownItem from "../Components/CollapseDropdownItem/CollapseDropdownItem"

const FAQ_DATA = [
    {
        id: 0,
        question: "Is it possible to pay for plans using other payment methods (credit card, wire transfer etc.)?",
        answer: "Yes, please schedule a call with our team and we will help you with that."
    },
    {
        id: 1,
        question: "How do I set a password on my account and access the dashboard?",
        answer: "After you pay the fee for either the 14-day trial or one of the plans, you will be redirected to a registration form to set your password and then you can login."
    },
    {
        id: 2,
        question: "How can I contact the team for more information?",
        answer: "You received an email when you signed up on the website with information on scheduling a call. We look forward to talking with you."
    }
]

const Pricing = () => {
    const [email, setEmail] = useState("") // user email from idexo.com "get started" button/form
    console.log("email", email)

    // render canvas- orange play video button
    // cb runs once after initial mount/render
    useEffect(() => {
        let urlParam = window.location.search
        let userEmail = urlParam.split("=")[1]
        setEmail(userEmail)
        window.history.replaceState(null, null, "/pricing") // hide email in browser url from user

        const canvas = document.getElementById("canvas-play-btn")
        const ctx = canvas.getContext("2d")
        canvas.width = canvas.height = 96 // (96px == 6rem) this is NOT the same as css style width/height
        ctx.translate(48, 48) // move to x, y coordinates at center of canvas
        ctx.beginPath()
        const size = 30 // length of half of triangle height
        let angle = 90
        for (let j = 0; j < 4; j++) {
            const a = (angle * Math.PI) / 180
            const x = size * Math.sin(a)
            const y = size * Math.cos(a)
            ctx.lineTo(x, y)
            angle += 120
        }
        ctx.strokeStyle = "white"
        ctx.fillStyle = "white" // triangle inside color
        ctx.stroke() // draw black path line/curve of triangle
        ctx.fill()
        ctx.beginPath()
        ctx.arc(0, 0, 48, 0, 8) // x, y, radius ,starting angle, ending angle (radians)
    }, [])

    // This should be a backend function so we can put our api key here safely
    // 1) user clicks "start 14 day trial"
    // 2) frontend makes request to backend
    // 3) backend creates checkout charge POST /checkouts, and returns charge object (expires in 1hr)
    // 4) frontend takes charge url from response charge object and displays to user
    // 5) user clicks charge url, and is redirected to pay via coinbase
    // 6) backend webhooks
    // const onClick14DayTrial = async () => {
    //     try {
    //         const coinbaseCommerceURL = "https://api.commerce.coinbase.com/checkouts"
    //         // returns created checkout object (has id in response)
    //         const res = await fetch(coinbaseCommerceURL, {
    //             method: "POST",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "X-CC-Api-Key": "null"
    //             },
    //             body: JSON.stringify({
    //                 name: "Idexo Test",
    //                 description: "$50 for 14-day Trial of Full idexo System With 100 Included Transaction Credits",
    //                 requested_info: ['"email"'],
    //                 pricing_type: "fixed_price",
    //                 local_price: { amount: 50, currency: "$" }
    //             })
    //         })
    //         console.log("response", res)
    //         const response = await res.json()
    //         console.log("onClick14DayTrial", response)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    return (
        <div className="pricing-page">
            <section className="pricing-nav-bar">
                <Logo />
            </section>

            <section className="pricing-main">
                <section className="section-1-welcome">
                    {/* DESIGN 16 (no big orange play button) */}
                    {/* <div className="video-wrap design16">
                        <div className="video-area">
                            <img className="video-thumbnail" src={idexoLogoLargeWhite} alt="video thubmnail" />
                            <div className="getting-started">Getting started with idexo</div>
                        </div>
                    </div> */}

                    {/* DESIGN 15 */}
                    <div className="video-wrap design15">
                        <div className="video-section-design15">
                            <div className="video-area design15">
                                <div className="video-text-thumbnail">
                                    <img className="video-thumbnail" src={idexoLogoLargeWhite} alt="video thubmnail" />
                                    <div className="getting-started">Getting started with idexo</div>
                                </div>
                            </div>
                            <Button className="btn-design15-playvideo">
                                <canvas id="canvas-play-btn"></canvas>
                            </Button>
                        </div>
                    </div>
                    {/* DESIGN 15 */}

                    <div className="about-wrap">
                        <div className="about-summary">
                            We help innovators launch nft applications in minutes instead of months using no code and low code tools
                        </div>
                        <div className="about-details">
                            We focus on bringing you the technology so you can focus on how you build unique value with it. Watch the short demo video
                            and pick a $50 for 14-day trial or dedicated pricing plan
                        </div>
                        <div className="about-buttons">
                            <Button className="talk-to-us btn-font-bold" type="button">
                                TALK TO US
                            </Button>

                            <Button
                                className="start-trial btn-primary btn-font-bold"
                                href="https://commerce.coinbase.com/checkout/a35cb0bf-ac2c-470a-8b7b-d29ee0a52f40"
                                color="transparent"
                                target="_blank" // NOTE* all major browsers now automatically use rel="noopener noreferrer" with any target="_blank"
                                // data-custom="{email: 'm@idexo.com' }" // Not working
                                type="button"
                            >
                                START 14-DAY TRIAL
                            </Button>
                        </div>
                    </div>
                </section>

                <div className="pricing-title">Start your trial now or choose one of the below plans.</div>

                {/* <PricingDesign16 /> */}
                <PricingDesign15 />

                <section className="main-section-3">
                    <Card>
                        <CardBody>
                            <CardTitle className="h3">Frequently Asked Questions</CardTitle>
                            {FAQ_DATA.map((question, idx) => {
                                return <CollapseDropdownItem item={question} key={idx} />
                            })}
                        </CardBody>
                    </Card>
                </section>

                <Button
                    className="btn-primary btn-font-bold btn-width-100"
                    color="primary"
                    type="button"
                    href="https://commerce.coinbase.com/checkout/a35cb0bf-ac2c-470a-8b7b-d29ee0a52f40"
                    target="_blank"
                >
                    START 14-DAY TRIAL
                </Button>
            </section>
        </div>
    )
}

export default Pricing
