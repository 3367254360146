import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import { styled } from "@material-ui/core/styles"

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    "& .arrow": {
        color: theme.palette.common.black
    },
    "& .tooltip": {
        backgroundColor: theme.palette.common.black
    }
}))

export default CustomTooltip
