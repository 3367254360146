import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, FormGroup, Button, ModalFooter, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { allFormsValid } from "../../utils"

import SpinnerWrap from "../SpinnerWrap"

const apiUrl = "https://clients.idexo.io"

const AddClientModal = (props) => {
    const { open, onClose, onAddClient } = props
    const [company, setCompany] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [domain, setDomain] = useState("")
    const [slug, setSlug] = useState("")
    const [showSpinner, setShowSpinner] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    const handleSubmit = async () => {
        setShowSpinner(true)

        await createClient()
        setShowSpinner(false)
    }

    const createClient = async () => {
        try {
            const newClient = {
                email: company,
                domain,
                slug,
                company
            }

            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    ...newClient
                })
            })

            const response = await res.json()

            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                setAlertColor("danger")
            } else {
                setAlertMessage("Success! Redirecting you back to the clients page...")
                setAlertColor("success")
                setDisableModalButtons(true)
                onAddClient()
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={open} onClose={onClose} fade={true} centered={true}>
                <AvForm initialValues={{ company: "" }} onSubmit={() => handleSubmit()}>
                    <ModalHeader toggle={onClose} tag={"div"}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Add New Client</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                name="company"
                                label="Company"
                                className="form-control"
                                placeholder="Enter Company Name"
                                type="text"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="client-email"
                                label="Client Email"
                                className="form-control"
                                placeholder="Enter Client Email Address"
                                type="email"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="domain"
                                label="Domain"
                                className="form-control"
                                placeholder="Domain"
                                type="text"
                                value={domain}
                                onChange={(e) => setDomain(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="slug"
                                label="Slug"
                                className="form-control"
                                placeholder="app.idexo.com/slug/nftmarketing"
                                type="text"
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableModalButtons ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>
                        <Button
                            color="primary"
                            type="submit"
                            className="save-client-btn waves-effect"
                            disabled={showSpinner || !allFormsValid([company, domain, slug])}
                        >
                            <SpinnerWrap showSpinner={showSpinner} txtTrue={"Saving"} txtFalse={"Save"} />
                        </Button>
                        <Button color="secondary" className="btn-light waves-effect cancel-btn" onClick={onClose} disabled={showSpinner}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default AddClientModal
