import React, { useState } from "react"
import "./NFTMarketingModule.scss"
import BreadCrumb from "../BreadCrumb"
import SpinnerWrap from "../SpinnerWrap"
import { Button } from "reactstrap"
import jwt from "jsonwebtoken"
import NFTMarketing from "../../Pages/NFTMarketing"
import { deleteModule } from "../../utils"
import ConfirmSubmitModal from "../ConfirmSubmitModal/ConfirmSubmitModal"
import NFTCollectionPage from "../NFTCollection/NFTCollection"

const apiUrl = "https://projects.idexo.io/"

const NFTMarketingModule = ({ project, clickCancel, clickProjects, module, fetchModules, showNFTCollectionOnly }) => {
    const decoded = jwt.decode(localStorage.jwtToken)
    const [campaignInserted, setCampaignInserted] = useState(null) // used to find item in nftmarketing table (for deletion)

    // for delete nft marketing module modal
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteLoading, setShowDeleteLoading] = useState(false)
    const [disableDeleteButtons, setDisableDeleteButtons] = useState(false)
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error/success deleting module
    const [alertColor, setAlertColor] = useState("")

    const [showNFTCollectionPage, setShowNFTCollectionPage] = useState(false)

    const handleClickDeleteModule = async () => {
        setShowDeleteLoading(true)
        try {
            let newModules = project.modules.L.slice() // shallow copy (only has inserted, module_name, and type)
            newModules = deleteModule(newModules, {
                inserted: module.inserted.N
            })
            let finalUri = apiUrl + "?action=getuserprojects"
            if (localStorage.user_tenants && localStorage.selected_tenant) {
                const user_tenants = JSON.parse(localStorage.user_tenants)
                if (user_tenants.length > 0) finalUri = finalUri + `&tenant_id=${localStorage.selected_tenant}`
            }
            const res = await fetch(finalUri, {
                method: "DELETE",
                headers: { token: localStorage.jwtToken, "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "deleteNFTMarketingModule", // also deletes image from s3
                    project_name: project.project_name.S, // need this for updating projects table (primary key)
                    inserted: project.inserted.N, // time project was created (need for updating projects table, secondary key)
                    modules: newModules, // updated modules to replace in projects table
                    module_inserted: module.inserted.N, // deleted module inserted time- need this to find s3 image to delete
                    campaign_inserted: campaignInserted // object- need for updating nftmarketing table (secondary key)
                })
            })
            const response = await res.json()

            if (
                response.code !== 200 ||
                response.error || // server problem (ex. invalid action)
                response.message === "Internal server error" ||
                response.data.error || //problem updating projects table in dynamodb
                response.s3?.error || // problem deleting item in nftmarketing table dynamodb
                response.s3?.data?.error // problem deleting s3 image for nftmarketing
            ) {
                setModalAlertMessage("Error: " + (response.error || response.data.error || response?.s3?.error || response.s3?.data?.error))
                setAlertColor("danger")
                setShowDeleteLoading(false)
                return
            }

            // if succesful deletion, close delete modal in 3 seconds
            setModalAlertMessage("Successfully deleted module! Returning to project page...")
            setAlertColor("success")
            setDisableDeleteButtons(true)
            setTimeout(() => {
                setShowDeleteModal(false)
                setDisableDeleteButtons(false)
                clickCancel()
                fetchModules() // force table to refresh
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowDeleteLoading(false)
    }

    return (
        <div className="nft-marketing-module">
            {showNFTCollectionPage ? (
                <NFTCollectionPage
                    project={project}
                    module={module}
                    handleClickAddNFT={setShowNFTCollectionPage}
                    clickProjects={clickProjects}
                    clickProject={clickCancel}
                    clickCancel={() => setShowNFTCollectionPage(false)}
                    campaignData={showNFTCollectionPage}
                    showNFTCollectionOnly={showNFTCollectionOnly}
                    setCampaignData={setShowNFTCollectionPage}
                />
            ) : (
                <React.Fragment>
                    <BreadCrumb
                        items={["projects", `${project?.project_name?.S}`, `NFT ${showNFTCollectionOnly ? "Collection" : "Marketing"}`]}
                        links={["projects", "projects"]}
                        linkFunctions={[clickProjects, clickCancel]}
                    />
                    <div className="nft-marketing-row back-to-projects">
                        <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn" disabled={showDeleteLoading}>
                            Back to project
                        </Button>

                        <Button
                            className="delete-btn"
                            color="secondary"
                            onClick={() => {
                                setShowDeleteModal(true)
                                setModalAlertMessage("")
                            }}
                            id="delete-btn"
                            disabled={showDeleteLoading || disableDeleteButtons}
                        >
                            <SpinnerWrap showSpinner={showDeleteLoading} txtTrue={"DELETING"} txtFalse={"DELETE MODULE"} />
                        </Button>
                    </div>

                    <NFTMarketing
                        project={project}
                        module={module}
                        setCampaignInserted={setCampaignInserted}
                        showNFTCollectionOnly={showNFTCollectionOnly}
                        handleClickAddNFT={setShowNFTCollectionPage}
                    />

                    {/* DELETE MODULE MODAL */}
                    {showDeleteModal && (
                        <ConfirmSubmitModal
                            showConfirmSubmitModal={showDeleteModal}
                            setShowConfirmSubmitModal={setShowDeleteModal}
                            modalAlertMessage={modalAlertMessage}
                            setModalAlertMessage={setModalAlertMessage}
                            alertColor={alertColor}
                            setAlertColor={setAlertColor}
                            disableAllButtons={disableDeleteButtons}
                            showConfirmLoading={showDeleteLoading}
                            handleClickConfirm={handleClickDeleteModule}
                            modalTitle={`Delete NFT ${showNFTCollectionOnly ? "Collection" : "Marketing"} Module`}
                            modalText={
                                "Deleting a module deletes all of its contents and is not reversible. Are you sure you want to delete this module?"
                            }
                            confirmButtonText={"DELETE"}
                            confirmButtonLoading={"DELETING"}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default NFTMarketingModule
