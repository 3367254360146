import { ConnectionRejectedError } from "../error"

export default async function init() {
    const { ProvidedConnector, UserRejectedRequestError } = await import("@aragon/provided-connector")
    return {
        web3ReactConnector({ chainId, provider }) {
            return new ProvidedConnector({
                provider,
                supportedChainIds: [chainId]
            })
        },
        handleActivationError(err) {
            console.log("ConnectorProvided error:", err)
            return err instanceof UserRejectedRequestError ? new ConnectionRejectedError() : null
        }
    }
}
