import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label, Input } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import { Player } from "video-react"
import * as yup from "yup"

import { isValidFileType, toBase64, getImageInserted, formatAddress } from "../../utils"
import { useToast } from "../../hooks/toast"
import EditAttributes from "../EditAttributesModal/EditAttributes"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"
import "./editNFTModal.scss"

function initializeAttributes(nft) {
    let attributes = JSON.parse(nft?.imgMetaData?.attributes || "[]")
    if (attributes.length) {
        return attributes
    } else {
        return [{ trait_type: "", value: "" }] // this is wo we can display a non empty form
    }
}

// This component is also used as the ADD NFT Modal
const EditNFTModal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete", or "Mint NFT" Button
    handleSubmit,
    confirmButtonText,
    confirmButtonLoading,
    setNft,
    nft, // object from s3 referring to nft image, contains metadata/image string base64
    campaignData, // collection info- cap, name, description, chain, ...
    sumRandomnessWeights, // sum of all NFT's randomness_weight
    storage,
    ...rest
}) => {
    const closeAndResetModal = () => {
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        setNft(null)
    }
    const [name, setName] = useState(nft?.imgMetaData?.name || "")
    const [description, setDescription] = useState(nft?.imgMetaData?.description || "")
    const [randomness, setRandomness] = useState(nft?.imgMetaData?.randomness_weight || "") // stringified integer- ex. "1"
    const [img, setImg] = useState(nft || null) // image object
    const [attributesTabIsOpen, setAttributesTabIsOpen] = useState(false)
    const [attributes, setAttributes] = useState(() => initializeAttributes(nft)) // ex. [ {trait_type: "color", value: "gold"}, {trait_type:"animal", value:"dog"} ]
    const [jsonSyntaxError, setJsonSyntaxError] = useState("") // for attributes tab
    const nftIsPreminted = nft?.imgMetaData?.tx_status === "premint"
    const nftIsMinted = nft?.imgMetaData?.tx_status === "false" || nft?.imgMetaData?.tx_status === "done" || nft?.imgMetaData?.step === "pending"
    const [nftContentType, setNFTContentType] = useState(nft?.imgMetaData?.content_type || "") // ex. "video/mp4", "image/jpg"

    const toast = useToast()

    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`${formatAddress(address)} copied to clipboard.`)
    }

    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type // files[0] == JS Obj (blob datatype)

        if (!isValidFileType(fileType)) {
            setModalAlertMessage("You must select a valid file type: jpg, png, gif, mp4, or mov!")
            return setAlertColor("danger")
        }
        // don't let user submit file sizes larger than 15 MB for Arweave or 50 for Filecoin
        const maxsize = storage.toLowerCase() === "arweave" ? 15728640 : 52428800
        if (files[0].size >= maxsize) {
            // 15,728,640 bytes == 15 MB
            setModalAlertMessage("File size must be under 15 MB")
            return setAlertColor("danger")
        }

        // if (files[0].size >= 400000) { // 400k bytes. compress file- should there be size limit?
        //     files = [await compressFile(files[0])] // compressFile from utils.js
        // }
        // TO DO:
        // - find out why're we're converting the selected file (blob type) to base64 string, then back to blob type (on handleSubmit / upload to s3)
        // - streamline if possible

        // TO DO: - we may not need to do below
        // adds a key to blob where the value is a base64 string
        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setNFTContentType(fileType)
        // setImg(base64Files[0].base64) // string
        setImg(base64Files[0]) // imgFile JS Obj (blob datatype)
    }

    // TO DO: backend validation if new sumRandomnessWeights > cap
    const handleClickSubmit = async (e) => {
        let oldNft = nft ? nft : {} // {}, or else when adding a new nft, nft will be null and we'll get an error below

        let newAttributes = removeEmptyAttributes() // update state, attributes
        let action = "saveUserNFT" // backend action, updates image + metadata
        if (noImageChange()) action = "updateNFT" // update metadata only

        // shallow copy nft
        let newNFTData = Object.assign(oldNft, { imgMetaData: { randomness_weight: randomness } }) // we only need to update randomness
        setNft(newNFTData) // we need this or else we'll get an error in the modal on success if cap has been met

        handleSubmit(
            e,
            {
                name,
                description,
                randomness_weight: randomness,
                img: img,
                nftInserted: getImageInserted(nft?.imgKey),
                newAttributes,
                nftContentType
            },
            action
        )
    }

    // remove any attributes that have empty strings as trait_types or values
    const removeEmptyAttributes = (updateState = true) => {
        // ex1. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "","value": ""}] => [{"trait_type": "n1","value": "v1"}]
        // ex2. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "a","value": ""}] => [{"trait_type": "n1","value": "v1"}]
        // ex3. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "","value": "b"}] => [{"trait_type": "n1","value": "v1"}]
        let newAttributes = attributes.slice()
        newAttributes = newAttributes.filter((attr) => {
            return attr.trait_type !== "" && attr.value !== ""
        })
        if (updateState) setAttributes(newAttributes)
        return newAttributes
    }

    const isCollectionCapMet = (newRandomnessWeight) => {
        if (campaignData.cap.S === "-1") return false // -1 == collection is uncapped
        let oldRandomnessWeight = nft ? Number(nft.imgMetaData.randomness_weight) : 0 // if nft null, that means we clicked on the "ADD NFT" button, not the "edit" nft row
        let newSumRandomnessWeights = Number(sumRandomnessWeights) - oldRandomnessWeight + newRandomnessWeight

        // new sum of randomness weights can't be > collection cap
        return newSumRandomnessWeights > Number(campaignData.cap.S)
    }

    // returns boolean, true if on selecting image, the selected image is the same as the old image previously uploaded
    const noImageChange = () => {
        // Note* if nft === null, that means we're adding a new nft, not editing an nft
        return !!img?.imgURL // no image change if img from props has an attribute called "imgURL"
    }

    // returns boolean, true if on editing attributes, the new attributes data is the same as the old attributes data
    const noAttributesChange = () => {
        let oldAttributes = JSON.parse(nft?.imgMetaData?.attributes || "[]") // attributes from s3. ex = [{"trait_type": "color", "value": "green"}]

        // edge case if nft only has 1 attribute and user deletes it, we should update nft
        if (oldAttributes.length === 1 && attributes.length === 0) return false
        let newAttributes = removeEmptyAttributes(false) // should not mutate input attributes (currently it is mutating attributes)

        // loop through attributes in form
        for (let i = 0; i < newAttributes.length; i++) {
            let attrib = newAttributes[i]
            if (attrib.trait_type !== oldAttributes[i]?.trait_type || attrib.value !== oldAttributes[i]?.value) {
                return false
            }
        }

        // attributes have changed if old attributes length different from new one
        if (newAttributes.length !== oldAttributes.length) return false

        return true
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        return (
            noImageChange() &&
            name === nft?.imgMetaData?.name &&
            description === nft?.imgMetaData?.description &&
            randomness === nft?.imgMetaData?.randomness_weight &&
            noAttributesChange()
        )
    }

    // if user has just selected image, display that, else display image from s3 url
    const imgUrl =
        img?.base64 || img?.imgData || img === null
            ? `data:${nftContentType};base64,${img?.base64 || img?.imgData}`
            : JSON.parse(img?.imgURL || "").url

    // TO DO: remove empty spaces (if any) at the end of any input forms, before submitting
    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="edit-nft-modal"
        >
            <AvForm
                // NOTE* initialValue key's must match "name" attribute in AvField components!
                initialValues={{
                    "nft-name": name,
                    "nft-description": description,
                    "nft-randomness-weight": randomness
                }}
                enableReinitialize
                validationSchema={yup.object().shape({
                    "nft-name": yup.string().required("This field is required."),
                    "nft-randomness-weight": yup
                        .number()
                        .required("This field is required.")
                        // if callback in .test returns false, show error message
                        .test("valid nft-randomness?", "Cannot be less than 1", (num) => num >= 1)
                        .test("valid nft-randomness?", "Must be a positive integer", (num) => Number.isInteger(num))
                        .test(
                            "valid nft-randomness?",
                            "Cap exceeded! The sum of all NFT's randomness weights cannot be > collection cap",
                            (num) => !isCollectionCapMet(num)
                        )
                })}
                onSubmit={handleClickSubmit}
            >
                <ModalHeader
                    toggle={showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
                >
                    <div className={"edit-nft-tab" + (attributesTabIsOpen ? "" : " open")} onClick={() => setAttributesTabIsOpen(false)}>
                        {!!nft ? "Edit NFT" : "Add NFT"}
                    </div>
                    <div className={"attributes-tab" + (attributesTabIsOpen ? " open" : "")} onClick={() => setAttributesTabIsOpen(true)}>
                        Edit Attributes
                    </div>
                </ModalHeader>
                {attributesTabIsOpen ? (
                    <EditAttributes
                        attributes={attributes}
                        setAttributes={setAttributes}
                        disableFormsAndButtons={showConfirmLoading || disableAllButtons || nftIsPreminted || nftIsMinted}
                        jsonSyntaxError={jsonSyntaxError}
                        setJsonSyntaxError={setJsonSyntaxError}
                    />
                ) : (
                    // EDIT/ADD NFT Tab
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <div>
                                <Label htmlFor="full-name" className="form-label select-media">
                                    {/* jpg, png, gif, mov, mp4 */}
                                    Select Media
                                </Label>
                                <div className="media-file-types">Supported Media File Types: jpg, png, gif, mov, mp4</div>
                            </div>

                            <div className={"photo-upload-new-project"}>
                                <Dropzone
                                    onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)}
                                    maxFiles={1}
                                    disabled={showConfirmLoading || disableAllButtons || nftIsPreminted || nftIsMinted}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div>
                                            <div className="photo-select needsclick" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {/* OLD WAY- get image from lambda */}
                                                {/* {img?.imgData === "W29iamVjdCBPYmplY3Rd" || img === null || img?.imgData === "" ? ( */}
                                                {/* NEW WAY- get image from s3 */}
                                                {img?.contentLength === 0 || img === null || img?.imgData === "" ? (
                                                    // img.imgData === null if adding new nft
                                                    // img.imgData === "" if nft uploaded to s3 without image (1st time)
                                                    // img.imgData === "W29iamVjdCBPYmplY3Rd" if nft uploaded to s3 without image (2+ times). "W29iamVjdCBPYmplY3Rd" ~= null
                                                    <div className="photo-select needsclick">
                                                        <i className="display-6 text-muted bx bxs-camera-plus" />
                                                    </div>
                                                ) : (
                                                    <div className="img-preview-container">
                                                        {nftContentType === "video/mp4" || nftContentType === "video/quicktime" ? (
                                                            <Player
                                                                // img.preview -> truthy if user selected file
                                                                // imgUrl -> truthy if user clicked on edit nft row (edt nft modal)
                                                                src={img.preview || imgUrl}
                                                                playsInline
                                                                controls={false}
                                                                autoPlay={true}
                                                                loop={true}
                                                                // TO DO: fix css if video dimensions are rectangular (not square)
                                                                className="video-preview"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="selected-image"
                                                                alt="nft data"
                                                                // NOTE*
                                                                // - uploading to s3 as a blob obj
                                                                // - downloading directly from s3
                                                                // src={`data:image/jpeg;base64,${img?.base64 || img?.imgData}`} // OLD way- get image from lambda
                                                                // NOTE* this url expires in 5 minutes!!
                                                                src={imgUrl}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="nft-name"
                                label="Name"
                                placeholder="Enter NFT Name"
                                type="text"
                                className="form-control"
                                id="nft-name"
                                required
                                disabled={showConfirmLoading || disableAllButtons || nftIsPreminted || nftIsMinted}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="nft-description"
                                label="Description"
                                placeholder="Enter NFT Description"
                                type="textarea"
                                rows={5}
                                className="form-control"
                                id="nft-description"
                                disabled={showConfirmLoading || disableAllButtons || nftIsPreminted || nftIsMinted}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>
                        {/* "mb-3" === margin-bottom .75rem? */}
                        <FormGroup className="mb-2">
                            <AvField
                                name="nft-randomness-weight"
                                label="Randomness Weight"
                                placeholder="Enter Number" // must be positive integer?
                                type="number"
                                className="form-control"
                                id="randomness-weight"
                                // min="1"
                                // step="1"
                                required
                                disabled={showConfirmLoading || disableAllButtons || nftIsPreminted || nftIsMinted}
                                onChange={(e) => setRandomness(e.target.value)}
                            />
                        </FormGroup>
                        {/* if we don't have "1", then when we upload to s3 we will get an error 403 because s3 metadata values must be strings */}
                        <FormGroup check inline className="mb-3 unique-checkbox">
                            <Input
                                name="Unique"
                                type="checkbox"
                                id="unique-nft"
                                checked={String(randomness) === "1"}
                                disabled={disableAllButtons || showConfirmLoading || nftIsPreminted || nftIsMinted}
                                onChange={() => setRandomness("1")}
                            />
                            {/* 'for' property value must match input checkbox 'id' value */}
                            <Label check for="unique-nft">
                                Unique
                            </Label>
                        </FormGroup>
                        {(nftIsPreminted || nftIsMinted) && (
                            <div className="metadatauri">
                                {nft?.imgMetaData?.storage === "filecoin" ? (
                                    <React.Fragment>
                                        <div className="uri-type">
                                            Metadata IPFS URI:
                                            <Tooltip
                                                title="Note, IPFS addresses can only be accessed by an HTTP Gateway, or certain browsers (ex. Brave)"
                                                placement="bottom"
                                            >
                                                <i className="bx bx-info-circle font-size-20" />
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <span className="filecoin-url">{nft.imgMetaData.metadata_uri}</span>
                                            <Tooltip title="Copy IPFS URI to clipboard" placement="bottom">
                                                <i
                                                    className="bx bx-copy font-size-20"
                                                    onClick={(e) => handleCopyClick(e, nft?.imgMetaData?.metadata_uri)}
                                                />
                                            </Tooltip>
                                        </div>

                                        <div className="filecoin-http-uri uri-type">Metadata HTTP URI:</div>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={"https://nftstorage.link/ipfs/" + nft?.imgMetaData?.metadata_uri.slice(7)}
                                        >
                                            <span className="filecoin-url">
                                                {"https://nftstorage.link/ipfs/" + nft?.imgMetaData?.metadata_uri.slice(7)}
                                            </span>
                                        </a>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="uri-type">Metadata URI:</div>
                                        <a target="_blank" rel="noreferrer" href={nft.imgMetaData.metadata_uri}>
                                            {nft.imgMetaData.metadata_uri}
                                        </a>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </ModalBody>
                )}
                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                        {modalAlertMessage}
                    </Alert>
                    <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || noFormChange() || !!jsonSyntaxError}>
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={"SUBMITTING"}
                            txtFalse={"SUBMIT"}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                    </Button>
                    <Button
                        color="secondary"
                        outline
                        onClick={closeAndResetModal}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableAllButtons}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default EditNFTModal
