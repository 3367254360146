import React, { Component } from "react"
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap"
import BreadCrumb from "../Components/BreadCrumb"
//import { Link } from "react-router-dom"
//import integration from "../assets/images/welcome/integration.jpg"
//import marketing from "../assets/images/welcome/nft-marketing.jpg"
import ChatPage from "../Components/ChatPage"

class Welcome extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="content welcome-page">
                    <BreadCrumb items={["welcome"]} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="h3">Welcome to the idexo application!</CardTitle>
                                    <p>Ask your AI helper below to help you build out your projects.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="cards">
                        <ChatPage />
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

export default Welcome
