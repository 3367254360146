import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import * as yup from "yup"

import { toBase64, isValidImage, isValidAddress } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./EditPlayerModal.scss"

// This component is also used as the ADD NFT Modal
const EditPlayerModal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete", or "Mint NFT" Button
    handleSubmit,
    confirmButtonText,
    confirmButtonLoading,
    player,
    setSelectedPlayer,
    ...rest
}) => {
    // const toast = useToast()

    const [img, setImg] = useState(null) // image object- player avatar logo
    const [avatarLogoContentType, setAvatarLogoContentType] = useState("") // str- TO DO: jpg, png, or gif
    // if user has just selected an image blob, use that, else use s3 url from previously saved dynamo
    const imgUrl = !!img ? `data:${avatarLogoContentType};base64,${img?.base64}` : player?.avatar_url?.S

    const [email, setEmail] = useState(player?.email?.S || "")
    const [fabId, setFabId] = useState(player?.fab_id?.S || "") // player fab_id (unique id u)f

    // remove for now
    // const [walletAddress, setWalletAddress] = useState(player?.player_wallet?.S || "") // self custody wallet address

    const closeAndResetModal = () => {
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        setSelectedPlayer(null)
    }

    // const handleCopyClick = (e, address) => {
    //     e.stopPropagation()
    //     navigator.clipboard.writeText(address.replace("×", "x"))
    //     toast.addSuccess(`${formatAddress(address)} copied to clipboard.`)
    // }

    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type // files[0] == JS Obj (blob datatype)

        if (!isValidImage(fileType, true)) {
            setModalAlertMessage("You must select a valid file type: jpg, png, or gif!")
            return setAlertColor("danger")
        }

        // don't let user submit file sizes larger than 15 MB for an avatar
        if (files[0].size >= 15728640) {
            // 15,728,640 bytes == 15 MB
            setModalAlertMessage("File size must be under 15 MB")
            return setAlertColor("danger")
        }

        // adds a key to blob where the value is a base64 string
        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setAvatarLogoContentType(fileType)
        // setImg(base64Files[0].base64) // string
        setImg(base64Files[0]) // imgFile JS Obj (blob datatype)
    }

    const handleClickSubmit = async () => {
        const method = player === null ? "POST" : "PATCH"
        // if (walletAddress) body.player_wallet = walletAddress.toLowerCase() // self custody wallet EVM address
        handleSubmit(method, email, fabId, player, img)
    }

    // returns boolean, true if user does not update their image
    const noImageChange = () => {
        return !img
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        return (
            noImageChange() &&
            // playerId === player?.id?.S && // NOTE* player can't modify playerId after creation
            email === player?.email?.S
            // walletAddress === player?.wallet_address?.S
        )
    }

    // TO DO: remove empty spaces (if any) at the end of any input forms, before submitting
    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="edit-player-modal"
        >
            <AvForm
                // NOTE* initialValue key's must match "name" attribute in AvField components!
                initialValues={{
                    "player-fab-id": fabId,
                    "player-email": email
                    // "wallet-address": walletAddress // self custody address
                }}
                enableReinitialize
                validationSchema={yup.object().shape({
                    // "player-fab-id": yup.string().required("This field is required."), // remove requirement for now
                    // "player-email": yup.string().required("This field is required."), // remove requirement for now
                    "wallet-address": yup
                        .string()
                        // if callback in .test returns false, show error message
                        .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str) || !str)
                })}
                onSubmit={handleClickSubmit}
            >
                <ModalHeader
                    toggle={showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
                >
                    <div>{!player ? "Add Player" : "Edit Player"}</div>
                </ModalHeader>

                <ModalBody>
                    <FormGroup className="mb-4">
                        <div>
                            <Label htmlFor="full-name" className="form-label select-media">
                                Player Avatar
                            </Label>
                            <div className="media-file-types">Supported Media File Types: jpg, png, or gif</div>
                        </div>

                        <div className={"photo-upload-new-project"}>
                            {/* TO DO: fix css so clicking on entire circle will trigger upload */}
                            <Dropzone
                                onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)}
                                maxFiles={1}
                                disabled={showConfirmLoading || disableAllButtons}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div>
                                        <div className="photo-select needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {/* if player has no avatar image uploaded, show default icon */}
                                            {player?.avatar_url?.S === "" || (player?.avatar_url?.S === undefined && img === null) ? (
                                                <div className="photo-select needsclick">
                                                    <i className="display-6 text-muted bx bxs-camera-plus" />
                                                </div>
                                            ) : (
                                                <div className="img-preview-container">
                                                    <img className="selected-image" alt="player avatar" src={imgUrl} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="player-fab-id"
                            label="Play Fab ID"
                            placeholder="Enter Player Fab ID"
                            type="text"
                            className="form-control"
                            id="player-fab-id"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setFabId(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="player-email"
                            label="Email"
                            placeholder="Enter Player Email"
                            type="text"
                            className="form-control"
                            id="player-email"
                            // required
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    {/* "mb-3" === margin-bottom .75rem? */}
                    {/* <FormGroup className="mb-2">
                        <AvField
                            name="wallet-address"
                            label="Self Custody Wallet Address"
                            placeholder="Enter EVM Address"
                            type="text"
                            className="form-control"
                            id="wallet-address"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setWalletAddress(e.target.value)}
                        />
                        <div className="player-wallet-info">
                            This optional address is different than the idexo custodial wallet address automatically created for this player
                        </div>
                    </FormGroup> */}

                    {/* only show vault wallet address if this is the Edit Player Modal (i.e. player has already been created) */}
                    {player?.vault_wallet?.S && (
                        <div className="vault-wallet-wrap mb-3">
                            <div className="player-modal-label">CUSTODIAL WALLET ADDRESS</div>
                            <div className="vault-wallet-info">
                                {/* TO DO: add copy address tooltip */}
                                <span className="">{player?.vault_wallet?.S}</span>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                        {modalAlertMessage}
                    </Alert>
                    <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || noFormChange()}>
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={"SUBMITTING"}
                            txtFalse={"SUBMIT"}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                    </Button>
                    <Button
                        color="secondary"
                        outline
                        onClick={closeAndResetModal}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableAllButtons}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default EditPlayerModal
