import React, { useState, useMemo } from "react"
import { Button, Card, CardBody, CardTitle, Col, CardText, FormGroup, Input, Label, Row, Spinner, TabContent, Table, TabPane } from "reactstrap"
import BreadCrumb from "../Components/BreadCrumb"
import Select from "../Components/CustomSelect"
import ImageUpload from "../Components/ImageUpload"
import IntegrationMethods from "../Components/IntegrationMethods"
import Tabs from "../Components/Tabs/Tabs"
import { chainOptions } from "../common/chain"

const IntegrateNFTs = ({ project, clickCancel, clickProjects, module, fetchModules, disableButtonsAndForms }) => {
    const [activeTab, setActiveTab] = useState("1")
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        symbol: "",
        art: [],
        chain: "",
        cap: undefined,
        needMoreCustom: false
    })
    const [loading, setLoading] = useState(false)

    const handleChange = (key, value) => {
        formData[key] = value
        setFormData({ ...formData })
    }

    const isSubmitAvailable = useMemo(() => {
        for (const key of Object.keys(formData)) {
            if (key !== "needMoreCustom" && !formData[key]) {
                return false
            }
        }
        return true
    }, [formData])

    const onSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setActiveTab("2")
        }, 1000)
    }

    return (
        <React.Fragment>
            <div className="content integrate-nfts">
                {/* breadcrumb will look like: Home > Projects > project name > Integrate NFTs */}
                <BreadCrumb
                    items={["projects", `${project?.project_name?.S}`, "Integrate NFTs"]}
                    links={["projects", "projects"]}
                    linkFunctions={[clickProjects, clickCancel]}
                />
                <div className="back-to-projects">
                    <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn" disabled={disableButtonsAndForms}>
                        Back to project
                    </Button>
                </div>

                <Tabs tabs={["NFT Contract", "Integration"]} setActiveTab={setActiveTab} activeTab={activeTab} />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane className="show" tabId="1">
                                        <CardTitle className="h3">NFT Contract</CardTitle>
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="full-name" className="form-label">
                                                        Name
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="NFT Collection Name"
                                                        value={formData.name}
                                                        onChange={(e) => handleChange("name", e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="full-name" className="form-label">
                                                        Symbol
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="symbol"
                                                        placeholder="NFT Collection Symbol"
                                                        value={formData.symbol}
                                                        onChange={(e) => handleChange("symbol", e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="full-name" className="form-label">
                                                        Description
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        rows={5}
                                                        className="form-control"
                                                        id="description"
                                                        placeholder="Description"
                                                        value={formData.description}
                                                        onChange={(e) => handleChange("description", e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label for="chain">Chain</Label>
                                                    <Select
                                                        id="chain-select"
                                                        placeholder="Select Blockchain"
                                                        options={chainOptions}
                                                        selectedOption={formData.chain}
                                                        handleChange={(value) => handleChange("chain", value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="full-name" className="form-label">
                                                        Choose a Cap
                                                    </Label>
                                                    <Input
                                                        id="nft-cap"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter NFT Cap"
                                                        value={formData.cap}
                                                        onChange={(e) => handleChange("cap", e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-4">
                                                    <FormGroup className="mb-2 need-more-custom">
                                                        <Input
                                                            type="checkbox"
                                                            name="need-more-custom"
                                                            id="need-more-custom"
                                                            className="me-2"
                                                            onClick={() => handleChange("needMoreCustom", formData.needMoreCustom)}
                                                        />
                                                        <Label htmlFor="need-more-custom">Need more custom than above?</Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <FormGroup className="mb-3 text-center">
                                                    <Label htmlFor="full-name" className="form-label">
                                                        Choose your art
                                                    </Label>
                                                    <ImageUpload files={formData.art} handleChange={(img) => handleChange("art", img)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="flex justify-content-end">
                                            <Button
                                                color="primary"
                                                className="waves-effect w-xl btn-submit"
                                                disabled={!isSubmitAvailable}
                                                onClick={onSubmit}
                                            >
                                                {loading ? (
                                                    <React.Fragment>
                                                        SUBMITTING...
                                                        <Spinner
                                                            style={{
                                                                width: "0.7rem",
                                                                height: "0.7rem",
                                                                marginLeft: "1rem"
                                                            }}
                                                            color="light"
                                                        />
                                                    </React.Fragment>
                                                ) : (
                                                    "SUBMIT"
                                                )}
                                            </Button>
                                        </div>
                                    </TabPane>
                                    <TabPane className="show" tabId="2">
                                        <CardTitle className="h3">View Your Methods and How to Integrate Them</CardTitle>
                                        <IntegrationMethods />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h3">Progress</CardTitle>
                                <CardText className="P font-size-12 text-uppercase">Your saved history</CardText>
                                <div className="overflow-auto">
                                    <Table className="align-middle mb-0 table-nowrap">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Symbol</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Chain</th>
                                                <th scope="col">Cap</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">View More</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default IntegrateNFTs
