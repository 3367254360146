import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardTitle } from "reactstrap"
import jwt from "jsonwebtoken"
import { sortProjects, isStringBase64 } from "../utils"
import ProjectView from "./ProjectView"
import BreadCrumb from "../Components/BreadCrumb"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import CreateUpdateProjectModal from "../Components/CreateUpdateProjectModal/CreateUpdateProjectModal"

const apiUrl = "https://projects.idexo.io/"

const Projects = (props) => {
    const [projects, setProjects] = useState([]) // array of objects
    const [alertMessage, setAlertMessage] = useState("") // alert for project card
    const [projectView, setProjectView] = useState(false) // object that has info on project to show
    const [showLoading, setShowLoading] = useState(false) // for projects loading animation

    // For New Project Modal
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)

    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const fetchProjects = async () => {
        try {
            setShowLoading(true)
            let finalUri = apiUrl + "?action=getuserprojects"

            const res = await fetch(finalUri, {
                method: "GET",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "x-api-key": decoded.api_key,
                    "Content-Type": "application/json"
                }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error") {
                setAlertMessage("Error: " + response.error)
                setShowLoading(false)
                setProjects(null)
                return
            }

            // sort projects default by inserted time most recent to latest
            setProjects(sortProjects(response.data.Items))
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
    }

    // runs once after initial mount, and everytime the projectView resource state changes
    useEffect(() => {
        fetchProjects()
        // eslint-disable-next-line
    }, [projectView])

    const renderProjects = () => {
        localStorage.setItem("lastActive", new Date().getTime())
        if (showLoading) {
            return <LoadingCard loadingTxt={"Projects"} backgroundColor="white" />
        }

        if (!projects) {
            // response from GET fetch has error
            return <LoadingCard errorTxt={"projects"} backgroundColor="white" alertMessage={alertMessage} />
        }

        if (projects.length === 0) {
            return <LoadingCard emptyTxt="projects" backgroundColor="white" icon="bx-briefcase" />
        }

        return projects.map((project, idx) => {
            return (
                <Card key={idx}>
                    <CardBody>
                        <CardTitle className="h3">{project.project_name.S}</CardTitle>

                        <div className="projects-card">
                            <div className="left" onClick={() => setProjectView(project)}>
                                <div className="project-logo">
                                    {project?.project_logo?.S === " " ? (
                                        <i className="bx bx-briefcase"></i>
                                    ) : (
                                        <img
                                            className="project-logo"
                                            alt="project logo"
                                            // displaying old base64 string for projects with images stored as base64 string in DB
                                            src={
                                                isStringBase64(project?.project_logo?.S)
                                                    ? `data:image/jpeg;base64,${project?.project_logo?.S}`
                                                    : `https://imagespublic-738087076124.s3.amazonaws.com/${project?.project_logo?.S}`
                                            }
                                        />
                                    )}
                                </div>
                                <div className="description">
                                    <div>
                                        <p className="label">DESCRIPTION</p>
                                        <p className="details">{project.description.S}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="view">
                                <Button color="primary" outline disabled={showCreateProjectModal} onClick={() => setProjectView(project)}>
                                    VIEW
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        })
    }

    const handleClickCreateProject = () => {
        setAlertMessage("")
        setShowCreateProjectModal(true)
    }

    return (
        <React.Fragment>
            <div className="content projects">
                {/* if project view is an object, show single project view page, else show list of projects */}
                {!!projectView ? (
                    <ProjectView
                        project={projectView}
                        clickProjects={() => setProjectView(false)}
                        fetchProjects={fetchProjects}
                        setProjectView={setProjectView}
                    />
                ) : (
                    <React.Fragment>
                        <BreadCrumb items={["projects"]} links={[]} />

                        <div className="create-projects-row">
                            <Button
                                className="create-project-btn"
                                color="primary"
                                onClick={handleClickCreateProject}
                                disabled={showCreateProjectModal}
                            >
                                + Create Project
                            </Button>
                        </div>

                        {renderProjects()}

                        {showCreateProjectModal && (
                            <CreateUpdateProjectModal
                                showCreateProjectModal={showCreateProjectModal}
                                setShowCreateUpdateProjectModal={setShowCreateProjectModal}
                                setProjectView={setProjectView}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    )
}

export default Projects
