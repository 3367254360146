import React, { useState, useEffect } from "react"
import logo from "../assets/images/login-logo.png"
import nftMarketingPic from "../assets/images/new-register/nft-marketing.png"
import fantomLogo from "../assets/images/crypto/icons/fantom.png"
import binanceLogo from "../assets/images/new-register/icons/new-binance-chain-logo.png"
import ethLogo from "../assets/images/new-register/icons/ethereum.png"
import polygonLogo from "../assets/images/new-register/icons/polygonlogo.png"
import avalancheLogo from "../assets/images/new-register/icons/avalanchelogo.png"
import telegramLogo from "../assets/images/new-register/icons/telegram-logo.png"
import twitterLogo from "../assets/images/new-register/icons/twitter-logo.png"
import cIcon from "../assets/images/new-register/c-icon.png"
import messageOverlay from "../assets/images/new-register/message-overlay.png"
import messageOverlayVertical from "../assets/images/new-register/message-overlay-mobile.png"
import messageOverlayMobileVertical from "../assets/images/new-register/message-overlay-mobile-vertical.png"
import { Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { Link } from "react-router-dom"
import password from "../common/password"
import SpinnerWrap from "../Components/SpinnerWrap"
import SidebarNew from "../Components/SidebarNew/SidebarNew"
import Backdrop from "../Components/SidebarNew/backdrop"
import OkDialog from "../Components/OkDialog/OkDialog"

const apiUrl = "https://register.idexo.io/"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

const RegisterNew = (props) => {
    const [email, setEmail] = useState("") // used for signing up for newsletter but not registering a new account
    // const [password, setPassword] = useState("") // not being used
    const [showSpinner, setShowSpinner] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(null) // 1 = register button clicked, 2 = newsletter button clicked, for determining spinner animation
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [sidebarOpen, setSidebarOpen] = useState(false) // for hamburger sidebar navbar

    // this is for dynamically setting the position of the register message overlay depending on window width
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    const submitToNewsletter = async (e) => {
        e.preventDefault()
        setShowSpinner(true)
        setButtonClicked(2)
        setAlertMessage("")

        var isValidEmail = email.includes("@")
        var data = {
            email: email,
            source: "idexo-newsletter"
        }

        if (email === "") {
            setAlertMessage(
                "Please try again. Looks like you left the email form blank. You must enter a valid email in the space provided to signup for the newsletter."
            )
        } else if (!isValidEmail) {
            setAlertMessage("Please try again. You must enter a valid email in the form provided to signup for the newsletter.")
        } else {
            try {
                const res = await fetch("https://subscribe.idexo.io", {
                    method: "POST",
                    body: JSON.stringify(data)
                })

                const dataRes = await res.json()
                console.log(dataRes)

                if (dataRes.error) {
                    setAlertMessage(`Error: ${dataRes.error}`)
                } else {
                    setEmail("")
                    // setAlertColor("")
                    setAlertMessage("Successfully registered for the idexo.io newsletter! Look in your inbox for more information.")
                }
            } catch (err) {
                console.log(err)

                setAlertMessage(`Error: ${err.error}`)
            }
        }
        setShowSpinner(false)
    }

    const renderRegisterMessage = (isMobileVertical = false) => {
        return (
            <div className="register-message-wrap">
                <span className="register-message one">Create NFT collections and mint NFTs.</span>
                <span className="register-message two">Launch NFT marketing campaigns.</span>
                <span className="register-message three">Integrate NFTs into apps, games and more.</span>
                <span className="register-message four">Build custom-branded NFT verification portals and marketplaces.</span>

                {/* change message overlay image to vertical if we're in vertical width */}
                {isMobileVertical ? (
                    <img src={messageOverlayMobileVertical} alt="" className="register-overlay" />
                ) : windowDimensions.width < 992 ? (
                    <img src={messageOverlayVertical} alt="" className="register-overlay" />
                ) : (
                    <img src={messageOverlay} alt="" className="register-overlay" />
                )}
            </div>
        )
    }

    const registerUser = (event) => {
        setButtonClicked(1) // 1 = register button clicked, 2 = newsletter signup clicked, for determining spinner animations
        setAlertMessage("")
        setAlertColor("danger")
        setShowSpinner(true)

        return fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: event.email,
                password: event.password,
                type: "new"
            }),
            json: true
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setAlertMessage("Error: " + data.error)
                } else {
                    setAlertMessage("Registration submitted. Check your email for further instructions.")
                    setAlertColor("success")
                }
                setShowSpinner(false)
            })
            .catch((error) => {
                console.log(error)
                setShowSpinner(false)
            })
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <div className="register-page">
            {/* hide sidebar if window width > 991px */}
            {windowDimensions.width > 991 && sidebarOpen ? (
                setSidebarOpen(false)
            ) : (
                <React.Fragment>
                    <SidebarNew
                        navNames={["About Us", "Whitepaper"]}
                        navUrls={["https://idexo.com/about-us.html", "https://docsend.com/view/pv7i74c99jdikt2a"]}
                        show={sidebarOpen}
                        clickX={() => setSidebarOpen(false)}
                    />
                    {sidebarOpen ? <Backdrop click={() => setSidebarOpen(false)} /> : null}
                </React.Fragment>
            )}

            <header className="site-header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg p-0">
                        <Link to="/" className="navbar-brand p-0">
                            <img src={logo} alt="idexo-logo" />
                        </Link>

                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => {
                                setSidebarOpen(!sidebarOpen)
                            }}
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <span className="close-btn d-inline-block d-lg-none">
                                <i className="fal fa-times"></i>
                            </span>
                            <ul className="navbar-nav main-head-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="https://idexo.com/about-us.html">
                                        About Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://docsend.com/view/pv7i74c99jdikt2a">
                                        Whitepaper
                                    </a>
                                </li>
                            </ul>
                            {/* <ul class="head-button navbar-nav">
                                <li>
                                    <Link to="/login" className="head-btn">
                                        Login
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                    </nav>
                </div>
            </header>

            <section className="home-section">
                <div className="container">
                    {windowDimensions.width > 580 ? renderRegisterMessage() : null}

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-7 home-img-div">
                            <img src={nftMarketingPic} alt="nft marketing" className="nft-marketing-img" />
                        </div>

                        <div className="col-lg-6 col-xl-5 text-center">
                            <div className="home-bg-content">
                                <div className="headline-wrap">
                                    <img src={cIcon} alt="" className="c-icon left" />
                                    <span className="middle-section">
                                        <h1 className="h1-headline">Get started producing your NFTs</h1>
                                        <p className="headline-desc">
                                            Fill out the form below to access the software and create your first NFT collection in minutes.
                                        </p>
                                    </span>
                                    <img src={cIcon} alt="" className="c-icon right" />
                                </div>

                                <div className="register-wrap">
                                    <AvForm
                                        className="form needs-validation register-bottom"
                                        initialValues={{ email: "", password: "" }}
                                        onSubmit={registerUser}
                                        validationSchema={password.schema}
                                        autoComplete="off"
                                    >
                                        {alertMessage && buttonClicked === 1 ? <Alert color={alertColor}>{alertMessage}</Alert> : null}

                                        <div className="form-item">
                                            <AvField
                                                name="email"
                                                className="form-control"
                                                placeholder="Email*"
                                                type="email"
                                                required
                                                autoComplete="off"
                                                disabled={showSpinner}
                                            />
                                        </div>

                                        <div className="form-item">
                                            <AvField
                                                name="password"
                                                type="password"
                                                required
                                                placeholder="Password*"
                                                autoComplete="off"
                                                // Below is resulting in an uncaught error:
                                                // validate={{ myValidation: password.complexity }}
                                                disabled={showSpinner}
                                            />
                                        </div>

                                        <div className="form-item">
                                            <AvField
                                                name="confirmpassword"
                                                type="password"
                                                required
                                                placeholder="Confirm Password*"
                                                autoComplete="off"
                                                // Below is resulting in an uncaught error:
                                                // validate={{ myValidation: password.verify }}
                                                disabled={showSpinner}
                                            />
                                        </div>

                                        <div className="form-button d-grid">
                                            <button
                                                className="btn btn-primary btn-block waves-effect waves-light text-uppercase register-button"
                                                type="submit"
                                                disabled={showSpinner}
                                            >
                                                <SpinnerWrap
                                                    showSpinner={showSpinner && buttonClicked === 1}
                                                    txtTrue={"Registering"}
                                                    txtFalse={"Get Started"}
                                                />
                                            </button>
                                        </div>
                                    </AvForm>

                                    {windowDimensions.width < 580 ? renderRegisterMessage(true) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="integrations-wrap">
                <div className="integrations">
                    <h3 className="integrations-title">Integration Enabled</h3>
                    <ul className="integration-logos">
                        <li>
                            <img src={fantomLogo} alt="fantom logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={binanceLogo} alt="binance logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={ethLogo} alt="ethereum logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={polygonLogo} alt="polygon logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={avalancheLogo} alt="avalanche logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={telegramLogo} alt="telegram logo" className="integration-img" />
                        </li>
                        <li>
                            <img src={twitterLogo} alt="twitter logo" className="integration-img" />
                        </li>
                    </ul>
                </div>
            </section>

            <div className="spacer"></div>

            <footer className="site-footer light-bg">
                <OkDialog open={!!alertMessage && buttonClicked === 2} title={""} message={alertMessage} okClick={() => setAlertMessage("")} />

                <div className="container">
                    <div className="row row-wrap">
                        <div className="col-lg-6 download-form-div mb-5 mb-lg-0">
                            <p>Subscribe for updates on new blockchains and use cases.</p>
                            <form action="#" onSubmit={submitToNewsletter}>
                                <div className="input-group newsletter-signup-wrap">
                                    <div className="form-outline">
                                        <input
                                            className="form-control"
                                            type="email"
                                            id="email-input"
                                            value={email}
                                            placeholder="Enter your e-mail"
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={showSpinner}
                                        />
                                    </div>
                                    <button type="submit" className="site-btn newsletter-signup-btn" disabled={showSpinner}>
                                        <SpinnerWrap showSpinner={showSpinner && buttonClicked === 2} txtTrue={"Signing Up"} txtFalse={"Sign Up"} />
                                    </button>
                                </div>
                            </form>
                            <p className="copyright">© 2021. ZIP WEB 3 LIMITED.</p>
                        </div>
                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0 company-footer">
                            <h4>Company</h4>
                            <ul>
                                <li>
                                    <a href="https://idexo.com/about-us.html">About</a>
                                </li>

                                <li>
                                    <a href="https://idexo.com/news.html">News</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 mb-4 mb-md-0">
                            <h4>Community</h4>
                            <ul>
                                <li>
                                    <a href="https://twitter.com/idexo_io">Twitter</a>
                                </li>
                                <li>
                                    <a href="https://t.me/idexo_io">Telegram</a>
                                </li>

                                <li>
                                    <a href="https://ido.cl/coingecko">Coingecko</a>
                                </li>
                                <li>
                                    <a href="https://ido.cl/cmc">Coinmarketcap</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 ps-xl-5 col-md-4">
                            <h4>Resources</h4>
                            <ul>
                                <li>
                                    <a href="https://blog.idexo.io">Blog</a>
                                </li>
                                <li>
                                    <a href="https://idexo.gitbook.io/docs">Developer Docs</a>
                                </li>
                                <li>
                                    <a href="https://github.com/idexo">Github</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default RegisterNew
