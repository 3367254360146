import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Spinner } from "reactstrap"
import jwt from "jsonwebtoken"
import PurchaseTxCreditsModal from "./PurchaseTxCreditsModal"
import "./transactionCredits.scss"

const apiUrl = "https://balance.idexo.io/"

// TO DO: fill in non apiKey prices
const TX_CREDIT_DOLLAR_COSTS = {
    0: {
        // apiKey plan
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    1: {
        // reserved
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    2: {
        // trial
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    3: {
        // starter
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    4: {
        // professional
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    5: {
        // enterprise
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    },
    6: {
        // ultimate
        50: "$25",
        100: "$50",
        500: "$245",
        5000: "$2,250"
    }
}

const TransactionCredits = () => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const [transactionCredits, setTransactionCredits] = useState(null) // number- user's transaction credit balance
    const [showErrorTxCredits, setShowErrorTxCredits] = useState(false) // bool- error if failure fetching transaction credits
    const [showTxCreditsLoading, setShowTxCreditsLoading] = useState(false) // loading spinner animation for fetching tx credits
    const [selectedNumTxCredits, setSelectedNumTxCredits] = useState(null) // string- ex. "1,000"- number of transaction credits user selected/clicked on to buy

    const handleClickSelectCredits = (e) => {
        setSelectedNumTxCredits(e.currentTarget.children[0].innerHTML) // string- ex. "5,000"
    }

    // TO DO: (maintain DRY and move this to parent component)
    const fetchUserTransactionCredits = async () => {
        setShowTxCreditsLoading(true)
        setShowErrorTxCredits(false)
        try {
            const res = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "x-api-key": decoded.api_key,
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                setShowErrorTxCredits(true)
                return
            }
            setTransactionCredits(response.data.balance)
        } catch (error) {
            console.log(error)
            setShowErrorTxCredits(true)
        }
        setShowTxCreditsLoading(false)
    }

    useEffect(() => {
        if (!transactionCredits) {
            fetchUserTransactionCredits()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <div className="transaction-credits-tab">
                <Card>
                    <CardBody>
                        <CardTitle className="h3">
                            <span>Transaction Credits: </span>
                            <span className="num-tx-credits-wrap">
                                {showTxCreditsLoading ? <Spinner size="lg" color="primary" className="tx-credits-spinner" /> : transactionCredits}
                                {showErrorTxCredits && (
                                    <React.Fragment>
                                        <i className="bx bx-error font-size-18 align-middle mr-1" />
                                        <span className="txcredits-error-txt">error fetching</span>
                                    </React.Fragment>
                                )}
                            </span>
                        </CardTitle>

                        <div className="tx-credits-description-wrap">
                            <div className="label">BUY CREDITS</div>
                            <div className="value">Select how many transaction credits you would like to buy</div>
                        </div>

                        <section className="tx-credits-buy-wrap">
                            {/* Design Option 1- Cards */}
                            {/* <Card>50</Card>
                            <Card>100</Card>
                            <Card>500</Card>
                            <Card>1,000</Card> */}

                            {/* Design Option 2- buttons */}
                            {/* $25 - apiKey */}
                            <Button color="primary" className="btn-primary btn-font-bold" type="button" onClick={handleClickSelectCredits}>
                                <span className="tx-amount">50</span>
                                <div className="dollar-amount">{TX_CREDIT_DOLLAR_COSTS[decoded.paid][50]}</div>
                            </Button>
                            {/* $50 - apiKey*/}
                            <Button color="primary" className="btn-primary btn-font-bold" type="button" onClick={handleClickSelectCredits}>
                                <span className="tx-amount">100</span>
                                <div className="dollar-amount">{TX_CREDIT_DOLLAR_COSTS[decoded.paid][100]}</div>
                            </Button>
                            {/* $245 - apiKey*/}
                            <Button color="primary" className="btn-primary btn-font-bold" type="button" onClick={handleClickSelectCredits}>
                                <span className="tx-amount">500</span>
                                <div className="dollar-amount">{TX_CREDIT_DOLLAR_COSTS[decoded.paid][500]}</div>
                            </Button>
                            {/* $2250 - apiKey*/}
                            <Button color="primary" className="btn-primary btn-font-bold" type="button" onClick={handleClickSelectCredits}>
                                <span className="tx-amount">5,000</span>
                                <div className="dollar-amount">{TX_CREDIT_DOLLAR_COSTS[decoded.paid][5000]}</div>
                            </Button>
                        </section>
                    </CardBody>
                </Card>

                {/* only show purchase transaction credits modal, if selectedNumTxCredits not null */}
                {selectedNumTxCredits && (
                    <PurchaseTxCreditsModal selectedNumTxCredits={selectedNumTxCredits} setSelectedNumTxCredits={setSelectedNumTxCredits} />
                )}
            </div>
        </React.Fragment>
    )
}

export default TransactionCredits
