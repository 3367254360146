import React, { useState, useEffect } from "react"
import { Alert, Card, CardBody, FormGroup, Label, Button, Table, ModalHeader, ModalBody, Modal, ModalFooter } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { SelectField } from "@availity/select"
import Dropzone from "react-dropzone"
import { toBase64, isValidFileType, compressFile, sortUsersByInserted } from "../../utils"
import OkDialog from "../OkDialog/OkDialog"
import SpinnerWrap from "../SpinnerWrap"
import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import "./company.scss"

const apiUrlUpdateOrg = "https://org.idexo.io/" // for updating/getting user email/name/photo, & company name/logo
const apiUrlTeam = "https://team.idexo.io/" // for adding or getting teammates

const Company = ({ userCompanyData, fetchUserCompanyData, ...rest }) => {
    const [companyName, setCompanyName] = useState(userCompanyData?.company?.S || "")
    const [logo, setLogo] = useState(userCompanyData?.logo?.S || "") // base64 string referring to logo
    const [showSpinnerUpdate, setShowSpinnerUpdate] = useState(false) // update button (updating company name/logo)
    const [alertMessageUpdate, setAlertMessageUpdate] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [okDialogMessage, setOkDialogMessage] = useState("") // if wrong file type for uploading logo

    // Invite / New Teammate Modal
    const [openInviteModal, setOpenInviteModal] = useState(false)
    const [email, setEmail] = useState("") // teammate email
    const [name, setName] = useState("") // teammate name
    const [role, setRole] = useState("Member") // teammate role
    const [showSpinnerInvite, setShowSpinnerInvite] = useState(false) // submit button
    const [alertMessageInvite, setAlertMessageInvite] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    // Teammates Table
    const [showTeammatesLoading, setShowTeammatesLoading] = useState(false) // loading animation for teammates table
    const [alertMessageTeammates, setAlertMessageTeammates] = useState("") // show error if failure to fetch teammates
    const [teammates, setTeammates] = useState([])

    // TO DO: (low priority) this is a pattern we could probably abstract and refactor as helper function for reuse to keep DRY
    // returns boolean, true input form field data is NOT the same as the old form data
    const yesFormChange = () => {
        return companyName !== userCompanyData?.company?.S || logo !== userCompanyData?.logo?.S
    }

    const validCompanySubmit = () => {
        return !!companyName && !!logo && yesFormChange()
    }

    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type
        if (!isValidFileType(fileType)) {
            setOkDialogMessage("You must select a jpeg or png file!")
            setLogo(userCompanyData?.logo?.S || "") // set logo to old logo (or nothing if user has no logo)
            return
        }

        // if file size >= 400kb, compress file (so we meet dynamo 400kb limit)
        if (files[0].size >= 400000) {
            files = [await compressFile(files[0])]
        }

        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setLogo(base64Files[0].base64)
    }

    // update logo or company name
    const handleSubmit = async () => {
        setShowSpinnerUpdate(true)
        setAlertMessageUpdate("")

        try {
            let res = await fetch(apiUrlUpdateOrg, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken
                },
                body: JSON.stringify({
                    company: companyName,
                    logo: logo || " ", // empty string so we don't error out in dynamodb
                    action: "company"
                }),
                json: true
            })

            const dataRes = await res.json()
            if (dataRes.error) {
                setAlertColor("danger")
                setAlertMessageUpdate(`Error: ${dataRes.error}`)
            } else {
                setAlertColor("success")
                setAlertMessageUpdate("Successfully updated company profile!")
                fetchUserCompanyData()
            }
        } catch (err) {
            console.log(err)
            // setAlertMessage(err)
        }
        setShowSpinnerUpdate(false)
    }

    const handleInvitationSubmit = async () => {
        setShowSpinnerInvite(true)
        setAlertMessageInvite("")

        try {
            let res = await fetch(apiUrlTeam, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken
                },
                body: JSON.stringify({
                    company: companyName,
                    email: email,
                    fullname: name,
                    role: role
                }),
                json: true
            })

            const response = await res.json()
            if (response.error || response.code !== 200 || response?.data?.error) {
                setAlertColor("danger")
                setAlertMessageInvite(`Error: ${response.error || response?.data?.error}`)
            } else {
                setAlertColor("success")
                setAlertMessageInvite("Successfully added teammate!")
                fetchUserCompanyData()

                // close modal after 3 seconds
                setDisableModalButtons(true)
                setTimeout(() => {
                    setOpenInviteModal(false)
                    setDisableModalButtons(false)
                    fetchTeammates()
                }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinnerInvite(false)
    }

    const fetchTeammates = async () => {
        setShowTeammatesLoading(true)
        setAlertMessageTeammates("")

        try {
            const res = await fetch(apiUrlTeam, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken }
            })
            const response = await res.json()
            console.log(JSON.stringify(response, null, 2))
            if (response.error || response?.data?.error || response.code !== 200) {
                setAlertMessageTeammates(response.error || response?.data?.error)
            } else {
                setTeammates(sortUsersByInserted(response.data?.tenant_members?.data?.Items || [])) // default ascending sort
            }
        } catch (err) {
            console.log(err)
            setAlertMessageTeammates(String(err))
        }
        setShowTeammatesLoading(false)
    }

    // runs once after mounting
    useEffect(() => {
        fetchTeammates()
    }, [])

    return (
        <React.Fragment>
            <div className="company">
                <Card>
                    <CardBody>
                        <AvForm className="company-info-form" initialValues={{ name: "" }} onSubmit={handleSubmit} autoComplete="off">
                            <FormGroup className="mb-3">
                                <AvField
                                    label="Name"
                                    type="text"
                                    className="form-control"
                                    id="company-name"
                                    name="Name"
                                    placeholder="Enter Company Name"
                                    errorMessage="Required!"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    value={companyName}
                                    required
                                    disabled={showSpinnerUpdate}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label htmlFor="full-name" className="form-label">
                                    Logo
                                </Label>
                                <div className="d-flex">
                                    <div className="photo-upload">
                                        <Dropzone onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)} maxFiles={1}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="d-flex flex-column align-items-center h-100">
                                                    <div className="photo-select needsclick" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {logo ? (
                                                            <div className="img-preview-container">
                                                                <img
                                                                    className="selected-image"
                                                                    alt="company logo"
                                                                    src={logo !== " " ? `data:image/jpeg;base64,${logo}` : ""}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="photo-select needsclick">
                                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <Button
                                        color="primary"
                                        className="btn-light waves-effect w-xl mx-5 remove-photo-btn"
                                        disabled={!logo || showSpinnerUpdate}
                                        onClick={() => setLogo(null)}
                                    >
                                        Remove Logo
                                    </Button>
                                </div>
                            </FormGroup>
                            <div className="mt-2">
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={showSpinnerUpdate || !validCompanySubmit()}
                                    className={"waves-effect w-xl update-company-btn"}
                                >
                                    <SpinnerWrap showSpinner={showSpinnerUpdate} txtTrue={"Updating"} txtFalse={"Update"} />
                                </Button>
                            </div>

                            {alertMessageUpdate ? <Alert color={alertColor}>{alertMessageUpdate}</Alert> : null}
                        </AvForm>
                        <div className="divider" />
                        <div className="team-table">
                            <div className="d-flex mb-2 justify-content-end">
                                <Button
                                    color="primary"
                                    type="submit"
                                    className="waves-effect w-xl new-teammate-btn"
                                    onClick={() => {
                                        setOpenInviteModal(true)
                                        setName("")
                                        setEmail("")
                                        setRole("Member")
                                        setAlertMessageInvite("")
                                    }}
                                    disabled={showSpinnerUpdate || showSpinnerInvite}
                                >
                                    <i className="bx bx-plus" /> New Teammate
                                </Button>
                            </div>
                            <Table className="align-middle mb-0 table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" className="w-50">
                                            Name
                                        </th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showTeammatesLoading ? null : (
                                        <React.Fragment>
                                            {teammates.map((user) => (
                                                <tr>
                                                    <td className="name">
                                                        {user?.photo?.S ? (
                                                            <img
                                                                className="thumbnail"
                                                                src={`data:image/jpeg;base64,${user?.photo?.S}`}
                                                                alt={user?.fullname?.S}
                                                            />
                                                        ) : (
                                                            <i className="bx bx-user-circle" />
                                                        )}
                                                        <div>
                                                            <h5>{user?.fullname?.S}</h5>
                                                            <span>{user.email.S}</span>
                                                        </div>
                                                    </td>
                                                    <td>{user?.status?.S === "accepted" ? "active" : "pending"}</td>
                                                    <td>{user?.role?.S}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </Table>
                            {showTeammatesLoading && <LoadingCard loadingTxt={"teammates"} backgroundColor="grey" />}
                            {alertMessageTeammates && (
                                <LoadingCard errorTxt={"teammates"} backgroundColor="grey" alertMessage={alertMessageTeammates} />
                            )}
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Modal
                className="image-crop-modal"
                isOpen={openInviteModal}
                onClose={() => !showSpinnerInvite && setOpenInviteModal(false)}
                fade={true}
                centered={true}
            >
                <AvForm className="profile-form" initialValues={{ name: "" }} onSubmit={handleInvitationSubmit} autoComplete="off">
                    <ModalHeader toggle={() => !showSpinnerInvite && setOpenInviteModal(false)}>Invite</ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                label="Name"
                                type="text"
                                className="form-control"
                                id="user-name"
                                name="Name"
                                placeholder="Enter Name"
                                errorMessage="Required!"
                                required
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                disabled={showSpinnerInvite || disableModalButtons}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                label="Email"
                                type="text"
                                className="form-control"
                                id="user-email"
                                name="Email"
                                placeholder="Enter Email"
                                errorMessage="Required!"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                disabled={showSpinnerInvite || disableModalButtons}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <SelectField
                                label="Role"
                                name="Role"
                                isMulti={false}
                                onChange={(e) => setRole(e)}
                                isDisabled={showSpinnerInvite || disableModalButtons}
                                options={[
                                    { label: "Member", value: "member" },
                                    { label: "Admin", value: "admin" }
                                ]}
                                defaultValue={{ label: "Member", value: "member" }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        {alertMessageInvite ? <Alert color={alertColor}>{alertMessageInvite}</Alert> : null}
                        <Button
                            color="primary"
                            type="submit"
                            className="waves-effect w-xl invite-company-btn"
                            disabled={showSpinnerInvite || disableModalButtons}
                        >
                            <SpinnerWrap showSpinner={showSpinnerInvite} txtTrue={"Inviting"} txtFalse={"Invite"} />
                        </Button>
                        <Button
                            color="secondary"
                            outline
                            className="btn-light waves-effect cancel-btn"
                            onClick={() => setOpenInviteModal(false)}
                            disabled={showSpinnerInvite}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>

            <OkDialog open={okDialogMessage} message={okDialogMessage} okClick={() => setOkDialogMessage("")} />
        </React.Fragment>
    )
}

export default Company
