import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import jwt from "jsonwebtoken"
import * as yup from "yup"

import Tooltip from "../../Components/Tooltip"
import AddContractModal from "../SmartContract/AddContractModal"
import SpinnerWrap from "../SpinnerWrap"
import BreadCrumb from "../BreadCrumb"
import "./addModule.scss"

const apiUrl = "https://projects.idexo.io/"

const moduleTypes = {
    1: "nft collection",
    2: "nft marketing",
    3: "smart contract",
    4: "integrate nfts",
    5: "token vesting",
    6: "token staking",
    7: "nft surveys",
    8: "erc20 tokens",
    9: "token gating",
    10: "player management",
    11: "templates",
    12: "crm" // contact relationship management
}

// modules == array of objects
// returns boolean true if any module in modules array is of type == "CRM"
// adding comment to trigger new merge
const projectHasCRM = (modules) => {
    return modules.some((module) => module?.M?.type?.S === "crm")
}

const AddModule = ({ project, clickProjects, onClickCancel, fetchModules, modules, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const disableModule = decoded.paid === 0 ? " disable-module" : "" // 0 = "apiKey", 1 = "reserved", 2 = "trial", 3 = "starter", 4 = "professional", 5 = "enterprise", 6 = "ultimate"
    // const disableModule = " disable-module" // for testing UI

    const [moduleSelected, setModuleSelected] = useState(null) // numbers referring to which module was clicked
    const [showNewModuleModal, setShowNewModuleModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [moduleName, setModuleName] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    const handleClickNext = () => {
        switch (moduleSelected) {
            case 3:
                setOpenAddContractModal(true)
                break
            default:
                setAlertMessage("")
                setShowNewModuleModal(true)
        }
    }

    const handleSubmitNewModule = async () => {
        setShowSpinner(true)
        setAlertMessage("")
        setAlertColor("")

        try {
            // check if there's a "crm" module in this project (can only have 1 crm module per project)
            // NOTE* there's no backend validation for 1 crm module per project
            if (moduleTypes[moduleSelected] === "crm" && projectHasCRM(modules)) {
                setAlertMessage("You can only create one CRM module per project")
                setAlertColor("danger")
                setShowSpinner(false)
                return
            }

            const inserted = new Date().getTime() // milliseconds since 1 January 1970 UTC

            // ex. modules === [
            //                  { "M": { "inserted":{"N": "90123483"}, "type":{"S":"nft_marketing"}, "module_name":{"S":"first campaign"} } },
            //                  { "M": { "inserted":{"N": "90176355"}, "type":{"S":"smart_contract"}, "module_name":{"S":"custom contract"} } }
            //                ]
            // remove smart contracts from modules (since we keep them separate from dynamo)
            let newModules = modules.filter((mod) => !mod?.objKey) // only add module if it does not have an objKey (ie. it's not a smartcontract)
            newModules.push({
                M: {
                    inserted: { N: String(inserted) },
                    type: { S: moduleTypes[moduleSelected] },
                    module_name: { S: moduleName }
                }
            })

            let finalUri = apiUrl
            if (localStorage.user_tenants && localStorage.selected_tenant) {
                const user_tenants = JSON.parse(localStorage.user_tenants)
                if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
            }

            const res = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtToken, "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "createNewModule",
                    project_name: project.project_name.S,
                    inserted: String(project.inserted.N),
                    modules: newModules // updated modules to replace in backend
                })
            })
            const response = await res.json()
            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                setAlertColor("danger")
            } else {
                setAlertMessage("Success! Redirecting you back to the project page...")
                setAlertColor("success")
                setDisableModalButtons(true)

                // load project view page in 3 seconds
                setTimeout(() => {
                    // Reset form field data, modal state
                    setShowNewModuleModal(false)
                    setModuleName("")
                    setAlertMessage("")
                    onClickCancel() // go back to project view page
                    fetchModules()
                }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    // Smart Contract Module Functions
    const [openAddContractModal, setOpenAddContractModal] = useState(false)

    const handleAddContract = () => {
        // load project view page in 3 seconds
        setTimeout(() => {
            onClickCancel() // go back to project view page
            fetchModules()
        }, 3000)
    }

    return (
        <div className="add-module-wrap">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, "add module"]}
                links={["projects", "projects"]}
                linkFunctions={[clickProjects, onClickCancel]}
            />

            <div className="add-module-row cancel">
                <Button className="cancel-btn" color="secondary" onClick={onClickCancel} id="cancel">
                    Cancel
                </Button>
            </div>

            <Card>
                <CardBody>
                    <CardTitle className="h3">Add New Module</CardTitle>
                    <div className="choose-module-row">
                        <p className="label">Choose Module</p>
                        <p className="details">Select which type of module you would like to add to your project</p>
                    </div>

                    <div className="modules-wrap">
                        <div className={"module nft-collection" + (moduleSelected === 1 ? " selected" : "")} onClick={() => setModuleSelected(1)}>
                            <i className="bx bx-images module-logos"></i>
                            <span className="label-details">
                                <p className="label">NFT Collection</p>
                                <p className="details">Collection of individual NFTs</p>
                            </span>
                        </div>

                        {/*  if disableModule = truthy value (ie. = " disable-module"), show tooltip open */}
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module nft-marketing" + disableModule + (moduleSelected === 2 ? " selected" : "")}
                                // if disableModule = truthy value (ie. = " disable-module"), disable clicking on this module
                                onClick={() => !disableModule && setModuleSelected(2)}
                            >
                                <i className="bx bxl-telegram module-logos"></i>
                                <span className="label-details">
                                    <p className="label">NFT Marketing</p>
                                    <p className="details">Create social media NFT marketing campaigns</p>
                                </span>
                            </div>
                        </Tooltip>

                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module smart-contract" + disableModule + (moduleSelected === 3 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(3)}
                            >
                                <i className="bx bx-file module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Smart Contract</p>
                                    <p className="details">Add your own custom smart contract</p>
                                </span>
                            </div>
                        </Tooltip>

                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module integrate-nfts" + disableModule + (moduleSelected === 4 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(4)}
                            >
                                <i className="bx bx-sync module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Integrate NFTS</p>
                                    <p className="details">View API methods and how to integrate them</p>
                                </span>
                            </div>
                        </Tooltip>

                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module token-vesting" + disableModule + (moduleSelected === 5 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(5)}
                            >
                                <i className="bx bx-lock module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Token Vesting</p>
                                    <p className="details">Add vesting smart contracts and claiming UI portal</p>
                                </span>
                            </div>
                        </Tooltip>

                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module token-staking" + disableModule + (moduleSelected === 6 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(6)}
                            >
                                <i className="bx bx-cylinder module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Staking Systems</p>
                                    <p className="details">Add staking smart contracts and staking rewards claiming UI portal</p>
                                </span>
                            </div>
                        </Tooltip>

                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module nft-surveys" + disableModule + (moduleSelected === 7 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(7)}
                            >
                                <i className="bx bx-poll module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Surveys</p>
                                    <p className="details">Create Surveys based on NFT holders</p>
                                </span>
                            </div>
                        </Tooltip>
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module erc20-tokens" + disableModule + (moduleSelected === 8 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(8)}
                            >
                                <i className="bx bx-money module-logos"></i>
                                <span className="label-details">
                                    <p className="label">ERC20 TOKENS</p>
                                    <p className="details">Create ERC20 tokens</p>
                                </span>
                            </div>
                        </Tooltip>
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module token-gating" + disableModule + (moduleSelected === 9 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(9)}
                            >
                                <i className="bx bx-key module-logos"></i>
                                <span className="label-details">
                                    <p className="label">TOKEN GATING</p>
                                    <p className="details">Create a token gated experience for your users</p>
                                </span>
                            </div>
                        </Tooltip>
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module player-management" + disableModule + (moduleSelected === 10 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(10)}
                            >
                                <i className="bx bx-group module-logos"></i>
                                <span className="label-details">
                                    <p className="label">PLAYER MANAGEMENT</p>
                                    <p className="details">Manage the players for your web3 game</p>
                                </span>
                            </div>
                        </Tooltip>
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module templates" + disableModule + (moduleSelected === 11 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(11)}
                            >
                                <i className="bx bx-palette module-logos"></i>
                                <span className="label-details">
                                    <p className="label">TEMPLATES</p>
                                    <p className="details">Create templates for NFT collections</p>
                                </span>
                            </div>
                        </Tooltip>
                        <Tooltip title={!disableModule ? "" : "You must have a paid account plan in order to add this module"} placement="bottom">
                            <div
                                className={"module templates" + disableModule + (moduleSelected === 12 ? " selected" : "")}
                                onClick={() => !disableModule && setModuleSelected(12)}
                            >
                                <i className="bx bxs-group module-logos"></i>
                                <span className="label-details">
                                    <p className="label">Web3 CRM</p>
                                    <p className="details">Create great experiences and long term loyalty through 360 visibility and management.</p>
                                </span>
                            </div>
                        </Tooltip>
                    </div>

                    <Button className="next-btn" color="primary" disabled={!moduleSelected} onClick={handleClickNext}>
                        Next
                    </Button>
                </CardBody>
            </Card>

            {openAddContractModal && (
                <AddContractModal
                    open={openAddContractModal}
                    onClose={() => setOpenAddContractModal(false)}
                    onAddContract={handleAddContract}
                    project={project}
                    modules={modules}
                />
            )}

            <Modal isOpen={showNewModuleModal} centered={true} toggle={() => !disableModalButtons && !showSpinner && setShowNewModuleModal(false)}>
                <AvForm
                    className="new-project-form"
                    initialValues={{ "module-name": moduleName }}
                    enableReinitialize
                    validationSchema={yup.object().shape({
                        "module-name": yup.string().required("This field is required.")
                    })}
                    onSubmit={handleSubmitNewModule}
                >
                    <ModalHeader
                        toggle={
                            showSpinner || disableModalButtons ? null : () => !disableModalButtons && !showSpinner && setShowNewModuleModal(false)
                        }
                    >
                        Create New Module
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                name="module-name"
                                label="Module Name"
                                placeholder="Enter Module Name"
                                type="text"
                                className="form-control"
                                required
                                disabled={showSpinner || disableModalButtons}
                                onChange={(e) => setModuleName(e.target.value)}
                            />
                        </FormGroup>
                        You can configure the module settings on the project page after creating this new module
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableModalButtons ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>
                        <Button color="primary" type="submit" disabled={showSpinner || disableModalButtons}>
                            <SpinnerWrap
                                showSpinner={showSpinner}
                                txtTrue={"SUBMITTING"}
                                txtFalse={"SUBMIT"}
                                disabled={showSpinner || disableModalButtons}
                            />
                        </Button>
                        <Button
                            color="secondary"
                            outline
                            onClick={() => setShowNewModuleModal(false)}
                            className="btn-light waves-effect cancel-btn"
                            disabled={showSpinner || disableModalButtons}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        </div>
    )
}

export default AddModule
