import React, { Component } from "react"
const apiUrl = "https://admin.idexo.io/"
const forsale = {}

class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    getUser(event) {
        if (event.key === "Enter") {
            let span = document.getElementById("emailSpan")
            let input = document.getElementById("emailInput")
            let output = document.getElementById("output")
            input.disabled = true
            span.className = "bx bx-hourglass"
            fetch(apiUrl + "?path=user&email=" + encodeURIComponent(input.value), {
                headers: { token: localStorage.jwtToken }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Success: ", data)
                    input.disabled = false
                    setTimeout(() => (span.className = "bx bx-mail-send"), 500)
                    if (data.error) {
                        alert("Things are not going your way, server says: " + data.error)
                    } else if (data.data.Count === 0) {
                        alert("No user matches your search...")
                    } else {
                        output.innerText = JSON.stringify(data.data.Items, null, 4)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    getBalance(event) {
        if (event.key === "Enter") {
            let span = document.getElementById("balanceSpan")
            let input = document.getElementById("balanceInput")
            let output = document.getElementById("output")
            input.disabled = true
            span.className = "bx bx-hourglass"
            fetch(apiUrl + "?path=balance&user_id=" + encodeURIComponent(input.value), {
                headers: { token: localStorage.jwtToken }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Success: ", data)
                    input.disabled = false
                    setTimeout(() => (span.className = "bx bx-key"), 500)
                    if (data.error) {
                        alert("Things are not going your way, server says: " + data.error)
                    } else if (data.data.Count === 0) {
                        alert("No data found...")
                    } else {
                        output.innerText = JSON.stringify(data.data, null, 4)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    getWallet(event) {
        if (event.key === "Enter") {
            let span = document.getElementById("walletSpan")
            let input = document.getElementById("walletInput")
            let output = document.getElementById("output")
            input.disabled = true
            span.className = "bx bx-hourglass"
            fetch(apiUrl + "?path=wallet&user_id=" + encodeURIComponent(input.value), {
                headers: { token: localStorage.jwtToken }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Success: ", data)
                    input.disabled = false
                    setTimeout(() => (span.className = "bx bx-key"), 500)
                    if (data.error) {
                        alert("Things are not going your way, server says: " + data.error)
                    } else if (data.data.Count === 0) {
                        alert("No data found...")
                    } else {
                        output.innerText = JSON.stringify(data.data.Items, null, 4)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    getForsale(event) {
        if (event.key === "Enter") {
            let output = document.getElementById("output")
            let api_url = "https://nft.idexo.io/?"
            api_url += "&min=" + document.getElementById("forsalemin").value
            api_url += "&max=" + document.getElementById("forsalemax").value
            api_url += "&limit=" + document.getElementById("forsalelimit").value
            if (forsale.data && forsale.data.LastEvaluatedKey) {
                console.log(forsale.data.LastEvaluatedKey)
                api_url += "&startkey=" + encodeURIComponent(JSON.stringify(forsale.data.LastEvaluatedKey))
            }
            output.innerText = ""
            fetch(api_url, { headers: { token: localStorage.jwtToken } })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                        alert("Things are not going your way, server says: " + data.error)
                    } else if (data.data.Count === 0) {
                        alert("No data found...")
                    } else {
                        output.innerText = JSON.stringify(data.data.Items, null, 4)
                        forsale.data = data.data
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="content">
                    <h2>Admin options, proceed with caution!</h2>
                    <div className="app-email d-lg-block">
                        Enter user email:
                        <div className="position-relative">
                            <input id="emailInput" type="text" className="form-control" onKeyPress={this.getUser} />
                            <span id="emailSpan" className="bx bx-mail-send"></span>
                        </div>
                    </div>
                    <div className="app-email d-lg-block">
                        Enter user id to retrieve balance history:
                        <div className="position-relative">
                            <input id="balanceInput" type="text" className="form-control" onKeyPress={this.getBalance} />
                            <span id="balanceSpan" className="bx bx-key"></span>
                        </div>
                    </div>
                    <div className="app-email d-lg-block">
                        Enter user id to retrieve wallets:
                        <div className="position-relative">
                            <input id="walletInput" type="text" className="form-control" onKeyPress={this.getWallet} />
                            <span id="walletSpan" className="bx bx-key"></span>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="d-lg-block">
                        Query NFTs for sale:
                        <div className="form-control">
                            <input id="forsalemin" type="text" onKeyPress={this.getForsale} placeholder="min" />
                            <input id="forsalemax" type="text" onKeyPress={this.getForsale} placeholder="max" />
                            <input id="forsalelimit" type="text" onKeyPress={this.getForsale} placeholder="limit" />
                        </div>
                    </div>
                    <div className="content">
                        <pre id="output"></pre>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Settings
