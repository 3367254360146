import { ConnectionRejectedError } from "../error"

export default async function init() {
    const { InjectedConnector, UserRejectedRequestError } = await import("@web3-react/injected-connector")
    return {
        web3ReactConnector({ chainIds }) {
            return new InjectedConnector({ supportedChainIds: [...chainIds] })
        },
        handleActivationError(err) {
            console.log("ConnectorInjected error:", err)
            return err instanceof UserRejectedRequestError ? new ConnectionRejectedError() : null
        }
    }
}
