import "regenerator-runtime/runtime.js"
import { ConnectorConfigError } from "../error"

export default async function init() {
    const { WalletLinkConnector } = await import("@web3-react/walletlink-connector")
    return {
        web3ReactConnector({ chainId, url, appName, appLogoUrl }) {
            if (chainId !== 1) {
                throw new ConnectorConfigError("The WalletLink connector requires chainId to be 1.")
            }
            if (!/^https?:\/\//.test(url)) {
                throw new ConnectorConfigError("The WalletLink connector requires url to be an HTTP URL.")
            }
            return new WalletLinkConnector({ url, appName, appLogoUrl })
        }
    }
}
