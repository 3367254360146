import React from "react"
import { Button, Card, CardBody, CardTitle, FormGroup } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg"

import LockIcon from "../../assets/icons/lock-icon.png"
import { allFormsValid } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./TwitterCard.scss"

const TwitterCard = React.memo(
    ({
        // states/useStates from parent
        twitter_mint_command,
        setTwitterMintCommand,
        twitter_launch_url,
        setTwitterLuanchUrl,
        twitterData,
        paid,
        showSpinner,
        buttonId,
        handleSubmit
    }) => {
        // returns boolean, true if on editing form, the input form field data is the same as the old form data
        const noFormChange = () => {
            return twitter_mint_command === twitterData?.twitter_mint_command?.S && twitter_launch_url === twitterData?.twitter_launch_url?.S
        }

        return (
            <Card className="twitter-card">
                <CardBody>
                    <CardTitle className="h3 with-icon">
                        <TwitterIcon /> Twitter
                    </CardTitle>
                    <div className="gray-box">
                        {paid > 0 ? (
                            <AvForm
                                className="twitter-mint-command"
                                initialValues={{ "twitter-mint-command": "" }}
                                onSubmit={(e) => {
                                    alert("Work In Progress...")
                                    // TO DO: backend for launching live
                                    // using snake case to match backend requirements
                                    // handleSubmit(e, { twitter_mint_command, twitter_launch_url }, 4, "launchTwitterMint", false, twitterData?.inserted?.N)
                                }}
                            >
                                <FormGroup className="mb-4">
                                    <AvField
                                        label="Twitter Mint Command"
                                        type="text"
                                        className="form-control"
                                        id="twitter-mint-command"
                                        name="twitter-mint-command"
                                        placeholder="Enter Mint Command"
                                        value={twitter_mint_command}
                                        onChange={(e) => setTwitterMintCommand(e.target.value)}
                                        required
                                        disabled={showSpinner}
                                    />
                                </FormGroup>
                                {/* NOTE* launch tweet isn't the whole URL- just the NUMBER (conversation ID?)*/}
                                <FormGroup className="mb-3">
                                    <AvField
                                        label="Launch Tweet"
                                        type="text"
                                        className="form-control"
                                        id="launch-tweet"
                                        name="launch-tweet"
                                        placeholder="Paste link to launch tweet"
                                        value={twitter_launch_url}
                                        onChange={(e) => setTwitterLuanchUrl(e.target.value)}
                                        required
                                        disabled={showSpinner}
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        color="primary"
                                        outline
                                        className="waves-effect w-sm save-btn"
                                        disabled={showSpinner || !allFormsValid([twitter_mint_command, twitter_launch_url]) || noFormChange()}
                                        onClick={(e) =>
                                            handleSubmit(
                                                e,
                                                { twitter_mint_command, twitter_launch_url },
                                                3,
                                                "createDraftTwitterMint",
                                                false,
                                                twitterData?.inserted?.N
                                            )
                                        }
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 3} txtTrue={"Saving"} txtFalse={"Save"} />
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="waves-effect w-xl submit-btn"
                                        disabled={showSpinner || !allFormsValid([twitter_mint_command, twitter_launch_url])}
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 4} txtTrue={"LAUNCHING"} txtFalse={"LAUNCH LIVE"} />
                                    </Button>
                                </div>
                            </AvForm>
                        ) : (
                            <div className="lock">
                                <img src={LockIcon} alt="Lock" />
                                <p>You need to upgrade to a paid plan to access the Twitter Mint Command</p>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        )
    }
)

export default TwitterCard
