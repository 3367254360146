import React, { useState } from "react"
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap"
import BreadCrumb from "../Components/BreadCrumb"
import { formatAddress, networkIcons, updateModules } from "../utils"
import AddVestingModal from "../Components/Vesting/AddVestingModal"
import ConfirmSubmitModal from "../Components/ConfirmSubmitModal/ConfirmSubmitModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"

const apiUrl = "https://projects.idexo.io/"

const Vesting = ({ project, clickProjects, clickCancel, module, modules, setModules }) => {
    const [detailViewInfo, setDetailView] = useState(null) // will be an object referring to a contract row that was clicked in the table
    const [openAddVestingModal, setOpenAddVestingModal] = useState(null)
    const [showRowColor, setShowRowColor] = useState(false) // contract table row changes color on mouse hover (but not if mouse over buttons)

    // for delete button/modal
    const [showDeleteLoading, setShowDeleteLoading] = useState(false) // for delete project button loading animation
    const [showDeleteModal, setShowDeleteModal] = useState(false) // to prevent opening of contract modal when delete button is clicked
    const [alertColor, setAlertColor] = useState("")
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error/success deleting project
    const [disableDeleteButtons, setDisableDeleteButtons] = useState(false)
    const [selectedContract, setSelectedContract] = useState(null) // object referring to a contract when delete button in table row clicked

    const handleAddVestingModalClose = () => {
        setDetailView(null)
        setOpenAddVestingModal(false)
    }

    const handleClickDeleteContract = async (e, contract) => {
        e.stopPropagation()
        setModalAlertMessage("")
        setShowDeleteModal(true)
        setSelectedContract(contract.M)
    }

    const handleClickRow = (contract) => {
        setDetailView(contract.M)
    }

    const deleteContract = async () => {
        setShowDeleteLoading(true)
        try {
            setDisableDeleteButtons(true)
            const newContracts = module.contracts.L.filter((c) => {
                return c.M.inserted.N !== selectedContract.inserted.N
            })
            let newModule = { ...module }
            newModule.contracts.L = newContracts
            const newModules = updateModules(modules.slice(), { M: module })

            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule",
                    project_name: project?.project_name?.S,
                    inserted: String(project?.inserted?.N),
                    modules: newModules
                })
            })
            const response = await res.json()
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response.data.error) {
                console.log(response.error || response.data.error)
                setModalAlertMessage("Error: " + (response.error || response.data.error))
                setAlertColor("danger")
                setShowDeleteLoading(false)
                return
            }

            setModalAlertMessage("Successfully deleted smart contract! Returning to contracts page...")
            setAlertColor("success")
            setTimeout(() => {
                setShowDeleteModal(false)
                setDisableDeleteButtons(false)
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowDeleteLoading(false)
    }

    const renderTable = () => {
        if (!module.contracts || module.contracts.L.length === 0) {
            return <LoadingCard emptyTxt={"contracts"} icon="bxs-contact" />
        }
        return (
            <Table className="smart-contracts-table align-middle mb-0 table-nowrap">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Project Name</th>
                        <th scope="col">Contract</th>
                        <th scope="col">Beneficiary</th>
                        <th scope="col">Start Time</th>
                        <th scope="col">Cliff</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {module.contracts.L.map((c) => (
                        <tr
                            key={c.M.inserted.N}
                            onClick={() => handleClickRow(c)}
                            className={"address-table-tr" + (showRowColor ? " show-background" : "")}
                            onMouseEnter={() => setShowRowColor(true)}
                            onMouseLeave={() => setShowRowColor(false)}
                        >
                            <td className="name">
                                <img style={{ height: 30, width: 30 }} src={networkIcons[c.M.chain.S]} alt={c.M.chain.S} /> {c.M.contract_name.S}
                            </td>
                            <td className="name">{formatAddress(c.M.address.S)}</td>
                            <td className="address">{formatAddress(c.M.beneficiary.S)}</td>
                            <td className="owner">{new Date(Number(c.M.startTime.S)).toLocaleString()}</td>
                            <td className="owner">{c.M.cliff.S}</td>
                            <td className="owner">{c.M.duration.S}</td>
                            <td className="action">
                                <Button
                                    outline
                                    className="btn waves-effect waves-light"
                                    onClick={(e) => handleClickDeleteContract(e, c)}
                                    onMouseEnter={() => setShowRowColor(false)}
                                    onMouseLeave={() => setShowRowColor(true)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    return (
        <React.Fragment>
            <div className="vesting-contracts">
                {/* breadcrumb will look like: Home > Projects > project name > Vesting Contracts */}
                <BreadCrumb
                    items={["projects", `${project?.project_name?.S}`, "Vesting Contracts"]}
                    links={["projects", "projects"]}
                    linkFunctions={[clickProjects, clickCancel]}
                />
                <div className="back-to-projects">
                    <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn" disabled={showDeleteLoading}>
                        Back to project
                    </Button>
                </div>

                <Card>
                    <CardBody>
                        <CardTitle className="h3">Vesting Contracts</CardTitle>
                        <div className="d-flex justify-content-end mb-3">
                            <Button color="primary" className="add-contract-btn waves-effect w-xl" onClick={() => setOpenAddVestingModal(true)}>
                                New Vesting Contract
                            </Button>
                        </div>
                        <div className="overflow-auto">{renderTable()}</div>
                    </CardBody>
                </Card>
            </div>

            {(openAddVestingModal || detailViewInfo) && !showDeleteModal && (
                <AddVestingModal
                    onClose={handleAddVestingModalClose}
                    contract={detailViewInfo}
                    project={project}
                    module={module}
                    modules={modules}
                    setModules={setModules}
                />
            )}

            {/* DELETE CONTRACT MODAL */}
            {showDeleteModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showDeleteModal}
                    setShowConfirmSubmitModal={setShowDeleteModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableDeleteButtons}
                    showConfirmLoading={showDeleteLoading}
                    handleClickConfirm={deleteContract}
                    modalTitle={"Delete Smart Contract: " + selectedContract.contract_name.S}
                    modalText={
                        "Deleting a contract deletes all of its contents and is not reversible. Are you sure you want to delete this contract?"
                    }
                    confirmButtonText={"DELETE"}
                    confirmButtonLoading={"DELETING"}
                />
            )}
        </React.Fragment>
    )
}

export default Vesting
