export const SET_NFT_DATA_LOADING = "SET_NFT_DATA_LOADING"
export const SET_NFT_ITEMS = "SET_NFT_ITEMS"
export const SET_NFT_TOKENS = "SET_NFT_TOKENS"
export const SET_SALE_TOKENS = "SET_SALE_TOKENS"

export const setLoading = (value) => ({
    type: SET_NFT_DATA_LOADING,
    payload: value
})

export const setNFTItems = (value) => ({
    type: SET_NFT_ITEMS,
    payload: value
})

export const setSaleTokens = (value) => ({
    type: SET_SALE_TOKENS,
    payload: value
})

export const setNFTTokens = (value) => ({
    type: SET_NFT_TOKENS,
    payload: value
})
