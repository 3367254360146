import { ConnectionRejectedError, ConnectorConfigError } from "../error"

export default async function init() {
    const { UserRejectedRequestError, WalletConnectConnector } = await import("@web3-react/walletconnect-connector")
    return {
        web3ReactConnector({ rpc, bridge, pollingInterval }) {
            if (!rpc) {
                throw new ConnectorConfigError("The WalletConnect connector requires rpc to be set.")
            }
            // if (!/^https?:\/\//.test(rpcUrl)) {
            //     throw new ConnectorConfigError(
            //         'The WalletConnect connector requires rpcUrl to be an HTTP URL.'
            //     )
            // }
            return new WalletConnectConnector({
                bridge,
                pollingInterval,
                qrcode: true,
                rpc
            })
        },
        handleActivationError(err) {
            console.log("ConnectorWalletConnect error:", err)
            return err instanceof UserRejectedRequestError ? new ConnectionRejectedError() : null
        }
    }
}
