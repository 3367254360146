import React, { useState } from "react"
import jwt from "jsonwebtoken"

import { updateModules } from "../utils"
import TokenGatingBuilder from "../Components/TokenGatingBuilder/TokenGatingBuilder"
import SubdomainCard from "../Components/SubdomainCard/SubdomainCard"
import OkDialog from "../Components/OkDialog/OkDialog"
import BreadCrumb from "../Components/BreadCrumb"

const apiUrl = "https://projects.idexo.io/"

const TokenGating = ({ project, modules, module, setModules, handleClickAddTokens, clickProjects, clickCancel, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    // Site SubdomainCard Component
    const [subdomain, setSubdomain] = useState(module?.subdomain?.S || "")
    const [subdomain_file, setSubdomainFile] = useState(module?.subdomain_file?.S || "") // logo displayed in header nav for site subdomain
    const [showSpinner, setShowSpinner] = useState(false) // loading animation on buttons
    const [buttonId, setButtonId] = useState(null) // number refers to which button was clicked (for displaying spinner animation). 1 = save buton, 2 = submit button
    const [alertMessage, setAlertMessage] = useState("")

    // saving subdomain form (draft) or submitting (creating subdomain)
    // TO DO: move this function into the SubdomainCard so we maintain DRY (then delete all copies of this function in other components)
    const handleSubmit = async (newModule, btnId, subdomain = "") => {
        setButtonId(btnId)
        setShowSpinner(true)
        setAlertMessage("")
        try {
            let oldModules = modules.slice() // shallow copy
            let newModules = updateModules(oldModules, newModule) // find modules in modules array (by inserted time) and replace that module (adds a base64 string logo)
            let projectName = project?.project_name?.S
            let projectInserted = String(project?.inserted?.N)

            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule", // this action creates or updates a module
                    project_name: projectName, // hash_key
                    inserted: projectInserted, // range_key = inserted time of item in user_projects table
                    modules: newModules, // updated modules to replace in backend
                    subdomain: subdomain,
                    subdomain_file: newModule?.M?.subdomain_file?.S || "",
                    flags: { tokengating: 1 }
                })
            })
            const response = await res.json()
            // console.log("handleSubmit", response)

            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
            } else {
                const submitSuccessMessage = "Success! subdomain creation in progress. Please wait..." // success message if user clicked "submit"
                const saveSuccessMessage = "Successully saved your draft data!"
                setAlertMessage(!subdomain ? saveSuccessMessage : submitSuccessMessage)
                modules = newModules
                setModules(modules)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    return (
        <React.Fragment>
            <div className="token-gating">
                <BreadCrumb
                    items={["projects", `${project?.project_name?.S}`, `Token Gating Module: ${module?.module_name?.S}`]}
                    links={["projects", "projects"]}
                    linkFunctions={[clickProjects, clickCancel]}
                />

                {/* TO DO: 1) show logo if uploaded, 2) disable submit button if subdomain created/pending creation, 3) show UI that subdomain creation in progress/spinner animation */}
                <SubdomainCard
                    module={module}
                    headerTitle="Token Gating Subdomain"
                    fieldLabel="Token Gating Domain"
                    subdomain={subdomain}
                    setSubdomain={setSubdomain}
                    subdomain_file={subdomain_file}
                    setSubdomainFile={setSubdomainFile}
                    paid={decoded?.paid}
                    showSpinner={showSpinner}
                    buttonId={buttonId}
                    handleSubmit={handleSubmit}
                />

                <TokenGatingBuilder subdomain={subdomain} project={project} module={module} />

                <OkDialog open={!!alertMessage} title={""} message={alertMessage} okClick={() => setAlertMessage("")} />
            </div>
        </React.Fragment>
    )
}

export default TokenGating
