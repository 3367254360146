import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, Form, FormGroup, Input, Label } from "reactstrap"
import grapesjs from "grapesjs"
import gjsPresetNewsletter from "grapesjs-preset-newsletter"
import "grapesjs/dist/css/grapes.min.css"

import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import "./TokenGatingBuilder.scss"

const apiUrl = "https://projects.idexo.io/"

const grapesJSstorageConfig = {
    type: "remote", // save to remote db not localStorage
    autosave: true,
    autoload: true,
    stepsBeforeSave: 1, // auto save to remote db everytime there is 1 change done in grapesjs webuilder UI
    storeComponents: true,
    storeStyles: true,
    storeHTML: true,
    storeCss: true,
    // Callback runs before the store call (can be asynchronous).
    // onStore: (data) => data
    options: {
        remote: {
            headers: { "Content-Type": "application/json", token: localStorage.jwtToken },
            urlStore: apiUrl, // POST- endpoint to store data
            urlLoad: apiUrl, // GET- endpoint to get data
            contentTypeJson: true, // format of data to send to db
            credentials: "same-origin"
            // onLoad: () => {} // callback runs after GET- use this to extract project data from response
            // onStore: () => {} // callback runs before POST- use this to format data before sending to backend
        }
    }
}

// will only override values that are here, default values that aren't here will still exist in configuration
const grapesJSassetConfig = {
    // upload: apiUrl, // POST
    upload: false, // if false, create your own image upload process
    credentials: "same-origin",
    headers: { "Content-Type": "application/json", token: localStorage.jwtToken },
    showUrlInput: false, // don't show "Add Image" button & form path url on image dropzone
    multiUpload: false, // don't allow uploading multiple images at the same time
    autoAdd: true // automatically upload images to s3 when image is dropped onto dropzone
    // NOTE* server should respond with a JSON containing assets in a data key, eg: { data: [ 'https://.../image.png', {src: 'https://.../image2.png'} ] }
}

const TokenGatingBuilder = ({ subdomain, project, module }) => {
    // eslint-disable-next-line
    const [editor, setEditor] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropDownSelect, setDropDownSelect] = useState("Token Gating Card") // default dropdown text
    const [showLoading, setShowLoading] = useState(true) // loading animation for grapesjs designs
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("") // "danger" = red, "success" = green, "secondary" = grey
    const [showKYC, setShowKYC] = useState(null) // will be boolean after fetching from DB
    const [designInserted, setDesignInserted] = useState(null) // will be object {}. keys = designType ("gatingcard" vs "details page"), values = insertion time into token_gating_designs table

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)
    const toggleKYCcheckbox = () => setShowKYC((prevState) => !prevState)

    // grapesJSstorageConfig will vary depending on designType
    const updateGrapesJsStorageConfig = (designType) => {
        let extraAttributes = {
            project_name: project?.project_name?.S,
            project_inserted: project?.inserted?.N,
            module_inserted: module?.inserted?.N
        }
        grapesJSstorageConfig.options.remote.urlLoad = apiUrl + `?action=gettokengatingdesigns&subdomain=${subdomain}&type=${designType}` // GET endpoint
        let design

        // callback runs after GET- use this to extract project data from response
        grapesJSstorageConfig.options.remote.onLoad = async (response) => {
            console.log("GET response", response)
            design = response?.data?.Items?.[0] // there should only be one item. or if design = undefined, that means no previously saved data
            let data = {
                assets: design ? JSON.parse(design.assets.S) : [],
                styles: design ? JSON.parse(design.styles.S) : [],
                pages: design ? JSON.parse(design.pages.S) : [{ component: "" }] // NOTE* update this property to show default design if no saves designs
            }
            if (design) {
                setDesignInserted((prev) => {
                    return { ...prev, [designType]: design?.inserted?.N }
                })
                setShowKYC(design?.show_kyc?.BOOL || false)
            }
            setShowLoading(false)
            setAlertMessage("")
            return data
        }

        let dInserted // memoize this or else we'll be creating duplicate items in DB because of different insertion times (react renders 2x for safe mode, so onStore will be fired twice! even if only one change to UI)

        // callback runs before POST- use this to format data before sending to backend
        grapesJSstorageConfig.options.remote.onStore = (body, editor) => {
            setAlertMessage("")
            body.action = "createOrUpdateTokenGatingDesign" // add action attribute to body of POST
            body.subdomain = subdomain
            // on the first POST & no designs saved: dInserted, design?.inserted, and designInserted[designType] == undefined
            dInserted = String(dInserted || design?.inserted?.N || designInserted?.[designType] || new Date().getTime()) // inserted time of item in token_gating_designs table, or new insertion time = milliseconds since 1 January 1970 UTC
            body.inserted = dInserted
            body.extraAttributes = { ...extraAttributes, design_type: designType } // adding extra attributes to dynamo item
            if (!designInserted?.[designType]) {
                setDesignInserted((prev) => {
                    return { ...prev, [designType]: body.inserted } // we need to do this or else we'll be creating new design items for the same subdomain/designType
                })
            }
            console.log("POST body", body)
            return body
        }

        return grapesJSstorageConfig
    }

    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        return s3Response
    }

    // Get S3 presigned-url
    const fetchPresignedURL = async (s3Key, getObject = false) => {
        return fetch(apiUrl, {
            method: "POST",
            headers: { token: localStorage.jwtToken },
            body: JSON.stringify({
                action: "fetchPresignedURL",
                s3Params: { Key: s3Key },
                getObject: getObject // if true, then we are getting an image from lambda instead of uploading to s3
            })
        })
    }

    useEffect(() => {
        if (!module?.subdomain?.S) {
            setShowLoading(false)
            setAlertMessage("You must save a subdomain name before building your UI experience")
            setAlertColor("secondary")
            return
        }
        setShowLoading(true)

        let editor
        if (dropDownSelect === "Token Gating Card") {
            // NOTE* by default grapesjs uses localStorage as storage
            // connects <div id="grapejs-editor"> to grapejs library
            editor = grapesjs.init({
                // editor for designing token gating card
                container: "#grapejs-editor-tg-card",
                plugins: [gjsPresetNewsletter],
                pluginsOpts: { gjsPresetNewsletter: {} },
                storageManager: updateGrapesJsStorageConfig("gatingcard"), // for html/css
                assetManager: grapesJSassetConfig
            })
        } else {
            editor = grapesjs.init({
                // editor for designing token details page
                container: "#grapejs-editor-td-page",
                plugins: [gjsPresetNewsletter],
                pluginsOpts: { gjsPresetNewsletter: {} },
                storageManager: updateGrapesJsStorageConfig("detailspage"),
                assetManager: grapesJSassetConfig
            })
        }

        // calback runs if there's an error fetching
        editor.on("log:error", (err) => {
            setShowLoading(false)
            setAlertMessage("Error fetching or updating your design: " + String(err.message))
            setAlertColor("danger")
        })

        // all of these grapesjs callback events won't work if we use a custom .uploadFile() asset configuration
        // // callback runs before POST upload of asset image
        // editor.on("asset:upload:start", async () => {
        //     console.log("ASSET UPLOAD START")
        //     setAlertMessage("")
        // })
        // // callback runs after POST upload of asset image
        // editor.on("asset:upload:response", (response) => {
        //     console.log("ASSET UPLOAD RESPONSE", response)
        //     if (response?.code !== 200 || response?.error) {
        //         setAlertMessage("Error Uploading Asset: " + String(response.error))
        //         setAlertColor("danger")
        //     }
        // })
        // editor.on("asset:upload:error", (err) => {
        //     setAlertMessage("Error Uploading Asset: " + String(err))
        //     setAlertColor("danger")
        // })

        // 1) user drags image onto dropzone -> 2) grapesjs.uploadFile() -> fetchPresignedURL() -> uploadImageToS3() -> 3) grapesjs.onStore() -> 4) POST to lambda
        // NOTE* .uploadFile() completely overrides grapesjs built in event callbacks for asset uploading (all grapesjs asset:upload events are unusable)
        editor.AssetManager.getConfig().uploadFile = async (ev) => {
            setAlertMessage("")
            try {
                // TO DO: loading animations for uploading image
                // TO DO: don't let user upload file with same name as existing one for designType (or else it will overwrite)
                // TO DO: don't let user upload file with "/" in file name (or else we could have trouble retreiving later in s3)
                const fileList = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files // FileList object (blob)
                const designType = dropDownSelect === "Token Gating Card" ? "gatingcard" : "detailspage"
                const s3Key = `${subdomain}/${designType}/${fileList[0].name}` // fileName includes extension ex. "profile.jpg"

                // store image in public bucket, with public URLow
                // - get presigned URL to upload
                // - upload to public bucket, and set img source as the URL returned
                const res = await fetchPresignedURL(s3Key)
                const response = await res.json()
                // console.log("fetchPresignedURL", response)

                // error getting presigned URL
                if (response?.code !== 200 || response?.error) {
                    setAlertMessage("Error Uploading Asset: " + response?.error)
                    setAlertColor("danger")
                }

                const uploadUrl = JSON.parse(response.data)?.url
                const s3UploadResponse = await uploadImageToS3(uploadUrl, fileList[0])
                // console.log("s3UploadResponse", s3UploadResponse)

                // error uploading to s3
                if (!s3UploadResponse.ok) {
                    setAlertMessage("Error Uploading To S3")
                    setAlertColor("danger")
                }

                // const publicUrl = `https://imagespublic-738087076124.s3.us-east-1.amazonaws.com/${s3Key}` // also works
                const publicUrl = `https://s3.us-east-1.amazonaws.com/imagespublic-738087076124/${s3Key}`

                // for getting original image dimensions
                const img = new Image()
                img.src = publicUrl
                const imgWidth = img.width
                const imgHeight = img.height

                // add file to grapesjs UI
                const file = {
                    src: publicUrl,
                    type: fileList[0].type, // "image/jpg"
                    name: fileList[0].name,
                    s3Key: s3Key,
                    height: imgWidth, // px
                    width: imgHeight
                }
                const am = editor.AssetManager
                am.add(file) // file will available in grapesjs.onStore() callback
            } catch (err) {
                console.log(err)
                setAlertMessage("Error Uploading Asset: " + String(err))
                setAlertColor("danger")
            }
        }

        // console.log("ASSET CONFIG", editor.AssetManager.getConfig())
        setEditor(editor)

        // eslint-disable-next-line
    }, [dropDownSelect, module?.subdomain?.S])

    useEffect(() => {
        try {
            const designType = dropDownSelect === "Token Gating Card" ? "gatingcard" : "detailspage"
            async function updateShowKYC() {
                const res = await fetch(apiUrl, {
                    method: "POST",
                    headers: { token: localStorage.jwtToken },
                    body: JSON.stringify({
                        action: "updateShowKYC",
                        subdomain: subdomain,
                        inserted: designInserted[designType],
                        showKYC: showKYC
                    })
                })
                const response = await res.json()

                if (response.error || response.message === "Internal server error" || response?.data.error) {
                    alert("Error updating show kyc verification: " + (response.error || response?.data.error || response.message))
                }
            }
            // to avoid calling POST on first render
            if (showKYC !== null && designInserted[designType]) updateShowKYC()
        } catch (err) {
            console.log(err)
        }

        // eslint-disable-next-line
    }, [showKYC])

    return (
        <Card className="token-gating-builder">
            <CardBody>
                <CardTitle className="h3 with-icon">
                    <span>Build Your Token Gating Experience</span>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={"down"} className="dropdown-select">
                        <DropdownToggle caret>
                            {dropDownSelect} <i className="bx bx-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                title="These cards are what users see first when they go to your token gating page"
                                onClick={() => setDropDownSelect("Token Gating Card")}
                            >
                                Token Gating Card
                            </DropdownItem>
                            <DropdownItem
                                title="This page is what users see after clicking on a token gating card"
                                onClick={() => setDropDownSelect("Token Details Page")}
                            >
                                Token Details Page
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </CardTitle>
                {showLoading && <LoadingCard loadingTxt={"Token Gating Design"} />}
                {!!alertMessage && <Alert color={alertColor}>{alertMessage}</Alert>}
                <div id="grapejs-editor-tg-card" className={dropDownSelect === "Token Gating Card" && !showLoading ? "show" : "hide"}></div>
                <div id="grapejs-editor-td-page" className={dropDownSelect === "Token Details Page" && !showLoading ? "show" : "hide"}></div>
                {!showLoading && !alertMessage && module?.subdomain?.S && designInserted && (
                    <Form className="toggle-kyc">
                        <FormGroup>
                            <Input type="checkbox" className="toggle-kyc-verification" checked={!!showKYC} onChange={toggleKYCcheckbox} />
                            <Label>Enable KYC Verification</Label>
                            <i
                                className="bx bx-info-circle"
                                title="checking this box will enable users on your subdomain who are token gated to go through KYC verification"
                            />
                        </FormGroup>
                    </Form>
                )}
            </CardBody>
        </Card>
    )
}

export default TokenGatingBuilder
