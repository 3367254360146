import React from "react"
import { Card, CardBody, Button } from "reactstrap"
import { Link } from "react-router-dom"

import "./breadCrumb.scss"

const BreadCrumb = (props) => {
    const {
        addNFT = false,
        onClickAddNFT = () => {},
        createNew = false,
        onClickCreateNew = () => {},
        items = [],
        links = [],
        linkFunctions = [] // click handlers ex. when user clicks on breadcrumb item for projects page
    } = props

    return (
        <Card className="breadcrumb-card">
            <CardBody className="breadcrumb-card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item home">
                        <Link to="/">Home</Link>
                    </li>
                    {items.map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <i className="bx bx-chevron-right" />
                                {/* if we're on the last item, make it an active color (not clickable) */}
                                <li className={"breadcrumb-item" + (idx === items.length - 1 ? " active" : "")} aria-current="page" key={idx}>
                                    {idx === items.length - 1 ? (
                                        item
                                    ) : (
                                        <Link to={`/${links[idx]}`} onClick={linkFunctions[idx]}>
                                            {item}
                                        </Link>
                                    )}
                                </li>
                            </React.Fragment>
                        )
                    })}
                </ol>
                <div className="breadcrumb-buttons">
                    {addNFT && (
                        <Button color="primary" className="add-nft-btn" onClick={onClickAddNFT}>
                            ADD NFT
                        </Button>
                    )}
                    {createNew && (
                        <Button color="primary" className="create-new-btn" onClick={onClickCreateNew}>
                            CREATE NEW
                        </Button>
                    )}
                </div>
            </CardBody>
        </Card>
    )
}

export default BreadCrumb

// Example:
// <BreadCrumb items={["projects", `${project.name.S}`]} links={["projects"]} /> */}
// => breadcrumb will look like:
// Home > Projects > project name (last item is always active)
