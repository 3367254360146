import React from "react"
import { Spinner } from "reactstrap"
import "./spinnerWrap.scss"

const SpinnerWrap = (props) => {
    const { showSpinner, txtTrue, txtFalse } = props // txtTrue = text to display if showSpinner === true

    return (
        <React.Fragment>
            {showSpinner ? (
                <React.Fragment>
                    {txtTrue + "..."} <Spinner className="spinner-button" />
                </React.Fragment>
            ) : (
                <span>{txtFalse}</span>
            )}
        </React.Fragment>
    )
}

export default SpinnerWrap
