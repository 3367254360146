import React, { useState, useEffect, useRef } from "react"
import { Card, CardBody, CardTitle, Table, Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, FormGroup, Input } from "reactstrap"
import { useCSVDownloader } from "react-papaparse"
import jwt from "jsonwebtoken"

import { useToast } from "../hooks/toast"
import { formatAddress, formattedDateString, isStrNumeric } from "../utils"
import ConfirmSubmitModal from "../Components/ConfirmSubmitModal/ConfirmSubmitModal"
import PaginationArrows from "../Components/PaginationArrows/PaginationArrows"
import CSVuploadModal from "../Components/CSVuploadModal/CSVuploadModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import TableSettings from "../Components/TableSettings/TableSettings"
import EditCRMmodal from "../Components/EditCRMmodal/EditCRMmodal"
import BreadCrumb from "../Components/BreadCrumb"
import Tooltip from "../Components/Tooltip"

const apiUrl = "https://crm.idexo.io/"

function sortContactsByInserted(contacts, recentFirst = true) {
    return contacts.sort((a, b) => {
        if (recentFirst) return b.inserted.N - a.inserted.N
        return a.inserted.N - b.inserted.N
    })
}

// ([{}, {}, ...], 10000) => [ [{}, {}, ...], [{}], ... ]
// returns array of arrays of objects- each subarray  will refer to a contacts page
function formatPages(contacts, maxContactsPerPage) {
    if (Number(maxContactsPerPage) === 10000 || Number(maxContactsPerPage) >= contacts.length) return [contacts] // 10k == display all contacts on one page
    let pages = []
    let page = []

    for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i]

        if (page.length < maxContactsPerPage) {
            page.push(contact)

            // we've reached end of page, reset page
        } else {
            pages.push(page)
            page = [contact]
        }
        // last item
        if (i === contacts.length - 1 && page.length === maxContactsPerPage) {
            pages.push(page)
        }
    }

    return pages
}

// ([ {}, {}, ... ], [ "uuid1", "uuid2", ... ]) => [ {}, {}, ... ]
// ids == contactIds to remove from contacts array
function removeItems(contacts, ids) {
    let newContacts = []

    // create set of uuid's
    const idsSet = new Set(ids) // { "uuid1", "uuid2", ... }

    for (let i = 0; i < contacts.length; i++) {
        let contact = contacts[i]
        if (!idsSet.has(contact.id.S)) newContacts.push(contact)
    }

    return newContacts
}

// format crm item shape to match dynamo response, & adds "inserted", "id" attributes
// crmItem == ex. { email: 'test1@gmail.com', ... } object payload from POST body (create new crm item body) referring to new contact added
// return ex { email: {S: 'test1@gmail.com}, ... }
function formatCRMitem(crmItem, inserted, contactId) {
    const formattedItem = {}
    for (const key in crmItem) {
        const value = crmItem[key]
        const dataType = isStrNumeric(value) ? "N" : "S"
        formattedItem[key] = { [dataType]: value }
    }
    formattedItem.inserted = { N: inserted }
    formattedItem.id = { S: contactId }
    return formattedItem
}

// WEB3 CRM
const CRM = ({ project, module, clickProjects, clickCancel, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    const toast = useToast()

    const [showLoading, setShowLoading] = useState(false) // for loading animation of crm table
    const [contactsAlertMessage, setContactsAlertMessage] = useState("") // if error fetching contacts data
    const [contactsData, setContactsData] = useState([]) // array of objects, each referring to a contact
    const [selectedContact, setSelectedContact] = useState(null) // object- set when clicked on contact row
    const [showContactRowColor, setShowContactRowColor] = useState(false) // contacts table row changes color on mouse hover (but not if mouse over buttons)
    const [checkedContacts, setCheckedContacts] = useState({}) // { 0: true, 1:false, ... }, keys refers to index values of rows on current page, values refer to whether user clicked checkbox on row
    const [checkAllContacts, setCheckAllContacts] = useState(false) // if true- auto select/check all contacts on current page

    // add/edit/view contact modal
    const [showCRMmodal, setShowCRMmodal] = useState(false) // for adding/editing/viewing a contact
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error if editing/adding contact data
    const [alertColor, setAlertColor] = useState("")
    const [showConfirmLoading, setShowConfirmLoading] = useState(false)
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    // pagination stuff
    const [currentPage, setCurrentPage] = useState(0) // index of pages array- number referring to what page the user is on
    const [pages, setPages] = useState([]) // array of arrays of objects- [ [{}, {}, ...], [{}], ... ] each subarray  will refer to a contacts page
    const [contactsPerPage, setContactsPerPage] = useState(300) // 10,000 == ALL results
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("") // contactId of last item returned in GET all crm items query (used for exclusiveStartKey of next query)
    const initialRender = useRef(true) // using this variable to prevent callback in useEffect from running on initial render/mount
    // useRef(data) - returns object, with key called "current" and value = data passed into useRef

    // bulk upload CSV modal
    const [showCSVuploadModal, setShowCSVuploadModal] = useState(false)
    const [showActionsDropdown, setShowActionsDropdown] = useState(false) // actions dropdown menu allows download csv or other actions (deleting/selecting items)
    const { CSVDownloader } = useCSVDownloader()

    const [sortRecentSettings, setSortRecentSettings] = useState({ inserted: true }) // if true, show recent results first

    const [showDeleteItemsModal, setShowDeleteItemsModal] = useState(false)

    // updates local state so table shows latest data
    const updateCRMitem = (oldContact, newContact) => {
        // oldContact = original contact object before PATCH ex. { email:{S:{}}, id:{S:{}}, ... }
        // newContact == attribute/values that were updated ex. { email: 'test1@gmail.com', ... }

        const updatedContacts = contactsData.slice() // shallow copy

        // loop through old contacts
        for (let i = 0; i < updatedContacts.length; i++) {
            let contact = updatedContacts[i]

            // old contact id matches id of contact to update
            if (contact.id.S === oldContact.id.S) {
                const attributesToUpdate = Object.keys(newContact)

                // update each attribute that changed only
                attributesToUpdate.forEach((attr) => {
                    const updatedValue = String(newContact[attr])
                    const dataType = isStrNumeric(updatedValue) ? "N" : "S"
                    updatedContacts[i][attr] = { [dataType]: updatedValue }
                })
                break
            }
        }

        setContactsData(updatedContacts)
        const newPages = formatPages(updatedContacts, contactsPerPage)
        setPages(newPages)
    }

    // updates local state so table shows latest data
    const addCRMitem = (crmItem, inserted, contactId, vaultWallet, img) => {
        // crmItem == ex. { email: 'test1@gmail.com', ... } object payload from POST body (create new crm item body) referring to new contact added
        // img = img object of contact avatar (if any)

        // make item same as dynamo response {S:{}, N:{}} & add "inserted" and "id" attributes
        const formattedItem = formatCRMitem(crmItem, inserted, contactId)
        formattedItem.project_inserted = { S: project?.inserted?.N }
        formattedItem.tenant_id = { S: decoded.user_id }
        formattedItem.sk = { S: `${decoded.user_id}#CONTACT${contactId}` }

        if (vaultWallet) formattedItem.vault_wallet = { S: vaultWallet }

        if (img) {
            const bucket = "imagespublic-738087076124"
            const s3Key = `crm/${contactId}`
            formattedItem.avatar_url = { S: `https://s3.us-east-1.amazonaws.com/${bucket}/${s3Key}` }
        }

        // update local state to refresh table
        setContactsData((prev) => {
            return [...prev, formattedItem]
        })
        const newPages = formatPages([...contactsData, formattedItem], contactsPerPage)
        setPages(newPages)
    }

    // ids == array of strings contactIds to remove from local state
    const removeCRMitems = (ids) => {
        const allItems = removeItems(contactsData, ids)
        setContactsData(allItems)
        const newPages = formatPages(allItems, contactsPerPage)
        setPages(newPages)
    }

    const deleteCRMitems = async () => {
        setShowConfirmLoading(true)
        setModalAlertMessage("")
        setDisableModalButtons(true)

        try {
            const body = {}
            const crmIds = []
            const contacts = pages[currentPage]

            // grab all crm item ids on current page
            if (checkAllContacts) {
                contacts.forEach((contact) => {
                    crmIds.push(contact.id.S)
                })

                // grab selected crm item ids on current page
            } else {
                for (let key in checkedContacts) {
                    if (checkedContacts[key]) {
                        let contact = contacts[key]
                        crmIds.push(contact?.id?.S)
                    }
                }
            }
            body.crmIds = crmIds

            let finalUrl = apiUrl + `?path=delete_items`

            const res = await fetch(finalUrl, {
                method: "DELETE",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                },
                body: JSON.stringify(body)
            })
            const response = await res.json()
            console.log("deleteCRMitems", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                processErrors("Error: " + (response.error || response.data.error))
                return
            }
            setModalAlertMessage("Successfully deleted selected CRM items! Redirecting back to the CRM Module...")
            setAlertColor("success")
            setDisableModalButtons(true)
            setCheckedContacts({})
            setCheckAllContacts(false)
            removeCRMitems(crmIds)
            setTimeout(() => {
                setDisableModalButtons(false)
                setShowDeleteItemsModal(false)
                setModalAlertMessage("")
            }, 3000)
        } catch (err) {
            console.log(err)
            setAlertColor("danger")
            setModalAlertMessage("Error: " + err)
            setDisableModalButtons(false)
        }
        setShowConfirmLoading(false)
    }

    const deleteModalText = () => {
        let numItemsSelected = 0
        if (checkAllContacts) {
            numItemsSelected = pages?.[currentPage]?.length
        } else {
            for (let key in checkedContacts) {
                if (checkedContacts[key]) numItemsSelected++
            }
        }
        let message = `Delete ${numItemsSelected} item${numItemsSelected > 1 ? "s" : ""} from the crm table? This action cannot be reversed.`
        return message
    }

    const toggleDeleteItemsModal = () => {
        setShowDeleteItemsModal((prev) => !prev)
    }

    // if return == true, disable delete items drop down action
    const disableDeleteItems = () => {
        if (checkAllContacts) return false
        return !Object.values(checkedContacts).some((contact) => contact === true)
    }

    // TO DO: sorting other columns
    const toggleSortColumn = () => {
        setSortRecentSettings((prev) => {
            return {
                ...prev,
                inserted: !sortRecentSettings.inserted
            }
        })
        let sortedContacts = sortContactsByInserted(contactsData, !sortRecentSettings.inserted)
        setContactsData(sortedContacts)
        const newPages = formatPages(sortedContacts, contactsPerPage)
        setPages(newPages)
    }

    const toggleAllContacts = () => {
        if (checkAllContacts) setCheckedContacts({})
        setCheckAllContacts((prev) => !prev)
    }

    const handleClickCheckContact = (e) => {
        const contactIdx = Number(e.currentTarget.dataset.contactIdx)
        setCheckedContacts((prev) => {
            return {
                ...prev,
                [contactIdx]: !prev[contactIdx]
            }
        })
    }

    const handleClickRow = (e) => {
        // const contactIdx = e.currentTarget.getAttribute("data-contact-idx") // also works
        const contactIdx = e.currentTarget.dataset.contactIdx
        const contact = pages?.[currentPage]?.[contactIdx]
        setSelectedContact(contact)
        setShowCRMmodal(true)
    }

    const handleStopPropagation = (e) => {
        e.stopPropagation()
    }

    const showContactRowTrue = () => {
        setShowContactRowColor(true)
    }

    const showContactRowFalse = () => {
        setShowContactRowColor(false)
    }

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data.replace("×", "x"))
        toast.addSuccess(`${formatAddress(data)} is copied to clipboard.`)
    }

    const handleClickCopy = (e) => {
        e.stopPropagation()
        const copyValue = e.currentTarget.dataset.copyValue // Ex. vault wallet, sbtId, ...
        copyToClipboard(copyValue)
    }

    // returns array of objects, each object refering to a contact
    // - removes nested .S or .N from original contactsData
    const formatCSVdownloadData = () => {
        return contactsData.map((item) => {
            const attributeNames = Object.keys(item) // ex. [ "email", "project_inserted", "sk", "vault_wallet", "inserted", "id" ]
            const newItem = {}
            for (let i = 0; i < attributeNames.length; i++) {
                const attributeName = attributeNames[i]
                newItem[attributeName] = item[attributeName].S || item[attributeName].N
            }
            return newItem
        })
    }

    // Actions dropdown menu (csv download, delete items, select items, ...)
    const toggleDropdown = () => {
        setShowActionsDropdown((prev) => !prev)
    }

    const uploadCSVdata = async (csvArray, createNewWallets) => {
        const csvWithProjectInserted = csvArray.map((contact) => {
            contact.project_inserted = project?.inserted?.N
            return contact
        })
        setShowConfirmLoading(true)
        setModalAlertMessage("")
        setDisableModalButtons(true)

        try {
            const body = {
                csvData: csvWithProjectInserted,
                createNewWallets: createNewWallets
            }

            let finalUrl = apiUrl + `?path=uploadcsv`

            const res = await fetch(finalUrl, {
                method: "POST",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                },
                body: JSON.stringify(body)
            })
            const response = await res.json()
            console.log("uploadCSVdata", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                processErrors("Error: " + (response.error || response.data.error))
                return
            }
            setModalAlertMessage("Successfully uploaded all csv items! Redirecting back to the CRM Module...")
            setAlertColor("success")
            fetchCRMdata()
            setCurrentPage(0) // without this, if the user is on a different page, when we reset contactsData, table results might not show up
            setDisableModalButtons(true)
            setTimeout(() => {
                setDisableModalButtons(false)
                setShowCSVuploadModal(false)
                setModalAlertMessage("")
            }, 3000)
        } catch (err) {
            console.log(err)
            return processErrors("Error: " + err)
        }
        setShowConfirmLoading(false)
    }

    const clickPageNum = (pageIdx) => setCurrentPage(pageIdx)

    const clickPreviousPage = (e) => {
        if (currentPage + 1 > 1) setCurrentPage((prev) => prev - 1) // can't have negative pages
    }

    const clickNextPage = (e) => {
        if (currentPage < pages.length - 1 || lastEvaluatedKey) {
            setCurrentPage((prev) => prev + 1)

            // only fetch more data if there's no data for next page
            if (!pages[currentPage + 1]) fetchCRMdata(true)
        }
    }

    const processErrors = (errorMessage) => {
        setModalAlertMessage(errorMessage)
        setAlertColor("danger")
        setShowConfirmLoading(false)
        setDisableModalButtons(false)
    }

    // TO DO: move this function to utils & replace all instances for reuse/DRY
    // Get S3 presigned-url
    const fetchPresignedURL = async (s3Key, getObject = false) => {
        // TO DO: tenant/member editing/viewing feature
        return fetch("https://projects.idexo.io/", {
            method: "POST",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
            body: JSON.stringify({
                action: "fetchPresignedURL",
                s3Params: { Key: s3Key },
                getObject: getObject // if true, then we are getting an image from lambda instead of uploading to s3
            })
        })
    }

    // TO DO: move this function to utils & replace all instances for reuse/DRY
    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        return s3Response
    }

    // POST creating new contact or PATCH updating existing contact
    const handleSaveContact = async (method, img, email, walletAddress, contact, formData, createNewWallet) => {
        // img == img object
        // walletAddress == self custody wallet address
        setShowConfirmLoading(true)
        setModalAlertMessage("")
        setDisableModalButtons(true)

        try {
            console.log(`method ${method}, email ${email}, contact, img`, contact, img)
            let res
            let response

            const body = {
                email: email?.toLowerCase(),
                ...formData
            }
            if (walletAddress) body.wallet_address = walletAddress.toLowerCase() // self custody address
            if (!email) return processErrors("must enter an Email!")
            if (method === "POST") body.project_inserted = project.inserted.N // stringified number (ex. "1664571253267")

            // only upload image to s3 if user has just selected an image
            if (img && method === "POST") body.avatar_url = "S3KEY" // will overwrite this value in backend with proper url based on uuid contactId

            const finalUrl = method === "POST" ? `${apiUrl}?create_new_vault_wallet=${createNewWallet}` : `${apiUrl}?id=${contact?.id?.S}`
            // console.log("body", body) // ex. { email: 'test1@gmail.com', avatar_url: 'S3KEY' }
            console.log("finalUrl", finalUrl)

            // POST / PATCH contact data to CRM (contacts) DB
            res = await fetch(finalUrl, {
                method: method,
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                },
                body: JSON.stringify(body)
            })
            response = await res.json()
            console.log("handleSaveContact", response) // response.data => { id: contactId, vault_wallet: "0x123", inserted: "01234..." }

            // problem POST / FETCH to dynamo CRM table
            if (response.error || response.code !== 200 || response.data.error) {
                return processErrors("Error: " + (response.error || response.data.error))
            }

            // we need the contactId (from response above) in order to upload to s3
            let contactId = response?.data?.id || contact?.id?.S
            if (img) {
                const s3Key = `crm/${contactId}`

                // get presigned s3 URL to upload
                res = await fetchPresignedURL(s3Key)
                response = await res.json()
                console.log("fetchPresignedURL", response)

                // error getting presigned URL
                if (response?.code !== 200 || response?.error) return processErrors("Error Uploading Avatar: " + response?.error)

                // upload image to public bucket, with public URL
                const uploadUrl = JSON.parse(response.data)?.url
                const s3UploadResponse = await uploadImageToS3(uploadUrl, img)
                console.log("s3UploadResponse", s3UploadResponse)

                // error uploading to s3
                if (!s3UploadResponse.ok) return processErrors("Error Uploading Image Avatar")
            }

            const successMessage = method === "POST" ? "added a new contact!" : "updated contact!"
            setModalAlertMessage("successfully " + successMessage)
            setAlertColor("success")
            setDisableModalButtons(true)

            if (method === "POST") {
                const inserted = response?.data?.inserted
                const vaultWallet = response?.data?.vault_wallet
                addCRMitem(body, inserted, contactId, vaultWallet, img)
            } else {
                updateCRMitem(contact, body)
            }

            setTimeout(() => {
                setDisableModalButtons(false)
                setShowCRMmodal(false)
                setModalAlertMessage("")
            }, 3000)
        } catch (err) {
            console.log(err)
            return processErrors(String(err))
        }
        setShowConfirmLoading(false)
    }

    // fetches all contacts for this user's project (up to 6MB lambda payload limit)
    const fetchCRMdata = async (userClickedNextPage = false) => {
        // if userClickedNextPage == true, this function is called after user clicked next page. Concatenates CRM results
        // if userClickedNextPage == false, this function resets contactsData
        setShowLoading(true)
        setContactsAlertMessage("")

        try {
            // TO DO: testing tenant/membership editing/viewing feature
            let finalUri = apiUrl + `?project_inserted=${project?.inserted?.N}&query_limit=300`
            // let finalUri = apiUrl + `?project_inserted=${1670422610574}&query_limit=300` // FOR TESTING
            if (lastEvaluatedKey) finalUri += `&exclusive_start_key=${lastEvaluatedKey}`

            const res = await fetch(finalUri, {
                method: "GET",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                }
            })
            const response = await res.json()
            console.log("fetchCRMData", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                setContactsAlertMessage("Error: " + (response.error || response.data.error))
                setShowLoading(false)
                return
            }

            let allItems = response.data.Items // NOT SORTED
            if (userClickedNextPage) allItems = [...contactsData, ...response.data.Items]
            setContactsData(allItems)
            const newPages = formatPages(allItems, contactsPerPage)
            setPages(newPages)

            // // NOTE*
            // // - if there's a LastEvaluatedKey in the response, we've reached the 1MB dynamo limit or query params limit
            // // - if there's no LastEvaluatedKey in the response, we've retrieved the final page of results/there are no more items to be retrieved
            const lastEvalKey = response.data?.LastEvaluatedKey?.sk?.S?.split("#CONTACT")?.[1]
            setLastEvaluatedKey(lastEvalKey ? lastEvalKey : "") // reset to "" if we've reached end of query results
        } catch (err) {
            console.log(err)
            setContactsAlertMessage("Error: " + err)
        }
        setShowLoading(false)
    }

    // runs once on initial mount, and if any state variables in array change
    useEffect(() => {
        // we don't want to reformat on initial mount/render
        if (initialRender.current) {
            initialRender.current = false
        } else {
            const newPages = formatPages(contactsData, contactsPerPage)
            setPages(newPages)
            setCurrentPage(0)
        }
        // eslint-disable-next-line
    }, [contactsPerPage])

    // runs once on initial mount
    useEffect(() => {
        fetchCRMdata()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="crm-wrap">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, "WEB 3 CRM"]}
                links={["projects", "projects"]}
                linkFunctions={[clickProjects, clickCancel]}
            />

            <Card className="contacts-card">
                <CardBody>
                    <CardTitle className="h3">{module?.module_name?.S}</CardTitle>
                    {contactsData.length > 0 && (
                        <p className="contactscard-description">
                            Click on a row below to edit or see more info about a contact.
                            {lastEvaluatedKey &&
                                " This table has more items to retrieve. To retrieve the next page of items, click 'Retrieve Next Page' below."}
                        </p>
                    )}

                    <div className={"add-contact-row" + (lastEvaluatedKey ? " show-next-btn" : "")}>
                        {lastEvaluatedKey && (
                            <Button className="retrieve-next-page" onClick={clickNextPage}>
                                RETRIEVE NEXT PAGE
                            </Button>
                        )}
                        <Button color="primary" onClick={() => setShowCRMmodal(true)} disabled={showLoading}>
                            ADD CONTACT
                        </Button>
                    </div>

                    <div className="actions-csvupload-row">
                        <Dropdown isOpen={showActionsDropdown} toggle={toggleDropdown} direction={"down"} disabled={showLoading}>
                            <DropdownToggle caret={true} className="actions-toggle">
                                Actions
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <CSVDownloader filename="crm-items.csv" data={formatCSVdownloadData}>
                                        Download all results to CSV
                                    </CSVDownloader>
                                </DropdownItem>
                                {/* TO DO: */}
                                {/* <DropdownItem disabled title="Feature coming soon">
                                    <CSVDownloader filename="crm-items.csv" data={formatCSVdownloadData}>
                                        Download selected items to CSV
                                    </CSVDownloader>
                                </DropdownItem> */}
                                <DropdownItem onClick={toggleDeleteItemsModal} disabled={disableDeleteItems()}>
                                    Delete Items
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <Button color="primary" className="upload-csv-btn" outline onClick={() => setShowCSVuploadModal(true)} disabled={showLoading}>
                            Upload CSV
                        </Button>
                    </div>

                    {showLoading ? (
                        <LoadingCard loadingTxt={"Contacts Data"} />
                    ) : (
                        <React.Fragment>
                            <div className="settings-count-wrap">
                                <div className="settings-pagination-wrap">
                                    {/* Clicking Gear/Cog icon will open table preferences/settings modal */}
                                    <TableSettings updatePageSize={(newPageSize) => setContactsPerPage(newPageSize)} itemsPerPage={contactsPerPage} />

                                    <span className="spacer"></span>

                                    {/* < 1 2 3... > Previous Next Page Navigation Buttons */}
                                    <PaginationArrows
                                        currentPage={currentPage}
                                        pages={pages}
                                        clickPreviousPage={clickPreviousPage}
                                        clickNextPage={clickNextPage}
                                        clickPageNum={clickPageNum}
                                        lastEvaluatedKey={lastEvaluatedKey}
                                    />
                                </div>

                                <span className="crm-count" title="This is the number of crm items queried">
                                    Count ({contactsData.length})
                                </span>
                            </div>

                            <div className="overflow-auto">
                                <Table className="crm-table align-middle mb-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <FormGroup check>
                                                    <Input
                                                        type="checkbox"
                                                        title="Select all contacts"
                                                        checked={checkAllContacts}
                                                        onChange={toggleAllContacts}
                                                    />
                                                </FormGroup>
                                            </th>
                                            <th scope="col">Avatar</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Wallet</th>
                                            <th scope="col">
                                                <div className="create-date-col" onClick={toggleSortColumn}>
                                                    <span>Creation Date</span>
                                                    <i className={"bx font-size-20 bx-chevron-" + (sortRecentSettings.inserted ? "down" : "up")} />
                                                    {/* arrow down = latest items first in table */}
                                                    {/* arrow up = oldest items first in table */}
                                                </div>
                                            </th>
                                            <th scope="col">SBT ID</th>
                                            <th scope="col">Twitter Handle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pages?.[currentPage]?.map((contact, idx) => {
                                            return (
                                                <tr
                                                    key={contact?.id?.S}
                                                    className={"contact-row" + (showContactRowColor ? " show-background" : "")}
                                                    data-contact-idx={idx} // for passing data to event handler
                                                    onMouseEnter={showContactRowTrue}
                                                    onMouseLeave={showContactRowFalse}
                                                    onClick={handleClickRow}
                                                >
                                                    <td className="select-contact">
                                                        <FormGroup check onClick={handleStopPropagation}>
                                                            <Input
                                                                type="checkbox"
                                                                title="Select contact"
                                                                checked={!!checkedContacts[idx] || checkAllContacts}
                                                                data-contact-idx={idx} // for passing data to event handler
                                                                onMouseEnter={showContactRowFalse}
                                                                onMouseLeave={showContactRowTrue}
                                                                onChange={handleClickCheckContact}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                    <td className="image">
                                                        {/* TO DO: show loading animation */}
                                                        {/* if contact has no avatar image uploaded, show default icon */}
                                                        {contact?.avatar_url?.S === "" || contact?.avatar_url?.S === undefined ? (
                                                            <div
                                                                className="empty-photo"
                                                                onMouseEnter={showContactRowFalse}
                                                                onMouseLeave={showContactRowTrue}
                                                                onClick={handleStopPropagation}
                                                            >
                                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                alt="contact avatar"
                                                                src={contact?.avatar_url?.S}
                                                                onMouseEnter={showContactRowFalse}
                                                                onMouseLeave={showContactRowTrue}
                                                                onClick={handleStopPropagation}
                                                            />
                                                        )}
                                                    </td>
                                                    <td className="email">
                                                        {contact?.email?.S && (
                                                            <React.Fragment>
                                                                <span>{contact.email.S}</span>
                                                                <Tooltip title="Copy email to clipboard" placement="bottom">
                                                                    <i
                                                                        className="bx bx-copy font-size-20"
                                                                        data-copy-value={contact?.email?.S}
                                                                        onMouseEnter={showContactRowFalse}
                                                                        onMouseLeave={showContactRowTrue}
                                                                        onClick={handleClickCopy}
                                                                    />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                    {/* idexo custodial wallet address */}
                                                    <td className="wallet">
                                                        {contact?.vault_wallet?.S && (
                                                            <React.Fragment>
                                                                <span>{formatAddress(contact?.vault_wallet?.S)}</span>
                                                                <Tooltip title="Copy wallet address to clipboard" placement="bottom">
                                                                    <i
                                                                        className="bx bx-copy font-size-20"
                                                                        data-copy-value={contact?.vault_wallet?.S}
                                                                        onMouseEnter={showContactRowFalse}
                                                                        onMouseLeave={showContactRowTrue}
                                                                        onClick={handleClickCopy}
                                                                    />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                    <td className="join-date">{formattedDateString(new Date(Number(contact?.inserted?.N)))}</td>
                                                    <td className="sbt-id">
                                                        {contact?.sbt_id?.S && (
                                                            <React.Fragment>
                                                                <span>{formatAddress(contact?.sbt_id?.S)}</span>
                                                                <Tooltip title="Copy SBT ID to clipboard" placement="bottom">
                                                                    <i
                                                                        className="bx bx-copy font-size-20"
                                                                        data-copy-value={contact?.sbt_id?.S}
                                                                        onMouseEnter={showContactRowFalse}
                                                                        onMouseLeave={showContactRowTrue}
                                                                        onClick={handleClickCopy}
                                                                    />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                    <td className="twitter-handle">{contact?.twitter_handle?.S}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>

                            {/* if error fetching contacts data, show error card */}
                            {!!contactsAlertMessage && <LoadingCard errorTxt={"Contacts Data"} alertMessage={contactsAlertMessage} />}

                            {/* if no error fetching crm data, but there are no contacts */}
                            {!contactsAlertMessage && contactsData.length === 0 && (
                                <LoadingCard emptyTxt={"Contacts"} icon="bx-image-add" onClick={() => setShowCRMmodal(true)} className="add-nft" />
                            )}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>

            {/* ADD/EDIT/VIEW Contact Modal */}
            {showCRMmodal && (
                <EditCRMmodal
                    showConfirmSubmitModal={showCRMmodal}
                    setShowConfirmSubmitModal={setShowCRMmodal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    contact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    handleSubmit={handleSaveContact}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation
                    disableAllButtons={disableModalButtons}
                />
            )}

            {/* Bulk Upload CSV Modal  */}
            {showCSVuploadModal && (
                <CSVuploadModal
                    showConfirmSubmitModal={showCSVuploadModal}
                    setShowConfirmSubmitModal={setShowCSVuploadModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    handleSubmit={uploadCSVdata}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation
                    disableAllButtons={disableModalButtons}
                />
            )}

            {/* DELETE CRM ITEMS MODAL */}
            {showDeleteItemsModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={true}
                    setShowConfirmSubmitModal={setShowDeleteItemsModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading}
                    handleClickConfirm={deleteCRMitems}
                    modalTitle={"Delete CRM items"}
                    modalText={deleteModalText()}
                    confirmButtonText={"DELETE"}
                    confirmButtonLoading={"DELETING"}
                />
            )}
        </div>
    )
}

export default CRM
