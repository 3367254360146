import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap"
import jwt from "jsonwebtoken"
import ido from "idexo-sdk"

import { formatAddress, networkIcons } from "../utils"
import { useToast } from "../hooks/toast"
import ConfirmSubmitModal from "../Components/ConfirmSubmitModal/ConfirmSubmitModal"
import AddContractModal from "../Components/SmartContract/AddContractModal"
import ChangeOwnerModal from "../Components/SmartContract/ChangeOwnerModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import BreadCrumb from "../Components/BreadCrumb"
import Tooltip from "../Components/Tooltip"

const walletApiUrl = "https://wallet.idexo.io/"
const txApiUrl = "https://transactions.idexo.io/"
const apiUrl = "https://projects.idexo.io/"

const SmartContract = () => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const toast = useToast()
    const [contractData, setContractData] = useState([]) // will be array of objects each representing a smart contract
    const [detailViewInfo, setDetailView] = useState(null) // will be an object referring to a contract row that was clicked in the table
    const [openAddContractModal, setOpenAddContractModal] = useState(null)
    const [showChangeOwner, setShowChangeOwner] = useState(false)
    const [addresses, setAddresses] = useState([])
    const [showLoading, setShowLoading] = useState(false) // for fetching smart contract data table animation
    const [alertMessage, setAlertMessage] = useState("") // for displaying any errors when fetching smart contract data
    const [showRowColor, setShowRowColor] = useState(false) // contract table row changes color on mouse hover (but not if mouse over buttons)

    // for delete button/modal
    const [showDeleteLoading, setShowDeleteLoading] = useState(false) // for delete project button loading animation
    const [showDeleteModal, setShowDeleteModal] = useState(false) // to prevent opening of contract modal when delete button is clicked
    const [alertColor, setAlertColor] = useState("")
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error/success deleting project
    const [disableDeleteButtons, setDisableDeleteButtons] = useState(false)
    const [selectedContract, setSelectedContract] = useState(null) // object referring to a contract when delete button in table row clicked

    // TO DO: refactor so we can move to utils.js for reuse/DRY (this function is showing in multiple components)
    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`Address ${formatAddress(address)} is copied to clipboard.`)
    }

    // Also called when updating/editing contracts
    const handleAddContract = () => {
        fetchSmartContracts() // update state so we rerender/load table with new data
    }

    const handleAddContractModalClose = () => {
        if (detailViewInfo) {
            setDetailView(null)
        } else {
            setOpenAddContractModal(false)
        }
    }

    const handleChangeOwner = async (owner) => {
        const res = await fetch(txApiUrl + "?path=updateOwner", {
            method: "POST",
            headers: {
                token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                "x-api-key": decoded.api_key,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: selectedContract.id.S,
                owner: owner
            })
        })
        let response = await res.json()
        if (response.error || response.message === "Internal server error" || response?.data.error) {
            setAlertMessage("Error: " + (response.error || response?.data?.error || response.message))
            setAlertColor("danger")
        } else {
            try {
                await ido.Common.transferOwnership(decoded.api_key, selectedContract.network.S, selectedContract.address.S, owner)
            } catch (e) {
                console.log(e.response.data)
                if (e.response.data.error) {
                    alert(e.response.data.error)
                }
            }
        }
    }

    const handleClickChangeOwner = (e, contract) => {
        e.stopPropagation()
        if (contract?.step?.S === "done") {
            setSelectedContract(contract)
            setShowChangeOwner(true)
        } else {
            alert("We can change owner only on deployed contracts.")
        }
    }

    const handleClickDeleteContract = async (e, contract) => {
        e.stopPropagation() // prevents event from bubbling up to prevent parent click handler from being run (prevents contract modal from opening)
        setModalAlertMessage("")
        setSelectedContract(contract)
        setShowDeleteModal(true)
    }

    const handleClickRow = (contract) => {
        setDetailView(contract)
    }

    const sortContracts = (contractsArr, descending = true) => {
        // newer contracts last in table
        let sorted = contractsArr.slice() // shallow copy, so we don't modify state directly
        return sorted.sort((a, b) => {
            if (descending) return Number(a.inserted.N) - Number(b.inserted.N)
            return Number(b.inserted.N) - Number(a.inserted.N)
        })
    }

    const deleteContract = async () => {
        setShowDeleteLoading(true)
        try {
            let s3Key = selectedContract.objKey.split("/").slice(1).join("/") // removes user_id from key
            // if user added contract (ex. module), example s3Key === 'project-12345/1659449002952'    '12345' === project inserted time into dynamo
            // if idexo created contract (ex. nft createCollection), example s3Key === 'abc-123'    'abc-123' === tx_id of sdk transaction
            console.log("s3Key", s3Key)

            const res = await fetch(apiUrl, {
                method: "DELETE",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "deleteSmartContract",
                    contract_id: s3Key
                })
            })
            const response = await res.json()
            console.log("deleteContract", response)

            // problem from server deleting contract (i.e updating modules attribute in projects table)
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response.data.error) {
                console.log(response.error || response.data.error)
                setModalAlertMessage("Error: " + (response.error || response.data.error))
                setAlertColor("danger")
                setShowDeleteLoading(false)
                return
            }

            // if succesful deletion, close delete modal in 3 seconds
            setModalAlertMessage("Successfully deleted smart contract! Returning to contracts page...")
            setAlertColor("success")
            setDisableDeleteButtons(true)
            fetchSmartContracts() // force table to refresh
            setTimeout(() => {
                setShowDeleteModal(false)
                setDisableDeleteButtons(false)
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowDeleteLoading(false)
    }

    const fetchAddresses = async () => {
        try {
            const res = await fetch(walletApiUrl, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error") {
                setAddresses(null)
                return
            }

            const addressItems = response.data.Items.map((item) => ({
                value: item.address.S,
                name: item.name.S + " - " + formatAddress(item.address.S)
            }))
            setAddresses(addressItems)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchSmartContracts = async () => {
        setShowLoading(true)
        setAlertMessage("")

        try {
            // const res = await fetch(apiUrl + "?action=getusercontracts", {
            const res = await fetch(txApiUrl + "?path=contracts", {
                method: "GET",
                headers: { "x-api-key": decoded.api_key, "Content-Type": "application/json" }
            })
            const response = await res.json()
            console.log("fetchSmartContracts", response)

            if (response.code === 404 && response.error === "No s3 objects found") return setShowLoading(false)

            // problem from server fetching contracts
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                console.log(response.error || response?.data?.error)
                setAlertMessage("Error: " + (response.error || response?.data?.error || response.message))
                setShowLoading(false)
                return
            }
            // TO DO (backend): sort contracts by inserted so we can use that function in the sdk
            setContractData(sortContracts(response.data)) // default sort newer contracts last in table
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
    }

    useEffect(() => {
        fetchAddresses().then()
        fetchSmartContracts()
        // eslint-disable-next-line
    }, [])

    const renderTable = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"Smart Contracts"} />
        }

        if (alertMessage) {
            return <LoadingCard errorTxt={"smart contracts"} alertMessage={alertMessage} />
        }

        if (contractData.length === 0) {
            return <LoadingCard emptyTxt={"smart contracts"} icon="bx-file" />
        }

        // TO DO:
        // - add chain logo
        // - stick to uniform delete button css across all table rows in all pages/components
        return (
            <Table className="smart-contracts-table align-middle mb-0 table-nowrap">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Chain</th>
                        <th scope="col">Current Owner</th>
                        <th scope="col">Operators</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contractData.map((item, index) => (
                        <tr
                            key={index}
                            className={"address-table-tr" + (showRowColor ? " show-background" : "")}
                            onClick={() => handleClickRow(item)}
                            onMouseEnter={() => setShowRowColor(true)}
                            onMouseLeave={() => setShowRowColor(false)}
                        >
                            {/* NOTE* This column is the transactionType from sdk */}
                            {/* transaction_type ex. = "createCappedRoyalty" */}
                            {/* only contracts created from the UI will have a transaction_type property in the sdk_transactions table */}
                            <td className="name">
                                {/* {item.objMetaData?.name || item.objMetaData?.transaction_type} */}
                                {/* {item?.transaction_type?.S || getTransactionType(item?.function_name?.S, item?.args?.S)} */}
                                {item?.transaction_type?.S || item?.function_name?.S}
                            </td>
                            <td className="address">
                                {/* {item?.objMetaData?.address ? ( */}
                                {item?.address?.S ? (
                                    <React.Fragment>
                                        {/* <tt>{formatAddress(item?.objMetaData?.address)}</tt> */}
                                        <tt>{formatAddress(item?.address?.S)}</tt>
                                        <Tooltip title="Copy address to clipboard" placement="bottom">
                                            <i
                                                className="bx bx-copy font-size-20"
                                                // onClick={(e) => handleCopyClick(e, item?.objMetaData?.address)}
                                                onClick={(e) => handleCopyClick(e, item?.address?.S)}
                                                onMouseEnter={() => setShowRowColor(false)}
                                                onMouseLeave={() => setShowRowColor(true)}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>pending...</React.Fragment>
                                )}
                            </td>
                            {/* <td className="chain">{(item?.objMetaData?.chain || "").toLowerCase()}</td> */}
                            <td className="chain">
                                <img src={networkIcons[item?.network?.S]} alt={item?.network?.S} className="smartcontracts-chain-logo" />
                                <span>{(item?.network?.S || "").toLowerCase()}</span>
                            </td>
                            <td className="owner">
                                {/* <tt>{formatAddress(item?.objMetaData?.contract_owner || "0x00000000000")}</tt> */}
                                <tt>{formatAddress(item?.contract_owner?.S || item?.sender?.S || "0x00000000000")}</tt>
                                {/* only display option to edit owner if contract is deployed */}
                                {item?.step?.S === "done" && (
                                    // {item?.objMetaData?.tx_id && (
                                    <Tooltip title="Edit Owner" placement="bottom">
                                        <i
                                            className="bx bx-edit"
                                            onClick={(e) => handleClickChangeOwner(e, item)}
                                            onMouseEnter={() => setShowRowColor(false)}
                                            onMouseLeave={() => setShowRowColor(true)}
                                        />
                                    </Tooltip>
                                )}
                            </td>
                            <td className="operator">{item?.objMetaData?.operators}</td>
                            <td className="action">
                                <Button
                                    outline
                                    className="delete-btn waves-effect waves-light"
                                    onClick={(e) => handleClickDeleteContract(e, item)}
                                    onMouseEnter={() => setShowRowColor(false)}
                                    onMouseLeave={() => setShowRowColor(true)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    return (
        <React.Fragment>
            <div className="content smart-contract">
                <BreadCrumb items={["Smart Contract"]} />
                <Card>
                    <CardBody>
                        <CardTitle className="h3">Smart Contracts</CardTitle>
                        <div className="contract-page-description">
                            <p>Below are all your created contracts (Idexo contracts, and your custom added contracts). </p>
                            <p>To add a custom contract, create a Smart Contract Module in a project!</p>
                        </div>
                        {/* <div className="d-flex justify-content-end mb-3">
                            <Button color="primary" className="add-contract-btn waves-effect w-xl" onClick={() => setOpenAddContractModal(true)}>
                                Add Contract
                            </Button>
                        </div> */}
                        {contractData.length !== 0 && <span className="contract-table-instructions">Click on a row to edit or view a contract</span>}
                        <div className="overflow-auto">{renderTable()}</div>
                    </CardBody>
                </Card>
            </div>

            {(openAddContractModal || detailViewInfo) && !showDeleteModal && (
                <AddContractModal
                    open={openAddContractModal || !!detailViewInfo}
                    onClose={handleAddContractModalClose}
                    onAddContract={handleAddContract} // also handles updating contracts
                    type={detailViewInfo ? "edit" : "add"}
                    contract={detailViewInfo}
                    modules={contractData}
                />
            )}

            <ChangeOwnerModal
                open={showChangeOwner}
                onClose={() => setShowChangeOwner(false)}
                addresses={addresses}
                onChangeOwner={handleChangeOwner}
            />

            {/* DELETE CONTRACT MODAL */}
            {showDeleteModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showDeleteModal}
                    setShowConfirmSubmitModal={setShowDeleteModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableDeleteButtons}
                    showConfirmLoading={showDeleteLoading}
                    handleClickConfirm={deleteContract}
                    modalTitle={"Delete Smart Contract"}
                    modalText={
                        "Deleting a contract deletes all of its contents from idexo storage and is not reversible. However, it does not delete the contract on the blockchain. Are you sure you want to delete this contract?"
                    }
                    confirmButtonText={"DELETE"}
                    confirmButtonLoading={"DELETING"}
                />
            )}
        </React.Fragment>
    )
}

export default SmartContract
