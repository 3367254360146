import React, { useEffect } from "react"
import jwt from "jsonwebtoken"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import ProfileMenu from "./Dropdowns/ProfileMenu"
import Logo from "../Logo"
import { toggleSideMenu } from "../../store/common/commonActions"
import WalletOptions from "../WalletOptions"

const Header = (props) => {
    const { Refresh, toggleSideMenu, sideMenuCollapsed } = props
    const toggleMenu = () => toggleSideMenu(!sideMenuCollapsed)
    const history = useHistory()

    // last activity
    localStorage.setItem("lastActive", new Date().getTime())

    useEffect(() => {
        const kickOut = () => {
            clearInterval(localStorage.jwtInterval)
            localStorage.removeItem("jwtInterval")
            localStorage.removeItem("jwtToken")
            localStorage.removeItem("user_tenants")
            localStorage.removeItem("selected_tenant")
            localStorage.removeItem("lastActive")
            history.push("/logout")
            history.push("/login")
        }
        const refreshJwt = () => {
            if (localStorage.jwtToken) {
                if (new Date().getTime() < Number(localStorage.lastActive) + 5.1 * 60 * 1000) {
                    if (Refresh) {
                        fetch("https://login.idexo.io", {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.jwtToken
                            },
                            json: true
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                if (data.error) {
                                    kickOut()
                                } else {
                                    localStorage.setItem("jwtToken", data.data)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                } else {
                    var decoded = jwt.decode(localStorage.jwtToken)
                    if (new Date().getTime() > decoded.exp * 1000) {
                        kickOut()
                    }
                }
            } else {
                if (Refresh) {
                    kickOut()
                }
            }
        }
        localStorage.setItem("lastActive", new Date().getTime())
        if (localStorage.jwtToken) {
            try {
                var decoded = jwt.decode(localStorage.jwtToken)
                if (new Date().getTime() < Number(decoded.exp) * 1000) {
                    clearInterval(localStorage.jwtInterval)
                    const x = setInterval(refreshJwt, 5 * 60 * 1000)
                    localStorage.setItem("jwtInterval", x)
                }
            } catch (err) {
                console.log(err)
            }
        }

        return () => {
            clearInterval(localStorage.jwtInterval)
        }
    }, [Refresh, history])

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <button
                            type="button"
                            onClick={toggleMenu}
                            className="btn btn-sm font-size-22 header-item waves-effect"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <Logo />
                    </div>
                    <div className="d-flex">
                        {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button
                                onClick={() => setIsSearch(!isSearch)}
                                type="button"
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                            >
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div
                                className={
                                isSearch
                                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search ..."
                                        aria-label="Recipient's username"
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="submit">
                                        <i className="mdi mdi-magnify"></i>
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div> */}

                        {/* <div className="d-flex">
                            <button
                                type="button"
                                onClick={toggleFullscreen}
                                className="btn header-item expand noti-icon waves-effect"
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen"></i>
                            </button>
                        </div> */}
                        <WalletOptions />
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    sideMenuCollapsed: state.commonReducer.sideMenuCollapsed
})

const mapDispatchToProps = {
    toggleSideMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
