import React, { createContext, useState, useContext } from "react"
import { Toast, ToastBody, Button } from "reactstrap"
import { ReactComponent as SuccessIcon } from "../../assets/icons/Success.svg"
import { ReactComponent as ErrorIcon } from "../../assets/icons/Error.svg"
import { ReactComponent as WarningIcon } from "../../assets/icons/Warning.svg"

import "./styles.scss"

const ToastContext = createContext({
    addError: () => {},
    addSuccess: () => {},
    addInfo: () => {}
})

const AlertIcon = (type) => {
    switch (type) {
        case "success":
            return <SuccessIcon />
        case "error":
            return <ErrorIcon />
        case "info":
            return <WarningIcon />
        default:
            return <></>
    }
}

const CustomToast = (props) => {
    const { children, setSuccess, setError, setInfo } = props
    const [toastMessage, setToastMessage] = useState("")
    const [messageType, setMessageType] = useState("")

    const setMessage = (type, value) => {
        switch (type) {
            case "success":
                setSuccess && setSuccess(value)
                break
            case "error":
                setError(value)
                break
            case "info":
                setInfo(value)
                break
            default:
                break
        }
    }

    const showAlert = (message, type) => {
        setMessage(message, type)
        setTimeout(onClose, 5000)
    }

    const onClose = () => {
        setMessage(messageType, null)
        setToastMessage("")
        setMessageType("")
    }

    const addError = (message) => {
        setToastMessage(message)
        setMessageType("error")
        showAlert(message, "error")
    }
    const addSuccess = (message) => {
        setToastMessage(message)
        setMessageType("success")
        showAlert(message, "success")
    }
    const addInfo = (message) => {
        setToastMessage(message)
        setMessageType("info")
        showAlert(message, "info")
    }

    return (
        <ToastContext.Provider value={{ addError, addSuccess, addInfo }}>
            {children}
            {toastMessage && (
                <Toast className={messageType}>
                    <ToastBody>
                        {AlertIcon(messageType)}
                        <span>{toastMessage}</span>
                        <Button onClick={onClose} className="closeButton">
                            <i className="bx bx-x bx-sm" />
                        </Button>
                    </ToastBody>
                </Toast>
            )}
        </ToastContext.Provider>
    )
}

const useToast = () => {
    const context = useContext(ToastContext)
    if (!context) {
        throw new Error("index should be inside ToastProvider")
    }
    return context
}

export { CustomToast as ToastProvider, useToast }
