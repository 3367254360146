import React from "react"
import { PersistGate } from "redux-persist/integration/react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { UseWalletProvider } from "./libs/useMetaMaskWallet"
import { store, persistor } from "./store/index"
import { ToastProvider } from "./hooks/toast"
// import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <ToastProvider>
                    <UseWalletProvider
                        chainIds={[1, 3, 137, 300, 324, 43114]}
                        connector={"metamask"}
                        connectors={{
                            fortmatic: { apiKey: "" },
                            portis: { dAppId: "" },
                            walletconnect: {
                                rpc: {
                                    1: "https://mainnet.mycustomnode.com",
                                    3: "https://ropsten.mycustomnode.com",
                                    56: "https://bsc-dataseed.binance.org:443",
                                    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
                                    100: "https://dai.poa.network"
                                }
                            }
                        }}
                    >
                        <App />
                    </UseWalletProvider>
                </ToastProvider>
            </React.StrictMode>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
