import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, Table, ModalFooter, Card, CardBody, CardTitle } from "reactstrap"
import { Player } from "video-react"
import jwt from "jsonwebtoken"

import EditTemplateModal from "../Components/EditTemplateModal/EditTemplateModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import OkDialog from "../Components/OkDialog/OkDialog"
import BreadCrumb from "../Components/BreadCrumb"

const apiUrl = "https://templates.idexo.io/"

const Templates = ({
    project,
    module,
    clickProjects,
    clickCancel,
    // storage,
    ...rest
}) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const [showLoading, setShowLoading] = useState(false) // for fetching templates- table loading animation
    const [alertMessage, setAlertMessage] = useState("") // if error fetching templates
    const [templates, setTemplates] = useState([]) // array of objects (each object referring to an template item that has already been uploaded)
    const [template, setTemplate] = useState(null) // object referring to template item- set when user clicks on table row
    const [showNFTRowColor, setShowNFTRowColor] = useState(false) // template table row changes color on mouse hover (but not if mouse over buttons or image)
    const [showViewImageModal, setShowViewImageModal] = useState(false)
    const [fromUri, setFromUri] = useState(false)

    // const [showBulkUploadModal, setShowBulkUploadModal] = useState(false)
    const [okAlertMessage, setOkAlertMessage] = useState("") // string- if user tries to add more nfts than cap, or collection cap is locked

    // add/edit/view template modal
    const [showEditTemplateModal, setShowEditTemplateModal] = useState(false) // also for ADD/EDIT/VIEW Template Modal
    const [modalAlertMessage, setModalAlertMessage] = useState("") // for mint and add/edit modals
    const [alertColor, setAlertColor] = useState("")
    const [showConfirmLoading, setShowConfirmLoading] = useState(false) // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
    const [disableModalButtons, setDisableModalButtons] = useState(false) // for all modals

    const fetchTemplates = async (isUseEffectCall) => {
        setShowLoading(true)
        setAlertMessage("")

        try {
            let finalUri = apiUrl + "?action=get_all_templates"

            if (decoded.api_key) {
                const res = await fetch(finalUri, {
                    method: "GET",
                    headers: { "x-api-key": decoded.api_key, "Content-Type": "application/json" }
                })
                const response = await res.json()

                if (response.code !== 200 || response.error || response.message === "Internal server error") {
                    setAlertMessage("Error: " + response.error)
                    setShowLoading(false)
                    return
                }

                setShowLoading(false)
                if (isUseEffectCall) return response.data
                setTemplates(response.data)
            } else {
                setAlertMessage("Error: No API Key")
            }
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
    }

    // runs once after initial mount
    useEffect(() => {
        let running = false
        const main = async function () {
            running = true
            const templates = await fetchTemplates(true)
            if (running) {
                setTemplates(templates)
                running = false
            }
        }

        main()

        return () => {
            running = false
            setShowLoading(false)
        }

        // eslint-disable-next-line
    }, [])

    const popTemplate = async (id) => {
        try {
            let index = templates.findIndex((template) => template.id === id)
            console.log("INDEX", index)
            if (index !== -1) {
                let temp = [...templates]
                temp.splice(index, 1)
                setTemplates(temp)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeleteTemplate = async (id, idx) => {
        try {
            setShowLoading(true)
            let finalUri = apiUrl

            if (decoded.api_key) {
                popTemplate(id)

                const res = await fetch(finalUri, {
                    method: "DELETE",
                    headers: { "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                    body: JSON.stringify({ id })
                })
                const response = await res.json()

                if (response.code !== 200 || response.error || response.message === "Internal server error") {
                    setAlertMessage("Error: " + response.error)
                    setShowLoading(false)
                    return
                }

                fetchTemplates()
            }
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
    }

    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        return s3Response
    }

    // Get S3 presigned-url
    const fetchPresignedURL = async (s3Key, getObject = false) => {
        return fetch("https://projects.idexo.io/", {
            method: "POST",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
            body: JSON.stringify({
                action: "fetchPresignedURL",
                s3Params: { Key: s3Key },
                getObject: getObject // if true, then we are getting an image from lambda instead of uploading to s3
            })
        })
    }

    // ADDING (action = create_draft) a new item or UPDATING (action = update_draft) an existing item in table
    // TO DO: save_template, edit_template, create_draft_from_template
    const handleSubmit = async (e, templateInfo, action, img, imageType, setImg) => {
        console.log("action", action)
        const id = template?.id // will be undefined if createing a new template (i.e. action == "draft_template")
        let body

        if (action === "save_template" || action === "edit_template") {
            body = {
                id: id
            }
        } else if (action === "create_from_uri") {
            body = {
                custom_id: templateInfo.custom_id,
                uri: templateInfo.uri
            }
        } else {
            body = {
                id: id,
                custom_id: templateInfo.custom_id,
                metadata: {
                    name: templateInfo.name,
                    description: templateInfo.description,
                    image: templateInfo?.attributes?.image || "",
                    external_url: templateInfo?.external_url || "",
                    attributes: templateInfo?.attributes?.length === 0 ? [] : templateInfo?.attributes
                },
                type: "projects"
            }
        }

        try {
            setShowConfirmLoading(true)
            setModalAlertMessage("")
            setDisableModalButtons(true)

            // only upload image to s3 if user has just selected an image
            if (img) {
                const s3Key = `templates/${String(new Date().getTime())}`

                // get presigned s3 URL to upload
                const res = await fetchPresignedURL(s3Key)
                const response = await res.json()
                console.log("fetchPresignedURL", response)

                // error getting presigned URL
                if (response?.code !== 200 || response?.error) return processErrors("Error Uploading Template Image: " + response?.error)

                // upload image to public bucket, with public URL
                const uploadUrl = JSON.parse(response.data)?.url
                const s3UploadResponse = await uploadImageToS3(uploadUrl, img)
                console.log("s3UploadResponse", s3UploadResponse)

                // error uploading to s3
                if (!s3UploadResponse.ok) return processErrors("Error Uploading To S3")

                body.image_url = `https://s3.us-east-1.amazonaws.com/imagespublic-738087076124/${s3Key}`
                body.image_type = imageType
            }

            let finalUri = apiUrl + `?action=${action}`

            if (decoded.api_key) {
                const res = await fetch(finalUri, {
                    method: "POST",
                    headers: { "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                    body: JSON.stringify(body)
                })
                const response = await res.json()
                console.log(`${action} response`, response)

                if (response.code !== 200 || response.error || response.message === "Internal server error") {
                    return processErrors("Error: " + response.error)
                }

                setImg(null)

                setTemplate(response.data[0])
                setModalAlertMessage("success!")
                setAlertColor("success")
                fetchTemplates()
                setTimeout(() => {
                    setShowEditTemplateModal(false)
                    setFromUri(false)
                    setTemplate(null)
                    setModalAlertMessage(null)
                }, 2000)
            } else {
                setModalAlertMessage("Error: No API Key")
                setAlertColor("danger")
            }
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
        setDisableModalButtons(false)
    }

    const processErrors = (errorMessage) => {
        setModalAlertMessage(errorMessage)
        setAlertColor("danger")
        setShowConfirmLoading(false)
        setDisableModalButtons(false)
    }

    const createDraftFromTemplate = async (from_id) => {
        try {
            setShowLoading(true)

            let finalUri = apiUrl + "?action=create_draft_from_template"

            const res = await fetch(finalUri, {
                method: "POST",
                headers: { "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                body: JSON.stringify({ from_id })
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error") {
                setAlertMessage("Error: " + response.error)
                setShowLoading(false)
                return
            }

            setTemplate(response.data[0])
            console.log("Create response", response.data)
            fetchTemplates()
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
        setShowEditTemplateModal(true)
    }

    const handleCreateFromTemplate = async (from_id) => {
        await createDraftFromTemplate(from_id)
    }

    // for table row
    const renderAttributes = (attributes) => {
        // attributes === stringified array of objects. [{"trait_type": "color", "value": "blue"}, {"trait_type": "location", "value": "italy"}]
        if (attributes === undefined) return null
        // attributes = JSON.parse(attributes)
        return (
            <div className="attributes-inner">
                {attributes.map((attribute, idx) => {
                    return (
                        <div key={idx}>
                            {attribute.trait_type} : {attribute.value}
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderTemplates = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"Templates"} />
        }

        if (templates === null || templates === undefined) {
            return <LoadingCard errorTxt={"Templates"} alertMessage={alertMessage} />
        }

        if (templates.length === 0) {
            // NOTE* UploadFile is a Dropzone Wrapper. Currently we can not drop files into browser to upload
            // return <UploadFile files={uploadFiles} handleChange={setUploadFiles} handleSaveTemplate={handleSaveTemplate} />
            return (
                <LoadingCard
                    emptyTxt={"Templates"}
                    icon="bx-image-add"
                    onClick={() => {
                        setFromUri(false)
                        setShowEditTemplateModal(true)
                    }}
                    className="add-nft"
                />
            )
        }

        return (
            <div className="overflow-auto">
                <Table className="templates-table align-middle mb-0 table-nowrap">
                    <thead>
                        <tr>
                            <th scope="col" className="nft-name">
                                {""}
                            </th>
                            <th scope="col" className="nft-name">
                                Name
                            </th>
                            <th scope="col" className="nft-description">
                                Description
                            </th>
                            <th scope="col">Image</th>

                            <th scope="col" className="nft-attributes">
                                Attributes
                            </th>
                            <th scope="col" className="nft-premint"></th>

                            <th scope="col" className="nft-status"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates?.slice().map((template, idx) => {
                            return (
                                <tr
                                    key={idx}
                                    className={"nft-row" + (showNFTRowColor ? " show-background" : "")}
                                    onClick={(e) => {
                                        setTemplate(template)
                                        setFromUri(false)
                                        setShowEditTemplateModal(true)
                                    }}
                                    onMouseEnter={() => setShowNFTRowColor(true)}
                                    onMouseLeave={() => setShowNFTRowColor(false)}
                                >
                                    <td>{template?.step === "draft" ? "DRAFT" : "LIVE"}</td>
                                    <td className="name">
                                        <span className="name-val">{template?.metadata?.name}</span>
                                        {/* <div className="mobile-ui-description">{template?.metadata?.description}</div> */}
                                    </td>
                                    <td className="description-row">{template?.metadata?.description}</td>
                                    <td className="image">
                                        {/* TO DO: show loading animation for nft images/videos */}
                                        {/* if user hasn't uploaded an image, show default upload image icon */}
                                        {!template?.image_url ? (
                                            <div className="empty-photo">
                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {template?.image_type === "video/mp4" || template?.image_type === "video/quicktime" ? (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setTemplate(template)
                                                            setShowViewImageModal(true)
                                                        }}
                                                        onMouseEnter={() => setShowNFTRowColor(false)}
                                                        onMouseLeave={() => setShowNFTRowColor(true)}
                                                    >
                                                        <Player
                                                            src={template?.image_url}
                                                            playsInline
                                                            controls={false}
                                                            autoPlay={true}
                                                            loop={true}
                                                            className="template-table-video-preview"
                                                        />
                                                    </span>
                                                ) : (
                                                    <img
                                                        alt="imgdata"
                                                        src={template?.image_url || ""}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setTemplate(template)
                                                            setShowViewImageModal(true)
                                                        }}
                                                        onMouseEnter={() => setShowNFTRowColor(false)}
                                                        onMouseLeave={() => setShowNFTRowColor(true)}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className="attributes">{renderAttributes(template?.metadata?.attributes)}</td>
                                    <td className="premint-row">
                                        <Button
                                            color="secondary"
                                            outline
                                            className={"premint-nft-btn waves-effect waves-light " + template?.step}
                                            title="Duplicate this template into a new draft template"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleCreateFromTemplate(template?.id)
                                            }}
                                            onMouseEnter={() => setShowNFTRowColor(false)}
                                            onMouseLeave={() => setShowNFTRowColor(true)}
                                        >
                                            DRAFT FROM
                                        </Button>
                                    </td>
                                    <td className="status">
                                        <React.Fragment>
                                            <Button
                                                color="secondary"
                                                outline
                                                className={"edit-template-btn waves-effect waves-light " + template?.step}
                                                onClick={(e) => {
                                                    e.stopPropagation() // prevents event from bubbling up- stops parent click handler from being run (prevents edit template modal from opening)
                                                    setTemplate(template)
                                                    setFromUri(false)
                                                    setShowEditTemplateModal(true)
                                                }}
                                                onMouseEnter={() => setShowNFTRowColor(false)}
                                                onMouseLeave={() => setShowNFTRowColor(true)}
                                            >
                                                {template?.step === "draft" ? "EDIT" : "VIEW"}
                                            </Button>
                                            <i
                                                className={"bx bx-trash delete-nft " + (template?.step === "premint" ? "hide" : "")}
                                                title="Delete draft template"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleDeleteTemplate(template?.id)
                                                }}
                                                onMouseEnter={() => setShowNFTRowColor(false)}
                                                onMouseLeave={() => setShowNFTRowColor(true)}
                                            ></i>
                                        </React.Fragment>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className="templates-page">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, "Templates"]}
                links={["projects", "projects", "projects"]}
                linkFunctions={[clickProjects, clickCancel]}
            />
            <div className="back-to-project">
                <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn">
                    Back to Project
                </Button>
            </div>

            <Card>
                <CardBody>
                    <CardTitle className="h3">Templates: {module?.module_name?.S}</CardTitle>

                    <div className="add-table-item-row">
                        <Button
                            color="primary"
                            onClick={() => {
                                setFromUri(false)
                                setShowEditTemplateModal(true)
                            }}
                        >
                            ADD TEMPLATE
                        </Button>

                        <Button
                            style={{ marginLeft: 5 }}
                            color="primary"
                            onClick={() => {
                                setFromUri(true)
                                setShowEditTemplateModal(true)
                            }}
                        >
                            ADD FROM URI
                        </Button>
                    </div>

                    <div className="table-title">TEMPLATES</div>
                    {/* <Form className="filters-group-wrap">
                        <Label className="d-block filterby-group">FILTER BY</Label>
                        <div className="filters-group-grid">
                            <FormGroup check onClick={null}>
                                <Input type="checkbox" checked={true} />
                                <Label>draft</Label>
                            </FormGroup>
                            <FormGroup check onClick={null}>
                                <Input type="checkbox" checked={false} />
                                <Label>live</Label>
                            </FormGroup>
                        </div>
                    </Form> */}
                    {renderTemplates()}
                </CardBody>
            </Card>

            {/* SELECTED IMAGE EXPANDED MODAL */}
            {template && showViewImageModal && (
                <Modal
                    className="enlarge-image-modal"
                    isOpen={showViewImageModal}
                    onClose={() => setShowViewImageModal(false)}
                    fade={true}
                    centered={true}
                    toggle={() => setShowViewImageModal(false)}
                >
                    <ModalHeader toggle={() => setShowViewImageModal(false)}>Template Image</ModalHeader>
                    <ModalBody>
                        <React.Fragment>
                            {template?.image_type === "video/mp4" || template?.image_type === "video/quicktime" ? (
                                <Player
                                    src={template?.image_url}
                                    playsInline
                                    controls={false}
                                    autoPlay={true}
                                    loop={true}
                                    className="enlarge-nft-video-preview"
                                />
                            ) : (
                                <img alt="imgdata" src={template?.image_url} className="enlarge-image-modal-img" />
                            )}
                        </React.Fragment>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={() => setShowViewImageModal(false)} className="btn-light waves-effect ok-btn">
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            )}

            {/* ADD/EDIT/VIEW TEMPLATE MODAL */}
            {showEditTemplateModal && (
                <EditTemplateModal
                    showConfirmSubmitModal={showEditTemplateModal}
                    setShowConfirmSubmitModal={setShowEditTemplateModal}
                    fromUri={fromUri}
                    setFromUri={setFromUri}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    template={template}
                    setTemplate={setTemplate}
                    handleSubmit={handleSubmit}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation for Add/Edit/Mint/Premint/PremintAll/Delete modals
                    disableAllButtons={disableModalButtons}
                    storage={template?.metadata_storage || "arweave"}
                />
            )}

            <OkDialog
                open={!!okAlertMessage}
                message={okAlertMessage}
                okClick={() => {
                    setOkAlertMessage("")
                }}
            />
        </div>
    )
}

export default Templates
