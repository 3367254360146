import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, CardTitle, Input } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import * as yup from "yup"
import { allFormsValid, updateModules } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./QuestionModal.scss"

const apiUrl = "https://projects.idexo.io/"

// For ADDING, EDITING, or VIEWING a question to an NFT survey
const QuestionModal = ({
    project,
    modules,
    module,
    showQuestionModal,
    setShowQuestionModal,
    selectedQuestion,
    setSelectedQuestion,
    selectedQuestionIdx, // index in module.questions.SS array that selectedQuestion refers to (for easy updating/removal)
    setSelectedQuestionIdx,
    fetchSurveyData,
    disableAllButtons // if contract is pending or done
}) => {
    // selectedQuestion === question user selected from QuestionsPage table row
    const [showConfirmLoading, setShowConfirmLoading] = useState(false) // spinner animation to show over "SUBMIT" button
    const [disableButtonsAndForms, setDisableButtonsAndForms] = useState(false)
    const [alertColor, setAlertColor] = useState("") // "danger", or "success"
    const [alertMessage, setAlertMessage] = useState("")

    // Form States
    const [surveyQuestion, setSurveyQuestion] = useState(selectedQuestion?.title || "")
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState(selectedQuestion?.choices || ["", ""])

    const handleCloseModal = () => {
        setShowQuestionModal(false)
        setSelectedQuestion(null) // reset form data
        setSelectedQuestionIdx(null)
    }

    // ADDING a new question or UPDATING an existing one
    const handleClickSubmit = async () => {
        try {
            setShowConfirmLoading(true)
            setAlertMessage("")

            let newModule = {
                M: {
                    type: { S: module.type.S }, // "nft surveys"
                    inserted: { N: String(module.inserted.N) },
                    module_name: { S: module.module_name.S },
                    survey_name: { S: module.survey_name.S },
                    description: { S: module.description.S },
                    chain: { S: module.chain.S }, // ex. "fantom"
                    deposit_token: { S: module.deposit_token.S },
                    reward_token: { S: module.reward_token.S },
                    required_nfts: { S: module.required_nfts.S },
                    contract_type: { S: module.contract_type.S }, // ex. "standard"
                    status: { S: module.status.S } // ex. "draft" -> "pending" -> "done"
                    // questions: { array of stringified JS objects
                    //     SS: [
                    //         '{ title: "next feature", choices: ["nft surveys", "bulk nfts upload"] }',
                    //         '{ title: "solana support?", choices: ["yes", "no"] }'
                    //     ]
                    // }
                }
            }
            if (module?.address?.S) newModule.M.address = { S: module.address.S }

            let newQuestions
            // if we're adding a question
            if (!selectedQuestion) {
                newQuestions = module?.questions?.SS?.slice() || []
                newQuestions.push(JSON.stringify({ title: surveyQuestion, choices: multipleChoiceAnswers })) // stringify for easy storage in dynamo/to avoid nesting

                // else we're editing a question
            } else {
                // replace string in module.questions.SS array
                newQuestions = module?.questions?.SS?.slice()
                newQuestions[selectedQuestionIdx] = JSON.stringify({ title: surveyQuestion, choices: multipleChoiceAnswers })
            }
            newModule.M.questions = { SS: newQuestions }

            let oldModules = modules.slice() // shallow copy
            let newModules = updateModules(oldModules, newModule)
            newModules = newModules.filter((mod) => !mod?.objKey) // remove smart contracts from modules (since we keep them separate from dynamo)

            // save or update project item in dynamodb user_projects table
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule", // also updates modules
                    project_name: project.project_name.S,
                    inserted: String(project.inserted.N),
                    modules: newModules // updated modules to replace in backend
                })
            })
            const response = await res.json()
            console.log("createNewModule", response)

            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setShowConfirmLoading(false)
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                return setAlertColor("danger")
            } else {
                setAlertMessage("Success! Going back to the questions page...")
                setAlertColor("success")
                setDisableButtonsAndForms(true)
                fetchSurveyData() // forces parent, and parents parent to render/refresh stale prop data (module, modules)
                setTimeout(async () => {
                    handleCloseModal()
                }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
    }

    const handleAddChoice = (_e) => {
        let choicesCopy = multipleChoiceAnswers.slice()
        choicesCopy.push("")
        setMultipleChoiceAnswers(choicesCopy)
    }

    const handleRemoveChoice = (index) => {
        let choicesCopy = multipleChoiceAnswers.slice() // shallow copy old state so we don't modify it directly
        choicesCopy.splice(index, 1) // delete key/value pair in copy
        setMultipleChoiceAnswers(choicesCopy)
    }

    return (
        <Modal
            isOpen={showQuestionModal}
            centered={true}
            toggle={() => !disableButtonsAndForms && !showConfirmLoading && handleCloseModal()}
            className="question-modal"
        >
            <AvForm
                initialValues={{
                    // NOTE! keys have to match value at "name" attribute in AvField
                    "survey-question": surveyQuestion
                }}
                enableReinitialize // ? this works without the enableReinitialize property?!
                validationSchema={yup.object().shape({
                    "survey-question": yup.string().required("This field is required.")
                })}
                onSubmit={handleClickSubmit}
            >
                <ModalHeader
                    toggle={
                        showConfirmLoading || disableButtonsAndForms
                            ? null
                            : () => !disableButtonsAndForms && !showConfirmLoading && handleCloseModal()
                    }
                >
                    {!!selectedQuestion ? "Edit Question" : "Add Question"}
                </ModalHeader>

                <ModalBody>
                    <FormGroup className="mb-3">
                        <AvField
                            label="Survey Question"
                            type="text"
                            className="form-control"
                            id="survey-question"
                            name="survey-question"
                            placeholder="Enter Survey Question"
                            required
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setSurveyQuestion(e.target.value)}
                        />
                    </FormGroup>

                    <CardTitle className="h3">Enter Choices (Minimum Two)</CardTitle>

                    {multipleChoiceAnswers.map((choice, idx) => {
                        return (
                            <div key={idx} className="choices-row">
                                <div className="choices-col">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder={`Enter Choice ${idx + 1}`}
                                            name={`choice-${idx + 1}`}
                                            value={choice}
                                            onChange={(e) => {
                                                // shallow copy old state so we don't modify state directly
                                                let choicesCopy = multipleChoiceAnswers.slice()
                                                choicesCopy[idx] = e.target.value
                                                setMultipleChoiceAnswers(choicesCopy)
                                            }}
                                            disabled={showConfirmLoading || disableAllButtons}
                                        />
                                    </FormGroup>
                                </div>
                                {/* If there are 3 or more choices, display remove choice button on each row */}
                                {multipleChoiceAnswers.length >= 3 && !disableAllButtons && (
                                    <i
                                        className="bx bx-trash delete-choice"
                                        onClick={() => handleRemoveChoice(idx)}
                                        disabled={showConfirmLoading}
                                        title="Delete Choice"
                                    ></i>
                                )}
                            </div>
                        )
                    })}

                    <FormGroup>
                        <Button className="waves-effect add-choice" onClick={handleAddChoice} disabled={showConfirmLoading || disableAllButtons}>
                            Add Choice
                        </Button>
                    </FormGroup>
                </ModalBody>

                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableButtonsAndForms ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                        {alertMessage}
                    </Alert>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={
                            showConfirmLoading ||
                            disableButtonsAndForms ||
                            disableAllButtons ||
                            !allFormsValid([surveyQuestion, ...multipleChoiceAnswers])
                        }
                    >
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={"SUBMITTING"}
                            txtFalse={"SUBMIT"}
                            disabled={showConfirmLoading || disableButtonsAndForms || !allFormsValid([surveyQuestion, ...multipleChoiceAnswers])}
                        />
                    </Button>
                    <Button
                        color="secondary"
                        outline
                        onClick={handleCloseModal}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableButtonsAndForms}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default QuestionModal
