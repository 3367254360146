import React, { useState, useEffect, useCallback } from "react"
import { Col, Row } from "reactstrap"
import jwt from "jsonwebtoken"

import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
// import WaitingModal from "../Components/WaitingModal/WaitingModal"
import ProgressCard from "../Components/ProgressCard/ProgressCard"
import ERC20Card from "../Components/ERC20Card/ERC20Card"
import BreadCrumb from "../Components/BreadCrumb"

const apiUrl = "https://erc20tokens.idexo.io/" // backend

const ERC20Creator = ({ project, module, handleClickAddTokens, clickProjects, clickCancel, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    // ERC20 Token Component
    const [ERC20, setERC20] = useState(null) // object- original data from backend (used to compare changes in form input so user doesn't submit same data)

    // Progress Table Card Component
    const [showDetailsButtons, setShowDetailsButtons] = useState({}) // keys = button indexes, values = booleans, where true = show button, false = don't show button
    const [showProgressSpinner, setShowProgressSpinner] = useState(false) // for loading animation of progress table
    const [progressAlertMessage, setProgressAlertMessage] = useState("") // if error fetching campaign data

    var intervalId = null

    // every 10 seconds, check if contract has been successfully deployed/done, & update progress table state if done
    const checkPending = async (txId) => {
        const res = await fetch(apiUrl + "?action=checkpending&txid=" + (txId || ERC20?.tx_id?.S), {
            method: "GET",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
        })
        const response = await res.json()

        // TO DO: exponential back off maybe? stop checkPending if too many error responses
        if (response.error || response.code !== 200) return console.log(response.error)

        if (response.data?.step?.S === "done") {
            let newERC20 = { ...ERC20 }
            newERC20.source.S = "done"
            setERC20(newERC20) // this will update ERC20 deploy button tooltip to show "done" instead of "pending"
            clearInterval(intervalId)
            intervalId = null
        }
    }

    // checks transaction status of sdk Tokens.createTokenCapped (or Uncapped) contract
    const checkTransactionStatus = useCallback(
        async (erc20TokensItem) => {
            try {
                // if transaction (of erc20 token contract) is done, update dynamo and s3
                const res = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                        "x-api-key": decoded.api_key,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        action: "checkTransactionStatus",
                        tx_id: erc20TokensItem.tx_id.S,
                        inserted: erc20TokensItem.inserted.N,
                        project_name: project.project_name.S
                    })
                })
                const response = await res.json()
                console.log("checkTransactionStatus", response)

                if (response.error || response.code !== 200) return console.log(response.error)

                // TO DO: figure out a way to prevent fetching twice!
                // if collection contract succesfully deployed, update child component (progress table) state source to show "done"
                if (response?.data?.step?.S === "done") fetchModuleData() // forces state update (if we try updating ERC20 state, it will get overwritten later with old data)
            } catch (err) {
                console.log(err)
            }
        },

        // eslint-disable-next-line
        [project.project_name.S]
    )

    const fetchModuleData = useCallback(async () => {
        setShowProgressSpinner(true)
        setProgressAlertMessage("")

        try {
            // TO DO: update backend api to only return one erc20 token module instead of all modules that are erc20 tokens
            // fetches ALL items in erc20tokens table that belong to user_id (we will filter out later below)
            let finalUri = apiUrl + "?action=getitemsbyuserid"

            const res = await fetch(finalUri, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
            })
            const response = await res.json()
            console.log("fetchModuleData", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                setProgressAlertMessage("Error: " + (response.error || response.data.error))
                setShowProgressSpinner(false)
                return
            }

            // get only erc20 data with a module inserted time as the module that the user clicked on in the modules table (projectView.js)- there should only be 1 after we filter
            let erc20Data = response.data.Items.filter((item) => {
                return item?.module_inserted?.N === module?.inserted?.N
            })[0]
            setERC20(erc20Data || {})
            // if status is pending, check backend for updates (to see if status changed to deployed/done)
            if (erc20Data?.source?.S === "pending") checkTransactionStatus(erc20Data)
        } catch (err) {
            console.log(err)
            setProgressAlertMessage("Error: " + err)
        }
        setShowProgressSpinner(false)

        // eslint-disable-next-line
    }, [module?.inserted?.N, checkTransactionStatus])

    // runs once on initial mount
    useEffect(() => {
        fetchModuleData()
    }, [fetchModuleData])

    return (
        <React.Fragment>
            <div className="erc20-creator">
                <BreadCrumb
                    items={["projects", `${project?.project_name?.S}`, `ERC20 Module: ${module?.module_name?.S}`]}
                    links={["projects", "projects"]}
                    linkFunctions={[clickProjects, clickCancel]}
                />

                <Row>
                    <Col xs={0} sm={0} md={0}>
                        <Row>
                            <Col>
                                {ERC20 ? (
                                    <ERC20Card
                                        project={project}
                                        module={module}
                                        ERC20={ERC20}
                                        fetchModuleData={fetchModuleData}
                                        checkPending={(txId) => {
                                            intervalId = setInterval(() => checkPending(txId), 10000)
                                        }}
                                    />
                                ) : (
                                    <React.Fragment>
                                        {showProgressSpinner && (
                                            <LoadingCard loadingTxt={"your ERC20 data"} backgroundColor="white" className="erc20-loading" />
                                        )}
                                        {progressAlertMessage && (
                                            <LoadingCard
                                                errorTxt={"ERC20 data"}
                                                alertMessage={progressAlertMessage}
                                                backgroundColor="white"
                                                className="erc20-loading"
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <ProgressCard
                                    showProgressSpinner={showProgressSpinner}
                                    // TO DO:
                                    // showSpinner={showSpinner} // spinner for sibling component buttons (ex. 'Deploy' in ERC20Card)
                                    showDetailsButtons={showDetailsButtons}
                                    setShowDetailsButtons={setShowDetailsButtons}
                                    handleClickAddNFT={handleClickAddTokens}
                                    loadingErrorTxt={"ERC20 data"} // part of the text that shows when component is loading or there's an error
                                    campaignData={ERC20}
                                    progressAlertMessage={progressAlertMessage}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* triggers after user clicks "Deploy" or similar action */}
                {/* TO DO: */}
                {/* <WaitingModal isOpen={showSpinner && buttonId === 2} modalType={"none"} text={"Contract is deploying. Kindly wait..."} /> */}
            </div>
        </React.Fragment>
    )
}

export default ERC20Creator
