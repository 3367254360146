import {
    TOGGLE_SIDE_MENU,
    SET_NETWORK_OPTION,
    SET_VALIDATION_STATUS,
    SET_POINT_BALANCE,
    SET_TWITTER_LOGGED_IN,
    SET_TWITTER_USERNAME,
    SET_USER_LOGGEDIN,
    SET_NETWORK,
    SET_ALL_TOKENS
} from "./commonActions"

const initialState = {
    sideMenuCollapsed: false,
    networkOptionStatus: "Ethereum",
    validationStatus: "",
    pointBalance: 0,
    twitterLoggedIn: false,
    twitterUserName: "",
    loggedIn: false,
    network: "",
    allTokens: []
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_TOKENS:
            return {
                ...state,
                allTokens: action.payload
            }
        case SET_USER_LOGGEDIN:
            return {
                ...state,
                loggedIn: action.payload
            }
        case SET_NETWORK:
            return {
                ...state,
                network: action.payload
            }
        case TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuCollapsed: action.payload
            }
        case SET_NETWORK_OPTION:
            return {
                ...state,
                networkOptionStatus: action.payload
            }
        case SET_VALIDATION_STATUS:
            return {
                ...state,
                validationStatus: action.payload
            }
        case SET_POINT_BALANCE:
            return {
                ...state,
                pointBalance: action.payload
            }
        case SET_TWITTER_LOGGED_IN:
            return {
                ...state,
                twitterLoggedIn: action.payload
            }
        case SET_TWITTER_USERNAME:
            return {
                ...state,
                twitterUserName: action.payload
            }
        default:
            return state
    }
}

export default commonReducer
