export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU"
export const SET_NETWORK_OPTION = "SET_NETWORK_OPTION"
export const SET_VALIDATION_STATUS = "SET_VALIDATION_STATUS"
export const SET_POINT_BALANCE = "SET_POINT_BALANCE"
export const SET_TWITTER_LOGGED_IN = "SET_TWITTER_LOGGED_IN"
export const SET_TWITTER_USERNAME = "SET_TWITTER_USERNAME"
export const SET_USER_LOGGEDIN = "SET_USER_LOGGEDIN"
export const SET_NETWORK = "SET_NETWORK"
export const SET_ALL_TOKENS = "SET_ALL_TOKENS"

export const setAllTokens = (value) => ({
    type: SET_ALL_TOKENS,
    payload: value
})

export const setLogin = (value) => ({
    type: SET_USER_LOGGEDIN,
    payload: value
})

export const setNetwork = (value) => ({
    type: SET_NETWORK,
    payload: value
})

export const toggleSideMenu = (value) => ({
    type: TOGGLE_SIDE_MENU,
    payload: value
})

export const setNetworkOption = (value) => ({
    type: SET_NETWORK_OPTION,
    payload: value
})

export const setValidationStatus = (value) => ({
    type: SET_VALIDATION_STATUS,
    payload: value
})

export const setPointBalance = (value) => ({
    type: SET_POINT_BALANCE,
    payload: value
})

export const setTwitterLoggedIn = (value) => ({
    type: SET_TWITTER_LOGGED_IN,
    payload: value
})

export const setTwitterUserName = (value) => ({
    type: SET_TWITTER_USERNAME,
    payload: value
})
