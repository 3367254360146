import contractAbi from "../abi/PolygonCommunityNFT.json"

class PolygonCommunityNFT {
    constructor(web3, address) {
        this.web3 = web3
        this.contract = new this.web3.eth.Contract(contractAbi.abi, address)
    }

    async getBalance(address) {
        return await this.contract.methods.balanceOf(address).call()
    }

    async getOwnerOfToken(tokenId) {
        return await this.contract.methods.ownerOf(tokenId).call()
    }

    async getTokenURI(tokenId) {
        return await this.contract.methods.getTokenURI(tokenId).call()
    }

    async getTokenAccelerator(tokenId) {
        return await this.contract.methods.getTokenAccelerator(tokenId).call()
    }
}

export default PolygonCommunityNFT
