import React, { useState } from "react"
import { TabContent, TabPane } from "reactstrap"
import BreadCrumb from "../Components/BreadCrumb"
import Tabs from "../Components/Tabs/Tabs"
import BillingTab from "../Components/Billing/BillingTab"
import MethodCredits from "../Components/Billing/MethodCredits"
import TransactionCredits from "../Components/Billing/TransactionCredits"

const Billing = () => {
    const [activeTab, setActiveTab] = useState("1")

    return localStorage.jwtToken ? (
        <React.Fragment>
            <div className="content billing-page">
                <BreadCrumb items={["Billing"]} />

                <Tabs tabs={["Billing", "Method Credits", "Transaction Credits"]} setActiveTab={setActiveTab} activeTab={activeTab} />

                <TabContent activeTab={activeTab}>
                    <TabPane className="show" tabId="1">
                        {activeTab === "1" && <BillingTab />}
                    </TabPane>
                    <TabPane className="show" tabId="2">
                        {activeTab === "2" && <MethodCredits />}
                    </TabPane>
                    <TabPane className="show" tabId="3">
                        {activeTab === "3" && <TransactionCredits />}
                    </TabPane>
                </TabContent>
            </div>
        </React.Fragment>
    ) : null
}

export default Billing
