import React, { useState } from "react"
import { Alert, Card, CardBody, FormGroup, Label, Button } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { toBase64, allFormsValid, isValidFileType, compressFile } from "../../utils"
import Dropzone from "react-dropzone"
import SpinnerWrap from "../SpinnerWrap"
import "./user.scss"
import OkDialog from "../OkDialog/OkDialog"

const apiUrlUpdateOrg = "https://org.idexo.io/" // for updating/getting user email/name/photo, & company name/logo

const User = ({ userCompanyData, fetchUserCompanyData, ...rest }) => {
    const [email, setEmail] = useState(userCompanyData?.email?.S || "")
    const [name, setName] = useState(userCompanyData?.fullname?.S || "")
    const [photo, setPhoto] = useState(userCompanyData?.photo?.S || "") // base64 string referring to photo
    const [showSpinnerUpdate, setShowSpinnerUpdate] = useState(false)
    const [alertMessageUpdate, setAlertMessageUpdate] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [okDialogMessage, setOkDialogMessage] = useState("") // if wrong file type, or trying to change email address

    // TO DO: (low priority) this is a pattern we could probably be abstracted and refactored as helper function for reuse to keep DRY
    // returns boolean, true input form field data is NOT the same as the old form data
    const yesFormChange = () => {
        return name !== userCompanyData?.fullname?.S || photo !== userCompanyData?.photo?.S
    }

    const validProfileSubmit = () => {
        // photo and full name field required, and form data must change
        return allFormsValid([name, email]) && !!photo && yesFormChange()
    }

    // TO DO: (low priority)- abstract/refactor into helper function for reuse/DRY
    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type
        if (!isValidFileType(fileType)) {
            setOkDialogMessage("You must select a jpeg or png file!")
            setPhoto(userCompanyData?.photo?.S || "") // set photo to old photo (or nothing if user has no photo)
            return
        }

        // if file size >= 400kb, compress file (so we meet dynamo 400kb limit)
        if (files[0].size >= 400000) {
            files = [await compressFile(files[0])]
        }

        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setPhoto(base64Files[0].base64)
    }

    const handleSubmit = async (e, apiUrl, formData, method, action) => {
        setAlertMessageUpdate("")

        if (action === "user") {
            // update user email/name/photo
            setShowSpinnerUpdate(true)
        }

        try {
            let res = await fetch(apiUrl, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.jwtToken
                },
                body: JSON.stringify({
                    ...formData,
                    action: action
                }),
                json: true
            })

            const dataRes = await res.json()
            console.log(dataRes)

            if (dataRes.error) {
                setAlertColor("danger")
                setAlertMessageUpdate(`Error: ${dataRes.error}`)
            } else {
                setAlertColor("success")
                let alertMessage =
                    action === "user"
                        ? "Successfully updated user profile!"
                        : "Password reset submitted. Check your email to complete the password reset"
                setAlertMessageUpdate(alertMessage)
                fetchUserCompanyData()
            }
        } catch (err) {
            console.log(err)
            // setAlertMessageUpdate(err)
        }
        setShowSpinnerUpdate(false)
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <AvForm
                        className="profile-form"
                        initialValues={{ name: "" }}
                        onSubmit={(e) => handleSubmit(e, apiUrlUpdateOrg, { email, fullname: name, photo }, "POST", "user")}
                        autoComplete="off"
                    >
                        <FormGroup
                            className="mb-3"
                            onClick={() => setOkDialogMessage("You cannot change your email address once the account is created")}
                        >
                            <AvField
                                label="Email Address"
                                type="text"
                                className="form-control"
                                id="email"
                                name="Email Address"
                                placeholder="Enter Email Address"
                                errorMessage="Required!"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={true} // dynamodb can't update a user's email (because that's a primary key)
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                label="Full Name"
                                type="text"
                                className="form-control"
                                id="name"
                                name="Full Name"
                                placeholder="Enter Full Name"
                                errorMessage="Required!"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label htmlFor="full-name" className="form-label">
                                Photo
                            </Label>
                            <div className="d-flex">
                                <div className="photo-upload">
                                    <Dropzone onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)} maxFiles={1}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="d-flex flex-column align-items-center h-100">
                                                <div className="photo-select needsclick" {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {photo ? (
                                                        <div className="img-preview-container">
                                                            <img
                                                                className="selected-image"
                                                                alt="user data"
                                                                src={photo !== " " ? `data:image/jpeg;base64,${photo}` : ""}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="photo-select needsclick">
                                                            <i className="display-6 text-muted bx bxs-camera-plus" />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                <Button
                                    color="primary"
                                    className="btn-light waves-effect w-xl mx-5 remove-photo-btn"
                                    disabled={!photo}
                                    onClick={() => setPhoto(null)}
                                >
                                    Remove Photo
                                </Button>
                            </div>
                        </FormGroup>
                        <div className="mt-2">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={showSpinnerUpdate || !validProfileSubmit()}
                                className={"waves-effect w-xl update-user-profile-btn"}
                            >
                                <SpinnerWrap showSpinner={showSpinnerUpdate} txtTrue={"Updating"} txtFalse={"Update"} />
                            </Button>
                        </div>
                        {alertMessageUpdate ? <Alert color={alertColor}>{alertMessageUpdate}</Alert> : null}
                    </AvForm>
                </CardBody>
            </Card>

            <OkDialog open={okDialogMessage} message={okDialogMessage} okClick={() => setOkDialogMessage("")} />
        </React.Fragment>
    )
}

export default User
