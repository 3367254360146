import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import jwt from "jsonwebtoken"
import CoinbaseIcon from "../../assets/images/crypto/icons/coinbase-logo.png"
import { PAID_PLANS } from "../../utils"

const [apiKey, reserved, trial, starter, professional, enterprise, ultimate] = [
    "apiKey",
    "reserved",
    "trial",
    "starter",
    "professional",
    "enterprise",
    "ultimate"
]

// TO DO: update all (except Crypto apiKey)
const PAYMENT_LINKS = {
    CRYPTO: {
        [apiKey]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        // TO DO (low priority): these need to up updated (price will scale according to plan)
        [reserved]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        [trial]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        [starter]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        [professional]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        [enterprise]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            1000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        },
        [ultimate]: {
            50: "https://commerce.coinbase.com/checkout/577f8e20-4394-428e-aebe-788465d97d22",
            100: "https://commerce.coinbase.com/checkout/5a8c97d4-2c9f-4ec4-b970-1205a9742f99",
            500: "https://commerce.coinbase.com/checkout/6cd2abf4-c9af-4590-b9fd-f8b543903dbf",
            5000: "https://commerce.coinbase.com/checkout/27f9a75f-3fdb-4f5c-85e9-c8fc8260eac4"
        }
    },
    CREDITCARD: {
        // NOTE* for now apiKey 50tx costs the same as trial/starter/pro/ultimate 50tx, (and same with other tx amounts)
        // TO DO (low priority): scaled pricing depending on plan
        [apiKey]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [reserved]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [trial]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [starter]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            1000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [professional]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [enterprise]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        },
        [ultimate]: {
            50: "https://buy.stripe.com/fZe00mg2mf1oehWaEJ",
            100: "https://buy.stripe.com/00g00mdUe1ay7Ty5kq",
            500: "https://buy.stripe.com/dR64gC4jE9H40r68wD",
            5000: "https://buy.stripe.com/6oE28u2bwg5s2ze9AI"
        }
    }
}

const PurchaseTxCreditsModal = ({ selectedNumTxCredits, setSelectedNumTxCredits }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const planType = PAID_PLANS[decoded.paid] //  "apiKey", "reserved", "trial", "starter", "professional", "enterprise", "ultimate"
    // const planType = "apiKey" // uncomment this for testing UI
    // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("CRYPTO") // string- "CRYPTO" or "CREDITCARD"

    // adding aditional params to stripe payment link will prefill email form on stripe checkout page
    const additionalParams = () => {
        if (selectedPaymentMethod === "CRYPTO") return ""
        const userEmail = decoded.email.replace("@", "%40")
        return `?prefilled_email=${userEmail}`
    }

    return (
        <Modal
            isOpen={!!selectedNumTxCredits}
            centered={true}
            toggle={() => setSelectedNumTxCredits(null)}
            className="purchase-tx-credits-modal-wrap"
        >
            <ModalHeader toggle={() => setSelectedNumTxCredits(null)}>
                Purchase <span>{selectedNumTxCredits}</span> Transaction Credits
            </ModalHeader>
            <ModalBody>
                <div className="modal-body-label">Select Payment Method</div>
                <div
                    className={"payment-option coinbase" + (selectedPaymentMethod === "CRYPTO" ? " selected" : "")}
                    onClick={() => setSelectedPaymentMethod("CRYPTO")}
                >
                    <img src={CoinbaseIcon} alt="coinbase" className="pmt-option-icon"></img>
                    <span>CRYPTO</span>
                </div>

                {/* Only show credit card option if user has paid plan (not free or trial) */}
                {/* {planType === apiKey || planType === trial ? null : (
                    <div
                        className={"payment-option stripe" + (selectedPaymentMethod === "CREDITCARD" ? " selected" : "")}
                        onClick={() => setSelectedPaymentMethod("CREDITCARD")}
                    >
                        <i className="bx bx bx-credit-card font-size-18 align-middle mr-1 pmt-option-icon" />
                        <span>CREDIT CARD</span>
                    </div>
                )} */}

                {/* Credit card option available to all plans: */}
                <div
                    className={"payment-option stripe" + (selectedPaymentMethod === "CREDITCARD" ? " selected" : "")}
                    onClick={() => setSelectedPaymentMethod("CREDITCARD")}
                >
                    <i className="bx bx bx-credit-card font-size-18 align-middle mr-1 pmt-option-icon" />
                    <span>CREDIT CARD</span>
                </div>
            </ModalBody>
            <ModalFooter className="submit-cancel">
                <Button
                    color="primary"
                    type="button"
                    disabled={!selectedPaymentMethod}
                    // parseFloat -> removes comma and convert to Number ex. "1,000" => 1000
                    href={PAYMENT_LINKS?.[selectedPaymentMethod]?.[planType][parseFloat(selectedNumTxCredits.replace(/,/g, ""))] + additionalParams()}
                >
                    PURCHASE
                </Button>
                <Button color="secondary" outline onClick={() => setSelectedNumTxCredits(null)} className="btn-light waves-effect cancel-btn">
                    CANCEL
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default PurchaseTxCreditsModal
