import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Row, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import jwt from "jsonwebtoken"
import { chainOptions } from "../../common/chain"
import { allFormsValid } from "../../utils"
import "./StakingCard.scss"
import Select from "../CustomSelect"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"

const CONTRACT_TYPES = [
    {
        value: "Standard",
        name: "Standard"
    }
]

const StakingCard = ({
    module,
    // states/useStates from parent
    stakingAlertMessage, // message if error fetching staking data
    setStakingAlertMessage,
    stakingAlertColor,
    showSpinner, // boolean- for button loading animation
    setShowSpinner,
    buttonId, // refers to which button we clicked (so we know which button to show loading animation)
    setButtonId,
    showTooltip,
    paid,
    setShowTooltip,

    // helper functions
    handleSubmit
    // updateProgressTableStatus,
}) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const [status, setStatus] = useState("") // contract deployment status ex. "pending", "done"
    // Form State
    const [stakingName, setStakingName] = useState(module.staking_name?.S || "")
    const [symbol, setSymbol] = useState(module.symbol?.S || "")
    const [chain, setChain] = useState(module.chain?.S || "")
    const [depositToken, setDepositToken] = useState(module.deposit_token?.S || "")
    const [rewardToken, setRewardToken] = useState(module.reward_token?.S || "")
    const [contractType, setContractType] = useState(module.contract_type?.S || "Standard") // only possible type for now is standard - later will add ability to choose from different types of staking contracts

    const handleClickSave = async (_e) => {
        try {
            setStatus("")
            let newModule = {
                M: {
                    type: { S: module.type.S },
                    inserted: { N: String(module.inserted.N) },
                    module_name: { S: module.module_name.S },
                    staking_name: { S: stakingName },
                    symbol: { S: symbol },
                    chain: { S: chain },
                    deposit_token: { S: depositToken },
                    reward_token: { S: rewardToken },
                    contract_type: { S: contractType } // ex. "Standard"
                }
            }
            if (module.subdomain) newModule.M["subdomain"] = module.subdomain
            if (module.subdomain_file) newModule.M["subdomain_file"] = module.subdomain_file

            await handleSubmit(newModule, 1) // 1 = buttonId refers to which button we clicked (so we know which button to show loading animation)
        } catch (err) {
            console.log(err)
        }
    }

    const handleClickDeploy = async () => {
        // setAlertMessage("") // reset alert message (if any)
        // setAlertColor("")
        setShowSpinner(true)
        setButtonId(2) // so spinner knows which button to display animation

        // TO DO: show alert/prevent user from deploying if:
        // - user hasn't saved any data (ex. staking name, chain, rewardTokwn), OR
        // - user changed the form data and did not save first (i.e. there is a change in the form data)
        // if (Object.keys(NFTCollection).length === 0 || !noFormChange()) {
        //     setAlertMessage("You must save your NFT collection data first")
        //     setAlertColor("danger")
        //     setShowSpinner(false)
        //     return
        // }

        try {
            let api_key = decoded.api_key

            console.log(api_key)
            alert("Work In Progress...")
        } catch (err) {
            console.log(err)
            processErrors(err)
        }
        setShowSpinner(false)
    }

    const processErrors = (err) => {
        let errorMessage = String(err)
        if (err?.response?.data?.error) {
            errorMessage = "Not enough credits for this method!" // err.response.data.code === 500

            // TO DO: redirect to method credits page i.e. /billing with state passed to set it to method credits tab
        } else if (err?.response?.data?.message === "Forbidden") {
            errorMessage = "Invalid API Key"
        }
        console.log(errorMessage)
        // setAlertMessage(errorMessage)
        // NOTE* user will have credit_balance deducted by 1 even if there's failure to deploy
        // - admin will have to manually update credit_balance
        // setAlertColor("danger")
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        // TO DO: uncomment change variable names once backend finished
        // return (
        //     collection_name === NFTCollection?.collection_name?.S &&
        //     symbol === NFTCollection?.symbol?.S &&
        //     chain === NFTCollection?.chain?.S &&
        //     description === NFTCollection?.description?.S &&
        //     cap === NFTCollection?.cap?.S
        // )
    }

    return (
        <Card className="token-staking-card">
            <CardBody>
                <AvForm
                    className="nft-form"
                    initialValues={{
                        "token-staking-name": module.staking_name?.S,
                        "staking-symbol": module.symbol?.S,
                        "chain-select": module.chain?.S,
                        "deposit-token": module.deposit_token?.S,
                        "reward-token": module.reward_token?.S,
                        "contract-type-select": module.contract_type?.S
                    }}
                    onSubmit={handleClickDeploy}
                >
                    <CardTitle className="h3">Token Staking</CardTitle>
                    <Row>
                        <div className="d-flex flex-column align-items-center mb-2"></div>
                        <Col xs={12} sm={6}>
                            <FormGroup className="mb-3">
                                <AvField
                                    label="Name"
                                    type="text"
                                    className="form-control"
                                    id="token-staking-name"
                                    name="token-staking-name"
                                    placeholder="Enter Staking Pool Name"
                                    errorMessage="Required!"
                                    onChange={(e) => setStakingName(e.target.value)}
                                    required
                                    disabled={showSpinner || status === "pending" || status === "done"}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <FormGroup className="mb-3">
                                <AvField
                                    label="Symbol"
                                    type="text"
                                    className="form-control"
                                    id="staking-symbol"
                                    name="staking-symbol"
                                    placeholder="Enter Staking Pool Symbol"
                                    errorMessage="Required!"
                                    onChange={(e) => setSymbol(e.target.value)}
                                    required
                                    disabled={showSpinner || status === "pending" || status === "done"}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="mb-3">
                        <Select
                            label="Chain"
                            id="chain-select"
                            name="chain-select"
                            className={chain === "" ? "noselection" : ""}
                            placeholder="Select Blockchain"
                            options={chainOptions}
                            selectedOption={chain}
                            errorMessage="Required!"
                            required
                            disabled={showSpinner || status === "pending" || status === "done"}
                            handleChange={(value) => setChain(value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            label="Deposit Token"
                            type="text"
                            className="form-control"
                            id="deposit-token"
                            name="deposit-token"
                            placeholder="Enter Deposit Token"
                            errorMessage="Required!"
                            onChange={(e) => setDepositToken(e.target.value)}
                            required
                            disabled={showSpinner || status === "pending" || status === "done"}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            label="Reward Token"
                            type="text"
                            className="form-control"
                            id="reward-token"
                            name="reward-token"
                            placeholder="Enter Reward Token"
                            errorMessage="Required!"
                            onChange={(e) => setRewardToken(e.target.value)}
                            required
                            disabled={showSpinner || status === "pending" || status === "done"}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <Select
                            label="Choose Contract Type"
                            id="contract-type-select"
                            name="contract-type-select"
                            className={contractType === "" ? "noselection" : ""}
                            placeholder="Select Contract Type"
                            options={CONTRACT_TYPES}
                            selectedOption={contractType}
                            errorMessage="Required!"
                            required
                            disabled={showSpinner || status === "pending" || status === "done"}
                            handleChange={(value) => setContractType(value)}
                        />
                    </FormGroup>

                    <div className="deploy-alert">
                        <Alert color={stakingAlertColor} toggle={() => setStakingAlertMessage("")} isOpen={!!stakingAlertMessage}>
                            {stakingAlertMessage}
                        </Alert>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Button
                            color="primary"
                            outline
                            className="waves-effect w-sm save-btn"
                            disabled={
                                showSpinner ||
                                !allFormsValid([stakingName, symbol, chain, depositToken, rewardToken, contractType]) ||
                                noFormChange() ||
                                status === "pending"
                            }
                            onClick={handleClickSave}
                        >
                            <SpinnerWrap showSpinner={showSpinner && buttonId === 1} txtTrue={"Saving"} txtFalse={"Save"} />
                        </Button>
                        {/* <Tooltip
                                title={"You must have a paid account to submit this form"}
                                placement="top"
                                disableFocusListener
                                open={showTooltip && paid === 0}
                            > */}
                        <Tooltip
                            title={`Contract is ${status === "done" ? "deployed" : "pending"}, cannot redeploy`}
                            placement="top"
                            disableFocusListener
                            open={(showTooltip && status === "pending") || (showTooltip && status === "done")}
                        >
                            {/* need this span for showing tooltip */}
                            <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                <Button
                                    color="primary"
                                    className="waves-effect submit-btn"
                                    disabled={
                                        showSpinner ||
                                        !allFormsValid([stakingName, symbol, chain, depositToken, rewardToken]) ||
                                        paid === 0 || // not a paid account
                                        status === "pending"
                                    }
                                >
                                    <SpinnerWrap showSpinner={showSpinner && buttonId === 2} txtTrue={"DEPLOYING"} txtFalse={"DEPLOY"} />
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </AvForm>
            </CardBody>
        </Card>
    )
}

export default StakingCard
