import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Row, Input, Label, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import * as yup from "yup"
import ido from "idexo-sdk"
import jwt from "jsonwebtoken"

import { chainOptions } from "../../common/chain"
import { isValidAddress, allFormsValid } from "../../utils"
import Select from "../CustomSelect"
import ImageUpload from "../ImageUpload"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"
import "./NFTCollectionCard.scss"

// declaring constants for nft collection contract & storage types
// - easier to change all instances, just change here
// - easier debugging/fail loudly if we mispell
const STANDARD = "standard"
const ROYALTY_NFT = "royalty" // NOTE! if we change this, we still need to change this in nftmarketing lambda
const SBT = "soulbound"
const SBT_COMMUNITY = "Soulbound (SBT) Community"
const ARWEAVE = "arweave"
const FILECOIN = "filecoin"
const LINKED_SBT = "Linked Soulbound (SBT)"

// For dropdown select component- NFT Contract Types
const CONTRACT_TYPES = [
    {
        value: STANDARD, // this is the value kept in react state/sent to backend
        name: "Standard" // this is what's displayed in dropdown item
    },
    {
        value: ROYALTY_NFT,
        name: "Royalty NFT"
    },
    {
        value: SBT,
        name: "Soulbound (SBT)"
    },
    {
        value: LINKED_SBT,
        name: "Linked Soulbound (SBT)"
    }
    // DISABLING FOR NOW
    // {
    //     value: SBT_COMMUNITY,
    //     name: "Soulbound (SBT) Community"
    // }
]

const NFT_METADATA_STORAGE_TYPES = [
    {
        value: ARWEAVE,
        name: ARWEAVE
    },
    {
        value: FILECOIN,
        name: FILECOIN
    }
]

const NFTCollectionCard = React.memo(
    ({
        // states/useStates from parent
        project,
        NFTCollection, // original data from backend (used to compare changes in form input so user doesn't submit same data)
        imgFile,
        setImgFile,
        showSpinner,
        setShowSpinner,
        imgURL,
        showNFTImageLoading,
        collection_name,
        setCampaignName,
        symbol,
        setSymbol,
        chain,
        setChain,
        description,
        setDescription,
        cap,
        setCap,
        buttonId,
        setButtonId,
        showTooltip,
        setShowTooltip,

        // helper functions
        handleSubmit,
        s3Params,
        updateProgressTableStatus,
        fetchCampaignData,
        checkPending
    }) => {
        const [alertMessage, setAlertMessage] = useState("") // if trying to deploy with no user nft collection data in backend (ex. collection name, cap, chain)
        const [alertColor, setAlertColor] = useState("")
        const [status, setStatus] = useState(NFTCollection?.source?.S)

        // Form States
        const [contractType, setContractType] = useState(NFTCollection?.contract_type?.S || STANDARD) // "standard", "royalty", or "soulbound", ...
        const [royaltiesCollectorWallet, setRoyaltiesCollectorWallet] = useState(NFTCollection?.royalties_collector_wallet?.S || "") // address
        const [royaltyFeeBasisPoints, setRoyaltyFeeBasisPoints] = useState(NFTCollection?.royalty_fee_basis_points?.N || "") // Number
        const [metadataStorage, setMetadataStorage] = useState(NFTCollection?.metadata_storage?.S || ARWEAVE) // string- nft metadata storage options (ex. arweave/filecoin)

        // check if user is a tenant member
        let decoded = null
        if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
        else decoded = jwt.decode(localStorage.jwtToken)
        //

        // cb runs once after initial mount, then everytime after contractType changes
        useEffect(() => {
            // reset royalty fields if user switches contractType from "royalty" to "standard"
            if (contractType === STANDARD || contractType === SBT || contractType === SBT_COMMUNITY || contractType === LINKED_SBT) {
                setRoyaltiesCollectorWallet("")
                setRoyaltyFeeBasisPoints("")
            } else if (contractType === ROYALTY_NFT) {
                setRoyaltiesCollectorWallet(NFTCollection?.royalties_collector_wallet?.S || "")
                setRoyaltyFeeBasisPoints(NFTCollection?.royalty_fee_basis_points?.N || "")
            }
        }, [contractType, NFTCollection?.royalties_collector_wallet?.S, NFTCollection?.royalty_fee_basis_points?.N])

        // returns boolean, true if on editing form, the input form field data is the same as the old form data
        const noFormChange = () => {
            return (
                collection_name === NFTCollection?.collection_name?.S &&
                symbol === NFTCollection?.symbol?.S &&
                chain === NFTCollection?.chain?.S &&
                description === NFTCollection?.description?.S &&
                cap === NFTCollection?.cap?.S &&
                imgFile.length === 0 &&
                contractType === NFTCollection?.contract_type?.S &&
                (contractType === ROYALTY_NFT
                    ? royaltiesCollectorWallet === NFTCollection?.royalties_collector_wallet?.S &&
                      royaltyFeeBasisPoints === NFTCollection?.royalty_fee_basis_points?.N
                    : true) &&
                metadataStorage === NFTCollection?.metadata_storage?.S
            )
        }

        const handleClickNoCap = () => {
            if (String(cap) === "-1") {
                // reset cap to old cap (if any and old cap not -1). will be empty "" if cap not yet set
                setCap(NFTCollection?.cap?.S === "-1" ? "" : NFTCollection?.cap?.S)
            } else {
                setCap("-1") // -1 === no cap
            }
        }

        const handleClickDeploy = async () => {
            setAlertMessage("") // reset alert message (if any)
            setAlertColor("")
            setShowSpinner(true)
            setButtonId(2) // so spinner knows which button to display animation

            // TO DO: enable deployment without saving data first (we will save and deploy in backend)
            // show alert/prevent user from deploying if:
            // - user hasn't saved any data (ex. collection name, chain, cap), OR
            // - user changed the form data and did not save first (i.e. there is a change in the form data)
            if (Object.keys(NFTCollection).length === 0 || !noFormChange()) {
                setAlertMessage("You must save your NFT collection data first")
                setAlertColor("danger")
                setShowSpinner(false)
                return
            }

            try {
                const capped = String(cap) !== "-1"
                // if user is a tenant member, get api_key from localStorage
                let api_key = decoded.api_key

                let res
                let baseUri = ""

                // s3 item metadata for smart contract
                const options = {
                    project_name: project.project_name.S,
                    project_inserted: NFTCollection.project_inserted.N,
                    module_inserted: NFTCollection.module_inserted.N,
                    campaign_inserted: NFTCollection.inserted.N,
                    transaction_type: NFTCollection.transaction_type.S // ex. "createCollectionCapped"
                    // metadata_storage: metadataStorage // "arweave", "filecoin" // Maybe we don't need this here
                }

                if (contractType === ROYALTY_NFT) {
                    options.royalties_collector_wallet = royaltiesCollectorWallet
                    options.royalty_fee_basis_points = royaltyFeeBasisPoints
                }

                // use ido-sdk API to create NFT Collection
                if (capped && contractType === STANDARD) {
                    res = await ido.NFTs.createCollectionCapped(api_key, chain.toLowerCase(), collection_name, symbol, cap, options) // Valid- 200
                } else if (!capped && contractType === STANDARD) {
                    res = await ido.NFTs.createCollectionUncapped(api_key, chain.toLowerCase(), collection_name, symbol, options) // Valid- 200
                } else if (capped && contractType === ROYALTY_NFT) {
                    res = await ido.NFTs.createCappedRoyalty(
                        api_key,
                        chain.toLowerCase(),
                        collection_name,
                        symbol,
                        royaltiesCollectorWallet.toLowerCase(), // royaltyCollector
                        royaltyFeeBasisPoints, // royaltyBP
                        cap,
                        options
                    )
                } else if (!capped && contractType === ROYALTY_NFT) {
                    res = await ido.NFTs.createUncappedRoyalty(
                        api_key,
                        chain.toLowerCase(),
                        collection_name,
                        symbol,
                        royaltiesCollectorWallet.toLowerCase(),
                        royaltyFeeBasisPoints,
                        options
                    )
                } else if (capped && contractType === SBT) {
                    res = await ido.SBTs.createSBTCapped(api_key, chain.toLowerCase(), collection_name, symbol, baseUri, cap, options)
                } else if (!capped && contractType === SBT) {
                    res = await ido.SBTs.createSBTUncapped(api_key, chain.toLowerCase(), collection_name, symbol, baseUri, options)
                } else if (!capped && contractType === LINKED_SBT) {
                    res = await ido.SBTs.createLinkedSBTUncapped(api_key, chain.toLowerCase(), collection_name, symbol, baseUri, options) // need to add this method to the SDK
                } else if (capped && contractType === LINKED_SBT) {
                    alert("Linked SBT Only Available As Uncapped Contract. Remove the cap to continue.")
                } else if (capped && contractType === SBT_COMMUNITY) {
                    alert("Coming Soon! createSBTCommunityCapped") // WIP
                } else if (!capped && contractType === SBT_COMMUNITY) {
                    alert("Coming Soon! createSBTCommunityUncapped") // WIP
                    // ? will baseUri be in params?
                    // res = await ido.SBTs.createSBTCommunityUncapped(apiKey, chain.toLowerCase(), collection_name, symbol, baseUri, options)
                }
                console.log("handleClickDeploy", res)
                // FOR TESTING----
                // TO DO:
                // - test other chains beside fantom
                // NOTE* if apiKey is valid & there's an error in lambda, user still gets method credit deducted! we will have to manually add 1 credit back

                // 1) Not Valid- 403 forbidden- Cap-        invalid apiKey-
                // const res = await ido.NFTs.createCollectionCapped("potato_API", "fantom", collection_name, symbol, "3")

                // 2) Not Valid- 403 forbidden- UnCapped-   invalid apiKey-
                // const res = await ido.NFTs.createCollectionUncapped("potato_API", "fantom", collection_name, symbol)

                // 3) Not Valid- 400-           Cap-        valid apiKey-    no method credits
                // const res = await ido.NFTs.createCollectionCapped(apiKey, "fantom", collection_name, symbol, "3")

                // 4) Not Valid- 400-           Uncapped-   valid apiKey-    no method credits
                // const res = await ido.NFTs.createCollectionUncapped(apiKey, "fantom", collection_name, symbol)
                // console.log("transaction", res)
                // FOR TESTING----

                // TO DO:
                // - remove below nested data's (backend)
                // - retest:
                //   - no method name
                //   - contract transaction sending failure
                if (!res || res.error || res.data?.error || res?.data?.data?.error) {
                    setAlertMessage(String(res?.error || res?.data?.error || res?.data?.data?.error))
                    setAlertColor("danger")
                } else {
                    const contractRes = res.data.data
                    const tx_hash = contractRes.tx_hash
                    const tx_id = contractRes.tx_id
                    const contract_owner = contractRes.sender
                    setAlertMessage(`Transaction succesfully sent. Contract Pending. tx_hash: ${tx_hash}`)
                    setAlertColor("success")

                    const resp = await updateProgressTableStatus(NFTCollection?.inserted?.N, tx_id, contract_owner)
                    console.log("updateProgressTableStatus", await resp?.json())

                    // change status so "Deploy" button becomes disabled
                    setStatus("pending")
                    await fetchCampaignData() // this should force sibling progress table component to update/refresh
                    checkPending(tx_id)
                }
            } catch (err) {
                console.log(err)
                handleErrors(err)
            }
            setShowSpinner(false)
        }

        const handleErrors = (err) => {
            let errorMessage = String(err)

            if (err?.response?.data?.error?.includes("Not enough credits")) {
                errorMessage = err.response.data.error // err.response.data.code === 500
            } else if (err?.response?.data?.error) {
                errorMessage = err.response.data.error
                // TO DO: redirect to method credits page i.e. /billing with state passed to set it to method credits tab
            } else if (err?.response?.data?.message === "Forbidden") {
                errorMessage = "Invalid API Key"
            }

            setAlertMessage(errorMessage)
            // NOTE* user will have credit_balance deducted by 1 even if there's failure to deploy
            // - admin will have to manually update credit_balance
            setAlertColor("danger")
        }

        const determineTransactionType = () => {
            const capped = String(cap) !== "-1"
            let transactionType
            if (capped && contractType === STANDARD) {
                transactionType = "createCollectionCapped"
            } else if (!capped && contractType === STANDARD) {
                transactionType = "createCollectionUncapped"
            } else if (capped && contractType === ROYALTY_NFT) {
                transactionType = "createCappedRoyalty"
            } else if (!capped && contractType === ROYALTY_NFT) {
                transactionType = "createUncappedRoyalty"
            } else if (capped && contractType === SBT) {
                transactionType = "createSBTCapped"
            } else if (!capped && contractType === SBT) {
                transactionType = "createSBTUncapped"
            } else if (!capped && contractType === LINKED_SBT) {
                transactionType = "createUncappedLinkedSBT"
            } else if (capped && contractType === SBT_COMMUNITY) {
                transactionType = "createSBTCommunityCapped"
            } else if (!capped && contractType === SBT_COMMUNITY) {
                transactionType = "createSBTCommunityUncapped"
            }
            return transactionType
        }

        return (
            <Card className="nft-collection-card">
                <CardBody>
                    <AvForm
                        className="nft-form"
                        // NOTE* initialValue key's must match "name" attribute in AvField components!
                        initialValues={{
                            "nft-collection-name": collection_name,
                            "nft-symbol": symbol,
                            "chain-select": chain,
                            description: description,
                            "nft-cap": cap === "-1" ? " " : cap,
                            "contract-type-select": contractType,
                            "royalties-collector-wallet": royaltiesCollectorWallet,
                            "royalty-fee-basis-points": royaltyFeeBasisPoints,
                            "metadata-storage": metadataStorage
                        }}
                        enableReinitialize
                        validationSchema={yup.object().shape({
                            "nft-collection-name": yup.string().required("This field is required."),
                            "nft-symbol": yup.string().required("This field is required."),
                            "chain-select": yup.string().required("This field is required."),
                            description: yup.string().required("This field is required."),
                            "nft-cap": yup
                                .string()
                                .test("valid cap min?", "Cap minimum is 1", (val) => {
                                    if (cap === "-1") return true
                                    // show error message 'cap min is 1' if return value evaluates to false
                                    return Number(val) >= 1
                                })
                                .test("valid cap step?", "Cap must be increments of 1", (val) => Number(val) % 1 === 0)
                                .required("This field is required."),
                            "royalties-collector-wallet": yup
                                .string()
                                // if callback in .test returns false, show error message
                                .test("valid collector-wallet?", "You must enter a valid EVM address!", (str) => {
                                    if (contractType === ROYALTY_NFT) return isValidAddress(str)
                                    return true
                                }),
                            "royalty-fee-basis-points": yup.number().test("valid royalty fee bp?", "Number must be greater than 0", (num) => {
                                if (contractType === ROYALTY_NFT) return num > 0
                                return true
                            })
                        })}
                        onSubmit={handleClickDeploy}
                    >
                        <CardTitle className="h3">NFT Collection</CardTitle>
                        <Row>
                            <div className="d-flex flex-column align-items-center mb-2">
                                <ImageUpload
                                    files={imgFile}
                                    handleChange={setImgFile}
                                    disabled={showSpinner || showNFTImageLoading}
                                    onlyOneImage={true}
                                    imgURL={imgURL}
                                    showNFTImageLoading={showNFTImageLoading}
                                />
                                <p className="image-upload-title">Upload image to represent collection (optional)</p>
                            </div>
                            <Col xs={12} sm={6}>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="nft-collection-name"
                                        label="NFT Collection Name"
                                        placeholder="Enter Collection Name"
                                        type="text"
                                        className="form-control"
                                        id="nft-collection-name"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setCampaignName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="nft-symbol"
                                        label="NFT Symbol"
                                        placeholder="Enter Symbol"
                                        type="text"
                                        className="form-control"
                                        id="nft-symbol"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setSymbol(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="mb-3">
                            <Select
                                name="chain-select"
                                label="Chain"
                                placeholder="Select Blockchain"
                                id="chain-select"
                                options={chainOptions}
                                selectedOption={chain}
                                disabled={showSpinner || status === "pending" || status === "done"}
                                handleChange={(value) => setChain(value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="description"
                                label="Description"
                                placeholder="Enter Description"
                                type="text"
                                className="form-control"
                                id="description"
                                disabled={showSpinner || status === "pending" || status === "done"}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            {/* Max number of nft's to be minted */}
                            {/* TO DO: add tooltip with explanation */}
                            <AvField
                                name="nft-cap"
                                label="Cap"
                                placeholder={cap === "-1" ? "No Cap" : cap || "Enter NFT Cap"}
                                type="number"
                                className="form-control"
                                id="nft-cap"
                                // required={cap === "" || cap !== "-1" || cap === "0"}
                                min="1" // minimum cap a user can submit
                                step="1" // no decimals, only increments of 1
                                disabled={showSpinner || cap === "-1" || status === "pending" || status === "done"}
                                onChange={(e) => setCap(e.target.value)}
                            />
                            <FormGroup check inline className="no-cap">
                                <Input
                                    name="no-cap"
                                    type="checkbox"
                                    id="no-cap"
                                    checked={cap === "-1"}
                                    disabled={showSpinner || status === "pending" || status === "done"}
                                    onChange={handleClickNoCap}
                                />
                                {/* 'for' property value must match input checkbox 'id' value */}
                                <Label check for="no-cap">
                                    No Cap
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Select
                                name="contract-type-select"
                                label="Contract Type"
                                placeholder="Select Contract Type"
                                id="contract-type-select"
                                options={CONTRACT_TYPES}
                                selectedOption={contractType}
                                disabled={showSpinner || status === "pending" || status === "done"}
                                handleChange={(value) => setContractType(value)}
                            />
                        </FormGroup>
                        {/* if contract type is "Royalty NFT", add two more form fields below */}
                        {contractType === ROYALTY_NFT && (
                            <React.Fragment>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="royalties-collector-wallet"
                                        label="Royalties Collector Wallet"
                                        placeholder="Enter Wallet Address"
                                        type="text"
                                        className="form-control"
                                        id="royalties-collector-wallet"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setRoyaltiesCollectorWallet(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="royalty-fee-basis-points"
                                        label="Royalty Fee Basis Points"
                                        placeholder="Enter Number"
                                        type="Number"
                                        className="form-control"
                                        id="royalty-fee-basis-points"
                                        min="1"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setRoyaltyFeeBasisPoints(e.target.value)}
                                    />
                                </FormGroup>
                            </React.Fragment>
                        )}

                        <FormGroup className="mb-3">
                            <Select
                                name="metadata-storage"
                                label="Metadata Storage"
                                placeholder="Select Storage Type"
                                id="metadata-storage"
                                options={NFT_METADATA_STORAGE_TYPES}
                                selectedOption={metadataStorage}
                                disabled={showSpinner || status === "pending" || status === "done"}
                                handleChange={(value) => setMetadataStorage(value)}
                            />
                        </FormGroup>

                        <div className="deploy-alert">
                            <Alert color={alertColor} toggle={() => setAlertMessage("")} isOpen={!!alertMessage}>
                                {alertMessage}
                            </Alert>
                        </div>
                        <div className="d-flex justify-content-between">
                            {/* SAVE BUTTON */}
                            <Button
                                color="primary"
                                type="button"
                                outline
                                className="waves-effect w-sm save-btn"
                                disabled={
                                    // !imgFile[0] || // uncomment this to make uploading a collection image required
                                    showSpinner ||
                                    !allFormsValid([collection_name, symbol, chain, description, cap, metadataStorage]) ||
                                    cap < -1 ||
                                    cap === 0 ||
                                    noFormChange() ||
                                    NFTCollection?.source?.S === "pending" ||
                                    NFTCollection?.source?.S === "done" ||
                                    status === "pending" ||
                                    // disable button if user hasnt filled out 2 form fields related to royalty nfts
                                    (contractType === ROYALTY_NFT ? !allFormsValid([royaltiesCollectorWallet, royaltyFeeBasisPoints]) : false)
                                }
                                onClick={(e) => {
                                    let formData = {
                                        s3Params: s3Params(),
                                        collection_name,
                                        symbol,
                                        chain,
                                        description,
                                        cap,
                                        contract_type: contractType, // "standard", "royalty", ...
                                        transaction_type: determineTransactionType(), // ex. "createCollectionCapped", "createCappedRoyalty", ...
                                        metadata_storage: metadataStorage // "arweave", "filecoin"
                                    }
                                    if (contractType === ROYALTY_NFT) {
                                        formData.royalties_collector_wallet = royaltiesCollectorWallet
                                        formData.royalty_fee_basis_points = royaltyFeeBasisPoints
                                    }
                                    handleSubmit(
                                        e,
                                        formData,
                                        1,
                                        "createDraftNFTCollection",
                                        imgFile,
                                        NFTCollection?.inserted?.N // insertedTime === time item (ex. twitter, telegram, nftcollection) in nftmarketing dynamo table was inserted
                                    )
                                }}
                            >
                                <SpinnerWrap showSpinner={showSpinner && buttonId === 1} txtTrue={"Saving"} txtFalse={"Save"} />
                            </Button>
                            <Tooltip
                                title={`Contract is ${status === "done" ? "deployed" : "pending"}, cannot redeploy`}
                                placement="top"
                                disableFocusListener
                                open={(showTooltip && status === "pending") || (showTooltip && status === "done")}
                            >
                                {/* need this span for showing tooltip */}
                                <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                    {/* DEPLOY BUTTON */}
                                    <Button
                                        color="primary"
                                        className="waves-effect submit-btn"
                                        type="submit"
                                        disabled={
                                            // !imgFile[0] ||
                                            showSpinner ||
                                            !allFormsValid([collection_name, symbol, chain, description, cap]) ||
                                            cap < -1 ||
                                            NFTCollection?.source?.S === "pending" ||
                                            NFTCollection?.source?.S === "done" ||
                                            status === "pending" ||
                                            (contractType === ROYALTY_NFT ? !allFormsValid([royaltiesCollectorWallet, royaltyFeeBasisPoints]) : false)
                                        }
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 2} txtTrue={"DEPLOYING"} txtFalse={"DEPLOY"} />
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        )
    }
)

export default NFTCollectionCard
