import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label, Form, Input } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import * as yup from "yup"

import { toBase64, isValidImage, isValidAddress, formatAddress } from "../../utils"
import { useToast } from "../../hooks/toast"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"
import "./EditCRMmodal.scss"

// CRM- Contact Relationship Management
// This component is also used as the ADD/VIEW CONTACT Modal-
const EditCRMmodal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete" Button
    handleSubmit,
    confirmButtonText,
    confirmButtonLoading,
    contact,
    setSelectedContact,
    ...rest
}) => {
    const toast = useToast()

    const [img, setImg] = useState(null) // image object- contact avatar logo
    const [avatarLogoContentType, setAvatarLogoContentType] = useState("") // str- TO DO: jpg, png, or gif
    // if user has just selected an image blob, use that, else use s3 url from previously saved dynamo
    const imgUrl = !!img ? `data:${avatarLogoContentType};base64,${img?.base64}` : contact?.avatar_url?.S

    const [email, setEmail] = useState(contact?.email?.S || "")
    const [walletAddress, setWalletAddress] = useState(contact?.wallet_address?.S || "") // self custody wallet address
    const [telegramId, setTelegramId] = useState(contact?.telegram_id?.S || "") // telegram
    const [twitterHandle, setTwitterHandle] = useState(contact?.twitter_handle?.S || "") // NOT twitter_id
    const [linkedInId, setLinkedInId] = useState(contact?.linkedIn_id?.S || "") // linkedin
    const [sbtTokenId, setSbtTokenId] = useState(contact?.sbt_id?.S || "") // token id on sbt contract

    const [createNewWallet, setCreateNewWallet] = useState(false) // if false, no new vault_wallet address will be created for this contact

    const closeAndResetModal = () => {
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        setSelectedContact(null)
    }

    const toggleCreateNewWallet = () => {
        if (!showConfirmLoading && !disableAllButtons) {
            setCreateNewWallet((prev) => !prev)
        }
    }

    const handleCopyClick = (e, data) => {
        e.stopPropagation()
        navigator.clipboard.writeText(data.replace("×", "x"))
        toast.addSuccess(`${formatAddress(data)} copied to clipboard.`)
    }

    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type // files[0] == JS Obj (blob datatype)

        if (!isValidImage(fileType, true)) {
            setModalAlertMessage("You must select a valid file type: jpg, png, or gif!")
            return setAlertColor("danger")
        }

        // don't let user submit file sizes larger than 15 MB for an avatar
        if (files[0].size >= 15728640) {
            // 15,728,640 bytes == 15 MB
            setModalAlertMessage("File size must be under 15 MB")
            return setAlertColor("danger")
        }

        // adds a key to blob where the value is a base64 string
        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setAvatarLogoContentType(fileType)
        // setImg(base64Files[0].base64) // string
        setImg(base64Files[0]) // imgFile JS Obj (blob datatype)
    }

    const handleClickSubmit = async () => {
        const method = contact === null ? "POST" : "PATCH"
        const formData = {}
        if (telegramId) formData.telegram_id = telegramId.toLowerCase()
        if (twitterHandle) formData.twitter_handle = twitterHandle.toLowerCase()
        if (linkedInId) formData.linkedin_id = linkedInId.toLowerCase()
        if (sbtTokenId) formData.sbt_id = sbtTokenId.toLowerCase()
        handleSubmit(method, img, email, walletAddress, contact, formData, createNewWallet) // walletAddress == self custody wallet address
    }

    // returns boolean, true if user does not update their image
    const noImageChange = () => {
        return !img
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        return noImageChange() && email === contact?.email?.S && walletAddress === contact?.wallet_address?.S
    }

    // TO DO: remove empty spaces (if any) at the end of any input forms, before submitting
    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="edit-contact-modal"
        >
            <AvForm
                // NOTE* initialValue key's must match "name" attribute in AvField components!
                initialValues={{
                    "contact-email": email,
                    "wallet-address": walletAddress, // self custody address
                    "telegram-id": telegramId,
                    "twitter-handle": twitterHandle,
                    "linkedIn-id": linkedInId,
                    "sbt-id": sbtTokenId
                }}
                enableReinitialize
                validationSchema={yup.object().shape({
                    "wallet-address": yup
                        .string()
                        // if callback in .test returns false, show error message
                        .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str) || !str)
                })}
                onSubmit={handleClickSubmit}
            >
                <ModalHeader
                    toggle={showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
                >
                    <div>{!contact ? "Add Contact" : "Edit Contact"}</div>
                </ModalHeader>

                <ModalBody>
                    <FormGroup className="mb-4">
                        <div>
                            <Label htmlFor="full-name" className="form-label select-media">
                                Contact Avatar
                            </Label>
                            <div className="media-file-types">Supported Media File Types: jpg, png, or gif</div>
                        </div>

                        <div className={"photo-upload-new-project"}>
                            {/* TO DO: fix css so clicking on entire circle will trigger upload */}
                            <Dropzone
                                onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)}
                                maxFiles={1}
                                disabled={showConfirmLoading || disableAllButtons}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div>
                                        <div className="photo-select needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {/* if contact has no avatar image uploaded, show default icon */}
                                            {contact?.avatar_url?.S === "" || (contact?.avatar_url?.S === undefined && img === null) ? (
                                                <div className="photo-select needsclick">
                                                    <i className="display-6 text-muted bx bxs-camera-plus" />
                                                </div>
                                            ) : (
                                                <div className="img-preview-container">
                                                    <img className="selected-image" alt="contact avatar" src={imgUrl} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="contact-email"
                            label="Email"
                            placeholder="Enter Contact Email"
                            type="text"
                            className="form-control"
                            id="contact-email"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="twitter-handle"
                            label="Twitter Handle"
                            placeholder="Enter Twitter Handle"
                            type="text"
                            className="form-control"
                            id="twitter-handle"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setTwitterHandle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="telegram-id"
                            label="Telegram Username"
                            placeholder="Enter Telegram Username"
                            type="text"
                            className="form-control"
                            id="telegram-id"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setTelegramId(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="linkedIn-id"
                            label="LinkedIn Profile URL"
                            placeholder="Enter LinkedIn Profile URL"
                            type="text"
                            className="form-control"
                            id="linkedIn-id"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setLinkedInId(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <AvField
                            name="sbt-id"
                            label="SBT Token Id"
                            placeholder="Enter SBT Token Id"
                            type="number"
                            className="form-control"
                            id="sbt-id"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setSbtTokenId(e.target.value)}
                        />
                    </FormGroup>

                    {/* "mb-3" === margin-bottom .75rem? */}
                    {/* SELF CUSTODY WALLET ADDRESS */}
                    <FormGroup className="mb-4">
                        <AvField
                            name="wallet-address"
                            label="Self Custody Wallet Address"
                            placeholder="Enter EVM Address"
                            type="text"
                            className="form-control"
                            id="wallet-address"
                            disabled={showConfirmLoading || disableAllButtons}
                            onChange={(e) => setWalletAddress(e.target.value)}
                        />
                        {/* <div className="contact-wallet-info">
                            This optional address is different than the idexo custodial wallet address automatically created for this contact
                        </div> */}
                    </FormGroup>

                    {/* only show vault wallet address if this is the Edit/View Contact Modal (i.e. contact has already been created) */}
                    {contact?.vault_wallet?.S && (
                        <div className="vault-wallet-wrap mb-4">
                            <div className="contact-modal-label">CUSTODIAL WALLET ADDRESS</div>
                            <div className="contact-modal-info">
                                <span className="">{contact?.vault_wallet?.S}</span>
                                <Tooltip title="Copy address to clipboard" placement="bottom">
                                    <i className="bx bx-copy font-size-20" onClick={(e) => handleCopyClick(e, contact?.vault_wallet?.S)} />
                                </Tooltip>
                            </div>
                        </div>
                    )}

                    {/* only show option to create a new vault_wallet address if this is the ADD new contact modal (not edit) */}
                    {!contact && (
                        <Form className="create-new-wallet-wrap">
                            <FormGroup className="form-switch" onClick={toggleCreateNewWallet}>
                                <Input
                                    type="checkbox"
                                    className="create-new-wallet"
                                    checked={createNewWallet}
                                    disabled={showConfirmLoading || disableAllButtons}
                                    title="Turning this setting on will create a new idexo cusodial wallet address for this contact"
                                />
                                <Label
                                    title="Turning this setting on will create a new idexo cusodial wallet address for this contact"
                                    className={showConfirmLoading || disableAllButtons ? "disable-cursor" : ""}
                                >
                                    Create New Custodial Wallet
                                </Label>
                            </FormGroup>
                        </Form>
                    )}

                    {/* only show contact id if this is the Edit/View Contact Modal (i.e. contact has already been created) */}
                    {/* contact.id == id of item in crm table */}
                    {contact?.id?.S && (
                        <div className="contact-id-wrap mb-2">
                            <div className="contact-modal-label">CONTACT ID</div>
                            <div className="contact-modal-info">
                                <span className="">{contact?.id?.S}</span>
                                <Tooltip title="Copy Contact ID to clipboard" placement="bottom">
                                    <i className="bx bx-copy font-size-20" onClick={(e) => handleCopyClick(e, contact?.id?.S)} />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                        {modalAlertMessage}
                    </Alert>
                    <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || noFormChange()}>
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={"SUBMITTING"}
                            txtFalse={"SUBMIT"}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                    </Button>
                    <Button
                        color="secondary"
                        outline
                        onClick={closeAndResetModal}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableAllButtons}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default EditCRMmodal
