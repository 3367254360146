import React, { Component } from "react"
import { Button, Alert, Card, CardBody } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { Link } from "react-router-dom"
import { Logo } from "./Fragments"
import password from "../common/password"
import SpinnerWrap from "../Components/SpinnerWrap"

const apiUrl = "https://login.idexo.io"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = { alertMessage: "" }

        this.loginUser = this.loginUser.bind(this)
        this.handleLoad = this.handleLoad.bind(this)
    }

    componentDidMount() {
        // console.log('Login Component Mounted', this.props.location.state.referrer);
        window.addEventListener("load", this.handleLoad)
    }

    componentWillUnmount() {
        window.removeEventListener("load", this.handleLoad)
    }

    handleLoad() {
        let s = window.location.search
        if (s.includes("id=") && s.includes("t=")) {
            let time = s.replace(/.*t=/i, "")
            if (Number(time.slice(0, 10)) > Math.floor(new Date().getTime() / 1000)) {
                let id = s.replace(/&t=.*/i, "").replace("?id=", "")
                this.setState({ showSpinner: true })
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ id: id }),
                    json: true
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data)
                        // check if jwt
                        if (data.error) {
                            this.setState({ alertMessage: "Error: " + data.error, showSpinner: false })
                        } else if (data.data) {
                            localStorage.setItem("jwtToken", data.data)
                            if (this.props.location.state) {
                                window.location.replace(
                                    `/${this.props.location.state.referrer}${this.props.location.state.search}${this.props.location.state.hash}`
                                )
                            } else {
                                window.location.replace(`/`)
                            }
                            this.setState({ showSpinner: false })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.setState({ showSpinner: false })
                    })
            }
        }
    }

    loginUser(event) {
        let message = password.complexity(event.password)
        if (message) return this.setState({ alertMessage: message, showSpinner: false })
        this.setState({ alertMessage: "", showSpinner: true })
        return fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: event.email,
                password: event.password
            }),
            json: true
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    this.setState({ alertMessage: "Error: " + data.error, showSpinner: false })
                } else {
                    localStorage.setItem("jwtToken", data.data)
                    if (this.props.location.state) {
                        window.location.replace(
                            `/${this.props.location.state.referrer}${this.props.location.state.search}${this.props.location.state.hash}`
                        )
                    } else {
                        window.location.replace(`/`)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({ alertMessage: String(error), showSpinner: false })
            })
    }

    signIn = (res, type) => {
        const { socialLogin } = this.props
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId
            }
            socialLogin(postData, this.props.history, type)
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId
            }
            socialLogin(postData, this.props.history, type)
        }
    }

    //handleGoogleLoginResponse
    googleResponse = (response) => {
        this.signIn(response, "google")
    }

    //handleTwitterLoginResponse
    twitterResponse = () => {}

    //handleFacebookLoginResponse
    facebookResponse = (response) => {
        this.signIn(response, "facebook")
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages">
                    <Card className="overflow-hidden">
                        <CardBody>
                            <Logo></Logo>
                            <AvForm
                                className="form"
                                initialValues={{ email: "", password: "" }}
                                validationSchema={password.schemaLogin}
                                onSubmit={this.loginUser}
                            >
                                {this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.state.alertMessage ? <Alert color="danger">{this.state.alertMessage}</Alert> : null}

                                <div className="form-item">
                                    <AvField name="email" label="Email" type="email" required placeholder="Email" disabled={this.state.showSpinner} />
                                </div>

                                <div className="form-item">
                                    <AvField
                                        name="password"
                                        label="Password"
                                        type="password"
                                        required
                                        placeholder="Password"
                                        disabled={this.state.showSpinner}
                                    />
                                </div>

                                <div className="form-button d-grid">
                                    <Button
                                        className="btn btn-primary btn-block waves-effect waves-light text-uppercase"
                                        color="primary"
                                        type="submit"
                                        disabled={this.state.showSpinner}
                                    >
                                        <SpinnerWrap showSpinner={this.state.showSpinner} txtTrue={"Logging In"} txtFalse={"Login"} />
                                    </Button>
                                </div>

                                <div className="from-link text-center">
                                    <Link to="/forgot-password" className="fw-medium text-uppercase">
                                        Forgot Password?
                                    </Link>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <p className="more-link text-center text-uppercase">
                        Don't have an account ?
                        <Link to="register" className="mx-3 fw-medium">
                            Signup now
                        </Link>
                    </p>
                </div>
            </React.Fragment>
        )
    }
}

export default Login
