import React, { Component } from "react"
import { Col, Row } from "reactstrap"

import profile from "../assets/images/profile-img.png"
import logo from "../assets/images/login-logo.png"

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-primary bg-soft">
                    <Row>
                        <Col className="col-7">
                            <div className="text-primary p-4">
                                <h5 className="text-primary">{this.props.title}</h5>
                                <p>{this.props.message}</p>
                            </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

class Logo extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <span id="logoSpan" className="avatar-title">
                    <img src={logo} className="logo-image" alt="" />
                </span>
            </React.Fragment>
        )
    }
}

export { Profile, Logo }
