export const SET_TRADING_DATA_LOADING = "SET_TRADING_DATA_LOADING"
export const SET_MORE_TRADING_DATA_LOADING = "SET_MORE_TRADING_DATA_LOADING"
export const SET_TRADING_ITEMS = "SET_TRADING_ITEMS"
export const ADD_TRADING_ITEMS = "ADD_TRADING_ITEMS"
export const SET_TRADING_TOKENS = "SET_TRADING_TOKENS"
export const SET_TOKENS_LAST_EVALUATED_KEY = "SET_TOKENS_LAST_EVALUATED_KEY"

export const setLoading = (value) => ({
    type: SET_TRADING_DATA_LOADING,
    payload: value
})

export const setMoreItemsLoading = (value) => ({
    type: SET_MORE_TRADING_DATA_LOADING,
    payload: value
})

export const setTradingItems = (value) => ({
    type: SET_TRADING_ITEMS,
    payload: value
})

export const addTradingItems = (value) => ({
    type: ADD_TRADING_ITEMS,
    payload: value
})

export const setTradingTokens = (value) => ({
    type: SET_TRADING_TOKENS,
    payload: value
})

export const setLastEvaluatedKey = (value) => ({
    type: SET_TOKENS_LAST_EVALUATED_KEY,
    payload: value
})
