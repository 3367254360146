import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Form, FormGroup, Input, Label } from "reactstrap"

import CSVReader from "../CSVReader/CSVReader"
import SpinnerWrap from "../SpinnerWrap"
import "./CSVuploadModal.scss"

// BULK UPLOAD CSV MODAL
const CSVuploadModal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete" Button
    handleSubmit,
    confirmButtonText,
    confirmButtonLoading,
    ...rest
}) => {
    const [csvData, setCSVdata] = useState(null) // array of objects, where each object is a row/item from the csv file (excluding header row)
    const [createNewWallets, setCreateNewWallets] = useState(false) // if false, no new custodial wallets will be created for each csv item in vault_wallet DB

    const closeAndResetModal = () => {
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
    }

    const toggleCreateNewWallets = () => {
        if (!showConfirmLoading && !disableAllButtons) {
            setCreateNewWallets((prev) => !prev)
        }
    }

    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="csv-upload-modal-wrap"
        >
            <ModalHeader
                toggle={showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            >
                Upload CSV File
            </ModalHeader>

            <ModalBody>
                <div className="csv-upload-text">You can upload a CSV file in order to bulk upload numerous CRM items</div>
                <div className="csv-upload-text">
                    <a
                        className={"csv-template-download" + (showConfirmLoading || disableAllButtons ? " disable-download" : "")}
                        href="https://imagespublic-738087076124.s3.amazonaws.com/crm-upload-template.csv"
                        target="_blank"
                        rel="noreferrer"
                        download="crm-upload-template.csv"
                    >
                        Click here to download a template csv file with example header columns
                    </a>
                </div>
                {/* NOTE* assumes CSV file being uploaded has a header row (1st row) */}
                <CSVReader
                    className="csvreader-upload"
                    setCSVdata={(csvArray) => setCSVdata(csvArray)}
                    disableAllButtons={showConfirmLoading || disableAllButtons}
                />

                <Form className="create-new-wallets-wrap">
                    <FormGroup className="form-switch" onClick={toggleCreateNewWallets}>
                        <Input
                            type="checkbox"
                            className="create-new-wallets"
                            checked={createNewWallets}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                        <Label
                            title="Turning this setting on will create a new idexo cusodial wallet address for every item you upload in your csv file"
                            className={showConfirmLoading || disableAllButtons ? "disable-cursor" : ""}
                        >
                            Create New Custodial Wallets
                        </Label>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter className="submit-cancel">
                <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                    {modalAlertMessage}
                </Alert>
                <Button
                    color="primary"
                    type="submit"
                    disabled={showConfirmLoading || disableAllButtons}
                    onClick={(e) => handleSubmit(csvData, createNewWallets)}
                >
                    <SpinnerWrap
                        showSpinner={showConfirmLoading}
                        txtTrue={"SUBMITTING"}
                        txtFalse={"SUBMIT"}
                        disabled={showConfirmLoading || disableAllButtons}
                    />
                </Button>
                <Button
                    color="secondary"
                    outline
                    onClick={closeAndResetModal}
                    className="btn-light waves-effect cancel-btn"
                    disabled={showConfirmLoading || disableAllButtons}
                >
                    CANCEL
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CSVuploadModal
