import React, { useCallback, useState, useEffect } from "react"
import BreadCrumb from "../Components/BreadCrumb"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"
import jwt from "jsonwebtoken"

import { formatAddress, networkIcons, isStringBase64 } from "../utils"
import { useToast } from "../hooks/toast"
import Tooltip from "../Components/Tooltip"
import AddModule from "../Components/AddModule/AddModule"
import CreateUpdateProjectModal from "../Components/CreateUpdateProjectModal/CreateUpdateProjectModal"
import AddContractModal from "../Components/SmartContract/AddContractModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import NFTMarketingModule from "../Components/NFTMarketingModule/NFTMarketingModule"
import ConfirmSubmitModal from "../Components/ConfirmSubmitModal/ConfirmSubmitModal"
import IntegrateNFTS from "./IntegrateNFTS"
import Vesting from "./Vesting"
import Staking from "./Staking"
import Survey from "./Survey"
import ERC20Creator from "./ERC20Creator"
import TokenGating from "./TokenGating"
import PlayerManagement from "./PlayerManagement"
import Templates from "./Templates"
import CRM from "./CRM"

const apiUrl = "https://projects.idexo.io/"

const moduleLogos = {
    "token staking": "bx-cylinder",
    "token vesting": "bx-lock",
    "nft marketing": "bxl-telegram",
    "nft collection": "bx-images",
    "integrate nfts": "bx-sync",
    "smart contract": "bx-file",
    "nft surveys": "bx-poll",
    "erc20 tokens": "bx-money",
    "token gating": "bx-key",
    "player management": "bx-group",
    templates: "bx-layer",
    crm: "bxs-group" // contact relationship management
}

const ProjectView = (props) => {
    const { project, clickProjects, fetchProjects, setProjectView } = props
    const toast = useToast()
    const decoded = jwt.decode(localStorage.jwtToken)
    const [showLoading, setShowLoading] = useState(false) // for fetching modules loading animation
    const [modules, setModules] = useState([])
    const [contracts, setcontracts] = useState([])
    const [alertMessage, setAlertMessage] = useState("") // for project card/modules table

    // for delete button/modal
    const [showDeleteLoading, setShowDeleteLoading] = useState(false) // for delete project button loading animation
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error/success deleting project
    const [alertColor, setAlertColor] = useState("")
    const [disableDeleteButtons, setDisableDeleteButtons] = useState(false)

    const [moduleClicked, setModuleClicked] = useState(null)
    const [moduleType, setModuleType] = useState(false)
    const [updateNFTCollection, setUpdateNFTCollection] = useState(false)
    const [updateERC20, setUpdateERC20] = useState(false)

    localStorage.setItem("lastActive", new Date().getTime())

    const problemDeletingNFTMarketingData = (res) => {
        let key = Object.keys(res).filter((k) => k.includes("deleted_nft"))[0]
        if (key === undefined) return false
        return !!res[key].error || !!res[key].data?.error
    }

    const handleClickDeleteProject = async () => {
        setShowDeleteLoading(true)
        try {
            let finalUri = apiUrl
            if (localStorage.user_tenants && localStorage.selected_tenant) {
                const user_tenants = JSON.parse(localStorage.user_tenants)
                if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
            }

            const res = await fetch(finalUri, {
                method: "DELETE",
                headers: { token: localStorage.jwtToken, "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "deleteProject",
                    inserted: project.inserted.N,
                    project_name: project.project_name.S
                })
            })
            const response = await res.json()

            // problem from server deleting users project
            if (
                response.code !== 200 ||
                response.error ||
                response.message === "Internal server error" ||
                response.data?.error ||
                response.deleted_data?.error || // problem deleting item in user_projects table (includes smart contract data)
                problemDeletingNFTMarketingData(response) // problem deleting items in nftmarketing table and/or S3 images
            ) {
                setModalAlertMessage("Error: " + (response.error || response.data.error || response.deleted_data?.error))
                setAlertColor("danger")
                setShowDeleteLoading(false)
                return
            }

            // if succesful deletion, load projects page in 3 seconds
            setModalAlertMessage("Successfully deleted project! Loading projects page now...")
            setAlertColor("success")
            setDisableDeleteButtons(true)
            setTimeout(() => {
                clickProjects()
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowDeleteLoading(false)
    }

    const handleClickViewModule = (type, module) => {
        setModuleClicked(module)
        setModuleType(type)
    }

    const handleUpdateContract = () => {
        setTimeout(() => {
            setModuleType(false)
            fetchModules()
        }, 3000)
    }

    // for copying project or module address
    const handleCopyClick = (address) => {
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`Address ${formatAddress(address)} is copied to clipboard.`)
    }

    const fetchModules = useCallback(async () => {
        try {
            setShowLoading(true)
            let finalUri = apiUrl
            if (localStorage.user_tenants && localStorage.selected_tenant) {
                const user_tenants = JSON.parse(localStorage.user_tenants)
                if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
            }
            const res = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtToken, "x-api-key": decoded.api_key, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "getUserProject",
                    project_name: project.project_name.S,
                    inserted: project.inserted.N
                })
            })
            const response = await res.json()
            console.log("fetchModules", response)

            if (response.code !== 200 || response.error || response.message === "Internal server error" || response.data.error) {
                setAlertMessage("Error: " + (response.error || response.data.error))
                setModules(null)
                setcontracts(null)
                setShowLoading(false)
            } else {
                setModules(response.data.Items[0].modules.L)
                if (response?.contracts?.data) {
                    setcontracts(response.contracts.data)
                }
            }
        } catch (err) {
            console.log(err)
        }

        setShowLoading(false)

        // eslint-disable-next-line
    }, [project])

    // runs once after initial mount
    useEffect(() => {
        fetchModules()
    }, [fetchModules])

    // we need to refetch/update state or else the module will show "not deployed yet", when it is deployed & we click "Back to Project"
    useEffect(() => {
        if (updateNFTCollection) {
            fetchModules()
            setUpdateNFTCollection(false)
        }
    }, [updateNFTCollection, fetchModules])

    useEffect(() => {
        if (updateERC20) {
            fetchModules()
            setUpdateERC20(false)
        }
    }, [updateERC20, fetchModules])

    const renderModules = () => {
        localStorage.setItem("lastActive", new Date().getTime())
        if (showLoading) {
            return <LoadingCard loadingTxt={"Modules"} />
        }

        if (modules === null || modules === undefined) {
            return <LoadingCard errorTxt={"modules"} alertMessage={alertMessage} />
        }

        if (modules.length === 0) {
            return <LoadingCard emptyTxt={"modules"} icon="bx-add-to-queue" />
        }

        return (
            <Table className="modules-table align-middle mb-0 table-nowrap">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Chain</th>
                        <th scope="col" className="module-address">
                            Address
                        </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {modules?.concat(contracts).map((module, idx) => {
                        // chain will be undefined if module's contract not deployed
                        const moduleChain = module?.M?.chain?.S || module?.objMetaData?.chain // module type is a smart contract if it has objMetaData
                        return (
                            <tr key={idx}>
                                <td className="name">
                                    <i className={`bx ${moduleLogos[module?.M?.type?.S || "smart contract"]} module-logo`}></i>
                                    {module?.M?.module_name?.S || module?.objMetaData?.name}
                                </td>
                                <td className="type">{module?.M?.type?.S || "smart contract"}</td>
                                <td className="chain">
                                    {moduleChain && (
                                        <React.Fragment>
                                            <img src={networkIcons[moduleChain]} alt={moduleChain} className="modules-chain-logo" />
                                            <span>{moduleChain}</span>
                                        </React.Fragment>
                                    )}
                                </td>
                                <td className="module-address">
                                    {module?.M?.address?.S || module?.objMetaData?.address ? (
                                        <div>
                                            <span>{formatAddress(module?.M?.address?.S || module?.objMetaData?.address || " ")}</span>
                                            <Tooltip title="Copy address to clipboard" placement="bottom">
                                                <i
                                                    className="bx bx-copy"
                                                    onClick={() => handleCopyClick(module?.M?.address?.S || module?.objMetaData?.address || "")}
                                                />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <span>Not Deployed Yet</span>
                                    )}
                                </td>
                                <td className="action">
                                    <Button
                                        color="secondary"
                                        outline
                                        className="view-btn waves-effect waves-light"
                                        onClick={() => {
                                            localStorage.setItem("lastActive", new Date().getTime())
                                            handleClickViewModule(module?.M?.type?.S || "smart contract", module?.M || module)
                                        }} // module === smart contract
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    return (
        <React.Fragment>
            {(!moduleType || moduleType === "smart contract" || moduleType.endsWith("Modal")) && (
                <div className="projects-view">
                    {/* On Click, go back to projects page */}
                    {/* breadcrumb will look like: Home > Projects > project name (last item is always active) */}
                    <BreadCrumb items={["projects", `${project?.project_name?.S}`]} links={["projects"]} linkFunctions={[clickProjects]} />
                    <div className="create-projects-row back-to-projects">
                        <Button className="back-btn" color="secondary" onClick={clickProjects} id="back-btn" disabled={showDeleteLoading}>
                            Back to all projects
                        </Button>
                    </div>
                    <Card>
                        <CardBody>
                            <CardTitle className="h3">{project?.project_name?.S}</CardTitle>
                            <div className="project-card-info">
                                <div className="project-logo">
                                    {/* <UploadThumbnailPhoto
                                        photo={project?.project_logo?.S}
                                        defaultIcon={"bx bxs-camera-plus"}
                                        handleSelectPhoto={handleSelectPhoto}
                                    /> */}
                                    {project?.project_logo?.S === " " ? (
                                        <i className="bx bx-briefcase"></i>
                                    ) : (
                                        <img
                                            className="project-logo"
                                            alt="project logo"
                                            // displaying old base64 string for projects with images stored as base64 string in DB
                                            src={
                                                isStringBase64(project?.project_logo?.S)
                                                    ? `data:image/jpeg;base64,${project?.project_logo?.S}`
                                                    : `https://imagespublic-738087076124.s3.amazonaws.com/${project?.project_logo?.S}`
                                            }
                                        />
                                    )}
                                </div>
                                <div className="description">
                                    <div>
                                        <p className="label">DESCRIPTION</p>
                                        <p className="details">{project?.description?.S}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="add-module-row">
                                <Button
                                    color="primary"
                                    className="add-module-btn"
                                    onClick={() => setModuleType("add module")}
                                    disabled={showDeleteLoading || showLoading}
                                >
                                    ADD MODULE
                                </Button>
                            </div>
                            <div className="label modules">Modules</div>

                            {renderModules()}
                        </CardBody>
                    </Card>

                    <div className="delete-project-row">
                        <span className="edit-project-btn" onClick={() => setModuleType("updateModal")}>
                            edit project
                        </span>
                        <a href="#top" className="delete-project-btn" onClick={() => setModuleType("deleteModal")}>
                            delete project
                        </a>
                    </div>
                </div>
            )}
            {/* DELETE PROJECT MODAL */}
            {moduleType === "deleteModal" && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={true}
                    setShowConfirmSubmitModal={setModuleType}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    disableAllButtons={disableDeleteButtons}
                    showConfirmLoading={showDeleteLoading}
                    handleClickConfirm={handleClickDeleteProject}
                    modalTitle={"Delete Project"}
                    modalText={"Deleting a project deletes all of its contents and is not reversible. Are you sure you want to delete this project?"}
                    confirmButtonText={"DELETE"}
                    confirmButtonLoading={"DELETING"}
                />
            )}
            {/* EDIT PROJECT MODAL */}
            {moduleType === "updateModal" && (
                <CreateUpdateProjectModal
                    showUpdateProjectModal={true}
                    setShowCreateUpdateProjectModal={setModuleType}
                    project={project}
                    modules={modules}
                    fetchProjects={fetchProjects}
                    setProjectView={setProjectView}
                />
            )}
            {/* Only render CONTRACT MODAL if contract object exists */}
            {moduleType === "smart contract" && (
                <AddContractModal
                    open={true}
                    onClose={() => setModuleType(false)}
                    onAddContract={handleUpdateContract}
                    project={project}
                    contract={moduleClicked}
                />
            )}
            {moduleType === "add module" && (
                <AddModule
                    project={project}
                    clickProjects={clickProjects}
                    onClickCancel={() => setModuleType(false)}
                    fetchModules={fetchModules}
                    modules={modules}
                />
            )}
            {/* NFT Marketing Module Component/Page (for editing module) */}
            {/* or NFT Collection Module Component/Page */}
            {(moduleType === "nft marketing" || moduleType === "nft collection") && (
                <NFTMarketingModule
                    project={project}
                    // we need to refetch/update state or else the module will show "not deployed yet", when it is deployed & we click "Back to Project"
                    clickCancel={() => {
                        setModuleType(false)
                        setUpdateNFTCollection(true)
                    }}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    fetchModules={fetchModules}
                    // prop attribute 'showNFTCollectionOnly' determines weather or not to render NFT Marketing page or just NFT Collection only
                    showNFTCollectionOnly={moduleType === "nft collection"}
                />
            )}
            {/* VESTING PAGE */}
            {moduleType === "token vesting" && (
                <Vesting
                    project={project}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    modules={modules}
                    setModules={setModules}
                />
            )}
            {/* INTEGRATE NFTs PAGE */}
            {moduleType === "integrate nfts" && (
                <IntegrateNFTS
                    project={project}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    fetchModules={fetchModules}
                />
            )}
            {/* STAKING PAGE */}
            {moduleType === "token staking" && (
                <Staking
                    project={project}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    modules={modules}
                    setModules={setModules}
                    stakingHasProject={true}
                />
            )}
            {/* SURVEYS PAGE */}
            {moduleType === "nft surveys" && (
                <Survey
                    project={project}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    modules={modules}
                    fetchModules={fetchModules}
                />
            )}
            {/* ERC20 TOKENS PAGE */}
            {moduleType === "erc20 tokens" && (
                <ERC20Creator
                    project={project}
                    // we need to refetch/update state or else the module will show "not deployed yet", when it is deployed & we click "Back to Project"
                    clickCancel={() => {
                        setModuleType(false)
                        setUpdateERC20(true)
                    }}
                    clickProjects={clickProjects}
                    module={moduleClicked}
                    modules={modules}
                    fetchModules={fetchModules}
                />
            )}
            {/* TOKEN GATING Builder PAGE */}
            {moduleType === "token gating" && (
                <TokenGating
                    project={project}
                    modules={modules}
                    module={moduleClicked}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    fetchModules={fetchModules}
                    setModules={setModules}
                />
            )}

            {/* PLAYER MANAGEMENT PAGE */}
            {moduleType === "player management" && (
                <PlayerManagement
                    project={project}
                    modules={modules}
                    module={moduleClicked}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    fetchModules={fetchModules}
                    setModules={setModules}
                />
            )}

            {/* TEMPLATES PAGE */}
            {moduleType === "templates" && (
                <Templates
                    project={project}
                    modules={modules}
                    module={moduleClicked}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    fetchModules={fetchModules}
                    setModules={setModules}
                />
            )}

            {/* CRM PAGE- Contact Relationship Management */}
            {moduleType === "crm" && (
                <CRM
                    project={project}
                    modules={modules}
                    module={moduleClicked}
                    clickCancel={() => setModuleType(false)}
                    clickProjects={clickProjects}
                    fetchModules={fetchModules}
                    setModules={setModules}
                />
            )}
        </React.Fragment>
    )
}

export default ProjectView
