import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Alert, Label } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import jwt from "jsonwebtoken"
import * as yup from "yup"

import { toBase64, isValidImage, compressFile, isStringBase64 } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./CreateUpdateProjectModal.scss"

const apiUrl = "https://projects.idexo.io/"

const CreateUpdateProjectModal = ({
    showCreateProjectModal = false,
    showUpdateProjectModal = false,
    setShowCreateUpdateProjectModal,
    setProjectView,
    project,
    modules,
    fetchProjects,
    ...rest
}) => {
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)

    const [projectName, setProjectName] = useState(project?.project_name?.S || "")
    const [description, setDescription] = useState(project?.description?.S || "")
    const [projectLogo, setProjectLogo] = useState(null) // imgFile JS Obj (blob datatype)

    const [alertColor, setAlertColor] = useState("") // danger === red, success === green
    const [alertMessage, setAlertMessage] = useState("") // alert for create/update project modal
    const [showSpinner, setShowSpinner] = useState(false) // loading animation for submit button
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    // TO DO: (low priority)- abstract/refactor into helper function for reuse/DRY
    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type
        if (!isValidImage(fileType)) {
            setAlertMessage("You must select a jpeg or png file!")
            setAlertColor("danger")
            setProjectLogo("") // reset to empty logo
            return
        }

        // TO DO: (low priority)- add loading spinner animation for compressing image
        // if file size >= 400kb, compress file (so we meet dynamo 400kb limit)
        if (files[0].size >= 400000) {
            files = [await compressFile(files[0])]
        }

        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        // setProjectLogo(base64Files[0].base64) // string- base64
        setProjectLogo(base64Files[0]) // imgFile JS Obj (blob datatype)
    }

    // TO DO: move this function to utils & replace all instances for reuse/DRY
    // Get S3 presigned-url
    const fetchPresignedURL = async (s3Key, getObject = false) => {
        return fetch("https://projects.idexo.io/", {
            method: "POST",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
            body: JSON.stringify({
                action: "fetchPresignedURL",
                s3Params: { Key: s3Key },
                getObject: getObject // if true, then we are getting an image from lambda instead of uploading to s3
            })
        })
    }

    // TO DO: move this function to utils & replace all instances for reuse/DRY
    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        return s3Response
    }

    const handleSubmit = async () => {
        setShowSpinner(true)
        setAlertMessage("")
        setAlertColor("")

        try {
            const inserted = showUpdateProjectModal ? project?.inserted?.N : new Date().getTime() // milliseconds since 1 January 1970 UTC

            let s3Key = " " // need empty " " because dynamo can't store "" if user doesn't upload a logo
            if (projectLogo) s3Key = `project-logos/${decoded.user_id}/project-inserted-${inserted}`

            const body = {
                action: "createNewProject", // this action also updates a project
                project_name: projectName,
                project_logo: s3Key,
                description: description,
                // NOTE* if we don't have this || we will delete the entire projects module array!!!!
                modules: modules || [], // can have empty List (of map objects) in dynamo (but not empty list of strings/numbers)
                inserted
            }

            let finalUri = apiUrl
            if (localStorage.user_tenants && localStorage.selected_tenant) {
                const user_tenants = JSON.parse(localStorage.user_tenants)
                if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
            }
            console.log("finalUri", finalUri)

            let res = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify(body)
            })

            let response = await res.json()
            if (response.error || response.message === "Internal server error" || response?.data?.error) {
                return processErrors("Error: " + (response.error || response?.data?.error || response.message))
            }

            // only upload image to s3 if user has just selected an image
            if (projectLogo) {
                // get presigned s3 URL to upload
                res = await fetchPresignedURL(s3Key)
                response = await res.json()
                console.log("fetchPresignedURL", response)

                // error getting presigned URL
                if (response?.code !== 200 || response?.error) return processErrors("Error Uploading Project Logo: " + response?.error)

                // upload image to public bucket, with public URL
                const uploadUrl = JSON.parse(response.data)?.url
                const s3UploadResponse = await uploadImageToS3(uploadUrl, projectLogo)
                console.log("s3UploadResponse", s3UploadResponse)

                // error uploading to s3
                if (!s3UploadResponse.ok) return processErrors("Error Uploading Project Logo")
            }

            let message = showCreateProjectModal ? "the new" : ""
            setAlertMessage(`Success! Loading ${message} project page...`)
            setAlertColor("success")
            setDisableModalButtons(true)

            // load project view page in 3 seconds
            setTimeout(() => {
                // window.location.href = `/projects/:id` // TO DO (maybe): add dynamic route with :id (address of project) in param

                // Reset form field data, modal state
                setShowCreateUpdateProjectModal(false)
                setProjectName("")
                setDescription("")
                setAlertMessage("")
                setProjectView(response.data.Attributes) // this will ensure new/updated project details show
            }, 3000)
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    const processErrors = (errorMessage) => {
        setAlertMessage(errorMessage)
        setAlertColor("danger")
        setShowSpinner(false)
    }

    return (
        <Modal
            isOpen={showCreateProjectModal || showUpdateProjectModal}
            centered={true}
            toggle={() => !disableModalButtons && !showSpinner && setShowCreateUpdateProjectModal(false)}
            className="create-update-project-modal"
        >
            <AvForm
                className="new-project-form"
                initialValues={{
                    "project-name": projectName,
                    "project-description": description
                }}
                enableReinitialize
                validationSchema={yup.object().shape({
                    "project-name": yup.string().required("This field is required."),
                    "project-description": yup.string().required("This field is required.")
                })}
                onSubmit={handleSubmit}
            >
                <ModalHeader toggle={showSpinner || disableModalButtons ? null : () => setShowCreateUpdateProjectModal(false)}>
                    {showCreateProjectModal ? "Create New Project" : "Update Project"}
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="mb-3">
                        <Label htmlFor="full-name" className="form-label">
                            Select Project Logo
                        </Label>

                        <div className="photo-upload-new-project">
                            <Dropzone onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                    <div>
                                        <div className="photo-select needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {(project?.project_logo?.S !== " " && project) || projectLogo ? (
                                                <div className="img-preview-container">
                                                    <img
                                                        className="selected-image"
                                                        alt="user data"
                                                        src={
                                                            // displaying old base64 string for projects with images stored as base64 string in DB
                                                            projectLogo || isStringBase64(project?.project_logo?.S)
                                                                ? `data:image/jpeg;base64,${projectLogo.base64 || project?.project_logo?.S}`
                                                                : `https://imagespublic-738087076124.s3.amazonaws.com/${project?.project_logo?.S}`
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div className="photo-select needsclick">
                                                    <i className="display-6 text-muted bx bxs-camera-plus" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <span
                            onClick={() => {
                                if (showUpdateProjectModal) {
                                    setAlertMessage("Project name can not be changed once the project is created")
                                    setAlertColor("danger")
                                }
                            }}
                        >
                            <AvField
                                name="project-name"
                                label="Project Name"
                                placeholder="Enter Project Name"
                                type="text"
                                className="form-control"
                                id="project-name"
                                required
                                disabled={showSpinner || disableModalButtons || showUpdateProjectModal}
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </span>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="project-description"
                            label="description"
                            placeholder="Enter Project Description"
                            type="textarea"
                            className="form-control"
                            id="description"
                            required
                            disabled={showSpinner || disableModalButtons}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableModalButtons ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                        {alertMessage}
                    </Alert>
                    <Button color="primary" type="submit" disabled={showSpinner || disableModalButtons}>
                        <SpinnerWrap
                            showSpinner={showSpinner}
                            txtTrue={"SUBMITTING"}
                            txtFalse={"SUBMIT"}
                            disabled={showSpinner || disableModalButtons}
                        />
                    </Button>{" "}
                    <Button
                        color="secondary"
                        outline
                        onClick={() => setShowCreateUpdateProjectModal(false)}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showSpinner || disableModalButtons}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default CreateUpdateProjectModal
