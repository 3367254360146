import React from "react"
import "./confirmSubmitModal.scss"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap"
import { Form as AvForm } from "@availity/form"
import SpinnerWrap from "../SpinnerWrap"

const ConfirmSubmitModal = ({
    // Submit ex. submition of form- Delete Project / Module, Mint NFT
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete", or "Mint NFT" Button
    handleClickConfirm,
    modalTitle,
    modalText,
    confirmButtonText,
    confirmButtonLoading,
    handleCloseModal = () => {},
    children,
    ...rest
}) => {
    const closeAndResetModal = () => {
        localStorage.setItem("lastActive", new Date().getTime())
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        handleCloseModal()
    }

    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="confirm-submit-modal"
        >
            <AvForm initialValues={{ name: "" }} onSubmit={handleClickConfirm}>
                <ModalHeader
                    toggle={showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
                >
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    {modalText}
                    {children}
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                        {modalAlertMessage}
                    </Alert>
                    <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons}>
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={confirmButtonLoading}
                            txtFalse={confirmButtonText}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                    </Button>{" "}
                    <Button
                        color="secondary"
                        outline
                        onClick={closeAndResetModal}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableAllButtons}
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default ConfirmSubmitModal
