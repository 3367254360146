import React from "react"
import BreadCrumb from "../Components/BreadCrumb"

const Zapier = () => {
    return (
        <React.Fragment>
            <div className="content zapier">
                <BreadCrumb items={["Zapier"]} />

                <zapier-full-experience client-id="vcqTWoizI6LUuzdfDkOAhAZ9cIOs0p9Zac0fEfpK" theme="light" app-search-bar-display="show" />
            </div>
        </React.Fragment>
    )
}

export default Zapier
