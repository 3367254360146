import React, { useState } from "react"
import { Button, ModalBody, FormGroup, Label, Input } from "reactstrap"
import { isValidJSON } from "../../utils"
import "./EditAttributes.scss"

const EditAttributes = ({ attributes, setAttributes, disableFormsAndButtons, jsonSyntaxError, setJsonSyntaxError }) => {
    const [attributesStr, setAttributesStr] = useState(JSON.stringify(attributes, null, 2) || "")
    const [showPasteJSON, setShowPasteJSON] = useState(false)

    const handleClickDeleteAttribute = (e, idx) => {
        e.preventDefault()
        if (disableFormsAndButtons) return
        let newAttributes = attributes.slice() // shallow copy
        newAttributes.splice(idx, 1) // remove attribute at index idx
        setAttributes(newAttributes)
    }

    const handleClickAddAttribute = (e) => {
        e.preventDefault()
        let newAttributes = attributes.slice()
        newAttributes.push({ trait_type: "", value: "" })
        setAttributes(newAttributes)
    }

    const handleOnChangeAttribute = (e, idx, type) => {
        // type === whether we're updating the "NAME" (key) or "VALUE" of an attribute object
        let newAttributes = attributes.slice() // shallow copy ex. = [{ trait_type: "", value: "" }]

        // updating the key (ie. trait_type) of an attribute object
        if (type === "NAME") {
            newAttributes[idx].trait_type = e.target.value
            setAttributes(newAttributes)

            // updating the value of an attribute
        } else {
            newAttributes[idx].value = e.target.value
            setAttributes(newAttributes)
        }
    }

    const handleChangeJSONAttributes = (e) => {
        let attributesObj
        try {
            attributesObj = JSON.parse(e.target.value)
            // check if json attributes have correct format (ie. array of objects with specific key names)
            if (!isValidJSON(attributesObj)) {
                setJsonSyntaxError('Error: JSON must be an array of objects with the following pattern: [ { "trait_type": "", "value": "" }, ... ]')
            } else {
                setJsonSyntaxError("")
            }
            setAttributes(attributesObj)
            setAttributesStr(e.target.value)
        } catch (err) {
            console.log(err)
            setJsonSyntaxError("Error: Invalid JSON Syntax")
            setAttributesStr(e.target.value)
        }
    }

    return (
        <ModalBody className="edit-attributes-tab">
            <div className="attributes-wrap">
                {showPasteJSON ? (
                    <div>
                        <FormGroup className="mb-3">
                            <Label htmlFor="full-name" className="form-label">
                                Use JSON
                            </Label>
                            <div>
                                JSON must be an array of objects. Each object must have exactly two keys: "trait_type" and "value"
                                <br />
                                <br />
                                <div>Example:</div>
                            </div>
                            <div>{'[ { "trait_type": "color", "value": "blue" }, { "trait_type": "location", "value":"italy" } ]'}</div>
                            <br />
                            <Input
                                type="textarea"
                                rows={10}
                                className="form-control"
                                id="paste-json"
                                placeholder="Paste an array of objects here (ex. { trait_type: 'color', value: 'blue' }]"
                                value={attributesStr}
                                onChange={handleChangeJSONAttributes}
                                disabled={disableFormsAndButtons}
                            />
                        </FormGroup>
                        {jsonSyntaxError && <div style={{ color: "#f46a6a" }}>{jsonSyntaxError}</div>}
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="name-value">
                            <span className="name-col">NAME</span>
                            <span className="value-col">VALUE</span>
                            <span className="delete-col">delete</span>
                        </div>

                        {attributes.map((attribute, idx) => {
                            return (
                                <div className="attributes-row" key={idx}>
                                    <input
                                        name="Name"
                                        placeholder="Enter Attribute Name"
                                        type="text"
                                        className="form-control attribute-name"
                                        value={attribute.trait_type}
                                        onChange={(e) => handleOnChangeAttribute(e, idx, "NAME")}
                                        disabled={disableFormsAndButtons}
                                    />
                                    <input
                                        name="Name"
                                        placeholder="Enter Attribute Value"
                                        type="text"
                                        className="form-control attribute-value"
                                        value={attribute.value}
                                        onChange={(e) => handleOnChangeAttribute(e, idx, "VALUE")}
                                        disabled={disableFormsAndButtons}
                                    />
                                    <i
                                        className="bx bx-trash delete-attribute"
                                        onClick={(e) => handleClickDeleteAttribute(e, idx)}
                                        disabled={disableFormsAndButtons}
                                        title="Delete Attribute"
                                    ></i>
                                </div>
                            )
                        })}
                    </React.Fragment>
                )}

                <div className="manual-or-json">
                    <Button
                        className={"add-attribute " + (showPasteJSON ? "hide" : "")}
                        onClick={handleClickAddAttribute}
                        disabled={disableFormsAndButtons}
                    >
                        Add Attribute
                    </Button>
                    <Button className="use-json" onClick={() => setShowPasteJSON((prev) => !prev)} disabled={disableFormsAndButtons}>
                        {showPasteJSON ? "Manual" : "Use JSON"}
                    </Button>
                </div>
            </div>
        </ModalBody>
    )
}

export default EditAttributes
