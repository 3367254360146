import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import Dropzone from "react-dropzone"
import { Player } from "video-react"
import * as yup from "yup"

import { isValidFileType, toBase64, formatAddress } from "../../utils"
import { useToast } from "../../hooks/toast"
import EditAttributes from "../EditAttributesModal/EditAttributes"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"
import "./EditTemplateModal.scss"

function initializeAttributes(templateOrigin) {
    const template = JSON.parse(JSON.stringify(templateOrigin)) // depth copy

    // let attributes = template?.metadata?.attributes || "[]"
    let attributes = template?.metadata?.attributes || []
    if (attributes.length) {
        return attributes
    } else {
        return [{ trait_type: "", value: "" }] // this is so we can display a non empty form in edit attributes tab
    }
}

// This component is also used as the ADD TEMPLATE Modal
const EditTemplateModal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    fromUri,
    setFromUri,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading, // spinner animation on "Submit", "Delete", or "Mint TEMPLATE" Button
    handleSubmit,
    confirmButtonText,
    confirmButtonLoading,
    setTemplate,
    template, // object- contains metadata/image
    storage,
    ...rest
}) => {
    const [img, setImg] = useState(null) // image object
    const [templateImageType, setTemplateImageType] = useState(template?.image_type || "") // ex. "video/mp4", "image/jpg"
    // if user has just selected an image blob, use that, else use s3 url from previously saved dynamo
    const imgUrl = !!img ? `data:${templateImageType};base64,${img?.base64}` : template?.image_url

    const [name, setName] = useState(template?.metadata?.name || "")
    const [description, setDescription] = useState(template?.metadata?.description || "")
    const [customId, setCustomId] = useState(template?.custom_id || "")
    const [uri, setUri] = useState("")
    const [externalUrl, setExternalUrl] = useState(template?.metadata?.external_url || "")

    const [attributesTabIsOpen, setAttributesTabIsOpen] = useState(false)
    const [attributes, setAttributes] = useState(() => initializeAttributes(template)) // ex. [ {trait_type: "color", value: "gold"}, {trait_type:"animal", value:"dog"} ]
    const [jsonSyntaxError, setJsonSyntaxError] = useState("") // for attributes tab

    const toast = useToast()

    const closeAndResetModal = () => {
        setFromUri(false)
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        setTemplate(null)
    }

    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`${formatAddress(address)} copied to clipboard.`)
    }

    const handleAcceptedFiles = async (files) => {
        const fileType = files[0].type // files[0] == JS Obj (blob datatype)

        if (!isValidFileType(fileType)) {
            setModalAlertMessage("You must select a valid file type: jpg, png, gif, mp4, or mov!")
            return setAlertColor("danger")
        }
        // don't let user submit file sizes larger than 15 MB for Arweave or 50 for Filecoin
        const maxsize = storage.toLowerCase() === "arweave" ? 15728640 : 52428800
        if (files[0].size >= maxsize) {
            // 15,728,640 bytes == 15 MB
            setModalAlertMessage("File size must be under 15 MB")
            return setAlertColor("danger")
        }

        // if (files[0].size >= 400000) { // 400k bytes. compress file- should there be size limit?
        //     files = [await compressFile(files[0])] // compressFile from utils.js
        // }

        // TO DO: we may not need to do below
        // adds a key to blob where the value is a base64 string
        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )

        setTemplateImageType(fileType)
        // setImg(base64Files[0].base64) // string
        setImg(base64Files[0]) // imgFile JS Obj (blob datatype)
    }

    const handleClickSubmit = async (e) => {
        let action = null
        // if template is null, then we are creating a new template
        if (fromUri && !template && uri && customId) {
            action = "create_from_uri"
        }
        if (!template && !fromUri) {
            action = "create_draft"
        } else if (noFormChange() && template && template.step === "draft") {
            action = "save_template"
        }
        // if template is not null and template.step === "draft", then we are updating a draft template
        else if (!noFormChange() && template && template.step === "draft") {
            action = "update_draft"
            // if template is not null and template.step === "saved", then we are editing an existing template
        } else if (template && template.step === "saved") {
            action = "edit_template"
        }

        if (action) {
            console.log("action: ", action)
            // TO DO:
            // let action = template?.step === "saved" ? "edit_template" : hasChange() ? "update_draft" : "save_template"
            // let action = !template ? "create_draft" : "update_draft" // TEMP FOR TESTING
            // create_draft = POST creates new item in table
            // update_draft = PATCH updates existing item in table

            const templateInfo = {
                name: name,
                description: description,
                uri: uri,
                custom_id: customId,
                external_url: externalUrl,
                attributes: removeEmptyAttributes(attributes) || []
            }

            handleSubmit(e, templateInfo, action, img, templateImageType, setImg) // img object = blob or null
        } else {
            setModalAlertMessage("Invalid action!")
            return setAlertColor("danger")
        }
    }

    // remove any attributes that have empty strings as trait_types or values
    const removeEmptyAttributes = (updateState = true) => {
        // ex1. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "","value": ""}] => [{"trait_type": "n1","value": "v1"}]
        // ex2. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "a","value": ""}] => [{"trait_type": "n1","value": "v1"}]
        // ex3. attributes = [{"trait_type": "n1","value": "v1"}, {"trait_type": "","value": "b"}] => [{"trait_type": "n1","value": "v1"}]
        // ex4. attributes = [{"trait_type": "","value": ""}] => [ ]
        let newAttributes = attributes?.slice()
        newAttributes = newAttributes.filter((attr) => {
            return attr.trait_type !== "" && attr.value !== ""
        })
        if (updateState) setAttributes(newAttributes)
        return newAttributes
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        const newAttributes = removeEmptyAttributes(false)
        if (!template) return !name && !description && !externalUrl && !img && !newAttributes.length
        return (
            name === template?.metadata?.name &&
            description === template?.metadata?.description &&
            externalUrl === template?.metadata?.external_url &&
            customId === template?.custom_id &&
            !img &&
            JSON.stringify(newAttributes) === JSON.stringify(template?.metadata?.attributes)
        )
    }

    // TO DO: remove empty spaces (if any) at the end of any input forms, before submitting
    return (
        <Modal
            isOpen={showConfirmSubmitModal}
            centered={true}
            toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
            className="edit-template-modal"
        >
            {fromUri ? (
                <AvForm
                    // NOTE* initialValue key's must match "name" attribute in AvField components!
                    initialValues={{
                        "template-uri": uri,
                        "template-custom-id": customId
                    }}
                    enableReinitialize
                    validationSchema={yup.object().shape({
                        "template-uri": yup.string().required("This field is required."),
                        "template-custom-id": yup.string().required("This field is required.")
                        // if callback in .test returns false, show error message
                    })}
                    onSubmit={handleClickSubmit}
                >
                    <ModalHeader>
                        <div>Create Template From Token URI</div>
                    </ModalHeader>

                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                name="template-uri"
                                label="Metadata URI"
                                placeholder="Enter Metadata URI"
                                type="text"
                                className="form-control"
                                id="template-uri"
                                required
                                disabled={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                                onChange={(e) => setUri(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="template-custom-id"
                                label="Custom Id"
                                placeholder="Enter Template Custom Id"
                                type="text"
                                className="form-control"
                                id="template-custom-id"
                                required
                                disabled={showConfirmLoading || disableAllButtons}
                                onChange={(e) => setCustomId(e.target.value)}
                            />
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                            {modalAlertMessage}
                        </Alert>

                        <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || !!jsonSyntaxError}>
                            <SpinnerWrap
                                showSpinner={showConfirmLoading}
                                txtTrue={"CREATING..."}
                                txtFalse={"CREATE TEMPLATE"}
                                disabled={showConfirmLoading || disableAllButtons}
                            />
                        </Button>

                        <Button
                            color="secondary"
                            outline
                            onClick={closeAndResetModal}
                            className="btn-light waves-effect cancel-btn"
                            disabled={showConfirmLoading || disableAllButtons}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            ) : (
                <AvForm
                    // NOTE* initialValue key's must match "name" attribute in AvField components!
                    initialValues={{
                        "template-name": name,
                        "template-description": description,
                        "template-ext-url": externalUrl,
                        "template-custom-id": customId
                    }}
                    enableReinitialize
                    validationSchema={yup.object().shape({
                        "template-name": yup.string().required("This field is required."),
                        "template-description": yup.string().required("This field is required.")
                        // if callback in .test returns false, show error message
                    })}
                    onSubmit={handleClickSubmit}
                >
                    <ModalHeader
                        toggle={
                            showConfirmLoading || disableAllButtons ? null : () => !disableAllButtons && !showConfirmLoading && closeAndResetModal()
                        }
                    >
                        <div className={"edit-template-tab" + (attributesTabIsOpen ? "" : " open")} onClick={() => setAttributesTabIsOpen(false)}>
                            {!!template ? "Edit Template" : "Add Template"}
                        </div>
                        <div className={"attributes-tab" + (attributesTabIsOpen ? " open" : "")} onClick={() => setAttributesTabIsOpen(true)}>
                            Edit Attributes
                        </div>
                    </ModalHeader>
                    {attributesTabIsOpen ? (
                        <EditAttributes
                            attributes={attributes}
                            setAttributes={setAttributes}
                            disableFormsAndButtons={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                            jsonSyntaxError={jsonSyntaxError}
                            setJsonSyntaxError={setJsonSyntaxError}
                        />
                    ) : (
                        // EDIT/ADD TEMPLATE Tab
                        <ModalBody>
                            <FormGroup className="mb-3">
                                <div>
                                    <Label htmlFor="full-name" className="form-label select-media">
                                        {/* jpg, png, gif, mov, mp4 */}
                                        Select Media
                                    </Label>
                                    <div className="media-file-types">Supported Media File Types: jpg, png, gif, mov, mp4</div>
                                </div>

                                <div className={"photo-upload-new-project"}>
                                    {/* TO DO: fix css so clicking on entire circle will trigger upload */}
                                    <Dropzone
                                        onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)}
                                        maxFiles={1}
                                        disabled={showConfirmLoading || disableAllButtons || template?.step === "saved"}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div>
                                                <div
                                                    className={"photo-select needsclick" + (template?.step === "saved" ? " disable-cursor" : "")}
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    {/* if there is no template image uploaded, show default icon */}
                                                    {(template?.image_url === "" && !img) || (!template && !img) ? ( // TO DO: use this pattern for EditPlayerModal
                                                        <div className="photo-select needsclick">
                                                            <i className="display-6 text-muted bx bxs-camera-plus" />
                                                        </div>
                                                    ) : (
                                                        <div className="img-preview-container">
                                                            {templateImageType === "video/mp4" || templateImageType === "video/quicktime" ? (
                                                                <Player
                                                                    // img.preview -> truthy if user selected file
                                                                    // imgUrl -> truthy if user clicked on edit/add/view template row (edit template modal)
                                                                    src={img?.preview || imgUrl}
                                                                    playsInline
                                                                    controls={false}
                                                                    autoPlay={true}
                                                                    loop={true}
                                                                    // TO DO: fix css if video dimensions are rectangular (not square)
                                                                    className="video-preview"
                                                                />
                                                            ) : (
                                                                <img className="selected-image" alt="template data" src={imgUrl} />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <AvField
                                    name="template-name"
                                    label="Name"
                                    placeholder="Enter Template Name"
                                    type="text"
                                    className="form-control"
                                    id="template-name"
                                    required
                                    disabled={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <AvField
                                    name="template-description"
                                    label="Description"
                                    placeholder="Enter Template Description"
                                    type="textarea"
                                    rows={5}
                                    className="form-control"
                                    id="template-description"
                                    required
                                    disabled={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <AvField
                                    name="template-ext-url"
                                    label="External URL"
                                    placeholder="Enter External URL"
                                    type="text"
                                    className="form-control"
                                    id="template-ext-url"
                                    disabled={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                                    onChange={(e) => setExternalUrl(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <AvField
                                    name="template-custom-id"
                                    label="Custom ID"
                                    placeholder="Enter Template Custom ID"
                                    type="text"
                                    className="form-control"
                                    id="template-custom-id"
                                    disabled={template?.step === "saved" || showConfirmLoading || disableAllButtons}
                                    onChange={(e) => setCustomId(e.target.value)}
                                />
                            </FormGroup>

                            {
                                <div className="metadatauri">
                                    {template?.storage === "filecoin" ? (
                                        <React.Fragment>
                                            <div className="uri-type">
                                                Metadata IPFS URI:
                                                <Tooltip
                                                    title="Note, IPFS addresses can only be accessed by an HTTP Gateway, or certain browsers (ex. Brave)"
                                                    placement="bottom"
                                                >
                                                    <i className="bx bx-info-circle font-size-20" />
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <span className="filecoin-url">{template?.metadata_uri}</span>
                                                <Tooltip title="Copy IPFS URI to clipboard" placement="bottom">
                                                    <i
                                                        className="bx bx-copy font-size-20"
                                                        onClick={(e) => handleCopyClick(e, template?.templateURI)}
                                                    />
                                                </Tooltip>
                                            </div>

                                            <div className="filecoin-http-uri uri-type">Metadata HTTP URI:</div>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={"https://templatestorage.link/ipfs/" + template?.metadata_uri.slice(7)}
                                            >
                                                <span className="filecoin-url">
                                                    {"https://templatestorage.link/ipfs/" + template?.metadata_uri.slice(7)}
                                                </span>
                                            </a>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="uri-type">Metadata URI:</div>
                                            <a target="_blank" rel="noreferrer" href={template?.metadata_uri}>
                                                {template?.metadata_uri}
                                            </a>
                                        </React.Fragment>
                                    )}
                                </div>
                            }
                        </ModalBody>
                    )}
                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                            {modalAlertMessage}
                        </Alert>

                        {/* <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || noFormChange() || !!jsonSyntaxError}>
                        <SpinnerWrap
                            showSpinner={showConfirmLoading}
                            txtTrue={"SAVING"}
                            // txtFalse={changed ? "SAVE CHANGES" : "CREATE LIVE TEMPLATE"}
                            txtFalse={"SAVE"}
                            disabled={showConfirmLoading || disableAllButtons}
                        />
                    </Button> */}

                        {(template?.step === "draft" || !template) && (
                            <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || !!jsonSyntaxError}>
                                <SpinnerWrap
                                    showSpinner={showConfirmLoading}
                                    txtTrue={!noFormChange() ? "SAVING..." : "CREATING..."}
                                    txtFalse={!template ? "CREATE DRAFT" : !noFormChange() ? "SAVE DRAFT" : "CREATE LIVE"}
                                    disabled={showConfirmLoading || disableAllButtons}
                                />
                            </Button>
                        )}

                        {template?.step === "saved" && (
                            <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons}>
                                <SpinnerWrap
                                    showSpinner={showConfirmLoading}
                                    txtTrue={"REQUESTING..."}
                                    txtFalse="EDIT TEMPLATE"
                                    disabled={showConfirmLoading || disableAllButtons}
                                />
                            </Button>
                        )}

                        {/* {template?.step === "saved" && noFormChange() && (
                        <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons || !!jsonSyntaxError}>
                            <SpinnerWrap
                                showSpinner={showConfirmLoading}
                                txtTrue={"REQUESTING..."}
                                txtFalse={"CREATE LIVE"}
                                disabled={showConfirmLoading || disableAllButtons}
                            />
                        </Button>
                    )} */}

                        <Button
                            color="secondary"
                            outline
                            onClick={closeAndResetModal}
                            className="btn-light waves-effect cancel-btn"
                            disabled={showConfirmLoading || disableAllButtons}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            )}
        </Modal>
    )
}

export default EditTemplateModal
