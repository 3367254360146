import React, { useState, useEffect } from "react"
import { TabContent, TabPane } from "reactstrap"
import BreadCrumb from "../Components/BreadCrumb"
import Api from "../Components/Account/Api"
import User from "../Components/Account/User"
import Company from "../Components/Account/Company"
import Tabs from "../Components/Tabs/Tabs"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"

const apiUrlUpdateOrg = "https://org.idexo.io/" // for updating/getting user email/name/photo, & company name/logo

const Settings = () => {
    const [activeTab, setActiveTab] = useState("1")
    const [userCompanyData, setUserCompanyData] = useState(null) // object that has user and company info (ex. user/company logo, name)
    const [showLoading, setShowLoading] = useState(true) // loading spinner animation for forms/logos
    const [alertMessage, setAlertMessage] = useState("") // for getting user & company data

    const fetchUserCompanyData = async () => {
        setShowLoading(true)
        setAlertMessage("")

        try {
            // fetches entire user object in dynamodb users table that matches email (from jwt)
            const res = await fetch(apiUrlUpdateOrg + "?action=user", {
                method: "GET",
                headers: { token: localStorage.jwtToken }
            })

            const response = await res.json() //=> promise that resolves to JS object
            console.log("fetchUserCompanyData", response)

            if (response.error) {
                setAlertMessage(response.error)
            } else {
                setUserCompanyData(response.data.Items[0])
            }
        } catch (err) {
            console.log(err)
            setAlertMessage(String(err))
        }
        setShowLoading(false)
    }

    // runs once after mounting
    useEffect(() => {
        fetchUserCompanyData()
    }, [])

    return (
        <React.Fragment>
            <div className="content account-settings">
                <BreadCrumb items={["Account"]} />

                <Tabs tabs={["User", "Company", "API"]} setActiveTab={setActiveTab} activeTab={activeTab} />

                <TabContent activeTab={activeTab}>
                    {/* render loading animation or error message if userCompanyData not yet retrieved from backend */}
                    {userCompanyData ? (
                        <React.Fragment>
                            <TabPane className="show" tabId="1">
                                {activeTab === "1" && <User userCompanyData={userCompanyData} fetchUserCompanyData={fetchUserCompanyData} />}
                            </TabPane>
                            <TabPane className="show" tabId="2">
                                {activeTab === "2" && <Company userCompanyData={userCompanyData} fetchUserCompanyData={fetchUserCompanyData} />}
                            </TabPane>
                            <TabPane className="show" tabId="3">
                                {activeTab === "3" && <Api />}
                            </TabPane>
                        </React.Fragment>
                    ) : (
                        // render loading animation, or error message (if failure to get userCompanyData)
                        <React.Fragment>
                            {showLoading && <LoadingCard loadingTxt={"User Data"} backgroundColor="white" />}
                            {alertMessage && <LoadingCard errorTxt={"user data"} backgroundColor="white" alertMessage={alertMessage} />}
                        </React.Fragment>
                    )}
                </TabContent>
            </div>
        </React.Fragment>
    )
}

export default Settings
