import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"

import { networkIcons, formatAddress, updateModules, numberWithCommas } from "../../utils"
import { useToast } from "../../hooks/toast"
import ConfirmSubmitModal from "../ConfirmSubmitModal/ConfirmSubmitModal"
import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import BreadCrumb from "../BreadCrumb"
import Tooltip from "../Tooltip"
import OkDialog from "../OkDialog/OkDialog"
import QuestionModal from "./QuestionModal"
import "./QuestionsPage.scss"

const apiUrl = "https://projects.idexo.io/"

// TO DO: confirm how survey results are formatted/added in the backend
// const TEST_QUESTIONS_AND_RESULTS = [
//     {
//         title: "What's the next feature idexo should build?",
//         choices: ["nft minting on doge chain", "bulk nfts uploading", "dark mode"],
//         results: ["1027/1488", "163/1488", "300/1488"]
//     },
//     { title: "solana support?", choices: ["yes", "no"], results: ["1368/1488", "120/1488"] }
// ]

const initializeQuestions = (mod) => {
    if (!mod?.questions || mod?.questions?.SS.length === 0) return []
    // 	  [
    //         '{ title: "next feature", choices: ["nft surveys", "bulk nfts upload"] }',
    //         '{ title: "solana support?", choices: ["yes", "no"] }'
    //     ]
    // return TEST_QUESTIONS_AND_RESULTS // FOR TESTING
    return mod.questions.SS.map((ques) => JSON.parse(ques))
}

const QuestionsPage = ({ project, modules, module, clickProjects, clickProject, clickCancel, fetchSurveyData }) => {
    const toast = useToast()
    const status = module?.status?.S || "" // "draft" -> "pending" -> "done"
    const showSurveyResults = module?.status?.S === "pending" || module?.status?.S === "done"
    // const showSurveyResults = true // FOR TESTING
    const [okAlertMessage, setOkAlertMessage] = useState("") // alert if clicking on "add question" and contract status is already "done" or "pending"

    // delete quesiton modal
    const [modalAlertMessage, setModalAlertMessage] = useState(false)
    const [modalAlertColor, setModalAlertColor] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(status === "pending" || status === "done" || false)
    const [showConfirmLoading, setShowConfirmLoading] = useState(false)
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false)

    const [showQuestionModal, setShowQuestionModal] = useState(false)
    const [showQuestionRowColor, setShowQuestionRowColor] = useState(false) // questions table row changes color on mouse hover (but not if mouse over buttons)
    const [showLoading, setShowLoading] = useState(false) // for fetching questions table loading animation

    const [surveyQuestions, setSurveyQuestions] = useState(() => initializeQuestions(module)) // array of JS objects. NOTE* we're receiving array of stringified JS Objects from backend
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(null) // index in module.questions.SS array that selectedQuestion refers to (for easy updating/removal)

    const totalResponses = surveyQuestions?.length === 0 || !showSurveyResults ? 0 : Number((surveyQuestions[0]?.results[0]).split("/")[1])

    const handleClickDeleteQuestion = async () => {
        try {
            setShowConfirmLoading(true)
            setModalAlertMessage("")

            let newModule = {
                M: {
                    type: { S: module.type.S }, // "nft surveys"
                    inserted: { N: String(module.inserted.N) },
                    module_name: { S: module.module_name.S },
                    survey_name: { S: module.survey_name.S },
                    description: { S: module.description.S },
                    chain: { S: module.chain.S }, // ex. "fantom"
                    reward_amount: { S: module.reward_amount.S }, // stringified number
                    reward_token: { S: module.reward_token.S }, // string- address
                    required_nfts: { S: module.required_nfts.S },
                    contract_type: { S: module.contract_type.S }, // ex. "standard"
                    status: { S: module.status.S } // ex. "draft" -> "pending" -> "done"
                    // questions: { array of stringified JS objects
                    //     SS: [
                    //         '{ title: "next feature", choices: ["nft surveys", "bulk nfts upload"] }',
                    //         '{ title: "solana support?", choices: ["yes", "no"] }'
                    //     ]
                    // }
                }
            }
            if (module?.address?.S) newModule.M.address = { S: module.address.S }

            let newQuestions = module?.questions?.SS?.slice()
            newQuestions.splice(selectedQuestionIdx, 1) // in place, remove question at index selectedQuestionIdx
            newModule.M.questions = { SS: newQuestions }

            let oldModules = modules.slice() // shallow copy
            let newModules = updateModules(oldModules, newModule)
            newModules = newModules.filter((mod) => !mod?.objKey) // remove smart contracts from modules (since we keep them separate from dynamo)

            // save or update project item in dynamodb user_projects table
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule", // also updates modules
                    project_name: project.project_name.S,
                    inserted: String(project.inserted.N),
                    modules: newModules // updated modules to replace in backend
                })
            })
            const response = await res.json()
            console.log("createNewModule", response)

            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setShowConfirmLoading(false)
                setModalAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                return setModalAlertColor("danger")
            } else {
                setModalAlertMessage("Success! Going back to the questions page...")
                setModalAlertColor("success")
                setDisableModalButtons(true)
                fetchSurveyData() // forces parent, and parents parent to render/refresh stale prop data (module, modules)
                setTimeout(async () => {
                    setShowDeleteQuestionModal(false)
                    setSelectedQuestion(null)
                    setSelectedQuestionIdx(null)
                }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowConfirmLoading(false)
    }

    // TO DO: refactor so we can move to utils.js for reuse/DRY (this function is showing in multiple components)
    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`Address ${formatAddress(address)} is copied to clipboard.`)
    }

    // cb runs once after initial mount, and then everytime module dependecy changes
    useEffect(() => {
        setShowLoading(false)
        setSurveyQuestions(() => initializeQuestions(module))
    }, [module])

    const renderQuestions = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"Questions"} />
        }

        if (module?.questions?.SS?.length === 0 || module?.questions === undefined) {
            return (
                <LoadingCard
                    emptyTxt={"questions"}
                    icon="bx-image-add"
                    onClick={() => {
                        setShowQuestionModal(true)
                    }}
                    className="empty-add-question"
                />
            )
        }
        // TO DO: show error card, if error fetching questions/survey data

        return (
            <div className="overflow-auto">
                <Table className="questions-table align-middle mb-0 table-nowrap">
                    <thead>
                        <tr>
                            <th scope="col" className="survey-question-th">
                                QUESTION
                            </th>
                            {!showSurveyResults && (
                                <th scope="col" className="survey-numchoices-th">
                                    NUM CHOICES
                                </th>
                            )}
                            <th scope="col" className="survey-choices-th">
                                CHOICES
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {surveyQuestions?.map((question, idx) => {
                            return (
                                <tr
                                    key={idx}
                                    className={"survey-row" + (showQuestionRowColor ? " show-background" : "")}
                                    onClick={(_e) => {
                                        setSelectedQuestion(question)
                                        setSelectedQuestionIdx(idx)
                                        setShowQuestionModal(true)
                                    }}
                                    onMouseEnter={() => setShowQuestionRowColor(true)}
                                    onMouseLeave={() => setShowQuestionRowColor(false)}
                                >
                                    <td className="survey-question-td">
                                        <span className="question-val">{question.title}</span>
                                    </td>

                                    {!showSurveyResults && <td className="num-choices">{question.choices.length}</td>}

                                    <td className="survey-choices-td">
                                        <div className="survey-choices-wrap">
                                            {question.choices.map((choice, idx) => {
                                                let result
                                                let percentVotes // % of users who voted on this choice
                                                let style = {}
                                                if (showSurveyResults) {
                                                    result = question?.results[idx] // ex. "92/100"
                                                    const choiceVotes = Number(result.split("/")[0])
                                                    percentVotes = (choiceVotes / totalResponses) * 100 // 92
                                                    style = { width: `${percentVotes}%` } // for displaying bar chart
                                                }
                                                return (
                                                    <div className="choice-wrap" key={idx}>
                                                        {showSurveyResults && <span className="percent-votes">{Math.floor(percentVotes)}%</span>}
                                                        <div className={"choice-item" + (showSurveyResults ? " results" : "")} style={style}>
                                                            {choice}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {!showSurveyResults && ( // TO DO: get rid of className condition & css, if this UI looks good after testing
                                            <i
                                                title="delete question"
                                                className={"bx bx-trash delete-question " + (status === "pending" || status === "done" ? "hide" : "")}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setSelectedQuestion(question)
                                                    setSelectedQuestionIdx(idx)
                                                    setShowDeleteQuestionModal(true)
                                                }}
                                                onMouseEnter={() => setShowQuestionRowColor(false)}
                                                onMouseLeave={() => setShowQuestionRowColor(true)}
                                            ></i>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {showSurveyResults && <div className="total-responses">{numberWithCommas(totalResponses)} responses</div>}
            </div>
        )
    }

    return (
        <div className="questions-page">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, "NFT Survey", "Survey Questions"]}
                links={["projects", "projects", "projects"]}
                linkFunctions={[clickProjects, clickProject, clickCancel]}
            />

            <div className="back-to-survey">
                <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn">
                    Back to Survey
                </Button>
            </div>

            <Card className="questions-card">
                <CardBody>
                    <CardTitle className="h3">NFT Survey Details: {module?.survey_name?.S}</CardTitle>

                    <div className="survey-summary">
                        <div className="survey-chain item">
                            <img src={networkIcons[module?.chain.S]} alt={module?.chain?.S} className="chain-logo" />
                            <div className="chain-right">
                                <div className="summary-label">CHAIN</div>
                                <div className="value">{module?.chain?.S}</div>
                            </div>
                        </div>
                        <div className="survey-name item">
                            <div className="summary-label">SURVEY NAME</div>
                            <div className="value">{module?.survey_name?.S}</div>
                        </div>
                        <div className="survey-reward-amount item">
                            <div className="summary-label">REWARD AMOUNT</div>
                            <div className="value">{module?.reward_amount?.S}</div>
                        </div>
                        <div className="survey-reward-token item">
                            <div className="summary-label">REWARD TOKEN</div>
                            <div className="value reward-token">
                                <span>{formatAddress(module?.reward_token?.S)}</span>
                                <span className="reward-token-address">
                                    <Tooltip title="Copy address to clipboard" placement="bottom">
                                        <i className="bx bx-copy font-size-20 ml-1" onClick={(e) => handleCopyClick(e, module?.reward_token?.S)} />
                                    </Tooltip>
                                </span>
                            </div>
                        </div>
                        <div className="summary-description item">
                            <div className="summary-label">DESCRIPTION</div>
                            <div className="value">{module?.description?.S}</div>
                        </div>
                        <div className="survey-reward-amount item">
                            <div className="summary-label">CONTRACT TYPE</div>
                            <div className="value">{module?.contract_type?.S}</div>
                        </div>
                        <div className="survey-required-nfts item">
                            <div className="summary-label">REQUIRIED NFTS</div>
                            {/* TO DO: call method on contract to get name */}
                            <div className="value">{formatAddress(module?.required_nfts?.S)}</div>
                        </div>
                    </div>

                    <div className="survey-status">
                        <span className="summary-label">Contract Status:</span>
                        <span className="summary-value step">{module?.tx_status?.S || "DRAFT"}</span>
                    </div>

                    <div className="survey-contract-address">
                        <span className="summary-label" title="">
                            Contract Address:
                        </span>

                        <span className="survey-value address">
                            {module?.address?.S ? (
                                <React.Fragment>
                                    <span className="contract-address">{module?.address?.S?.toLowerCase()}</span>
                                    <Tooltip title="Copy address to clipboard" placement="bottom">
                                        <i className="bx bx-copy font-size-20 ml-1" onClick={(e) => handleCopyClick(e, module?.address?.S)} />
                                    </Tooltip>
                                </React.Fragment>
                            ) : (
                                <span className="not-deployed">Survey Contract Not Deployed</span>
                            )}
                        </span>
                    </div>

                    <div className="add-question-row">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                                if (status === "pending" || status === "done") {
                                    setOkAlertMessage("Contract is pending or deployed, cannot add new questions!")
                                } else {
                                    setShowQuestionModal(true)
                                }
                            }}
                        >
                            ADD QUESTION
                        </Button>
                    </div>

                    {renderQuestions()}
                </CardBody>
            </Card>

            {/* ADD, EDIT, or VIEW Question Modal */}
            {showQuestionModal && (
                <QuestionModal
                    project={project}
                    modules={modules}
                    module={module}
                    showQuestionModal={showQuestionModal}
                    setShowQuestionModal={setShowQuestionModal}
                    selectedQuestion={selectedQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                    selectedQuestionIdx={selectedQuestionIdx} // index in module.questions.SS array that selectedQuestion refers to (for easy updating/removal)
                    setSelectedQuestionIdx={setSelectedQuestionIdx}
                    fetchSurveyData={fetchSurveyData}
                    disableAllButtons={disableModalButtons}
                />
            )}

            {showDeleteQuestionModal && (
                <ConfirmSubmitModal
                    showConfirmSubmitModal={showDeleteQuestionModal}
                    setShowConfirmSubmitModal={setShowDeleteQuestionModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={modalAlertColor}
                    setAlertColor={setModalAlertColor}
                    disableAllButtons={disableModalButtons}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation on delete modal
                    handleClickConfirm={handleClickDeleteQuestion}
                    modalTitle={"Delete Draft Question"}
                    modalText={"Are you sure you want to delete this question (Note* once you deploy the contract, the question cannot be deleted)"}
                    confirmButtonText={"DELETE QUESTION"}
                    confirmButtonLoading={"DELETING"}
                    handleCloseModal={() => {
                        setSelectedQuestion(null)
                        setSelectedQuestionIdx(null)
                    }}
                >
                    <div className="mt-2 nft-name-confirm-submit">
                        Question: <span> {selectedQuestion.title}</span>
                    </div>
                </ConfirmSubmitModal>
            )}

            <OkDialog
                open={!!okAlertMessage}
                message={okAlertMessage}
                okClick={() => {
                    setOkAlertMessage("")
                }}
            />
        </div>
    )
}

export default QuestionsPage
