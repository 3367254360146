import React, { useState } from "react"

const apiUrl = "https://chatresponse.idexo.io/"

// Mock API function - replace this with your actual API call
const sendMessageToApi = async (message) => {
    try {
        const body = {
            question: message,
            action: "postQuestion"
        }
        let finalUri = apiUrl

        if (localStorage.user_tenants && localStorage.selected_tenant) {
            const user_tenants = JSON.parse(localStorage.user_tenants)
            if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
        }
        console.log("finalUri", finalUri)

        let res = await fetch(finalUri, {
            method: "POST",
            headers: { token: localStorage.jwtToken },
            body: JSON.stringify(body)
        })
        console.log("res: " + JSON.stringify(res))
        let response = await res.json()
        if (response.error || response.message === "Internal server error" || response?.data?.error) {
            return processErrors("Error: " + (response.error || response?.data?.error || response.message))
        }
        console.log("response: " + JSON.stringify(response))
        return response.message
    } catch (err) {
        console.log(err)
    }

    // setShowSpinner(false)

    // Simulate API call
}

const processErrors = (errorMessage) => {
    console.log(errorMessage)
    // setShowSpinner(false)
}

const ChatPage = () => {
    const [messages, setMessages] = useState([])
    const [userInput, setUserInput] = useState("")
    //const [showSpinner, setShowSpinner] = useState(false) // loading animation for submit button

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!userInput.trim()) return

        const updatedMessages = [...messages, { sender: "User", text: userInput.trim() }]
        setMessages(updatedMessages)

        const response = await sendMessageToApi(userInput.trim())
        setMessages((prevMessages) => [...prevMessages, { sender: "System", text: response }])
        setUserInput("")
    }

    return (
        <div className="d-flex flex-column vh-100">
            <div className="p-3" style={{ zIndex: 2 }}>
                <form onSubmit={handleSubmit} className="d-flex gap-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Type your message here..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                    />
                    <button type="submit" className="btn btn-primary">
                        Send
                    </button>
                </form>
            </div>
            <div className="overflow-auto p-3 flex-grow-1" style={{ marginTop: "-1rem", zIndex: 1 }}>
                {messages.map((message, index) => (
                    <div key={index} className={`d-flex ${message.sender === "User" ? "justify-content-end" : "justify-content-start"}`}>
                        <div className={`rounded px-3 py-2 my-2 ${message.sender === "User" ? "bg-primary text-white" : "bg-light text-dark"}`}>
                            {message.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChatPage
