import React, { useState, useEffect } from "react"
import { Button, Card, Spinner } from "reactstrap"
import jwt from "jsonwebtoken"
import { PAID_PLANS } from "../../utils"
import PricingDesign15 from "../Pricing/PricingDesign15"
import "./billingTab.scss"

const apiUrl = "https://balance.idexo.io/"

const BillingTab = () => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const planType = PAID_PLANS[decoded.paid] //  "apiKey", "reserved", "trial", "starter", "professional", "enterprise", "ultimate"
    const [transactionCredits, setTransactionCredits] = useState(null) // number- user's transaction credit balance
    const [showErrorTxCredits, setShowErrorTxCredits] = useState(false) // bool- error if failure fetching transaction credits
    const [showTxCreditsLoading, setShowTxCreditsLoading] = useState(false) // loading spinner animation for fetching tx credits

    const fetchUserTransactionCredits = async () => {
        setShowTxCreditsLoading(true)
        setShowErrorTxCredits(false)
        try {
            const res = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "x-api-key": decoded.api_key,
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                setShowErrorTxCredits(true)
                return
            }
            setTransactionCredits(response.data.balance)
        } catch (error) {
            console.log(error)
            setShowErrorTxCredits(true)
        }
        setShowTxCreditsLoading(false)
    }

    useEffect(() => {
        if (!transactionCredits) {
            fetchUserTransactionCredits()
        }

        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <div className="billing-page">
                <Card className="billing-summary-card">
                    <div className="bill-summary-item">
                        <div className="label">PLAN</div>
                        <div className="value">{planType}</div>
                    </div>
                    <div className="bill-summary-middle">
                        {planType === "apiKey" && (
                            <Button
                                className="start-trial btn-primary btn-font-bold"
                                href="https://commerce.coinbase.com/checkout/a35cb0bf-ac2c-470a-8b7b-d29ee0a52f40"
                                color="transparent"
                                target="_blank" // NOTE* all major browsers now automatically use rel="noopener noreferrer" with any target="_blank"
                                type="button"
                            >
                                START 14-DAY TRIAL
                            </Button>
                        )}
                        {planType === "trial" && (
                            <React.Fragment>
                                <div className="label">EXPIRY</div>
                                {/* TO DO- backend */}
                                <div className="value">Nov 30, 2022</div> {/* temporary placeholder */}
                            </React.Fragment>
                        )}
                        {/* all other plans- pro, enterprise, ultimate */}
                        {planType !== "apiKey" && planType !== "trial" && (
                            <React.Fragment>
                                <div className="label">NEXT BILL DATE</div>
                                {/* TO DO- backend */}
                                <div className="value">Nov 30, 2022</div> {/* temporary placeholder */}
                            </React.Fragment>
                        )}
                    </div>
                    <div className="bill-summary-item">
                        <div className="label">TRANSACTION CREDITS</div>
                        <div className={"value" + (showTxCreditsLoading ? " tx-credits-spinner-wrap" : "")}>
                            {showTxCreditsLoading ? <Spinner size="lg" color="primary" className="tx-credits-spinner" /> : transactionCredits}
                            {showErrorTxCredits && (
                                <React.Fragment>
                                    <i className="bx bx-error font-size-18 align-middle mr-1" />
                                    <span className="txcredits-error-txt">error fetching</span>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Card>

                <PricingDesign15 isBillingTab={true} />

                {/* <Row className="staker-status-wrap">
                    <Card>
                        <CardBody className="status-body">
                            <span className="staker-status">
                                <p className="staker-title">Staker Status</p>
                                <p className="staker-description">Status hasn't been validated now. No worries, you can do it now!</p>
                            </span>
                            <Button className="orange-button validate">VALIDATE NOW</Button>
                        </CardBody>
                    </Card>
                </Row> */}
            </div>
        </React.Fragment>
    )
}

export default BillingTab
