import React, { useState } from "react"
import { Button, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Alert, Label } from "reactstrap"
import { connect } from "react-redux"
import { Field as AvField, Form as AvForm } from "@availity/form"
import DateTimePicker from "react-datetime-picker"
import { QrReader } from "react-qr-reader"
import clsx from "clsx"
import * as yup from "yup"

import { chainOptions } from "../../common/chain"
import { updateModules, isValidAddress } from "../../utils"
import Select from "../CustomSelect"
import SpinnerWrap from "../SpinnerWrap"
import "./addVestingModal.scss"

const apiUrl = "https://projects.idexo.io/"

const AddVestingModal = (props) => {
    const { onClose, contract, project, module, modules, setModules } = props
    const [name, setName] = useState(contract?.contract_name?.S || "")
    const [chain, setChain] = useState(contract?.chain?.S || "")
    const [address, setAddress] = useState(contract?.address?.S || "")
    const [beneficiary, setBeneficiary] = useState(contract?.beneficiary?.S || "") // string- address
    const [startTime, setStartTime] = useState(contract?.startTime?.S || new Date().getTime())
    const [cliff, setCliff] = useState(contract?.cliff?.S || "")
    const [duration, setDuration] = useState(contract?.duration?.S || "")
    const [showSpinner, setShowSpinner] = useState(false)
    const [openQRModal, setOpenQRModal] = useState(false)
    const [qrScanErr, setQrScanErr] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    const allFormsValid = (forms) => {
        return forms.every((form) => form.replace(/\s/g, ""))
    }

    const handleSubmit = async () => {
        setShowSpinner(true)
        return await createOrUpdateModule()
    }

    const createOrUpdateModule = async () => {
        try {
            const obj = {
                inserted: { N: String(new Date().getTime()) },
                contract_name: { S: name },
                chain: { S: chain },
                address: { S: address },
                beneficiary: { S: beneficiary },
                startTime: { S: String(startTime) },
                cliff: { S: cliff },
                duration: { S: duration }
            }
            if (modalType === "Edit") {
                obj.inserted = contract.inserted
                module.contracts.L.forEach((c) => {
                    if (c.M.inserted === obj.inserted) c.M = obj
                })
            } else {
                if (!module.contracts) module.contracts = { L: [] }
                module.contracts.L.push({ M: obj })
            }
            const newModules = updateModules(modules.slice(), { M: module })
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule",
                    project_name: project?.project_name?.S,
                    inserted: String(project?.inserted?.N),
                    modules: newModules
                })
            })
            const response = await res.json()
            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                setAlertColor("danger")
            } else {
                setAlertMessage(`Success! Redirecting you back to the project page...`)
                setAlertColor("success")
                setDisableModalButtons(true)
                setModules(newModules)
                setTimeout(onClose, 2000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    const handleScan = (result, error) => {
        if (result) {
            const address = getAddressFromQr(result?.text)
            setAddress(address)
            setOpenQRModal(false)
        } else if (error) {
            setQrScanErr(true)
            console.error(error)
        }
    }

    const getAddressFromQr = (address = "") => {
        const str = address.split("?")[0]
        const str1 = str.split(":")

        return str1.length > 1 ? str1[1] : str1[0]
    }

    const handleOpenImageDialog = () => {
        const inputEl = document.querySelector(".qr-reader input")
        if (inputEl) {
            inputEl.click()
        }
    }

    const onCloseQrScanModal = () => {
        setOpenQRModal(false)
        setQrScanErr(false)
    }

    const noContractChange = () => {
        if (modalType === "Edit") {
            return name === module.name && address === module.address && chain === module.chain
        }
        return false
    }

    const modalType = contract ? "Edit" : "Add"

    return (
        <React.Fragment>
            <Modal
                className="add-vesting-contract-modal"
                isOpen={true}
                onClose={onClose}
                fade={true}
                centered={true}
                toggle={showSpinner || disableModalButtons ? null : onClose}
            >
                <AvForm
                    enableReinitialize
                    initialValues={{
                        pname: name,
                        tokena: address,
                        beneficiary: beneficiary,
                        cliff: cliff,
                        duration: duration
                    }}
                    validationSchema={yup.object().shape({
                        pname: yup.string().required("This field is required."),
                        tokena: yup
                            .string()
                            .required("This field is required.")
                            .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str)),
                        "chain-select": yup.string().required("This field is required."),
                        beneficiary: yup
                            .string()
                            .required("This field is required.")
                            .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str)),
                        cliff: yup.number().required("This field is required."),
                        duration: yup.number().required("This field is required.")
                    })}
                    onSubmit={handleSubmit}
                >
                    <ModalHeader toggle={showSpinner || disableModalButtons ? null : onClose} tag={"div"}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>{modalType} Vesting</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                name="pname"
                                label="Project Name"
                                placeholder="Enter Project Name"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Select
                                label="Chain"
                                id="chain-select"
                                name="chain-select"
                                placeholder="Select Blockchain"
                                options={chainOptions}
                                selectedOption={chain}
                                errorMessage="Required!"
                                required
                                handleChange={(value) => setChain(value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="contract-address-input mb-3 d-flex">
                            <AvField
                                name="tokena"
                                label="Token Address"
                                className="form-control"
                                placeholder="0x..."
                                type="text"
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                            <span className="qr-btn" onClick={() => setOpenQRModal(true)}>
                                <i className="fa fa-qrcode" />
                            </span>
                        </FormGroup>
                        <FormGroup className="contract-address-input mb-3 d-flex">
                            <AvField
                                name="beneficiary"
                                label="Beneficiary Address"
                                className="form-control"
                                placeholder="0x..."
                                type="text"
                                onChange={(e) => setBeneficiary(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                            <span className="qr-btn" onClick={() => setOpenQRModal(true)}>
                                <i className="fa fa-qrcode" />
                            </span>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <div className="form-group">
                                <Label>
                                    <strong className="text-danger d-inline align-text-top" style={{ fontSize: "130%", lineHeight: "100%" }}>
                                        *
                                    </strong>
                                    Start Time
                                </Label>
                                <br />
                                <DateTimePicker
                                    className={"form-control"}
                                    required={true}
                                    onChange={(x) => setStartTime(new Date(x).getTime())}
                                    value={new Date(Number(startTime))}
                                    disabled={showSpinner}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="cliff"
                                label="Cliff (months)"
                                placeholder="Enter Cliff"
                                type="number"
                                onChange={(e) => setCliff(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="duration"
                                label="Duration (months)"
                                placeholder="Enter Duration"
                                type="number"
                                onChange={(e) => setDuration(e.target.value)}
                                required
                                disabled={showSpinner}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableModalButtons ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>
                        <Button
                            color="primary"
                            type="submit"
                            className="save-contract-btn waves-effect"
                            disabled={
                                showSpinner || disableModalButtons || !allFormsValid([name, address, chain, cliff, duration]) || noContractChange()
                            }
                        >
                            {modalType === "Add" ? (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Saving"} txtFalse={"Save"} />
                            ) : (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Updating"} txtFalse={"Update"} />
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            outline
                            className="btn-light waves-effect cancel-btn"
                            onClick={onClose}
                            disabled={showSpinner || disableModalButtons}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
            <Modal className="scan-qr-modal" isOpen={openQRModal} onClose={onCloseQrScanModal} fade={true} centered={true}>
                <ModalHeader toggle={onCloseQrScanModal}>Scan QR</ModalHeader>
                <ModalBody>
                    {qrScanErr && <p className="text-center">The QR could not be read</p>}
                    <div className={clsx("container", qrScanErr ? "invalid" : "")}>
                        <QrReader className="qr-reader" onResult={handleScan} />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button color="secondary" className="btn-light waves-effect me-3" onClick={onCloseQrScanModal} disabled={showSpinner}>
                            Close
                        </Button>
                        <Button color="primary" type="file" className="waves-effect" onClick={handleOpenImageDialog}>
                            Select an Image
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    networkOptionStatus: state.commonReducer.networkOptionStatus
})

export default connect(mapStateToProps)(AddVestingModal)
