import React, { Component } from "react"
import { Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { Logo } from "./Fragments"

class Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount = () => {
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("jwtTenant")
        localStorage.removeItem("authUser")
        localStorage.removeItem("user_tenants")
        localStorage.removeItem("selected_tenant")
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages">
                    <Card>
                        <CardBody>
                            <Logo></Logo>
                        </CardBody>
                    </Card>
                    <div className="more-link text-center text-uppercase">
                        Already have an account ?
                        <Link to="/login" className="mx-3 fw-medium">
                            Login
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Logout
