import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, FormGroup, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import * as yup from "yup"
import jwt from "jsonwebtoken"

// import { chainOptions } from "../../common/chain" // Uncomment when we want to provide support on all chains
import { allFormsValid, updateModules, isValidAddress } from "../../utils"
import Select from "../CustomSelect"
import SpinnerWrap from "../SpinnerWrap"
import Tooltip from "../Tooltip"
import "./SurveyCard.scss"

const apiUrl = "https://projects.idexo.io/" // for saving data (not deploying contract)

// delete and use chainOptions from common when we want to provide support on all chains
const limitedChainOptions = [
    {
        value: "bnbchain", // this is what's displayed in dropdown item when selected, the value kept in react state, & sent to backend
        name: "BNBChain" // this is what's displayed in dropdown item (when not selected)
    },
    {
        value: "fantom",
        name: "Fantom"
    }
]

const CONTRACT_TYPES = [
    {
        value: "Standard",
        name: "Standard"
    }
]

const SurveyCard = ({ modules, module, project, paid, setDisableButtonsAndForms, fetchSurveyData, updateProgressTableStatus }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const status = module?.status?.S || "" // contract deployment status ex. "pending", "done"
    const [buttonSpinner, setButtonSpinner] = useState("") // string- "save" or "create" button
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [showTooltip, setShowTooltip] = useState(false) // for create button if contract already deployed

    // Form State
    const [surveyName, setSurveyName] = useState(module.survey_name?.S || "")
    const [description, setDescription] = useState(module.description?.S || "")
    const [chain, setChain] = useState(module.chain?.S || "")
    const [rewardAmount, setRewardAmount] = useState(module.reward_amount?.S || "") // number- uint256- only positive integers (1, 2, ..) no decimals
    const [rewardToken, setRewardToken] = useState(module.reward_token?.S || "") // string- address
    const [requiredNFTs, setRequiredNFTs] = useState(module.required_nfts?.S || "") // string- comma separated addresses. ex. "0xE, 0x123" (backend will be expecting an array of addresses)
    const [contractType, setContractType] = useState(module.contract_type?.S || "Standard") // only possible type for now is standard - later will add ability to choose from different types of staking contracts

    const handleClickSave = async (_e) => {
        try {
            setButtonSpinner("save")
            setAlertMessage("")
            setDisableButtonsAndForms(true) // disables buttons and forms on parent & sibling components (i.e. questionsCard)

            let newModule = {
                M: {
                    type: { S: module.type.S }, // "nft surveys"
                    inserted: { N: String(module.inserted.N) },
                    module_name: { S: module.module_name.S },
                    survey_name: { S: surveyName },
                    description: { S: description },
                    chain: { S: String(chain).toLowerCase() }, // ex. "bnbchain"
                    reward_amount: { S: String(rewardAmount) },
                    reward_token: { S: String(rewardToken) },
                    required_nfts: { S: String(requiredNFTs) }, // string- "0xE, 0x1, ..."
                    contract_type: { S: String(contractType) }, // ex. "Standard"
                    status: { S: "draft" } // "draft" -> "pending" -> "done"
                }
            }

            if (module?.address?.S) newModule.M.address = { S: module.address.S }
            if (module?.questions?.SS) newModule.M.questions = { SS: module.questions.SS }

            let oldModules = modules.slice() // shallow copy
            let newModules = updateModules(oldModules, newModule)
            newModules = newModules.filter((mod) => !mod?.objKey) // remove smart contracts from modules (since we keep them separate from dynamo)

            let finalUri = apiUrl

            // save or update project item in dynamodb user_projects table

            const res = await fetch(finalUri, {
                method: "POST",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" },
                body: JSON.stringify({
                    action: "createNewModule", // also updates modules
                    project_name: project.project_name.S,
                    inserted: String(project.inserted.N),
                    modules: newModules // updated modules to replace in backend
                })
            })
            const response = await res.json()

            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setButtonSpinner("")
                setAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                setDisableButtonsAndForms(false)
                return setAlertColor("danger")
            }

            setAlertMessage("successfully saved nft survey data!")
            setAlertColor("success")
            fetchSurveyData() // forces parent, and parents parent to render/refresh stale prop data (module, modules)
        } catch (err) {
            console.log(err)
        }
        setButtonSpinner("")
        setDisableButtonsAndForms(false)
    }

    const handleClickDeploy = async () => {
        // show alert/prevent user from deploying if:
        // - user hasn't saved any data (ex. staking name, chain, rewardTokwn, ... is empty), OR
        // - user changed the form data and did not save first (i.e. there is a change in the form data)
        if (!module.survey_name?.S || !noFormChange()) {
            setAlertMessage("You must save your survey data first")
            setAlertColor("danger")
            return
        }

        try {
            setButtonSpinner("create")
            setAlertMessage("")
            setDisableButtonsAndForms(true) // disables buttons and forms on parent & sibling components (i.e. questionsCard)

            // support for only fantom/bnbpremium for now
            let premiumChain = chain.toLowerCase() + "premium"
            if (chain.toLowerCase() === "bnbchain") premiumChain = "bnbpremium"

            // create array of addresses & remove empty spaces. [ "0x1", "0x2", ... ]
            const requiredNFTsArray = requiredNFTs.split(",").map((addr) => addr.replace(/\s/g, ""))

            let api_key = decoded.api_key

            // make sure data getting sent is in correct format
            const res = await fetch(`https://${premiumChain}.idexo.io/`, {
                method: "POST",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "x-api-key": api_key,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    transactionType: "createSimpleSurvey",
                    title: surveyName,
                    description: description,
                    rewardAmount: rewardAmount,
                    rewardToken: rewardToken,
                    requiredNfts: requiredNFTsArray,
                    options: {
                        project_name: project.project_name.S,
                        project_inserted: project.inserted.N,
                        module_inserted: module.inserted.N
                        // modules: newModules // TO DO: add updated modules to replace in backend
                    }
                })
            })
            const response = await res.json()

            if (response.error || response.code !== 200 || response?.data?.error) {
                setButtonSpinner("")
                setAlertMessage("Error: " + (response.error || response?.data?.error || response.message))
                setDisableButtonsAndForms(false)
                return setAlertColor("danger")
            }

            setAlertMessage("success! contract is pending...")
            setAlertColor("success")
            fetchSurveyData() // forces parent to render/refresh stale prop data (module, modules)
        } catch (err) {
            console.log(err)
            processErrors(err)
        }
        setButtonSpinner("")
        setDisableButtonsAndForms(false)
    }

    const processErrors = (err) => {
        // NOTE* user will have credit_balance deducted by 1 even if there's failure to deploy
        // - admin will have to manually update credit_balance
        let errorMessage = String(err)
        if (err?.response?.data?.error) {
            errorMessage = "Not enough credits for this method!" // err.response.data.code === 500

            // TO DO: redirect to method credits page i.e. /billing with state passed to set it to method credits tab
        } else if (err?.response?.data?.message === "Forbidden") {
            errorMessage = "Invalid API Key"
        }
        console.log(errorMessage)
        setAlertMessage(errorMessage)
        setAlertColor("danger")
    }

    // returns boolean, true if on editing form, the input form field data is the same as the old form data
    const noFormChange = () => {
        return (
            surveyName === module?.survey_name?.S &&
            description === module?.description?.S &&
            chain === module?.chain?.S &&
            rewardAmount === module?.reward_amount?.S &&
            rewardToken === module?.reward_token?.S &&
            requiredNFTs === module?.required_nfts?.S &&
            contractType === module?.contract_type?.S
        )
    }

    const validateRequiredNFTsField = (value) => {
        // value = string of adddresses separated by commas "0x1, 0x2, ... "
        if (!value) return false
        try {
            const addresses = value.split(",").map((addr) => addr.replace(/\s/g, "")) // create array of addresses & remove empty spaces. [ "0x1", "0x2", ... ]
            return addresses.every((address) => isValidAddress(address))
        } catch (err) {
            console.log(err)
            return false
        }
    }

    return (
        <Card className="nft-survey-card">
            <CardBody>
                <AvForm
                    className="nft-form"
                    initialValues={{
                        // NOTE! keys have to match value at "name" attribute in AvField
                        "survey-name": surveyName,
                        description: description,
                        "chain-select": chain,
                        "reward-amount": rewardAmount,
                        "reward-token": rewardToken,
                        "required-nfts": requiredNFTs,
                        "contract-type-select": contractType
                    }}
                    onSubmit={handleClickDeploy}
                    enableReinitialize // ? How is this working without the enableReinitialize property?!
                    validationSchema={yup.object().shape({
                        "survey-name": yup.string().required("This field is required."),
                        description: yup.string().required("This field is required."),
                        "chain-select": yup.string().required("This field is required."),
                        "reward-amount": yup
                            .string()
                            .required("This field is required.")
                            // if callback in .test returns false, show error message
                            .test(
                                "is positive integer?",
                                "Number must be a positive integer!", // no decimals
                                (val) => Number.isInteger(Number(val)) && Number(val) > 0 && !val.includes(".")
                            ),
                        "reward-token": yup
                            .string()
                            .required("This field is required.")
                            .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str)),
                        "required-nfts": yup
                            .string()
                            .required("This field is required.")
                            .test("test addresses", "You must enter valid addresses!", (str) => validateRequiredNFTsField(str)),
                        "contract-type-select": yup.string().required("This field is required.")
                    })}
                >
                    <CardTitle className="h3">New NFT Survey</CardTitle>
                    <FormGroup className="mb-3">
                        <AvField
                            name="survey-name"
                            label="Title"
                            placeholder="Enter Survey Name"
                            type="text"
                            className="form-control"
                            id="survey-name"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            onChange={(e) => setSurveyName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="description"
                            label="Description"
                            placeholder="Enter Description"
                            type="text"
                            className="form-control"
                            id="description"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Select
                            name="chain-select"
                            label="Chain"
                            placeholder="Select Blockchain"
                            className={chain === "" ? "noselection" : ""}
                            id="chain-select"
                            // options={chainOptions}
                            options={limitedChainOptions}
                            selectedOption={chain}
                            errorMessage="Required!"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            handleChange={(value) => setChain(value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="reward-amount"
                            label="Reward Amount"
                            placeholder="Enter Number"
                            type="number"
                            min="0"
                            className="form-control"
                            id="reward-amount"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            onChange={(e) => setRewardAmount(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="reward-token"
                            label="Reward Token"
                            placeholder="Enter Reward Token Address"
                            type="text"
                            className="form-control"
                            id="reward-token"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            onChange={(e) => setRewardToken(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <AvField
                            name="required-nfts"
                            label="Required NFTs"
                            placeholder="Enter NFT addresses separated by commas"
                            type="text"
                            className="form-control"
                            id="required-nfts"
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            onChange={(e) => setRequiredNFTs(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="mb-4">
                        <Select
                            name="contract-type-select"
                            label="Choose Contract Type"
                            placeholder="Select Contract Type"
                            className={chain === "" ? "noselection" : ""}
                            id="contract-type-select"
                            options={CONTRACT_TYPES}
                            selectedOption={contractType}
                            required
                            disabled={!!buttonSpinner || status === "pending" || status === "done"}
                            handleChange={(value) => setContractType(value)}
                        />
                    </FormGroup>

                    <div className="deploy-alert">
                        <Alert color={alertColor} toggle={() => setAlertMessage("")} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Button
                            color="primary"
                            outline
                            type="button"
                            disabled={
                                !!buttonSpinner ||
                                !allFormsValid([surveyName, description, chain, rewardAmount, rewardToken, requiredNFTs, contractType]) ||
                                noFormChange() ||
                                !validateRequiredNFTsField(requiredNFTs)
                            }
                            onClick={handleClickSave}
                        >
                            <SpinnerWrap showSpinner={buttonSpinner === "save"} txtTrue={"Saving"} txtFalse={"Save"} />
                        </Button>
                        <Tooltip
                            title={`Contract is ${status === "done" ? "deployed" : "pending"}, cannot redeploy`}
                            placement="top"
                            disableFocusListener
                            open={(showTooltip && status === "pending") || (showTooltip && status === "done")}
                        >
                            {/* need this span for showing tooltip */}
                            <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        !!buttonSpinner ||
                                        !allFormsValid([surveyName, description, chain, rewardAmount, rewardToken, requiredNFTs, contractType]) ||
                                        paid === 0 || // not a paid account
                                        status === "pending" ||
                                        status === "done"
                                    }
                                >
                                    <SpinnerWrap showSpinner={buttonSpinner === "create"} txtTrue={"CREATING"} txtFalse={"CREATE"} />
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </AvForm>
            </CardBody>
        </Card>
    )
}

export default SurveyCard
