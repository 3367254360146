import { FortmaticConnector } from "@web3-react/fortmatic-connector"
import { ConnectorConfigError } from "../error"

export default async function init() {
    return {
        web3ReactConnector({ chainId, apiKey }) {
            if (!apiKey) {
                throw new ConnectorConfigError("The Fortmatic connector requires apiKey to be set.")
            }
            return new FortmaticConnector({ apiKey, chainId })
        }
    }
}
