import { ConnectionRejectedError } from "../error"

export default async function init() {
    const { FrameConnector, UserRejectedRequestError } = await import("@web3-react/frame-connector")
    return {
        web3ReactConnector({ chainId }) {
            return new FrameConnector({ supportedChainIds: [chainId] })
        },
        handleActivationError(err) {
            console.log("ConnectorFrame error:", err)
            if (err instanceof UserRejectedRequestError) {
                return new ConnectionRejectedError()
            }
            if (err.message.startsWith("JSON.parse")) {
                return new Error("There seems to be an issue when trying to connect to Frame.")
            }
            return null
        }
    }
}
