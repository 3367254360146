import React, { useState, useEffect } from "react"
import { Col, Row, Button, Card, CardBody, CardTitle, CardText } from "reactstrap"
import OkDialog from "../Components/OkDialog/OkDialog"
import BreadCrumb from "../Components/BreadCrumb"
import SurveyCard from "../Components/SurveyCard/SurveyCard"
import WaitingModal from "../Components/WaitingModal/WaitingModal"
import AccordionTable from "../Components/AccordionTable/AccordionTable"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import QuestionsPage from "../Components/QuestionsPage/QuestionsPage"

const apiUrl = "https://projects.idexo.io/" // backend

const Survey = (props) => {
    const { project, modules, clickCancel, clickProjects, fetchModules } = props
    const [module, setModule] = useState(props.module || {})
    const status = module?.status?.S || "" // "draft" -> "pending" -> "done"

    // Progress Table Card Component
    const [showDetailsButtons, setShowDetailsButtons] = useState({}) // keys = button indexes, values = booleans, where true = show button, false = don't show button
    const [showProgressLoading, setShowProgressLoading] = useState(false) // spinner animation of progress table
    const [progressAlertMessage, setProgressAlertMessage] = useState("") // if error fetching campaign data

    // MISC
    // const [paid, setPaid] = useState(decoded?.paid || 0) // 0 = not paid account
    const [paid, setPaid] = useState(2) // 2 = paid. FOR TESTING
    const [showSpinner, setShowSpinner] = useState(false) // loading animation on buttons
    const [buttonId, setButtonId] = useState(null) // number refers to which button was clicked (for displaying spinner animation)
    const [alertMessage, setAlertMessage] = useState("")
    const [disableButtonsAndForms, setDisableButtonsAndForms] = useState(false) // disables buttons and forms on a card component while action is pending (ex. saving data)
    const [showQuestionsPage, setShowQuestionsPage] = useState(false)

    const updateProgressTableStatus = async (inserted, step, tx_hash, tx_id) => {
        // inserted === time item (ex. twitter, telegram, nftcollection) in nftmarketing dynamo table was inserted
        try {
            return await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "updateProgressStatus",
                    inserted: inserted, // inserted time of nftmarketing table item
                    step: step, // "pending", or "launched"
                    tx_hash,
                    tx_id
                    // Might not need below if we're not updating projects table:
                    // project_inserted: projectInserted, // refers to primary key in user_projects table
                    // module_inserted: moduleInserted // for identifying a module in a project
                })
            })
        } catch (err) {
            console.log(err)
        }
    }

    // checks if nft survey contract has been deployed, and if so updates table status
    // const checkTransactionStatus = async (stakingItem) => {
    //     try {
    //         const res = await fetch(apiUrl, {
    //             method: "POST",
    //             headers: { token: localStorage.jwtToken },
    //             body: JSON.stringify({
    //                 action: "checkTransactionStatus",
    //                 tx_id: stakingItem.tx_id.S,
    //                 inserted: stakingItem.inserted.N
    //             })
    //         })
    //         if (res.error) console.log(res.error)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    // cb runs once after initial mount, and then everytime modules dependecy changes
    useEffect(() => {
        let newModule = modules.filter((mod) => {
            // filter out smart contracts, & look for module that matches inserted
            return !mod?.objKey && mod?.M?.inserted?.N === module?.inserted?.N
        })[0] // there should be exactly 1 in this array
        setModule(newModule?.M) // update module
        // checkTransactionStatus()
        setPaid(2)
        setShowSpinner(false)
        setButtonId(null)
        setShowProgressLoading(false)
        setProgressAlertMessage("")
    }, [modules, module])

    return (
        <React.Fragment>
            {showQuestionsPage ? (
                <QuestionsPage
                    project={project}
                    modules={modules}
                    module={module}
                    clickProjects={clickProjects}
                    clickProject={clickCancel}
                    clickCancel={() => setShowQuestionsPage(false)}
                    fetchSurveyData={fetchModules}
                />
            ) : (
                <div className={"nft-survey"}>
                    {/* breadcrumb will look like: Home > Projects > project name > NFT Survey */}
                    <BreadCrumb
                        items={["projects", `${project?.project_name?.S}`, "NFT Survey"]}
                        links={["projects", "projects"]}
                        linkFunctions={[clickProjects, clickCancel]}
                    />
                    <div className="back-to-projects">
                        <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn" disabled={disableButtonsAndForms}>
                            Back to project
                        </Button>
                    </div>

                    <Row>
                        <OkDialog open={!!alertMessage} title={""} message={alertMessage} okClick={() => setAlertMessage("")} />
                        <Col xs={12} sm={12} md={6}>
                            <Row>
                                <Col>
                                    <SurveyCard
                                        project={project}
                                        modules={modules}
                                        module={module}
                                        // helper functions
                                        setDisableButtonsAndForms={setDisableButtonsAndForms}
                                        updateProgressTableStatus={updateProgressTableStatus}
                                        paid={paid}
                                        fetchSurveyData={fetchModules}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} sm={12} md={6}>
                            <Card className="progress-card">
                                <CardBody>
                                    <CardTitle className="h3">Progress</CardTitle>
                                    <CardText className="P font-size-12 text-uppercase">Your saved history</CardText>
                                    <div className="overflow-auto">
                                        <AccordionTable
                                            tableHeaders={["TYPE", "STATUS", "ACTION"]}
                                            headerClassNames={["survey-type", "survey-status", "survey-action"]}
                                            // if module has New NFT Survey data show it, otherwise show blank table
                                            tableRows={module?.chain?.S ? [module] : []}
                                            tableRowClassNames={[]}
                                            tableRowLiClassName={"survey-page "}
                                            addBtnClassName={status === "done" || status === "pending" ? "view-questions" : "add-questions"}
                                            showDetailsButtons={showDetailsButtons}
                                            setShowDetailsButtons={setShowDetailsButtons}
                                            toggleShowDetailButton={() => {}}
                                            handleClickAdd={() => setShowQuestionsPage(true)}
                                            renderViewDetail={() => {}}
                                            showProgressSpinner={showProgressLoading}
                                            addButtonTxt={status === "done" || status === "pending" ? "VIEW QUESTIONS" : "ADD QUESTIONS"}
                                            disableAllButtons={showSpinner || disableButtonsAndForms}
                                        />
                                        {showProgressLoading && !progressAlertMessage && <LoadingCard loadingTxt={"NFT Survey Data"} />}
                                        {progressAlertMessage && <LoadingCard errorTxt={"NFT Survey Data"} alertMessage={progressAlertMessage} />}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* triggers after user clicks "Create" or similar action */}
                    <WaitingModal isOpen={showSpinner && buttonId === 2} modalType={"none"} text={"Contract is deploying. Kindly wait..."} />
                </div>
            )}
        </React.Fragment>
    )
}

export default Survey
