import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { Button, Spinner } from "reactstrap"
import CameraIcon from "../assets/icons/camera.png"
import ImageCropModal from "./ImageCropModal"
import { toBase64, isValidFileType } from "../utils"
import OkDialog from "./OkDialog/OkDialog"

const ArtImg = ({ img, removeImg, editImg, onlyOneImage }) => {
    return (
        <div className={"art-img-view " + (onlyOneImage ? "only-one-image" : "")}>
            <div className="img-container" key={img.name}>
                <img
                    className={"selected-image " + (onlyOneImage ? "only-one-image" : "")}
                    alt={img.name}
                    src={URL.createObjectURL(img.base64) || img.preview}
                />
            </div>
            <div className="action-wrapper">
                <Button className="action-btn me-2" onClick={removeImg}>
                    <i className="bx bx-trash font-size-20" />{" "}
                </Button>
                <Button className="action-btn" onClick={editImg}>
                    <i className="bx bx-edit font-size-20" />{" "}
                </Button>
            </div>
        </div>
    )
}

const ImageUpload = ({ files, handleChange, disabled, className = "", onlyOneImage = false, imgURL, showNFTImageLoading }) => {
    // onlyOneImage = user can only upload 1 image, and UI is different
    const [imgFile, setImgFile] = useState(null) // FileObject
    const [acceptFiles, setAcceptFiles] = useState([])
    const [fileType, setFileType] = useState(null) // string, ex. "image/jpeg"

    const handleAcceptedFiles = async (files) => {
        const base64Files = await Promise.all(
            files.map((file) => {
                return toBase64(file)
            })
        )
        setImgFile(base64Files.shift())
        setFileType(files[0].type)
        setAcceptFiles(base64Files)
    }

    const onFinishCrop = (file) => {
        const index = files.findIndex((f) => f.preview === file.preview)

        if (index > -1) {
            files[index] = file
        } else {
            files.push(file)
        }
        handleChange([...files])
        setImgFile(acceptFiles.shift())
        setAcceptFiles([...acceptFiles])
    }

    const handleRemove = (image) => {
        const updatedArtList = files.filter((img) => img.preview !== image.preview)
        handleChange(updatedArtList)
    }

    return (
        <div className={"image-upload-container " + className}>
            {/* Show Dropzone if 1) onlyOneImage === false OR 2) onlyOneImage && image selected */}
            {(onlyOneImage === false || (onlyOneImage && files.length === 0)) && (
                <div className={disabled ? "image-upload disabled" : "image-upload"}>
                    <Dropzone onDrop={(dropFiles) => handleAcceptedFiles(dropFiles)} disabled={disabled}>
                        {({ getRootProps, getInputProps }) => (
                            <div className="d-flex flex-column align-items-center h-100">
                                <div className="list-image-select needsclick" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="list-image-select needsclick">
                                        {showNFTImageLoading ? (
                                            <Spinner size="lg" color="primary" className="nft-collection-spinner" />
                                        ) : (
                                            <img src={imgURL ? imgURL : CameraIcon} alt="Camera" className={imgURL ? "nft-collection-img" : ""} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )}
            {onlyOneImage === false && files.length > 0 && <div className="spacer"></div>}
            <div className={"img-gallery" + (onlyOneImage && files.length > 0 ? " active" : "")}>
                {files.map((img) => (
                    <ArtImg
                        key={img.name}
                        img={img}
                        removeImg={() => handleRemove(img)}
                        editImg={() => setImgFile(img)}
                        onlyOneImage={onlyOneImage}
                    />
                ))}
            </div>
            {imgFile && (
                <ImageCropModal
                    isOpen={!!imgFile && isValidFileType(fileType)}
                    onClose={() => setImgFile(null)}
                    imageFile={imgFile?.preview}
                    sourceImage={imgFile}
                    onEditPhoto={onFinishCrop}
                    onCancel={() => setImgFile(null)}
                    onlyOneImage={onlyOneImage}
                />
            )}
            <OkDialog
                open={!isValidFileType(fileType) && fileType !== null}
                message={"You must select a jpeg or png file!"}
                okClick={() => {
                    setImgFile(null)
                    setFileType(null)
                }}
            />
        </div>
    )
}

export default ImageUpload
