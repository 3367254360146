import React, { useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Label } from "reactstrap"
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import jwt from "jsonwebtoken"

import { isValidFileType } from "../../utils"
import WaitingModal from "../WaitingModal/WaitingModal"
import "./bulkUploadModal.scss"

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const apiUrl = "https://nftmarketing.idexo.io/"

// converts all json values (non-object and non-null values) to strings (so we don't error out in S3)
const convertValuesToStrings = (oldMetadata) => {
    let newMetadata = {}
    for (let key in oldMetadata) {
        if (key !== "attributes") {
            newMetadata[key] = String(oldMetadata[key])
        } else {
            // attributes value is JSON, so we use different method to convert to string
            newMetadata.attributes = JSON.stringify(oldMetadata[key])
        }
    }
    return newMetadata
}

const BulkUploadModal = ({ showBulkUploadModal, setShowBulkUploadModal, module, campaignData, NFTs, fetchNFTs }) => {
    const decoded = jwt.decode(localStorage.jwtToken)
    const [showConfirmLoading, setShowConfirmLoading] = useState(false) // uploading to s3 still in progress
    // eslint-disable-next-line
    const [disableAllButtons, setDisableAllButtons] = useState(false)
    const [modalAlertMessage, setModalAlertMessage] = useState("")
    // eslint-disable-next-line
    const [alertColor, setAlertColor] = useState("")

    const [mediaFiles, setMediaFiles] = useState([])
    const [metadataFiles, setMetadataFiles] = useState([])

    const [nftsObject, setNftsObject] = useState({}) // keys = file number, values = object that has s3 imgKey (for faster access than looping through NFTs array when updating json metadata)
    // nftsObject == { 1:{content_type, file_name, imgKey} }

    // 15,728,640 bytes == 15 MB (arweave), 50MB (filecoin)
    const maxFileSize = campaignData?.metadata_storage?.S?.toLowerCase() === "arweave" ? 15728640 : 52428800
    const noCollectionCap = campaignData?.cap?.S === "-1"
    const maxNumFiles = noCollectionCap ? 200 : Number(campaignData?.cap?.S) // max number of files a user can bulk upload

    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        // valid s3Response === {type:'cors', url: 'https://nftmarketing-738087076124.s3.us-east-1.ama…15af9d2e9&X-Amz-SignedHeaders=host&x-id=PutObject', redirected: false, status: 200, ok: true, …}
        return s3Response
    }

    // This callback runs everytime for every media file dropped (if multiple files are dropped, then this seems to run in order of dropped or async?)
    const processMediaFile = async (fileName, file, metadata, load, error, progress, abort, transfer, options) => {
        const controller = new AbortController()
        try {
            // dont let user submit files larger than 16MB (arweave), 50MB (filecoin)
            if (file.size >= maxFileSize) {
                setShowConfirmLoading(false)
                return error("Max File Size 15MB Exceeded")
            }

            // dont upload file if collection cap exceeded
            if (NFTs.length + mediaFiles.length > Number(campaignData?.cap?.S) && !noCollectionCap) {
                setShowConfirmLoading(false)
                return error("Collection Cap Exceeded!")
            }

            // TO DO:
            // if image has already been uploaded we are overwriting image, so we need to grab the old insertion time in order to overwrite image/metadata
            let inserted = new Date().getTime() // milliseconds since 1 January 1970 UTC

            const Key = `${decoded.user_id}/module-${module.inserted.N}/nftcollection-${campaignData.inserted.N}/nft-${inserted}`

            const s3Params = {
                Key: Key,
                Metadata: {
                    file_name: file.name,
                    content_type: file.type // "image/jpg"
                }
            }

            // Get S3 presigned-url
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "saveUserNFT", // string- ex. "saveUserNFT" (updates image + metadata) or "updateNFT" (update metadata only)
                    s3Params: s3Params // sent to server to get upload url
                })
            })
            const response = await res.json()
            // console.log("response", response) //=> { error:null, data:null, code:200, url:{message,error,code,data:""}

            if (response.code !== 200 || response.error) {
                setShowConfirmLoading(false)
                return error(response.error) // exit function and show error in UI
            }

            const uploadUrl = JSON.parse(response.url.data)?.url

            const s3UploadResponse = await uploadImageToS3(uploadUrl, file)

            if (!s3UploadResponse.ok) {
                setShowConfirmLoading(false)
                return error(s3UploadResponse.statusText) // error out if failure to upload to S3
            }

            // progress(bool, int, int)
            // - 1st param == false => infinite loading
            // - 2nd param == amount of data loaded/sent to server
            // - 3rd param == ? total file size?
            progress(true, file.size, file.size) // stop loading progress animation
            load("yay!") // callback runs to show upload is complete/UI green
        } catch (err) {
            console.log(err)
            error(err) // filepond callback to show error in UI
            setShowConfirmLoading(false) // turn processing files waiting modal spinner animation off
        }

        // so user can cancel uploading a file
        return {
            // callback runs if user clicks cancel upload button on image preview
            abort: function () {
                controller.abort()
                abort()
            }
        }
    }

    // runs everytime there's an error from server upload process
    const deterineErrorMessage = (err) => {
        if (err.message === "Cannot read properties of undefined (reading 'imgKey')") {
            return "No corresponding media file uploaded" // Ex. user must first upload 1.jpg before uploading 1.json
        } else if (err.body === "Max File Size 15MB Exceeded") {
            return "Max File Size 15MB Exceeded"
        } else if (err.body === "Collection Cap Exceeded!") {
            return "Collection Cap Exceeded!"
        }
        return "Error during upload" // default error message
    }

    // runs once after all files in list have been processed/uploaded
    const handleFinishUpload = async () => {
        await fetchNFTs() // rerender nftcollection details page to show new uploaded nfts
        setShowConfirmLoading(false)
    }

    // runs once after dropping all files into dropzone (or updating/removing)
    const handleDropAllFiles = (files, fileTypes) => {
        setShowConfirmLoading(true)
        if (fileTypes === "media") {
            setMediaFiles(files)
        } else {
            setMetadataFiles(files)
        }
    }

    const getJSONfromFile = async (file) => {
        // Always return a Promise
        return new Promise((resolve, reject) => {
            let content = ""
            const reader = new FileReader()
            // Wait till complete
            reader.onloadend = function (e) {
                content = e.target.result // stringified json
                resolve(JSON.parse(content)) // return JSON
            }
            // Make sure to handle error states
            reader.onerror = function (e) {
                reject(e)
            }
            reader.readAsText(file)
        })
    }

    // runs everytime for each json file dropped
    const processJSONfile = async (fileName, file, metadata, load, error, progress, abort, transfer, options) => {
        const controller = new AbortController()

        try {
            // dont let user submit files larger than 16MB (arweave), 50MB (filecoin)
            if (file.size >= maxFileSize) {
                setShowConfirmLoading(false)
                return error("Max File Size 15MB Exceeded")
            }

            // TO DO: exit if json file does not have valid schema/format
            let jsonMetadata = await getJSONfromFile(file)
            jsonMetadata = convertValuesToStrings(jsonMetadata)

            const fileNumber = file.name.split(".")[0] // ex. "3"
            // grab s3 imgKey in NFTs array that matches file_name
            const s3Params = {
                Key: nftsObject[fileNumber].imgKey,
                Metadata: {
                    // NOTE! all metadata values must be string datatype or else we'll get 403 error
                    file_name: nftsObject[fileNumber].file_name, // "2.jpg"
                    content_type: nftsObject[fileNumber].content_type, // "image/jpg"
                    randomness_weight: "1", // assumes each nft is unique
                    ...jsonMetadata // add JSON data (name, description, attributes) to s3Params metadata
                }
            }
            // console.log("s3Params", s3Params)

            // update nft image s3 item metadata
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "updateNFT",
                    Metadata: s3Params.Metadata,
                    Key: s3Params.Key,
                    s3Params: s3Params
                })
            })
            const response = await res.json()
            // console.log("response", response) //=> { error:null, data:null, code:200, url:{message,error,code,data:""}

            if (response.code !== 200 || response.error) {
                setShowConfirmLoading(false)
                return error(response.error) // exit function and show error in UI
            }

            // progress(bool, int, int)
            // - 1st param == false => infinite loading
            // - 2nd param == amount of data loaded/sent to server
            // - 3rd param == ? total file size?
            progress(true, file.size, file.size) // stop loading progress animation
            load("yay!") // callback runs to show upload is complete/UI green
        } catch (err) {
            console.log(err)
            error(err) // filepond callback to show error in UI
            setShowConfirmLoading(false)
        }

        // so user can cancel uploading a file
        return {
            // callback runs if user clicks cancel upload button on file preview
            abort: function () {
                controller.abort()
                abort()
            }
        }
    }

    useEffect(() => {
        let result = {}
        try {
            for (let i = 0; i < NFTs.length; i++) {
                let nft = NFTs[i]
                let imgKey = nft.imgKey
                let file_name = nft.imgMetaData.file_name
                let content_type = nft.imgMetaData.content_type
                let fileNumber = file_name.split(".")[0]
                result[fileNumber] = { imgKey, file_name, content_type }
            }
            setNftsObject(result)
        } catch (err) {
            console.log(err)
        }
    }, [NFTs])

    return (
        <React.Fragment>
            <Modal
                isOpen={showBulkUploadModal}
                centered={true}
                // toggle={() => !disableAllButtons && !showConfirmLoading && closeAndResetModal()}
                toggle={() => setShowBulkUploadModal(false)}
                className="bulk-upload-modal"
            >
                <ModalHeader
                    toggle={
                        showConfirmLoading || disableAllButtons
                            ? null
                            : () => !disableAllButtons && !showConfirmLoading && setShowBulkUploadModal(false)
                    }
                >
                    Bulk Upload NFTs
                </ModalHeader>
                <ModalBody>
                    <div className="select-media-section">
                        <div className="select-media-instructions">
                            <Label className="form-label select-media">
                                {/* jpg, png, gif, mov, mp4 */}
                                Select Media
                            </Label>
                            <div className="media-file-types">Supported Media File Types: jpg, png, gif, mov, mp4</div>
                        </div>

                        <FilePond
                            files={mediaFiles}
                            onupdatefiles={(files) => handleDropAllFiles(files, "media")}
                            allowMultiple={true}
                            allowRemove={false}
                            allowRevert={false}
                            maxFiles={maxNumFiles}
                            // server={apiUrl}
                            server={{ process: processMediaFile }}
                            onprocessfiles={handleFinishUpload}
                            name="media-files"
                            labelIdle="Drag & Drop your <span class='media-files'>Media</span> Files"
                            // acceptedFileTypes={["image/jpg"]} // not working
                            labelFileProcessingError={deterineErrorMessage}
                            dropValidation={true}
                            beforeDropFile={(file) => isValidFileType(file.type)}
                            className="media-dropzone"
                        />
                    </div>

                    <div className="select-metadata-section">
                        <div className="select-metadata-intructions">
                            <Label className="form-label select-media">Select Metadata</Label>
                            <div className="media-file-types">Supported Metadata File Type: JSON</div>
                        </div>

                        <FilePond
                            files={metadataFiles}
                            onupdatefiles={(files) => handleDropAllFiles(files, "json")}
                            allowMultiple={true}
                            allowRemove={false}
                            allowRevert={false}
                            maxFiles={maxNumFiles}
                            server={{ process: processJSONfile }}
                            onprocessfiles={handleFinishUpload}
                            name="metadata-files"
                            labelIdle="Drag & Drop your NFT <span class='metadata-files'>Metadata</span> files"
                            // replaces default error message on file
                            labelFileProcessingError={deterineErrorMessage}
                            dropValidation={true}
                            beforeDropFile={(file) => file.type === "application/json"}
                            className="metadata-dropzone"
                        />
                    </div>
                </ModalBody>

                <ModalFooter className="submit-cancel">
                    <Alert color={alertColor} toggle={disableAllButtons ? null : () => setModalAlertMessage("")} isOpen={!!modalAlertMessage}>
                        {modalAlertMessage}
                    </Alert>
                    <span></span>
                    {/* SUBMIT button removed since uploading is automatic once files are dropped */}
                    {/* <Button color="primary" type="submit" disabled={showConfirmLoading || disableAllButtons}>
                    <SpinnerWrap
                        showSpinner={showConfirmLoading}
                        txtTrue={"SUBMITTING"}
                        txtFalse={"SUBMIT"}
                        disabled={showConfirmLoading || disableAllButtons}
                    />
                </Button> */}
                    <Button
                        color="secondary"
                        outline
                        onClick={() => setShowBulkUploadModal(false)}
                        className="btn-light waves-effect cancel-btn"
                        disabled={showConfirmLoading || disableAllButtons}
                    >
                        CLOSE
                    </Button>
                </ModalFooter>
            </Modal>

            <WaitingModal isOpen={showConfirmLoading} modalType={"spinner"} text={"Processing Files..."} />
        </React.Fragment>
    )
}

export default BulkUploadModal
