import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Row, Input, Label, Alert } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import * as yup from "yup"
import ido from "idexo-sdk"
import jwt from "jsonwebtoken"

import { allFormsValid } from "../../utils"
import { chainOptions } from "../../common/chain"
import SpinnerWrap from "../SpinnerWrap"
import Select from "../CustomSelect"
import Tooltip from "../Tooltip"
import "./ERC20Card.scss"

const apiUrl = "https://erc20tokens.idexo.io/"

// declaring constants for module
// - easier to change all instances, just change here
// - easier debugging/fail loudly if we mispell
const STANDARD = "standard"

// For dropdown select component- ERC20 TOKEN Module Contract Types
const CONTRACT_TYPES = [
    {
        value: STANDARD, // this is the value kept in react state/sent to backend
        name: "Standard" // this is what's displayed in dropdown item
    }
]

const ERC20Card = React.memo(
    ({
        project,
        module,
        ERC20, // original data from backend (used to compare changes in form input so user doesn't submit same data)
        fetchModuleData,
        checkPending
    }) => {
        // Form States
        const [tokenName, setTokenName] = useState(ERC20?.token_name?.S || "")
        const [symbol, setSymbol] = useState(ERC20?.symbol?.S || "")
        const [description, setDescription] = useState(ERC20?.description?.S || "")
        const [chain, setChain] = useState(ERC20?.chain?.S || "")
        const [cap, setCap] = useState(ERC20?.cap?.S || "") // max number of tokens to be created. "-1" === no cap
        const [contractType, setContractType] = useState(ERC20?.contract_type?.S || STANDARD) // "standard",

        const [status, setStatus] = useState(ERC20?.source?.S || "draft") // "draft" -> "pending" -> "done"
        const [alertMessage, setAlertMessage] = useState("") // if trying to deploy with no user nft collection data in backend (ex. collection name, cap, chain)
        const [alertColor, setAlertColor] = useState("")
        const [showSpinner, setShowSpinner] = useState(false) // loading animation on "Save" and "Deploy" buttons
        const [buttonId, setButtonId] = useState(null) // number refers to which button was clicked 1 = save, 2 = deploy
        const [showTooltip, setShowTooltip] = useState(false) // for deploy button

        // check if user is a tenant member
        let decoded = null
        if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
        else decoded = jwt.decode(localStorage.jwtToken)
        //

        // returns boolean, true if on editing form, the input form field data is the same as the old form data
        const noFormChange = () => {
            return (
                tokenName === ERC20?.token_name?.S &&
                symbol === ERC20?.symbol?.S &&
                chain === ERC20?.chain?.S &&
                description === ERC20?.description?.S &&
                cap === ERC20?.cap?.S &&
                contractType === ERC20?.contract_type?.S
            )
        }

        const handleClickNoCap = () => {
            if (String(cap) === "-1") {
                // reset cap to old cap (if any and old cap not -1). will be empty "" if cap not yet set
                setCap(ERC20?.cap?.S === "-1" ? "" : ERC20?.cap?.S)
            } else {
                setCap("-1") // -1 === no cap
            }
        }

        // Creating or Updating an item in erc20tokens table
        const handleClickSave = async (e) => {
            setShowSpinner(true)
            setAlertMessage("")
            setButtonId(1)

            try {
                const inserted = ERC20?.inserted?.N ? ERC20?.inserted?.N : new Date().getTime() // milliseconds since 1 January 1970 UTC- time erc20 token was inserted in erc20tokens dynamo table
                const projectInserted = project.inserted.N
                const moduleInserted = module.inserted.N

                let finalUri = apiUrl
                // if (localStorage.user_tenants && localStorage.selected_tenant) {
                //     const user_tenants = JSON.parse(localStorage.user_tenants)
                //     if (user_tenants.length > 0) finalUri = finalUri + `?tenant_id=${localStorage.selected_tenant}`
                // }

                const res = await fetch(finalUri, {
                    method: "POST",
                    headers: {
                        token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                        "x-api-key": decoded.api_key,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        action: "createDraftERC20",
                        token_name: tokenName,
                        symbol: symbol,
                        chain: chain,
                        description: description,
                        cap: cap,
                        contract_type: contractType, // "standard"
                        transaction_type: String(cap) !== "-1" ? "createTokenUncapped" : "createCapped",
                        inserted, // inserted time of erc20tokens table item
                        project_inserted: projectInserted, // refers to primary key in user_projects table
                        module_inserted: moduleInserted // for identifying a module in a project
                    })
                })

                const response = await res.json()
                // valid response === { code:200, message:'nftmarketing', error:null, data:{Attributes:{}, error}, url:{error, data:'{"url":"https://nftmarketing-738087076124.s3.us-ea…7d280a9&X-Amz-SignedHeaders=host&x-id=PutObject"}'}  }

                // problem saving/updating text data to dynamo
                if (response.error || response.data?.error || response?.url?.error) {
                    setAlertMessage(`Error: ${response.error || response.data?.error || response?.url?.error}`)
                    setAlertColor("danger")
                }
                setAlertMessage("Successfully saved data!")
                setAlertColor("success")
                fetchModuleData() // this will update state so table will re render with new data
            } catch (err) {
                console.log(err)
                setAlertMessage(`Error: ${err}`)
                setAlertColor("danger")
            }
            setShowSpinner(false)
        }

        const handleClickDeploy = async () => {
            setAlertMessage("") // reset alert message (if any)
            setShowSpinner(true)
            setButtonId(2) // so spinner knows which button to display animation

            // TO DO: enable deployment without saving data first (we will save and deploy in backend)
            // show alert/prevent user from deploying if:
            // - user hasn't saved any data (ex. token name, chain, cap), OR
            // - user changed the form data and did not save first (i.e. there is a change in the form data)
            if (Object.keys(ERC20).length === 0 || !noFormChange()) {
                setAlertMessage("You must save your ERC20 token data first")
                setAlertColor("danger")
                setShowSpinner(false)
                return
            }

            try {
                const capped = String(cap) !== "-1"

                let api_key = decoded.api_key

                let res

                const options = {
                    project_name: project.project_name.S,
                    project_inserted: ERC20.project_inserted.N,
                    module_inserted: ERC20.module_inserted.N, // insertion time of module (projects table)
                    erc20tokens_inserted: ERC20.inserted.N, // insertion time of item in erc20tokens table
                    transaction_type: ERC20.transaction_type.S, // ex. "createCollectionCapped"
                    description: ERC20.description.S
                }

                // use ido-sdk API to create token
                if (capped && contractType === STANDARD) {
                    res = await ido.Tokens.createTokenCapped(api_key, chain.toLowerCase(), tokenName, symbol, cap, options) // Valid- 200
                } else if (!capped && contractType === STANDARD) {
                    res = await ido.Tokens.createTokenUncapped(api_key, chain.toLowerCase(), tokenName, symbol, options) // Valid- 200
                }
                console.log("handleClickDeploy", res)

                if (!res || res.error || res.data?.error || res?.data?.data?.error) {
                    setAlertMessage(String(res?.error || res?.data?.error || res?.data?.data?.error))
                    setAlertColor("danger")
                } else {
                    const contractRes = res.data.data
                    const tx_hash = contractRes.tx_hash
                    const tx_id = contractRes.tx_id
                    const contract_owner = contractRes.sender
                    setAlertMessage(`Transaction succesfully sent. Contract Pending. tx_hash: ${tx_hash}`)
                    setAlertColor("success")

                    const resp = await updateProgressTableStatus(ERC20?.inserted?.N, tx_id, contract_owner)
                    console.log("updateProgressTableStatus", await resp?.json())

                    // change status so "Deploy" button becomes disabled
                    setStatus("pending")
                    await fetchModuleData() // this should force sibling progress table component to update/refresh
                    checkPending(tx_id)
                }
            } catch (err) {
                console.log(err)
                handleErrors(err)
            }
            setShowSpinner(false)
        }

        // updates progress table status (dynamo erc20tokens table item) from "draft" -> "pending", & adds tx_id, contract_owner attributes
        const updateProgressTableStatus = async (inserted, tx_id, contract_owner) => {
            // inserted === time item was inserted into erc20tokens table
            try {
                let finalUri = apiUrl

                return await fetch(finalUri, {
                    method: "POST",
                    headers: {
                        token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                        "x-api-key": decoded.api_key,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        action: "updateProgressStatus",
                        inserted: inserted, // inserted time of nftmarketing table item
                        step: "pending", // ex. "pending", or "done"
                        tx_id, // dynamo params to add to nft marketing item
                        contract_owner // sender
                    })
                })
            } catch (err) {
                console.log(err)
            }
        }

        const handleErrors = (err) => {
            let errorMessage = String(err)

            if (err?.response?.data?.error?.includes("Not enough credits")) {
                errorMessage = err.response.data.error // err.response.data.code === 500
            } else if (err?.response?.data?.error) {
                errorMessage = err.response.data.error
                // TO DO: redirect to method credits page i.e. /billing with state passed to set it to method credits tab
            } else if (err?.response?.data?.message === "Forbidden") {
                errorMessage = "Invalid API Key"
            }

            setAlertMessage(errorMessage)
            // NOTE* user will have credit_balance deducted by 1 even if there's failure to deploy
            // - admin will have to manually update credit_balance
            setAlertColor("danger")
        }

        return (
            <Card className="erc20-card">
                <CardBody>
                    <AvForm
                        className="erc20-form"
                        // NOTE* initialValue key's must match "name" attribute in AvField components!
                        initialValues={{
                            "erc20-name": tokenName,
                            "erc20-symbol": symbol,
                            "chain-select": chain,
                            description: description,
                            "erc20-cap": cap === "-1" ? " " : cap,
                            "contract-type-select": contractType
                        }}
                        enableReinitialize
                        validationSchema={yup.object().shape({
                            "erc20-name": yup.string().required("This field is required."),
                            "erc20-symbol": yup.string().required("This field is required."),
                            "chain-select": yup.string().required("This field is required."),
                            description: yup.string().required("This field is required."),
                            "erc20-cap": yup
                                .string()
                                .test("valid cap min?", "Cap minimum is 1", (val) => {
                                    if (cap === "-1") return true
                                    // show error message 'cap min is 1' if return value evaluates to false
                                    return Number(val) >= 1
                                })
                                .test("valid cap step?", "Cap must be increments of 1", (val) => Number(val) % 1 === 0)
                                .required("This field is required.")
                        })}
                        onSubmit={handleClickDeploy}
                    >
                        <CardTitle className="h3">ERC20 TOKEN</CardTitle>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="erc20-name"
                                        label="Token Name"
                                        placeholder="Token Name"
                                        type="text"
                                        className="form-control"
                                        id="erc20-name"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setTokenName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup className="mb-3">
                                    <AvField
                                        name="erc20-symbol"
                                        label="Token Symbol"
                                        placeholder="Enter Symbol"
                                        type="text"
                                        className="form-control"
                                        id="erc20-symbol"
                                        disabled={showSpinner || status === "pending" || status === "done"}
                                        onChange={(e) => setSymbol(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="mb-3">
                            <Select
                                name="chain-select"
                                label="Chain"
                                placeholder="Select Blockchain"
                                id="chain-select"
                                options={chainOptions}
                                selectedOption={chain}
                                disabled={showSpinner || status === "pending" || status === "done"}
                                handleChange={(value) => setChain(value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="description"
                                label="Description"
                                placeholder="Enter Description"
                                type="text"
                                className="form-control"
                                id="description"
                                disabled={showSpinner || status === "pending" || status === "done"}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            {/* Max number of erc20 tokens to be minted */}
                            {/* TO DO: add tooltip with explanation */}
                            <AvField
                                name="erc20-cap"
                                label="Cap"
                                placeholder={cap === "-1" ? "No Cap" : cap || "Enter Token Cap"}
                                type="number"
                                className="form-control"
                                id="erc20-cap"
                                min="1" // minimum cap a user can submit
                                step="1" // no decimals, only increments of 1
                                disabled={showSpinner || cap === "-1" || status === "pending" || status === "done"}
                                onChange={(e) => setCap(e.target.value)}
                            />
                            <FormGroup check inline className="no-cap">
                                <Input
                                    name="no-cap"
                                    type="checkbox"
                                    id="no-cap"
                                    checked={cap === "-1"}
                                    disabled={showSpinner || status === "pending" || status === "done"}
                                    onChange={handleClickNoCap}
                                />
                                {/* 'for' property value must match input checkbox 'id' value */}
                                <Label check for="no-cap">
                                    No Cap
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Select
                                name="contract-type-select"
                                label="Contract Type"
                                placeholder="Select Contract Type"
                                id="contract-type-select"
                                options={CONTRACT_TYPES}
                                selectedOption={contractType}
                                disabled={showSpinner || status === "pending" || status === "done"}
                                handleChange={(value) => setContractType(value)}
                            />
                        </FormGroup>

                        <div className="deploy-alert">
                            <Alert color={alertColor} toggle={() => setAlertMessage("")} isOpen={!!alertMessage}>
                                {alertMessage}
                            </Alert>
                        </div>
                        <div className="d-flex justify-content-between">
                            {/* SAVE BUTTON */}
                            <Button
                                color="primary"
                                type="button"
                                outline
                                className="waves-effect w-sm save-btn"
                                disabled={
                                    showSpinner ||
                                    !allFormsValid([tokenName, symbol, chain, description, cap]) ||
                                    cap < -1 ||
                                    cap === 0 ||
                                    noFormChange() ||
                                    ERC20?.source?.S === "pending" ||
                                    ERC20?.source?.S === "done" ||
                                    status === "pending"
                                }
                                onClick={handleClickSave}
                            >
                                <SpinnerWrap showSpinner={showSpinner && buttonId === 1} txtTrue={"Saving"} txtFalse={"Save"} />
                            </Button>
                            <Tooltip
                                title={`Contract is ${status === "done" ? "deployed" : "pending"}, cannot redeploy`}
                                placement="top"
                                disableFocusListener
                                open={(showTooltip && status === "pending") || (showTooltip && status === "done")}
                            >
                                {/* need this span for showing tooltip */}
                                <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                    {/* DEPLOY BUTTON */}
                                    <Button
                                        color="primary"
                                        className="waves-effect submit-btn"
                                        type="submit"
                                        disabled={
                                            showSpinner ||
                                            !allFormsValid([tokenName, symbol, chain, description, cap]) ||
                                            cap < -1 ||
                                            ERC20?.source?.S === "pending" ||
                                            ERC20?.source?.S === "done" ||
                                            status === "pending"
                                        }
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 2} txtTrue={"DEPLOYING"} txtFalse={"DEPLOY"} />
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        )
    }
)

export default ERC20Card
