import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap"
import jwt from "jsonwebtoken"

import { useToast } from "../hooks/toast"
import { formatAddress, formattedDateString } from "../utils"
import EditPlayerModal from "../Components/EditPlayerModal/EditPlayerModal"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import BreadCrumb from "../Components/BreadCrumb"
import Tooltip from "../Components/Tooltip"

const apiUrl = "https://players.idexo.io/"

function sortPlayersByInserted(players, recentFirst = true) {
    return players.sort((a, b) => {
        if (recentFirst) return b.inserted.N - a.inserted.N
        return a.inserted.N - b.inserted.N
    })
}

const PlayerManagement = ({ project, module, handleClickShowPlayer, clickProjects, clickCancel, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    const toast = useToast()

    const [showLoading, setShowLoading] = useState(false) // for loading animation of players table
    const [playersAlertMessage, setPlayersAlertMessage] = useState("") // if error fetching players data
    const [playersData, setPlayersData] = useState([]) // array of objects
    const [selectedPlayer, setSelectedPlayer] = useState(null) // object- set when clicked on player row
    const [showPlayerRowColor, setShowPlayerRowColor] = useState(false) // players table row changes color on mouse hover (but not if mouse over buttons)

    // add/edit/view player modal
    const [showPlayerModal, setShowPlayerModal] = useState(false) // for editing/viewing player data
    const [modalAlertMessage, setModalAlertMessage] = useState("") // error if editing/adding player data
    const [alertColor, setAlertColor] = useState("")
    const [showConfirmLoading, setShowConfirmLoading] = useState(false)
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    console.log("playersData", playersData)

    const handleCopyClick = (e, address) => {
        e.stopPropagation()
        navigator.clipboard.writeText(address.replace("×", "x"))
        toast.addSuccess(`Address ${formatAddress(address)} is copied to clipboard.`)
    }

    const processErrors = (errorMessage) => {
        setModalAlertMessage(errorMessage)
        setAlertColor("danger")
        setShowConfirmLoading(false)
        setDisableModalButtons(false)
    }

    // Get S3 presigned-url
    const fetchPresignedURL = async (s3Key, getObject = false) => {
        // TO DO: tenant/member editing/viewing feature
        return fetch("https://projects.idexo.io/", {
            method: "POST",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
            body: JSON.stringify({
                action: "fetchPresignedURL",
                s3Params: { Key: s3Key },
                getObject: getObject // if true, then we are getting an image from lambda instead of uploading to s3
            })
        })
    }

    const uploadImageToS3 = async (url, img) => {
        // PUT request- upload file directly to s3 bucket using presigned URL
        const s3Response = await fetch(url, {
            method: "PUT",
            body: img // image object (blob)
        })
        return s3Response
    }

    // POST creating new player or PATCH updating existing player
    const handleSavePlayer = async (method, email, fabId, player, img) => {
        // img == img object
        setShowConfirmLoading(true)
        setModalAlertMessage("")
        setDisableModalButtons(true)

        try {
            console.log(`method ${method}, email ${email}, fabId ${fabId}, player, img`, player, img)
            let res
            let response

            const body = {}
            if (email) body.email = email
            if (fabId) body.fab_id = fabId
            if (!email && !fabId) return processErrors("must enter a Play Fab ID or an Email")

            // only upload image to s3 if user has just selected an image
            if (img && method === "POST") body.avatar_url = "S3KEY" // will overwrite this value in backend with proper url based on uuid playerId

            const finalUrl = method === "POST" ? apiUrl : `${apiUrl}?id=${player?.id?.S}`
            console.log("body", body) // ex. { email: 'test1@gmail.com', fab_id: 'abc-123', avatar_url: 'S3KEY' }
            console.log("finalUrl", finalUrl)

            // POST / PATCH player data to players DB
            res = await fetch(finalUrl, {
                method: method,
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                },
                body: JSON.stringify(body)
            })
            response = await res.json()
            console.log("handleSavePlayer", response) // response.data => { id: playerId, vault_wallet: "0x123" }

            // problem POST / FETCH to dynamo players table
            if (response.error || response.code !== 200 || response.data.error) {
                return processErrors("Error: " + (response.error || response.data.error))
            }

            // we need the playerId (from response above) in order to upload to s3
            if (img) {
                const playerId = response?.data?.id || player?.id?.S
                const s3Key = `players/${playerId}`
                console.log("s3Key", s3Key)

                // get presigned s3 URL to upload
                res = await fetchPresignedURL(s3Key)
                response = await res.json()
                console.log("fetchPresignedURL", response)

                // error getting presigned URL
                if (response?.code !== 200 || response?.error) return processErrors("Error Uploading Avatar: " + response?.error)

                // upload image to public bucket, with public URL
                const uploadUrl = JSON.parse(response.data)?.url
                const s3UploadResponse = await uploadImageToS3(uploadUrl, img)
                console.log("s3UploadResponse", s3UploadResponse)

                // error uploading to s3
                if (!s3UploadResponse.ok) return processErrors("Error Uploading Image Avatar")
            }

            const successMessage = method === "POST" ? "added a new player!" : "updated player!"
            setModalAlertMessage("successfully " + successMessage)
            setAlertColor("success")
            fetchPlayersData()
        } catch (err) {
            console.log(err)
            setModalAlertMessage(String(err))
            setAlertColor("danger")
        }
        setShowConfirmLoading(false)
        setDisableModalButtons(false)
    }

    const fetchPlayersData = async () => {
        setShowLoading(true)
        setPlayersAlertMessage("")

        try {
            // TO DO: testing tenant/membership editing/viewing feature
            let finalUri = apiUrl

            const res = await fetch(finalUri, {
                method: "GET",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json",
                    "x-api-key": decoded.api_key
                }
            })
            const response = await res.json()
            console.log("fetchPlayersData", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                setPlayersAlertMessage("Error: " + (response.error || response.data.error))
                setShowLoading(false)
                return
            }

            setPlayersData(sortPlayersByInserted(response.data.Items))
        } catch (err) {
            console.log(err)
            setPlayersAlertMessage("Error: " + err)
        }
        setShowLoading(false)
    }

    // runs once on initial mount
    useEffect(() => {
        fetchPlayersData()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="player-management-wrap">
            <BreadCrumb
                items={["projects", `${project?.project_name?.S}`, "Player Management"]}
                links={["projects", "projects"]}
                linkFunctions={[clickProjects, clickCancel]}
            />

            <Card className="players-card">
                <CardBody>
                    <CardTitle className="h3">Players</CardTitle>
                    {playersData.length > 0 && (
                        <p className="playerscard-description">Click on a row below to edit or see more info about a player</p>
                    )}

                    <div className="add-player-row">
                        <Button color="primary" onClick={() => setShowPlayerModal(true)}>
                            ADD PLAYER
                        </Button>
                    </div>

                    {showLoading ? (
                        <LoadingCard loadingTxt={"Players Data"} />
                    ) : (
                        <React.Fragment>
                            <div className="overflow-auto">
                                <Table className="players-table align-middle mb-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">Avatar</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Player ID</th>
                                            <th scope="col">Wallet</th>
                                            <th scope="col">Join Date</th>
                                            <th scope="col">Last Login</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {playersData?.map((player, idx) => {
                                            return (
                                                <tr
                                                    key={idx}
                                                    className={"player-row" + (showPlayerRowColor ? " show-background" : "")}
                                                    onClick={(e) => {
                                                        setSelectedPlayer(player)
                                                        setShowPlayerModal(true)
                                                    }}
                                                    onMouseEnter={() => setShowPlayerRowColor(true)}
                                                    onMouseLeave={() => setShowPlayerRowColor(false)}
                                                >
                                                    <td className="image">
                                                        {/* TO DO: show loading animation */}
                                                        {/* if player has no avatar image uploaded, show default icon */}
                                                        {player?.avatar_url?.S === "" || player?.avatar_url?.S === undefined ? (
                                                            <div
                                                                className="empty-photo"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                }}
                                                                onMouseEnter={() => setShowPlayerRowColor(false)}
                                                                onMouseLeave={() => setShowPlayerRowColor(true)}
                                                            >
                                                                <i className="display-6 text-muted bx bxs-camera-plus" />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                alt="player avatar"
                                                                src={player?.avatar_url?.S}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    // TO DO: open larger image modal
                                                                    // setSelectedImage(nft)
                                                                }}
                                                                onMouseEnter={() => setShowPlayerRowColor(false)}
                                                                onMouseLeave={() => setShowPlayerRowColor(true)}
                                                            />
                                                        )}
                                                    </td>
                                                    <td className="email">{player?.email?.S}</td>
                                                    <td className="player-id">
                                                        <span>{formatAddress(player?.id?.S)}</span>
                                                        <Tooltip title="Copy player id to clipboard" placement="bottom">
                                                            <i
                                                                className="bx bx-copy font-size-20"
                                                                onClick={(e) => handleCopyClick(e, player?.id?.S)}
                                                                onMouseEnter={() => setShowPlayerRowColor(false)}
                                                                onMouseLeave={() => setShowPlayerRowColor(true)}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                    {/* idexo custodial wallet address */}
                                                    <td className="wallet">
                                                        {player?.vault_wallet?.S && (
                                                            <React.Fragment>
                                                                <span>{formatAddress(player?.vault_wallet?.S)}</span>
                                                                <Tooltip title="Copy wallet address to clipboard" placement="bottom">
                                                                    <i
                                                                        className="bx bx-copy font-size-20"
                                                                        onClick={(e) => handleCopyClick(e, player?.vault_wallet?.S)}
                                                                        onMouseEnter={() => setShowPlayerRowColor(false)}
                                                                        onMouseLeave={() => setShowPlayerRowColor(true)}
                                                                    />
                                                                </Tooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                    <td className="join-date">{formattedDateString(new Date(Number(player?.inserted?.N)))}</td>
                                                    <td className="last-login">{player?.last_login?.S}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>

                            {/* if error fetching players data, show error card */}
                            {!!playersAlertMessage && <LoadingCard errorTxt={"Players Data"} alertMessage={playersAlertMessage} />}

                            {/* if no error fetching players data, but there are no players */}
                            {!playersAlertMessage && playersData.length === 0 && (
                                <LoadingCard emptyTxt={"Players"} icon="bx-image-add" onClick={() => setShowPlayerModal(true)} className="add-nft" />
                            )}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>

            {/* ADD OR EDIT PLAYER MODAL */}
            {showPlayerModal && (
                <EditPlayerModal
                    showConfirmSubmitModal={showPlayerModal}
                    setShowConfirmSubmitModal={setShowPlayerModal}
                    modalAlertMessage={modalAlertMessage}
                    setModalAlertMessage={setModalAlertMessage}
                    alertColor={alertColor}
                    setAlertColor={setAlertColor}
                    player={selectedPlayer}
                    setSelectedPlayer={setSelectedPlayer}
                    handleSubmit={handleSavePlayer}
                    showConfirmLoading={showConfirmLoading} // submit button loading animation
                    disableAllButtons={disableModalButtons}
                />
            )}
        </div>
    )
}

export default PlayerManagement
