import contractAbi from "../abi/PolygonNFTTrading.json"

class PolygonNFTTrading {
    constructor(web3, address) {
        this.web3 = web3
        this.contract = new this.web3.eth.Contract(contractAbi.abi, address)
    }

    async bid(tokenId) {
        return await this.contract.methods.bid(process.env.REACT_APP_POLYGON_TRADING_MINTER_CONTRACT, tokenId).call()
    }

    async closeForSale(tokenId) {
        const accounts = await this.web3.eth.getAccounts()
        return await this.contract.methods.closeForSale(process.env.REACT_APP_POLYGON_TRADING_MINTER_CONTRACT, tokenId).send({ from: accounts[0] })
    }

    async getOrderState(tokenId) {
        return await this.contract.methods.getOrderState(process.env.REACT_APP_POLYGON_TRADING_MINTER_CONTRACT, tokenId).call()
    }

    async openForSale(tokenId, price) {
        const accounts = await this.web3.eth.getAccounts()
        return await this.contract.methods
            .openForSale(process.env.REACT_APP_POLYGON_TRADING_MINTER_CONTRACT, tokenId, price)
            .send({ from: accounts[0] })
    }
}

export default PolygonNFTTrading
