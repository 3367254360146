var AbiInputType
;(function (AbiInputType) {
    AbiInputType["TUPLE"] = "tuple"
    AbiInputType["STRING"] = "string"
    AbiInputType["ADDRESS"] = "adress"
    AbiInputType["BOOLEAN"] = "bool"
})(AbiInputType || (AbiInputType = {}))

var isStructInput = function (input) {
    return input ? (input.type || "").includes(AbiInputType.TUPLE) : false
}

var getStructType = function (tuple) {
    var tupleArgs = (tuple.components || []).map(function (c) {
        if (isStructInput(c)) return getStructType(c)
        return c.type
    })
    return "tuple(".concat(tupleArgs.join(","), ")")
}

var __assign =
    (this && this.__assign) ||
    function () {
        __assign =
            Object.assign ||
            function (t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i]
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
                }
                return t
            }
        return __assign.apply(this, arguments)
    }

// takes in JS object
var prepareFunction = function (fn) {
    if (!fn) return fn
    return __assign(__assign({}, fn), {
        inputs: (fn.inputs || []).map(function (input) {
            return __assign(__assign({}, input), {
                type: isStructInput(input) ? getStructType(input) : input.type,
                originalType: input.type
            })
        })
    })
}

var getFunctionArgument = function (input) {
    if (input.type.includes("tuple")) {
        var tupleArguments = (input.components || []).map(getFunctionArgument).join(",")
        return "(".concat(tupleArguments, ")")
    }
    return input.type
}

// takes in a JS object, and returns a string that refers to the function name and its parameters
// ex. => "approve(address,uint256)"
var getFunctionKey = function (func) {
    var inputs = func.inputs || []
    var inputTypesAsString = inputs.map(getFunctionArgument).join(",")
    return func.type === "constructor" ? func.type : "".concat(func.name, "(").concat(inputTypesAsString, ")")
}

// takes in stringified JSON, returns object of functions
var parse = function (abi) {
    var functions = JSON.parse(abi) // array of objects
    var parsedFunctions = {}

    functions.forEach(function (func) {
        if (func.type !== "event") {
            var key = getFunctionKey(func) // gets name of function
            if (key) {
                parsedFunctions[key] = prepareFunction(func)
            }
        }
    })
    return parsedFunctions
}

// takes in JSON, returns object of functions formatted for easy display/interaction
function getParsedFunctions(abi) {
    const strABI = JSON.stringify(abi) // convert JSON to string
    const methods = parse(strABI) // object
    return Object.keys(methods).map((methodName) => {
        return { nameAndInputs: methodName, ...methods[methodName] }
    })
    // return parse(strABI) // object
}

export { getParsedFunctions }

// getParsedFunctions(createNFTCollectionCappedABI); //=>
// {
//   constructor: {
//     inputs: [ [Object], [Object], [Object], [Object] ],
//     stateMutability: 'nonpayable',
//     type: 'constructor'
//   },
//   'DEFAULT_ADMIN_ROLE()': {
//     inputs: [],
//     name: 'DEFAULT_ADMIN_ROLE',
//     outputs: [ [Object] ],
//     stateMutability: 'view',
//     type: 'function'
//   },
//   'OPERATOR_ROLE()': {
//     inputs: [],
//     name: 'OPERATOR_ROLE',
//     outputs: [ [Object] ],
//     stateMutability: 'view',
//     type: 'function'
//   },
//   'acceptOwnership()': {
//     inputs: [],
//     name: 'acceptOwnership',
//     outputs: [],
//     stateMutability: 'nonpayable',
//     type: 'function'
//   },
//   ...
// }
