import * as yup from "yup"

export default class password {
    static verify = (ctx) => {
        if (ctx.password !== ctx.confirmpassword) {
            return "Passwords didn't match. Try again."
        }
        return this.complexity(ctx.password)
    }

    static complexity = (value) => {
        if (value.length < 10) {
            return "Your password must be at least 10 characters"
        } else if (!new RegExp("^(?=.*[A-Z]).*$").test(value)) {
            return "Password must have Uppercase letter"
        } else if (!new RegExp("^(?=.*[a-z]).*$").test(value)) {
            return "Password must have lowercase letter"
        } else if (!new RegExp("^(?=.*[0-9]).*$").test(value)) {
            return "Password must have at least a number"
        } else if (!new RegExp("^(?=.*[!@#&%=,;:<>^$.|?*+()]).*$").test(value)) {
            return "Password must have at least a symbol '!@#&%=,;:<>^$.|?*+()'"
        }
        return false
    }

    static schemaLogin = yup.object().shape({
        email: yup.string().required("This field is required."),
        password: yup.string().required("This field is required.")
    })

    static schema = yup.object().shape({
        email: yup.string().required("This field is required."),
        password: yup.string().required("This field is required."),
        confirmpassword: yup.string().required("This field is required.")
    })
}
