import React, { useState, useEffect, useCallback, useRef } from "react"
import { Col, Row } from "reactstrap"
import jwt from "jsonwebtoken"
import OkDialog from "../Components/OkDialog/OkDialog"
import { determineType } from "../utils"
import NFTCollectionCard from "../Components/NFTCollectionCard/NFTCollectionCard"
import TwitterCard from "../Components/TwitterCard/TwitterCard"
import TelegramCard from "../Components/TelegramCard/TelegramCard"
import SubdomainCard from "../Components/SubdomainCard/SubdomainCard"
import ProgressCard from "../Components/ProgressCard/ProgressCard"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import WaitingModal from "../Components/WaitingModal/WaitingModal"

const apiUrl = "https://nftmarketing.idexo.io/" // backend

const updateCampaignDataPending = (items) => {
    const newItems = items.slice()
    for (let i = 0; i < newItems.length; i++) {
        // if item is type NFTcollection (other types include twitter, telegram, & subdomain)
        if (newItems[i].collection_name) {
            newItems[i].source.S = "done"
            break
        }
    }
    return newItems
}

const NFTMarketing = ({ project, module, setCampaignInserted, handleClickAddNFT, showNFTCollectionOnly = false, ...rest }) => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //
    // NOTE* using snake case below to match backend db
    // NFT Collection Component
    const [NFTCollection, setNFTCollection] = useState({}) // original data from backend (used to compare changes in form input so user doesn't submit same data)
    const [showNFTImageLoading, setShowNFTImageLoading] = useState(true) // loading animation for nft collection thumbnail
    const [imgFile, setImgFile] = useState([]) // array with one object that has info about image (ex. filename, blob data, ...) to be uploaded to S3
    const [imgURL, setImgURL] = useState("") // s3 signed url of image the represents nft collection
    const [imageModalMessage, setImageModalMessage] = useState("")
    const [showViewImageSpinner, setShowViewImageSpinner] = useState(false)
    const [collection_name, setCampaignName] = useState("")
    const [symbol, setSymbol] = useState("")
    const [description, setDescription] = useState("")
    const [chain, setChain] = useState("")
    const [cap, setCap] = useState("") // max number of nft's to be minted for campaign. -1 === no cap
    const collectionNameRef = useRef(collection_name) // useRef will prevent fetchCampaignData() from being run everytime collection_name input form field changes

    // TwitterCard Component
    const [twitterData, setTwitterData] = useState({}) // original data from backend (used to compare changes in form input so user doesn't submit same data)
    const [twitter_mint_command, setTwitterMintCommand] = useState("")
    const [twitter_launch_url, setTwitterLuanchUrl] = useState("")
    const twitterLaunchUrlRef = useRef(twitter_launch_url) // useRef will prevent fetchCampaignData() from being run everytime twitter_luanch_url input form field changes

    // TelegramCard Component
    const [telegramData, setTelegramData] = useState({}) // original data from backend (used to compare changes in form input so user doesn't submit same data)
    const [telegram_group_name, setTelegramGroupName] = useState("") // super group name
    const [telegram_bot_name, setBotName] = useState("")
    const [telegram_bot_command, setBotCommand] = useState("")
    const telegramGroupNameRef = useRef(telegram_group_name) // useRef will prevent fetchCampaignData() from being run everytime telegram_group_name input form field changes

    // Site SubdomainCard Component
    const [subdomainData, setSubdomainData] = useState({})
    const [subdomain, setSubdomain] = useState("")
    const [subdomain_file, setSubdomainFile] = useState("")
    const subdomainRef = useRef(subdomain) // useRef will prevent fetchCampaignData() from being run everytime subdomain input form field changes

    // Progress Table Card Component
    const [showDetailsButtons, setShowDetailsButtons] = useState({}) // keys = button indexes, values = booleans, where true = show button, false = don't show button
    const [showProgressSpinner, setShowProgressSpinner] = useState(false) // for loading animation of progress table
    const [progressAlertMessage, setProgressAlertMessage] = useState("") // if error fetching campaign data

    // MISC
    const [showTooltip, setShowTooltip] = useState(false) // for nft collection submit button
    const [showSpinner, setShowSpinner] = useState(false) // loading animation on buttons
    const [buttonId, setButtonId] = useState(null) // number refers to which button was clicked (for displaying spinner animation)
    const [alertMessage, setAlertMessage] = useState("")
    const [campaignData, setCampaignData] = useState(null) // object = {Items:[]} will contain info from all fields (from backend)

    var intervalId = null

    // every 10 seconds, check if contract has been successfully deployed/done, & update progress table state if done
    const checkPending = async (txId) => {
        const res = await fetch(apiUrl + "?action=checkpending&txid=" + (txId || campaignData?.tx_id?.S), {
            method: "GET",
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
        })
        const response = await res.json()

        // TO DO: exponential back off maybe? stop checkPending if too many error responses
        if (response.error || response.code !== 200) return console.log(response.error)

        if (response.data?.step?.S === "done") {
            const newItems = updateCampaignDataPending(campaignData.Items)
            setCampaignData({ Items: newItems }) // this will update the progressCard status to show "done" instead of "pending"
            let newNFTCollection = { ...NFTCollection }
            newNFTCollection.source.S = "done"
            setNFTCollection(newNFTCollection) // this will update NFTCollectionCard deploy button tooltip to show "done" instead of "pending"
            clearInterval(intervalId)
            intervalId = null
        }
    }

    // updates progress table status (dynamo nft marketing table item) to ex. "pending", & adds tx_id, contract_owner attributes
    const updateProgressTableStatus = async (inserted, tx_id, contract_owner) => {
        // inserted === time item (ex. twitter, telegram, nftcollection) in nftmarketing dynamo table was inserted
        try {
            let finalUri = apiUrl

            return await fetch(finalUri, {
                method: "POST",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "x-api-key": decoded.api_key,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    action: "updateProgressStatus",
                    inserted: inserted, // inserted time of nftmarketing table item
                    step: "pending", // ex. "pending", or "done"
                    tx_id, // dynamo params to add to nft marketing item
                    contract_owner // sender
                    // Might not need below if we're not updating projects table:
                    // project_inserted: project.inserted.N // refers to primary key in user_projects table
                    // module_inserted: moduleInserted // for identifying a module in a project
                })
            })
        } catch (err) {
            console.log(err)
            // TO DO: backend- send telegram message to us if we error out in the backend at this step
        }
    }

    const getImage = useCallback(
        async (showImageModal = true) => {
            setImageModalMessage("")
            setShowViewImageSpinner(true)
            try {
                let additionalParams = `&moduleinserted=${module.inserted.N}` // we can use user_id and module_inserted to find unique key in s3 for image

                // call to lambda to get presigned GET s3 url
                let finalUri = apiUrl + `?action=getimage`

                const res = await fetch(finalUri + additionalParams, {
                    method: "GET",
                    headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
                })

                if (res.ok) {
                    const response = await res.json() // array with one image object
                    const stringURL = JSON.parse(response.data[0].imgURL).url
                    setImgURL(stringURL)
                    // showImageModal && setImageModalMessage(<img src={`data:image/jpeg;base64,${stringURL}`} />)
                    showImageModal && setImageModalMessage(<img alt="imgdata" src={`${stringURL}`} />)

                    // error 404 means user has no image uploaded
                } else if (res.status !== 404) {
                    setImageModalMessage("Error getting image")
                }
            } catch (err) {
                console.log(err)
                setImageModalMessage(String(err))
            }

            setShowNFTImageLoading(false)
            setShowViewImageSpinner(false)
        },

        // eslint-disable-next-line
        [module.inserted.N]
    )

    // this will be passed in body to backend to get signed URL from s3
    // TODO: add tenant_id to s3 key
    const s3Params = useCallback(() => {
        let user_id = decoded.user_id

        const Key = `${user_id}/modulenftmarketing/${module.inserted.N}`
        return {
            Key: Key // user can have multiple images uploaded if they create multiple nft marketing modules
        }
    }, [decoded.user_id, module.inserted.N])

    const uploadImageToS3 = async (uploadUrl) => {
        try {
            // PUT request: upload file directly to s3 bucket using presigned URL (returned from post request above)
            const s3Response = await fetch(uploadUrl, {
                method: "PUT",
                body: imgFile[0].base64 // blob datatype
            })
            // valid s3Response === {type: 'cors', url: 'https://nftmarketing-738087076124.s3.us-east-1.ama…15af9d2e9&X-Amz-SignedHeaders=host&x-id=PutObject', redirected: false, status: 200, ok: true, …}
            // problem saving image to s3
            if (!s3Response.ok) {
                setAlertMessage(`Saved data except for NFT Collection Image. Error: ${s3Response.statusText}`)
            } else {
                setAlertMessage("Successfully saved all data and NFT Collection Image!")
            }
        } catch (err) {
            console.log(err)
            setAlertMessage(`Saved data except for NFT Collection Image. Error: ${String(err)}`)
        }
    }

    // Creating or Updating a campaign item in nftmarketing table (example of item = nft marketing, twitter, telegram, ...)
    // insertedTime === time item (ex. twitter, telegram, nftcollection) in nftmarketing dynamo table was inserted
    const handleSubmit = async (newModule, formData, id, action, image = false, insertedTime) => {
        if (newModule.M) {
            // if we click on save/submit in nftverification card only
            action = action ? "launchNFTVerificationSite" : "createDraftNFTVerificationSite"
            formData = { subdomain: newModule.M.subdomain.S }
            if (newModule.M.subdomain_file) {
                formData.subdomain_file = newModule.M.subdomain_file.S
            }
        }
        setButtonId(id)
        setShowSpinner(true)

        try {
            const inserted = insertedTime ? insertedTime : new Date().getTime() // milliseconds since 1 January 1970 UTC.
            const projectInserted = project.inserted.N
            const moduleInserted = module.inserted.N

            let finalUri = apiUrl

            const res = await fetch(finalUri, {
                method: "POST",
                headers: {
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "x-api-key": decoded.api_key,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ...formData,
                    action: action,
                    inserted, // inserted time of nftmarketing table item
                    project_inserted: projectInserted, // refers to primary key in user_projects table
                    module_inserted: moduleInserted, // for identifying a module in a project
                    no_image: imgFile.length === 0, // to tell backend not to make S3 URL
                    flags: { nft_validation: 1 }
                })
            })
            const response = await res.json()
            // valid response === { code:200, message:'nftmarketing', error:null, data:{Attributes:{}, error}, url:{error, data:'{"url":"https://nftmarketing-738087076124.s3.us-ea…7d280a9&X-Amz-SignedHeaders=host&x-id=PutObject"}'}  }

            // problem saving/updating text data to dynamo or getting S3 upload url
            if (response.error || response.data?.error || response?.url?.error) {
                setAlertMessage(`Error: ${response.error || response.data?.error || response?.url?.error}`)

                // if we're in here that means we clicked save on the nft collection button/tab AND we succesfully saved the nft collection data
            } else if (image && imgFile.length > 0) {
                // only upload S3 image if user has selected a picture
                const uploadUrl = JSON.parse(response.url.data)?.url
                await uploadImageToS3(uploadUrl)

                // TO DO: remove this once we finish building submit feature
            } else if (typeof response.data === "string" && response.data.includes("WIP")) {
                setAlertMessage(response.data)

                // if we're here that means we did not click on the save nft collection button & we succesfully saved data
                // Or we did click on the save nft collection button (but did not choose picture to upload)
            } else {
                setAlertMessage("Successfully saved data!")
            }
            fetchCampaignData() // this will update state so table will re render with new data
            imgFile.length > 0 && getImage(false) // update image only if user has chosen one to upload
        } catch (err) {
            console.log(err)
            setAlertMessage(`Error: ${err}`)
        }
        setShowSpinner(false)
    }

    // checks transaction status of sdk nft createCollection contract
    const checkTransactionStatus = useCallback(
        async (nftMarketingItem) => {
            try {
                let finalUri = apiUrl

                // if transaction (of nft collection contract) is done, update dynamo and s3
                const res = await fetch(finalUri, {
                    method: "POST",
                    headers: {
                        token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                        "x-api-key": decoded.api_key,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        action: "checkTransactionStatus",
                        tx_id: nftMarketingItem.tx_id.S,
                        inserted: nftMarketingItem.inserted.N,
                        project_name: project.project_name.S
                    })
                })
                const response = await res.json()
                console.log("checkTransactionStatus", response)

                if (response.error || response.code !== 200) return console.log(response.error)

                // TO DO: figure out a way to prevent fetching twice!
                // if collection contract succesfully deployed, update child component (progress table) state source to show "done"
                if (response?.data?.step?.S === "done") fetchCampaignData() // forces state update (if we try updating campaignData state, it will get overwritten later with old data)
            } catch (err) {
                console.log(err)
            }
        },

        // eslint-disable-next-line
        [project.project_name.S]
    )

    const fetchCampaignData = useCallback(async () => {
        setShowProgressSpinner(true)
        setProgressAlertMessage("")

        try {
            // fetches ALL items in nftmarketing table that belong to user_id (we will filter out later below)
            let finalUri = apiUrl + "?action=getitemsbyuserid"

            const res = await fetch(finalUri, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken, "Content-Type": "application/json" }
            })
            const response = await res.json()
            console.log("fetchCampaignData", response)

            // problem fetching
            if (response.error || response.code !== 200 || response.data.error) {
                setProgressAlertMessage("Error: " + (response.error || response.data.error))
                setShowProgressSpinner(false)
                return
            }

            let items = [] // array of objects (from nftmarketing table)
            // get only campaign data with a module inserted time as the module that the user clicked on in the modules table (projectView.js)
            items = response.data.Items.filter((item) => {
                return item?.module_inserted?.N === module?.inserted?.N
            })

            let times = {}
            // find/set insertion time for each campaign type
            for (let i = 0; i < items.length; i++) {
                let item = items[i]
                let type = determineType(item) //=> ex. "NFT Collection"
                times[type] = item.inserted.N
            }

            if (setCampaignInserted) setCampaignInserted(times)

            for (let i = 0; i < items.length; i++) {
                let item = items[i] // object- ex. nft collection, twitter, telegram, ...
                let type = determineType(item)

                // prefill form data (only do this once after mounting to prevent infinite rerenders)
                // we use useRef bec. if we reference a state variable here, this fetchCampaignData will run everytime that state changes
                if (type === "NFT Collection" && !collectionNameRef.current) {
                    setNFTCollection(item) // original data from backend (used to compare changes in form input so user doesn't submit same data)
                    setCampaignName(item.collection_name.S)
                    setSymbol(item.symbol.S)
                    setChain(item.chain.S)
                    setDescription(item.description.S)
                    setCap(item.cap.S)
                    if (!imgURL) {
                        getImage(false)
                    }
                    // if status is pending, check backend for updates (to see if status changed to deployed/done)
                    if (item.source.S === "pending") {
                        checkTransactionStatus(item)
                    }
                } else if (type === "Twitter" && !twitterLaunchUrlRef.current) {
                    setTwitterData(item) // original data from backend (used to compare changes in form input so user doesn't submit same data)
                    setTwitterLuanchUrl(item.twitter_launch_url.S)
                    setTwitterMintCommand(item.twitter_mint_command.S)
                } else if (type === "Telegram" && !telegramGroupNameRef.current) {
                    setTelegramData(item) // original data from backend (used to compare changes in form input so user doesn't submit same data)
                    setTelegramGroupName(item.telegram_group_name.S)
                    setBotName(item.telegram_bot_name.S)
                    setBotCommand(item.telegram_bot_command.S)
                } else if (type === "Verification Site" && !subdomainRef.current) {
                    setSubdomainData(item)
                    setSubdomainFile(item.subdomain_file.S)
                    setSubdomain(item.subdomain.S)
                }
            }

            // only stop image animation loading if none of the nftmarketing items are of type "NFT Collection"
            if (items.length === 0 || items.every((item) => determineType(item) !== "NFT Collection")) {
                setShowNFTImageLoading(false)
            }
            setCampaignData({ Items: items })
        } catch (err) {
            console.log(err)
            setProgressAlertMessage("Error: " + err)
            setShowNFTImageLoading(false)
        }
        setShowProgressSpinner(false)

        // eslint-disable-next-line
    }, [
        // NOTE* we use useRef hook to access state variables outside of useEffect without triggering effect callback (this will prevent fetching everytime form field data changes)
        module?.inserted?.N,
        setCampaignInserted,
        checkTransactionStatus,
        getImage,
        imgURL
    ])

    // runs once on initial mount
    useEffect(() => {
        fetchCampaignData()
    }, [fetchCampaignData])

    return (
        <React.Fragment>
            <div className="content nft-marketing">
                <Row>
                    <OkDialog open={!!alertMessage} title={""} message={alertMessage} okClick={() => setAlertMessage("")} />
                    <OkDialog open={!!imageModalMessage} title={""} message={imageModalMessage} okClick={() => setImageModalMessage("")} />
                    {/* conditionally format widths to 100% if rendering nftCollection card only */}
                    <Col xs={showNFTCollectionOnly ? 0 : 12} sm={showNFTCollectionOnly ? 0 : 12} md={showNFTCollectionOnly ? 0 : 6}>
                        <Row>
                            <Col>
                                {campaignData ? (
                                    <NFTCollectionCard
                                        handleSubmit={handleSubmit}
                                        s3Params={s3Params}
                                        updateProgressTableStatus={updateProgressTableStatus}
                                        fetchCampaignData={fetchCampaignData}
                                        checkPending={(txId) => {
                                            intervalId = setInterval(() => checkPending(txId), 10000)
                                        }}
                                        // states/state updaters
                                        project={project}
                                        NFTCollection={NFTCollection}
                                        imgFile={imgFile}
                                        setImgFile={setImgFile}
                                        showSpinner={showSpinner}
                                        setShowSpinner={setShowSpinner}
                                        imgURL={imgURL}
                                        showNFTImageLoading={showNFTImageLoading}
                                        collection_name={collection_name}
                                        setCampaignName={setCampaignName}
                                        symbol={symbol}
                                        setSymbol={setSymbol}
                                        chain={chain}
                                        setChain={setChain}
                                        description={description}
                                        setDescription={setDescription}
                                        cap={cap}
                                        setCap={setCap}
                                        buttonId={buttonId}
                                        setButtonId={setButtonId}
                                        showTooltip={showTooltip}
                                        setShowTooltip={setShowTooltip}
                                    />
                                ) : (
                                    <React.Fragment>
                                        {showProgressSpinner && (
                                            <LoadingCard loadingTxt={"NFT Collection"} backgroundColor="white" className="nft-collection-loading" />
                                        )}
                                        {progressAlertMessage && (
                                            <LoadingCard
                                                errorTxt={"NFT Collection"}
                                                alertMessage={progressAlertMessage}
                                                backgroundColor="white"
                                                className="nft-collection-loading"
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <ProgressCard
                                    showProgressSpinner={showProgressSpinner}
                                    showSpinner={showSpinner} // spinner for sibling component buttons (ex. 'Deploy' in NFTCollectionCard)
                                    showDetailsButtons={showDetailsButtons}
                                    setShowDetailsButtons={setShowDetailsButtons}
                                    handleClickAddNFT={handleClickAddNFT}
                                    setImageModalMessage={setImageModalMessage}
                                    imgURL={imgURL}
                                    campaignData={campaignData}
                                    getImage={getImage}
                                    showViewImageSpinner={showViewImageSpinner}
                                    progressAlertMessage={progressAlertMessage}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {!showNFTCollectionOnly && (
                        <Col xs={12} sm={12} md={6}>
                            <TwitterCard
                                twitter_mint_command={twitter_mint_command}
                                setTwitterMintCommand={setTwitterMintCommand}
                                twitter_launch_url={twitter_launch_url}
                                setTwitterLuanchUrl={setTwitterLuanchUrl}
                                twitterData={twitterData}
                                paid={decoded?.paid}
                                showSpinner={showSpinner}
                                buttonId={buttonId}
                                handleSubmit={handleSubmit}
                            />

                            <TelegramCard
                                telegram_group_name={telegram_group_name}
                                setTelegramGroupName={setTelegramGroupName}
                                telegram_bot_name={telegram_bot_name}
                                setBotName={setBotName}
                                telegram_bot_command={telegram_bot_command}
                                setBotCommand={setBotCommand}
                                paid={decoded?.paid}
                                showSpinner={showSpinner}
                                buttonId={buttonId}
                                telegramData={telegramData}
                                handleSubmit={handleSubmit}
                            />

                            <SubdomainCard
                                module={module}
                                headerTitle="NFT Verification Site Subdomain"
                                fieldLabel="NFT Verification Sub Domain"
                                subdomainData={subdomainData}
                                subdomain={subdomain}
                                setSubdomain={setSubdomain}
                                subdomain_file={subdomain_file}
                                setSubdomainFile={setSubdomainFile}
                                paid={decoded?.paid}
                                showSpinner={showSpinner}
                                buttonId={buttonId}
                                handleSubmit={handleSubmit}
                            />
                        </Col>
                    )}
                </Row>

                {/* triggers after user clicks "Deploy" or similar action */}
                <WaitingModal isOpen={showSpinner && buttonId === 2} modalType={"none"} text={"Contract is deploying. Kindly wait..."} />
            </div>
        </React.Fragment>
    )
}

export default NFTMarketing
