import {
    SET_TRADING_DATA_LOADING,
    SET_MORE_TRADING_DATA_LOADING,
    SET_TRADING_ITEMS,
    ADD_TRADING_ITEMS,
    SET_TRADING_TOKENS,
    SET_TOKENS_LAST_EVALUATED_KEY
} from "./tradingActions"

const initialState = {
    items: [],
    tokens: [],
    lastEvaluatedKey: null,
    loading: true,
    moreItemsLoading: false
}

const tradingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRADING_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_MORE_TRADING_DATA_LOADING:
            return {
                ...state,
                moreItemsLoading: action.payload
            }
        case SET_TRADING_ITEMS:
            return {
                ...state,
                items: action.payload
            }
        case ADD_TRADING_ITEMS:
            return {
                ...state,
                items: [...state.items, ...action.payload]
            }
        case SET_TRADING_TOKENS:
            return {
                ...state,
                loading: false,
                moreItemsLoading: false,
                tokens: action.payload
            }
        case SET_TOKENS_LAST_EVALUATED_KEY:
            return {
                ...state,
                lastEvaluatedKey: action.payload
            }
        default:
            return { ...state }
    }
}

export default tradingReducer
