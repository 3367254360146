import React from "react"
import "./WaitingModal.scss"
import { Modal, ModalBody, Spinner } from "reactstrap"

// modalType = 3 types of modals:
// 1) "none" = no modal just greyed screen that user can't click around on
// 2) "white" = modal with white modal body background and text ex. "Loading..."
// 3) "spinner" = no modal. greyed screen and spinner loading animation + white text
const WaitingModal = ({ isOpen, modalType = "white", className = "", text = "", ...rest }) => {
    return (
        <Modal isOpen={isOpen} centered={true} className={"waiting-modal " + modalType + " " + className}>
            <ModalBody>
                <Spinner /> <span>{text}</span>
            </ModalBody>
        </Modal>
    )
}

export default WaitingModal
