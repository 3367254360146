import React, { useEffect, useState } from "react"
import { Button, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Alert, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { Field as AvField, Form as AvForm } from "@availity/form"
import { QrReader } from "react-qr-reader"
import clsx from "clsx"
import jwt from "jsonwebtoken"
import * as yup from "yup"

import { isValidAddress, extractUsefulMethods, blockchainExplorers, isValidJSONString, getTxGroup, getContractType } from "../../utils"
import { chainOptions } from "../../common/chain"
import Tooltip from "../../Components/Tooltip"
import Select from "../CustomSelect"
import SpinnerWrap from "../SpinnerWrap"
import MethodsTab from "./MethodsTab"
import "./addContractModal.scss"

const apiUrl = "https://projects.idexo.io/"

const initializeAddress = (contract) => {
    if (!contract) return ""
    if (contract?.address?.S || contract?.objMetaData?.address) return contract?.address?.S || contract?.objMetaData?.address
    if (contract?.tx_status?.S === "true") return "pending..."
    return "" // user added contract is in "draft" stage
}

const initializeAbi = (strAbi) => {
    if (!strAbi) return ""
    let abiObj = JSON.parse(JSON.parse(strAbi)) // remove nested stringified json's
    // if abi is from a user added contract, it does not have this .objBody key (like the idexo abi's do)?
    if (!abiObj.objBody) return JSON.stringify(abiObj, null, 2)
    abiObj.objBody = JSON.parse(abiObj.objBody) // convert nested objBody value from stringified json -> to json
    // parse again because of nested stringification
    if (typeof abiObj.objBody === "string") abiObj.objBody = JSON.parse(abiObj.objBody)
    return JSON.stringify(abiObj.objBody, null, 2) // return only the array portion (the ABI), and format nicely (2 spaces per indentation)
}

const formatABI = (strAbi) => {
    if (!strAbi) return ""
    let abiObj = JSON.parse(strAbi)
    return JSON.stringify(abiObj, null, 2) // format nicely (2 spaces per indentation)
}

// This is also the EDIT Contract Modal
const AddContractModal = (props) => {
    const { open, onClose, onAddContract, contract, project } = props
    const modalType = contract ? "Edit" : "Add"
    const contractIsPendingOrDone = contract?.step?.S === "pending" || contract?.step?.S === "done" || contract?.tx_status?.S === "true"
    const decoded = jwt.decode(localStorage.jwtToken)

    // FORM Data
    // NOTE* if contract has objMetaData or objBody, that means this is a user created contract / not Idexo
    const [name, setName] = useState(contract?.function_name?.S || contract?.objMetaData?.name || "")
    const [address, setAddress] = useState(() => initializeAddress(contract))
    const [chain, setChain] = useState(contract?.network?.S || contract?.objMetaData?.chain || "")
    const [abi, setAbi] = useState(() => initializeAbi(contract?.objBody) || "") // abi = stringified JSON, contract?.objBody = stringified stringified json abi
    const [operators, setOperators] = useState(contract?.operators?.S || contract?.objMetaData?.operators || "")
    const [methods, setMethods] = useState(contract?.methods?.L || []) // these are methods available on the custom smart contract

    const [showABILoading, setShowABILoading] = useState(false) // loading spinner animation input form for fetching ABI
    const [showABIError, setShowABIError] = useState(false) // error card if failure fetching ABI

    // eslint-disable-next-line
    const [txGroup, setTxGroup] = useState(() => getTxGroup(contract?.function_name?.S)) // string- ex. "collection", "royalty"
    // eslint-disable-next-line
    const [contractType, setContractType] = useState(() => getContractType(contract?.function_name?.S, contract?.args?.S)) // string- ex. "capped", "uncapped"
    const [showSpinner, setShowSpinner] = useState(false)
    const [openQRModal, setOpenQRModal] = useState(false)
    const [qrScanErr, setQrScanErr] = useState(false)
    const [methodsList, setMethodsList] = useState([])
    const [alertMessage, setAlertMessage] = useState("")
    const [alertColor, setAlertColor] = useState("")
    const [disableModalButtons, setDisableModalButtons] = useState(false)
    const [showMethodsTab, setShowMethodsTab] = useState(false)

    // returns true if form is NOT empty
    const isValidForm = (form) => {
        if (!form) return false
        form = form.replace(/\s/g, "") // removes empty spaces
        return !!form
    }

    const allFormsValid = (forms) => {
        return forms.every((form) => isValidForm(form))
    }

    // Saving a new contract ("module"), or editing one (if it's a user added contract and not an idexo contract)
    // TO DO: backend validation so user can't modify another user's contract metadata
    const addOrUpdateContract = async () => {
        setShowSpinner(true)
        try {
            const inserted = contract?.objMetaData?.inserted || new Date().getTime() // milliseconds since 1 January 1970 UTC.
            const contract_metadata = {
                name: name,
                address: address,
                chain: chain.toLowerCase(),
                operators: operators,
                user_id: decoded.user_id,
                project_inserted: project?.inserted?.N || contract?.objMetaData.project_inserted,
                project_name: project?.project_name?.S || contract?.objMetaData.project_name,
                inserted: String(inserted) // if we don't do this, S3 will error out when we add an s3 item metadata. Metadata values must be string datatypes!
            }

            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "addUserSmartContract",
                    inserted: inserted,
                    project_inserted: project?.inserted?.N || contract?.objMetaData.project_inserted,
                    abi: JSON.parse(abi),
                    contract_metadata
                })
            })
            const response = await res.json()
            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setAlertMessage("Error: " + (response.error || response?.data?.error || response.message))
                setAlertColor("danger")
            } else {
                setAlertMessage("Success! Closing modal...")
                setAlertColor("success")
                setDisableModalButtons(true)
                setTimeout(() => {
                    onClose()
                    onAddContract()
                }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    const handleScan = (result, error) => {
        if (result) {
            const address = getAddressFromQr(result?.text)
            setAddress(address)
            setOpenQRModal(false)
        } else if (error) {
            setQrScanErr(true)
            console.error(error)
        }
    }

    const getAddressFromQr = (address = "") => {
        const str = address.split("?")[0]
        const str1 = str.split(":")

        return str1.length > 1 ? str1[1] : str1[0]
    }

    const handleOpenImageDialog = () => {
        const inputEl = document.querySelector(".qr-reader input")
        if (inputEl) {
            inputEl.click()
        }
    }

    const onCloseQrScanModal = () => {
        setOpenQRModal(false)
        setQrScanErr(false)
    }

    // returns boolean, true if on editing contract, the input form field data is the same as the old contract data
    const noContractChange = () => {
        if (modalType === "Edit") {
            return (
                name === contract?.objMetaData?.name &&
                address === contract?.objMetaData?.address &&
                chain.toLowerCase() === contract?.objMetaData?.chain &&
                abi === initializeAbi(contract?.objBody) &&
                operators === contract?.objMetaData?.operators
            )
        }
        return false
    }

    const fetchABI = async () => {
        setShowABILoading(true)
        setShowABIError(false)

        try {
            const res = await fetch(apiUrl + "?action=getidexoabi&txgroup=" + txGroup + "&contracttype=" + contractType, {
                method: "GET",
                headers: { token: localStorage.jwtToken }
            })
            const response = await res.json()

            // problem from server fetching contracts
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                console.log(response.error || response?.data?.error || response.message)
                setShowABILoading(false)
                setShowABIError(true)
                return
            }
            setAbi(formatABI(response.data))
        } catch (err) {
            console.log(err)
        }
        setShowABILoading(false)
        setShowABIError(false)
    }

    useEffect(() => {
        if (abi) {
            // TO DO: we might not need this if we're not going to use it in child component (methodsTab)
            try {
                setMethodsList(extractUsefulMethods(JSON.parse(abi)))
            } catch (e) {
                console.log(e)
                setMethodsList([])
                setMethods([])
            }

            // if this modal is the EDIT (view) contract modal & this is an idexo written contract
        } else if (contract !== undefined && abi === "" && contract?.objMetaData === undefined) {
            fetchABI()
        }

        // eslint-disable-next-line
    }, [abi])

    return (
        <React.Fragment>
            <Modal
                className="add-smart-contract-modal"
                isOpen={open}
                onClose={onClose}
                fade={true}
                centered={true}
                toggle={showSpinner || disableModalButtons ? null : onClose}
            >
                <AvForm
                    initialValues={{ name: name, address: address, "chain-select": chain, abi: abi, operators: operators }}
                    onSubmit={addOrUpdateContract}
                    enableReinitialize
                    // NONE OF THESE PROPS WORK
                    // validateOnBlur // validation will be triggered when element loses focus. Default value is true
                    // validateOnChange
                    // validateOnMount={false}
                    validationSchema={yup.object().shape({
                        name: yup.string().required("This field is required."),
                        address: yup
                            .string()
                            .required("This field is required.")
                            // if callback in .test returns false, show error message
                            .test("is valid address?", "You must enter a valid EVM address!", (str) => isValidAddress(str)),
                        "chain-select": yup.string().required("This field is required."),
                        abi: yup
                            .string()
                            .required("This field is required.")
                            .test("valid abi", "You must enter a valid ABI in JSON format!", (value) => isValidJSONString(value))
                    })}
                >
                    <ModalHeader toggle={showSpinner || disableModalButtons ? null : onClose} tag={"div"}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="contract-methdods-h5-wrap">
                                <div className={"view-contract-tab" + (showMethodsTab ? " closed" : "")} onClick={() => setShowMethodsTab(false)}>
                                    {modalType} Contract
                                </div>
                                <div onClick={() => setShowMethodsTab(true)} className={"view-methods-tab" + (showMethodsTab ? "" : " closed")}>
                                    View Methods
                                </div>
                            </h5>
                        </div>
                    </ModalHeader>
                    {showMethodsTab ? (
                        <MethodsTab
                            methodsList={methodsList} // supers version
                            selectedMethods={methods} // we'll error out if we don't use methods variable
                            abi={abi}
                            disabled={showSpinner}
                        />
                    ) : (
                        <ModalBody>
                            <FormGroup className="mb-3">
                                <AvField
                                    name="name"
                                    label="Contract Name"
                                    placeholder="Contract Name"
                                    type="text"
                                    className="form-control"
                                    required
                                    disabled={showSpinner || contractIsPendingOrDone}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className={"contract-address-input mb-3 d-flex" + (contractIsPendingOrDone && " reduce-padding")}>
                                <AvField
                                    name="address"
                                    label="Smart Contract Address"
                                    placeholder="Contract Address"
                                    type="text"
                                    className="form-control"
                                    required
                                    disabled={showSpinner || contractIsPendingOrDone}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <Tooltip title="Open in blockchain explorer" placement="bottom">
                                    <i
                                        className="bx bx-link-external"
                                        onClick={() => {
                                            // open blockchain explorer in new tab
                                            const url = `${blockchainExplorers[chain.toLowerCase()]}${address}`
                                            window.open(url, "_blank", "noopener,noreferrer")
                                        }}
                                    />
                                </Tooltip>

                                {/* hide QR functionality if contract pending or done */}
                                <Tooltip title={contractIsPendingOrDone ? "" : "Input address using QR code"} placement="bottom">
                                    <span
                                        className={"qr-btn" + (contractIsPendingOrDone ? " hide-qr" : "")}
                                        onClick={() => {
                                            return contractIsPendingOrDone || setOpenQRModal(true)
                                        }}
                                    >
                                        <i className="fa fa-qrcode" />
                                    </span>
                                </Tooltip>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Select
                                    name="chain-select"
                                    label="Chain"
                                    placeholder="Select Blockchain"
                                    id="chain-select"
                                    options={chainOptions}
                                    selectedOption={chain}
                                    errorMessage="Required!"
                                    required
                                    disabled={showSpinner || contractIsPendingOrDone}
                                    handleChange={(value) => setChain(value)}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                {showABILoading && (
                                    <div className="abi-loading-wrap">
                                        <div className="abi-loading-title">ABI</div>
                                        <div className="abi-loading-card">
                                            <Spinner />
                                        </div>
                                    </div>
                                )}
                                {showABIError && (
                                    <div className="abi-loading-wrap">
                                        <div className="abi-loading-title">ABI</div>
                                        <div className="abi-loading-card">Error Fetching ABI</div>
                                    </div>
                                )}
                                {!showABILoading && !showABIError && (
                                    <AvField
                                        name="abi"
                                        label="ABI"
                                        placeholder="Enter ABI"
                                        type="textarea"
                                        className="form-control"
                                        id="abi"
                                        rows="8"
                                        required
                                        disabled={showSpinner || contractIsPendingOrDone}
                                        onChange={(e) => setAbi(e.target.value)}
                                    />
                                )}
                            </FormGroup>
                            {/* {methodsList.length > 0 && (
                                <FormGroup className="mb-3">
                                    <MethodsSelect
                                        label="Select Method"
                                        id="method-select"
                                        name="method-select"
                                        placeholder="Select Method"
                                        methods={methodsList}
                                        selectedMethods={methods}
                                        errorMessage="Required!"
                                        required
                                        disabled={showSpinner}
                                        handleChange={(value) => setMethods(value)}
                                    />
                                </FormGroup>
                            )} */}
                            <FormGroup className="mb-3">
                                <AvField
                                    name="operators"
                                    label="Operators"
                                    placeholder="Operators"
                                    type="text"
                                    className="form-control"
                                    id="operators"
                                    required
                                    disabled={showSpinner || contractIsPendingOrDone}
                                    onChange={(e) => setOperators(e.target.value)}
                                />
                            </FormGroup>
                        </ModalBody>
                    )}
                    <ModalFooter className="submit-cancel">
                        <Alert color={alertColor} toggle={disableModalButtons ? null : () => setAlertMessage("")} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>
                        <Button
                            color="primary"
                            type="submit"
                            className="save-contract-btn waves-effect"
                            disabled={
                                showSpinner ||
                                disableModalButtons ||
                                !allFormsValid([name, address, chain, abi, operators]) ||
                                noContractChange() ||
                                !isValidJSONString(abi)
                            }
                        >
                            {modalType === "Add" ? (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Saving"} txtFalse={"Save"} />
                            ) : (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Updating"} txtFalse={"Update"} />
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            outline
                            type="button"
                            className="btn-light waves-effect cancel-btn"
                            onClick={onClose}
                            disabled={showSpinner || disableModalButtons}
                        >
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
            <Modal className="scan-qr-modal" isOpen={openQRModal} onClose={onCloseQrScanModal} fade={true} centered={true}>
                <ModalHeader toggle={onCloseQrScanModal}>Scan QR</ModalHeader>
                <ModalBody>
                    {qrScanErr && <p className="text-center">The QR could not be read</p>}
                    <div className={clsx("container", qrScanErr ? "invalid" : "")}>
                        <QrReader className="qr-reader" onResult={handleScan} />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button color="secondary" className="btn-light waves-effect me-3" onClick={onCloseQrScanModal} disabled={showSpinner}>
                            Close
                        </Button>
                        <Button color="primary" type="file" className="waves-effect" onClick={handleOpenImageDialog}>
                            Select an Image
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    networkOptionStatus: state.commonReducer.networkOptionStatus
})

export default connect(mapStateToProps)(AddContractModal)
