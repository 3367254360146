import React, { useEffect, useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from "reactstrap"
import { networkIcons } from "../utils"
import clsx from "clsx"

const Select = (props) => {
    const {
        options,
        selectedOption, // chain ex. "avalanche"
        handleChange,
        placeholder,
        label,
        errorMessage = "Error",
        required = false,
        multiple = false,
        chainSelect = true,
        disabled, // boolean- if true, disable component/form box and grey the background
        ...rest
    } = props
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (selectedOption) {
            setError(false)
        }
    }, [selectedOption])

    const toggleOpen = () => {
        if (isOpen && required) {
            setError(!selectedOption)
        }
        setOpen(!isOpen)
    }

    const handleSelect = (option) => {
        if (multiple) {
            const index = selectedOption.findIndex((o) => o.value === option.value)

            if (index > -1) {
                selectedOption.splice(index, 1)
            } else {
                selectedOption.push(option)
            }
            handleChange([...selectedOption])
        } else {
            handleChange(option.value)
        }
        setError(false)
    }

    const isSelectedOption = (option) => {
        return selectedOption.findIndex((o) => o.value === option.value) > -1
    }

    return (
        <div className={clsx("custom-select", error ? "error" : "")}>
            {label && (
                <Label>
                    {required && <span className="show-required-star">*</span>}
                    {label}
                </Label>
            )}
            <Dropdown isOpen={isOpen} toggle={toggleOpen} className="d-flex select" {...rest}>
                <DropdownToggle
                    className={clsx("select-input", multiple && selectedOption.length > 0 ? "multiple" : "", disabled ? "disable-select" : "")}
                    tag="button"
                    type="button"
                    disabled={disabled}
                >
                    {!multiple ? (
                        <>
                            {selectedOption && chainSelect ? (
                                <img src={networkIcons[selectedOption]} alt={selectedOption} className="select-chain-logo" />
                            ) : null}
                            <span className="placeholder-txt">{selectedOption === "bnbchain" ? "BNBChain" : selectedOption || placeholder}</span>
                        </>
                    ) : (
                        <>
                            {selectedOption?.length === 0 ? (
                                <span className="placeholder">{placeholder}</span>
                            ) : (
                                selectedOption.map((option, idx) => (
                                    <span className="multi-option-item" key={idx}>
                                        {chainSelect && <img src={networkIcons[option.value]} alt={option.value} className="select-chain-logo" />}
                                        {option.name}
                                    </span>
                                ))
                            )}
                        </>
                    )}
                    <i className={`bx bxs-down-arrow ${isOpen ? "up" : "down"}`} />
                </DropdownToggle>
                <DropdownMenu>
                    {options.map((option, idx) => (
                        <DropdownItem
                            key={idx}
                            className="menu-item"
                            active={option.value === selectedOption}
                            onClick={() => handleSelect(option)}
                            toggle={!multiple}
                        >
                            <span>{option.name}</span>
                            {multiple ? (
                                <>{isSelectedOption(option) && <i className="bx bx-check check-icon" />}</>
                            ) : (
                                <>{option.value === selectedOption && <i className="bx bx-check check-icon" />}</>
                            )}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            <span className="error-msg">{errorMessage}</span>
        </div>
    )
}

export default Select
