import { combineReducers } from "redux"

import commonReducer from "./common/commonReducer"
import nftReducer from "./nft/nftReducer"
import tradingReducer from "./trading/tradingReducer"

const rootReducer = combineReducers({
    commonReducer,
    nftReducer,
    tradingReducer
})

export default rootReducer
