import { ConnectorConfigError } from "../error"

export default async function init() {
    const { PortisConnector } = await import("@web3-react/portis-connector")
    return {
        web3ReactConnector({ chainId, dAppId }) {
            if (!dAppId) {
                throw new ConnectorConfigError("The Portis connector requires dAppId to be set.")
            }
            return new PortisConnector({ dAppId, networks: [chainId] })
        }
    }
}
