import React, { useState, useEffect } from "react"
import ido from "idexo-sdk"
import jwt from "jsonwebtoken"
import { formatAddress } from "../../utils"
import { useToast } from "../../hooks/toast"
import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import { Card, CardBody, FormGroup, Table } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import "./api.scss"

const Api = () => {
    const toast = useToast()
    const [hideKey, setHideKey] = useState(true)
    const [showLoading, setShowLoading] = useState(true)
    const [historyData, setHistoryData] = useState(null)

    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)

    const showHide = () => {
        setHideKey(!hideKey)
    }

    const clipboard = () => {
        navigator.clipboard.writeText(decoded.api_key)
        toast.addSuccess(`Apikey copied to clipboard.`)
    }

    useEffect(() => {
        async function getTxs() {
            if (decoded.api_key) {
                let res = await ido.Utils.getTransactions(decoded.api_key, null, 0, new Date().getTime())
                if (!res.data.error) setHistoryData(res.data.data)
            }
            setShowLoading(false)
        }
        getTxs()
    }, [decoded.api_key])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <AvForm className="profile-form" initialValues={{}}>
                        <FormGroup className="mb-3">
                            <AvField
                                label="Your ApiKey for everything Idexo..."
                                type={hideKey ? "password" : "text"}
                                className="form-control"
                                id="apikey"
                                name="apikey"
                                value={decoded.api_key}
                                disabled={true}
                            />
                            <div className="button-box">
                                <div className="button__item" onClick={clipboard}>
                                    <i className="bx bx-copy font-size-18 align-middle mr-1" /> <span>Copy Key</span>
                                </div>
                                <div className="button__item" onClick={showHide}>
                                    <i className="bx bx-show font-size-18 align-middle mr-1" /> <span>{hideKey ? "Show" : "Hide"}</span>
                                </div>
                            </div>
                        </FormGroup>
                    </AvForm>
                    <div className="divider" />
                    <h2>History of API transactions</h2>
                    <div className="team-table">
                        <Table className="align-middle mb-0 table-nowrap">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Network</th>
                                    <th scope="col">Group</th>
                                    <th scope="col">Step</th>
                                    <th scope="col">inserted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showLoading || !historyData ? null : (
                                    <React.Fragment>
                                        {historyData.map((h) => (
                                            <tr key={h?.tx_hash?.S}>
                                                <td> {h?.function_name?.S}</td>
                                                <td>{formatAddress(h?.address?.S)}</td>
                                                <td>{h?.network?.S}</td>
                                                <td>{h?.tx_group?.S}</td>
                                                <td>{h?.step?.S}</td>
                                                <td>{h?.inserted?.N}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                )}
                            </tbody>
                        </Table>
                        {showLoading && <LoadingCard loadingTxt={"history"} backgroundColor="grey" />}
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Api
