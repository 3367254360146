import Web3 from "web3"
import imageCompression from "browser-image-compression"
import AddressIcon from "../src/assets/icons/address-icon.png"
import EthereumIcon from "../src/assets/images/crypto/icons/ethereum.webp"
import ArbitrumIcon from "../src/assets/images/crypto/icons/arbitrum.png"
import ArbitrumNovaIcon from "../src/assets/images/crypto/icons/arbitrumnova.png"
import AvalancheIcon from "../src/assets/images/crypto/icons/avalanche.webp"
import BaseIcon from "../src/assets/images/crypto/icons/base.png"
import MaticIcon from "../src/assets/images/crypto/icons/matic.webp"
import OKCIcon from "../src/assets/images/crypto/icons/okc.png"
import BinanceIcon from "../src/assets/images/crypto/icons/binance.png"
import FantomIcon from "../src/assets/images/crypto/icons/fantom.png"
import DogeIcon from "../src/assets/images/crypto/icons/dogechain.png"
import ArweaveIcon from "../src/assets/images/crypto/icons/arweave-logo.png"
import FilecoinIcon from "../src/assets/images/crypto/icons/filecoin-logo.png"
import SolanaIcon from "../src/assets/images/crypto/icons/solana.png"
import ZkSyncIcon from "../src/assets/images/crypto/icons/zksync.jpg"
import { KNOWN_CHAIN_CURRENCY } from "./libs/useMetaMaskWallet/utils"

const convertTokenToNFTDetails = (data) => ({
    id: data.id["S"],
    author: data.author["S"],
    source: data.source["S"],
    step: data.step["S"],
    wallet: data.wallet["S"],
    token_id: data.token_id["N"],
    inserted: data.inserted["N"]
})

const convertTradingTokenToNFTDetails = (data) => ({
    id: data.id["S"],
    author: data.author["S"],
    source: data.source["S"],
    step: data.step["S"],
    wallet: data.wallet["S"],
    token_id: data.token_id["N"],
    inserted: data.inserted["N"],
    updated: data.updated["N"],
    price: data.price["N"],
    token_uri: data.token_uri && data.token_uri["S"]
})

const convertDataToVotingDetails = (data) => ({
    winning_option_id: data.winning_option_id["N"],
    creator: data.creator["S"],
    contract: data.contract["S"],
    inserted: data.inserted["N"],
    status: data.status["S"],
    voters: data.voters ? data.voters["SS"] : [],
    choices: data.options["SS"].map((choice, index) => ({ voteId: index, description: choice })),
    start_time: data.start_time["N"],
    description: data.description["S"],
    end_time: data.end_time["N"],
    id: data.id["S"],
    pollId: data.poll_id["N"],
    minimum_stake_time: data.minimum_stake_time["N"]
})

const convertDataToClientDetails = (data) => ({
    id: data.id.S,
    email: data.email.S,
    company: data.company?.S,
    slug: data.slug?.S,
    domain: data.domain?.S
})

const convertDataToAddressDetails = (data) => ({
    id: data.id.S,
    user_id: data.user_id.S,
    name: data.name?.S,
    address: data.address?.S,
    chains: data.chains?.S,
    email: data.email?.S,
    nickname: data.nickname?.S,
    inserted: data.inserted?.N
})

const timeToEpoch = (time) => {
    return Math.floor(time / 1000)
}

// "0xABcd1234567" => "0xABcd...467"
const formatAddress = (address) => {
    if (!address) return ""
    if (address && address.length > 20) {
        return address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length)
    }
    return address
}

// val (str) = user's wallet balance in WEI
// wallet = wallet object
const formatBalance = (val, wallet) => {
    // returns string, rounds to 2 decimal places
    const float_val = val / 1000000000000000000 // convert wei to ETH
    let currency = "ETH"
    if (wallet) {
        // currency = KNOWN_CHAIN_CURRENCY[wallet.ethereum.networkVersion] // .networkVersion is DEPRECATED
        currency = KNOWN_CHAIN_CURRENCY[wallet.ethereum?.network?.chainId || wallet.chainId]
    }
    return float_val.toFixed(2) + ` ${currency}`
}

// adds commas/rounds to given decimal. Example:  "1234.213" => "1,234.21"
const formatNumber = (str, x = 2) => {
    var parts = str.toString().split(".") // convert str to string and create array of string(s)
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") // insert commas on left part
    if (!parts[1]) return parts[0] // if no input has no decimal, exit
    parts[1] = parts[1].substring(0, x).replace(/0+$/g, "") // cut off decimal places to x places
    if (!parts[1]) return parts[0]
    return parts.join(".")
}

const avgGasPrice = async () => {
    let gas = 95000000000

    // OLD API (NOT WORKING)
    // add average eth gas price to state after other async calls incase we error out
    // const avgGasPriceResponse = await fetch(`https://ethgasstation.info/api/ethgasAPI.json?`)
    // const avgGasPriceObj = await avgGasPriceResponse.json()
    // setAvgGasPrice(avgGasPriceObj.average * 100000000) // convert to wei, add 8 zeros

    try {
        // TO DO: Remove API Key
        const avgGasPriceResponse = await fetch(
            `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=VFAZT67RCH5W5U6VJXIV1SBZTF3ZC362MZ`
        )
        const avgGasPriceObj = await avgGasPriceResponse.json()
        console.log("avgGasPriceObj", avgGasPriceObj.result)
        gas = avgGasPriceObj.result["SafeGasPrice"] * 100000000 || 95000000000 // convert to wei, add 8 zeros
    } catch (error) {
        console.log(error)
    }

    return gas
}

const fetchNFTTokens = (items, chain) => {
    let tokensCount = 0
    for (const i of items) {
        const item = convertTokenToNFTDetails(i)
        if ((item.source.includes("avalanche") && chain === 43114) || (item.source.includes("polygon") && chain === 137)) {
            tokensCount++
        }
    }
    return tokensCount
}

// returns array [start date, end date]
const setMinPollDateRange = (days) => {
    let start = new Date()
    let startCopy = new Date(start.getTime()) // make copy because .setDate modifies dat obj

    // get date 7 days from start (in milliseconds from epoch)
    let end = startCopy.setDate(startCopy.getDate() + days)

    // convert milliseconds to JS date obj
    end = new Date(end)

    return [start, end]
}

// ABI service

const web3 = new Web3()
// const web3 = new Web3(window.ethereum || window.web3)

// "a" => false
const isValidAddress = (address) => {
    return web3.utils.isAddress(address)
}

const getMethodSignature = ({ inputs, name }) => {
    const params = inputs?.map((x) => x.type).join(",")
    return `${name}(${params})`
}

const getSignatureHash = (signature) => {
    return web3.utils.keccak256(signature).toString()
}

const getMethodSignatureAndSignatureHash = (method) => {
    const methodSignature = getMethodSignature(method)
    const signatureHash = getSignatureHash(methodSignature)
    return { methodSignature, signatureHash }
}

const isAllowedMethod = ({ name, type }) => {
    return type === "function" && !!name
}

const getMethodAction = ({ stateMutability }) => {
    if (!stateMutability) {
        return "write"
    }

    return ["view", "pure"].includes(stateMutability) ? "read" : "write"
}

const extractUsefulMethods = (abi) => {
    const allowedAbiItems = abi.filter(isAllowedMethod)

    return allowedAbiItems
        .map((method) => ({
            action: getMethodAction(method),
            ...getMethodSignatureAndSignatureHash(method),
            ...method
        }))
        .sort(({ name: a }, { name: b }) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1
        })
}

// (blob data type) => promise => on resolve returns JS Object { }
export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () =>
            resolve(
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    base64: reader.result.split(",")[1]
                })
            )
        reader.onerror = (error) => reject(error)
    })

// (FileList blob object) => string (base64)
const getBase64 = async (fileList) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(fileList)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

// used in Projects.js Page for sorting (in place) array of project objects (recent first) by inserted time
const sortProjects = (projects) => {
    return projects.sort((a, b) => {
        // a and b are objects (project)
        return Number(a.inserted.N) - Number(b.inserted.N)
    })
}

// used in AddContractModal.js when editing contract from project view modules table
// - find module in modules array (by inserted time) and replace that module
// - mutates input modules array
// - returns updated modules array
// ex. modules === [
//                  { "M": { "inserted":{"N": "90123483"}, "type":{"S":"nft_marketing"}, "module_name":{"S":"first campaign"} } },
//                  { "M": { "inserted":{"N": "90176355"}, "type":{"S":"smart_contract"}, "module_name":{"S":"custom contract"} } }
//                ]
const findAndReplaceModule = (modules, newModule) => {
    // newModule === { inserted: contract.inserted, name, address, chain, abi, methods }
    try {
        const inserted = new Date().getTime() // milliseconds since 1 January 1970 UTC
        for (let i = 0; i < modules.length; i++) {
            let module = modules[i]
            let insertedTime = module.M.inserted.N // "90176355"
            if (insertedTime === newModule.inserted) {
                console.log("insertedTime", insertedTime)
                modules[i] = {
                    M: {
                        inserted: { N: String(inserted) }, // update inserted time
                        type: { S: "smart contract" },
                        module_name: { S: newModule.name },
                        contract_name: { S: newModule.name },
                        address: { S: newModule.address },
                        chain: { S: newModule.chain },
                        abi: { S: newModule.abi },
                        methods: { L: newModule.methods }
                    }
                }
                return modules
            }
        }
    } catch (err) {
        console.log("Error with findAndReplaceModule util function", err)
    }
}

// TO DO: refactor findAndReplaceModule() to work for below, and retest smart contract page and staking page
// used in Staking page/StakingCard
// - find module in modules array (by inserted time) and replace that module
// - mutates input modules array
// - returns updated modules array
// ex. modules === [
//                  { "M": { "inserted":{"N": "90123483"}, "type":{"S":"nft_marketing"}, "module_name":{"S":"first campaign"} } },
//                  { "M": { "inserted":{"N": "90176355"}, "type":{"S":"smart_contract"}, "module_name":{"S":"custom contract"} } }
//                  { "M": { "inserted":{"N": "16547824"}, "type":{"S":"token staking"}, "module_name":{"S":"staking test1"} } }
//                ]
const updateModules = (modules, newModule) => {
    for (let i = 0; i < modules.length; i++) {
        if (modules[i].M.inserted.N === newModule.M.inserted.N) {
            for (const prop in newModule.M) {
                modules[i].M[prop] = newModule.M[prop]
            }
            return modules
        }
    }
}

const getTwoDigits = (val) => (val < 10 ? `0${val}` : val)

const formattedDateString = (date) => {
    const year = date.getFullYear()
    const month = getTwoDigits(date.getMonth() + 1)
    const day = getTwoDigits(date.getDate())

    return `${day}.${month}.${year}`
}

// used in smart contracts page for deleting a specific contract (which is actually embedded in a module)
// - inputs:
//      - 1 array of objects (each representing a module with 1 smart contract)
//      - 2 object with data about smart contract we want to remove
// - returns: array of objects
// - mutates array
const deleteModule = (modules, contract) => {
    return modules.filter((module, idx) => {
        // if return value true, that means keep module in array
        return module.M.inserted.N !== contract.inserted
        // inserted time acts like a module id (must be unique)
    })
}

// used in NFTMarketing Page, NFT Collection Page, User Component...
// returns true if form is NOT empty
const isValidForm = (form) => {
    if (!form) return false
    form = form.replace(/\s/g, "") // removes empty spaces
    return !!form
}

const allFormsValid = (forms) => {
    if (!Array.isArray(forms)) return false
    if (forms) return forms.every((form) => isValidForm(form))
}

const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== "string") return ""
    return string.charAt(0).toUpperCase() + string.slice(1)
}

// used in NFTMarketing page, ProjectView
// ex. NFT Collection, Twitter, Telegram, or Verification Site
const determineType = (item) => {
    if (item?.collection_name) {
        return "NFT Collection"
    } else if (item?.twitter_launch_url) {
        return "Twitter"
    } else if (item?.telegram_bot_name) {
        return "Telegram"
    } else if (item?.subdomain) {
        return "Verification Site"
    } else if (item?.type?.S === "nft surveys") {
        return "survey"
    }
}

const determineDetails = (item) => {
    if (item.collection_name) {
        return item.collection_name.S
    } else if (item.twitter_mint_command) {
        return item.twitter_mint_command.S
    } else if (item.telegram_bot_name) {
        return item.telegram_bot_name.S
    } else if (item.subdomain) {
        return item.subdomain.S
    }
}

const isValidImage = (type, acceptGif = false) => {
    if (!type || typeof type !== "string") return false
    const validTypes = {
        "image/jpeg": true,
        "image/jpg": true,
        "image/png": true,
        "image/gif": acceptGif
    }
    return validTypes[type] !== undefined
}

const isValidFileType = (type) => {
    if (!type || typeof type !== "string") return false
    const validTypes = {
        "image/jpeg": true,
        "image/jpg": true,
        "image/png": true,
        "image/gif": true,
        "video/mp4": true,
        "video/mov": true,
        "video/quicktime": true
    }
    return validTypes[type] !== undefined
}

const isStringBase64 = (str) => {
    if (!str) return false
    return str.length % 4 === 0 && /^[A-Za-z0-9+/]+[=]{0,2}$/.test(str)
}

// returns compressed file
const compressFile = async (imageBlob) => {
    const options = {
        maxSizeMB: 0.4, // 400kb
        // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: true // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    }

    try {
        return await imageCompression(imageBlob, options)
    } catch (err) {
        console.log(err)
    }
}

const base64ToFile = async (imgObj, fileName) => {
    const res = await fetch(`data:image/jpeg;base64,${imgObj.imgData}`) // response object. imgObj.imgData === base64 string
    const blob = await res.blob() // blob datatype
    const file = new File([blob], fileName, { type: "image/jpeg" })
    file.base64 = imgObj.imgData
    return file
}

// returns string referring to when image was inserted into s3 bucket
const getImageInserted = (imgKey) => {
    //=> '1651092441782'
    if (!imgKey) return null
    // imgKey example === "abc-12345/module-1651080484969/nftcollection-1651080539593/nft-1651092441782"
    return imgKey.split("/")[3].split("-")[1]
}

// returns array of objects sorted by inserted time (latest time last in array)- used in company/settings component
const sortUsersByInserted = (users) => {
    let usersCopy = users.slice() // shallow copy
    return usersCopy.sort((userA, userB) => {
        return userA.inserted.N - userB.inserted.N
    })
}

// json must be an array of objects with the following pattern: [{ trait_type: "", value: "" }, ...]
const isValidJSON = (attributesObj) => {
    if (!Array.isArray(attributesObj)) return false // check if json is array

    for (let i = 0; i < attributesObj.length; i++) {
        let attribute = attributesObj[i]
        // check if all elements in array are objects (but not arrays or null)
        if (typeof attribute !== "object" || Array.isArray(attribute) || attribute === null) return false

        // check if each object in array only has 2 keys
        let keys = Object.keys(attribute)
        // do attribute values have to be strings?
        if (keys.length !== 2) return false

        // check if each object has a key called "trait_type" & "value"
        if (!keys.includes("trait_type") || !keys.includes("value")) return false
    }

    return true
}

// 'true' => false
// '123' => false
// 'null' => false
// 'apple' => false
function isValidJSONString(jsonStr) {
    try {
        var o = JSON.parse(jsonStr)

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return !!o
        }
    } catch (e) {}

    return false
}

const getBase64Image = async (url) => {
    try {
        const response = await fetch(url, { method: "GET" })
        const blob = await response.blob()
        const reader = new FileReader()

        await new Promise((resolve, reject) => {
            reader.onload = resolve
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
        return reader.result.replace(/^data:.+;base64,/, "")
    } catch (err) {
        console.log(err)
    }
}

// returns true if every nft has an image uploaded
const everyNftHasImage = (nfts) => {
    return nfts.every((nft) => {
        return nft.contentLength > 0
    })
}

// returns true if all nfts have been preminted or minted
const allNftsMintedOrPreminted = (nfts) => {
    return nfts.every((nft) => {
        return nft.imgMetaData.tx_status === "false" || nft.imgMetaData.tx_status === "done" || nft.imgMetaData.tx_status === "premint"
    })
}

const blockchainExplorers = {
    fantom: "https://ftmscan.com/address/",
    ethereum: "https://etherscan.io/address/",
    avalanche: "https://avascan.info/blockchain/c/address/",
    binance: "https://bscxplorer.com/address/", // for projects created before 9/18/22
    bnbpremium: "https://bscxplorer.com/address/", // for survey contracts
    bnbchain: "https://bscxplorer.com/address/",
    okc: "https://exchainrpc.okex.org",
    arbitrum: "https://arbiscan.io/",
    base: "https://basescan.org",
    arbitrumnova: "https://nova.arbiscan.io/",
    polygon: "https://polygonscan.com/address/",
    dogechain: "https://explorer.dogechain.dog/address/",
    zksync: "",
    zksynctest: ""
}

// NOTE* update this if we add more chains for Multi.mintNFTFWithImage
const chainURLs = {
    arbitrum: "https://arbitrum.idexo.io",
    arweave: "https://ziparweave.idexo.io",
    avalanche: "https://avalanche.idexo.io",
    base: "https://base.idexo.io",
    bnbchain: "https://mainnetbsc.idexo.io",
    dogechain: "https://dogechain.idexo.io",
    ethereum: "https://mainneteth.idexo.io",
    fantom: "https://fantom.idexo.io",
    filecoin: "https://filecoin.idexo.io",
    okc: "https://okc.idexo.io",
    polygon: "https://polygon.idexo.io",
    solana: "https://solana.idexo.io",
    utilsUrl: "https://transactions.idexo.io",
    zksynctest: "https://zksynctest.idexo.io"
}

// TO DO: use this on other pages instead of redefining everywhere
// TO DO: stick to uniform lowercase chain name convention
const networkIcons = {
    // capitalized- needed for any items saved to nftmarketing table before 9/19/22 bnb name change
    Ethereum: EthereumIcon,
    Binance: BinanceIcon,
    binance: BinanceIcon,
    Base: BaseIcon,
    BSC: BinanceIcon,
    Polygon: MaticIcon,
    Matic: MaticIcon,
    Avalanche: AvalancheIcon,
    OKC: OKCIcon,
    Fantom: FantomIcon,
    Mumbai: MaticIcon,
    Dogechain: DogeIcon,
    ZkSync: ZkSyncIcon,
    ZkSyncTest: ZkSyncIcon,

    ethereum: EthereumIcon,
    base: BaseIcon,
    bnbchain: BinanceIcon,
    polygon: MaticIcon,
    matic: MaticIcon,
    mumbai: MaticIcon,
    arbitrum: ArbitrumIcon,
    arbitrumnova: ArbitrumNovaIcon,
    avalanche: AvalancheIcon,
    fantom: FantomIcon,
    okc: OKCIcon,
    dogechain: DogeIcon,
    arweave: ArweaveIcon,
    filecoin: FilecoinIcon,
    solana: SolanaIcon,
    zksync: ZkSyncIcon,
    zksynctest: ZkSyncIcon
}

// (1488) => "1,488"
function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// "1234" => true
// "1234.00" => true
// "  1234 " => true
// 1234 => false
// "12 34" => false
// "12b3" => false
// "abc" => false
// undefined => false
// null => false
function isStrNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
}

// functionName ex. == "createRoyaltyNFT", "createCollection"
function getTxGroup(functionName) {
    if (!functionName) return ""
    let groups = {
        createCollection: "collection",
        createRoyaltyNFT: "royalty",
        createSBT: "soulbound",
        createSimpleSurvey: "survey",
        createToken: "token",
        createVesting: "vesting"
        // TO DO: do this for staking & marketplace
        // NOTE* survey does not follow the same pattern where the function name is less specific than the transaction type!
    }
    return groups[functionName]
}

// functionName ex. == "createRoyaltyNFT", "createCollection"
// args ex. == "capped,test 1,T1,,0xe2fda73b817036ca5003e8e5080ef21e7b60c858,40,4"
function getContractType(functionName, args) {
    if (!functionName || !args) return ""
    return args.split(",")[0] // 1st argument passed into constructor of deployed contract
}

// functionName ex. == "createRoyaltyNFT", "createCollection"
// args ex. == "capped,test 1,T1,,0xe2fda73b817036ca5003e8e5080ef21e7b60c858,40,4"
function getTransactionType(functionName, args) {
    if (!functionName || !args) return ""
    const contractType = args.split(",")[0]
    return functionName + capitalizeFirstLetter(contractType)
    // NOTE* royalty group does not follow this naming pattern :(
}

// PAID attribute for jwt token
const PAID_PLANS = {
    0: "apiKey", // (defualt for new users) free
    1: "reserved", // unlimited access (for internal use)
    2: "trial",
    3: "starter",
    4: "professional",
    5: "enterprise",
    6: "ultimate"
}

// returns true if input planNum is above the user's current plan type (userPaidNum- jwt paid)
// Ex.
// planNum == "2"
// userPaidNum == "0"
// => true
const isPlanAboveUser = (planNum, userPaidNum) => {
    return Number(planNum) > Number(userPaidNum)
}

export {
    convertTokenToNFTDetails,
    convertTradingTokenToNFTDetails,
    convertDataToVotingDetails,
    formatAddress,
    formatBalance,
    formatNumber,
    avgGasPrice,
    fetchNFTTokens,
    timeToEpoch,
    setMinPollDateRange,
    isValidAddress,
    extractUsefulMethods,
    getBase64,
    sortProjects,
    findAndReplaceModule,
    updateModules,
    convertDataToClientDetails,
    convertDataToAddressDetails,
    formattedDateString,
    deleteModule,
    isValidForm,
    allFormsValid,
    capitalizeFirstLetter,
    determineType,
    determineDetails,
    isValidImage,
    isValidFileType,
    compressFile,
    isStringBase64,
    base64ToFile,
    getImageInserted,
    sortUsersByInserted,
    isValidJSON,
    getBase64Image,
    everyNftHasImage,
    allNftsMintedOrPreminted,
    blockchainExplorers,
    chainURLs,
    networkIcons,
    AddressIcon,
    numberWithCommas,
    isValidJSONString,
    isStrNumeric,
    getTxGroup,
    getContractType,
    getTransactionType,
    PAID_PLANS,
    isPlanAboveUser
}
