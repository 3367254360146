import React, { Component, useState, useEffect } from "react"
import { BrowserRouter as Router, Route, useLocation, Switch } from "react-router-dom"
import { Redirect } from "react-router-dom"
import jwt from "jsonwebtoken"
import * as dotenv from "dotenv"

import { IntercomProvider, useIntercom } from "react-use-intercom"

import AuthdLayout from "./Layouts/AuthdLayout/"
import NonAuthdLayout from "./Layouts/NonAuthdLayout/"
import "./assets/scss/theme.scss"

import Welcome from "./Pages/Welcome"
import Register from "./Pages/Register"
import Invite from "./Pages/Invite"
import RegisterNew from "./Pages/RegisterNew"
import Login from "./Pages/Login"
import Logout from "./Pages/Logout"
import ForgotPassword from "./Pages/ForgotPassword"
import Settings from "./Pages/Settings"
import Zapier from "./Pages/Zapier"

import Admin from "./Pages/Admin"
import Billing from "./Pages/Billing"
import NFTMarketing from "./Pages/NFTMarketing"
import IntegrateNFTS from "./Pages/IntegrateNFTS"
import SmartContract from "./Pages/SmartContract"
import Projects from "./Pages/Projects"
import Clients from "./Pages/Clients"
import Addresses from "./Pages/Addresses"
import Vesting from "./Pages/Vesting"
import Pricing from "./Pages/Pricing"
import TrialOrPaid from "./Pages/TrialOrPaid"

dotenv.config()

const AppRoute = ({ Refresh, Component, Layout, ...rest }) => {
    const location = useLocation()
    const { shutdown } = useIntercom()
    useEffect(() => {
        if (
            location.pathname === "/login" ||
            location.pathname === "/register" ||
            location.pathname === "/invite" ||
            location.pathname === "/forgot-password" ||
            location.pathname === "/logout"
        ) {
            shutdown()
        }
    }, [location, shutdown])
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}

const ProtectedRoute = ({ Refresh, Level, Paid, Component, ...rest }) => {
    const [auth, setAuth] = useState(10)
    const [paid, setPaid] = useState(0)
    const [queryParam, setQueryParam] = useState("")
    const [search, setSearch] = useState("")
    const [hash, setHash] = useState("")
    const location = useLocation()
    const { update } = useIntercom()

    useEffect(() => {
        setAuth(0)
        setQueryParam(location.pathname.slice(1))
        setSearch(location.search)
        setHash(location.hash)
        const authLevel = { new: 1, team: 1, tester: 2, developer: 3, admin: 5 }
        if (localStorage.jwtToken) {
            try {
                var decoded = jwt.decode(localStorage.jwtToken)
                if (new Date().getTime() > decoded.exp * 1000) {
                    localStorage.removeItem("jwtToken")
                    localStorage.removeItem("jwtTenant")
                    localStorage.removeItem("user_tenants")
                    localStorage.removeItem("selected_tenant")
                } else {
                    update({ email: decoded.email, userId: decoded.user_id })
                    setAuth(authLevel[decoded.type])

                    if (localStorage.jwtTenant) {
                        let decoded = jwt.decode(localStorage.jwtTenant)
                        console.log("tenant_decoded", decoded)
                        setPaid(Number(decoded.paid))
                    } else {
                        setPaid(Number(decoded.paid))
                    }
                }
            } catch (e) {
                console.log("error", e)
            }
        }
    }, [location.pathname, location.search, location.hash, update])

    const getUserTenants = async () => {
        if (localStorage.jwtToken) {
            try {
                var decoded = jwt.decode(localStorage.jwtToken)
                if (new Date().getTime() > decoded.exp * 1000) {
                    localStorage.removeItem("jwtToken")
                    localStorage.removeItem("jwtTenant")
                    localStorage.removeItem("user_tenants")
                    localStorage.removeItem("selected_tenant")
                } else {
                    // const hasApiKey = decoded?.api_key?.length > 6
                    // console.log(hasApiKey)
                    let teamURI = `https://team.idexo.io/`
                    fetch(teamURI, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.jwtToken
                        },
                        json: true
                    })
                        .then((res) => res.json())
                        .then(async (data) => {
                            console.log(data)
                            if (data?.error || data?.data?.user_tenants_new?.error) {
                                this.setState({ alertMessage: "Error: " + data?.error || data?.data?.user_tenants_new?.error, showSpinner: false })
                            } else if (data.data?.user_tenants_new?.data?.length > 0) {
                                localStorage.setItem("user_tenants", JSON.stringify(data?.data?.user_tenants_new?.data, null, 2))
                                // for now select the first tenant
                                const selected = data?.data?.user_tenants_new?.data?.[0]?.tenant_id
                                localStorage.setItem("selected_tenant", selected)
                                localStorage.setItem("jwtTenant", data?.data?.user_tenants_new?.data?.[0]?.jwt)
                            } else {
                                localStorage.removeItem("user_tenants")
                                localStorage.removeItem("selected_tenant")
                                localStorage.removeItem("jwtTenant")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            } catch {}
        }
    }

    useEffect(() => {
        getUserTenants().then((_) => {})
        return () => {
            //
        }
    }, [])

    return (
        <Route
            {...rest}
            render={(props) =>
                auth >= Level ? (
                    // 0 = apiKey, 1 = reserved (see we used it before - give it unlimited access for now), 2 = trial, 3 = starter plan, 4 = professional plan, 5 = enterprise plan, 6 = ultimate plan
                    <AuthdLayout Refresh={Refresh}>
                        {Paid.length === 0 || Paid.includes(paid) ? <Component {...props} /> : <TrialOrPaid />}
                    </AuthdLayout>
                ) : queryParam !== "" ? (
                    <Redirect to={{ pathname: "/login", state: { referrer: queryParam, search, hash } }} />
                ) : (
                    <Redirect to={{ pathname: "/login" }} />
                )
            }
        />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = { authenticated: true, user: null }
    }

    componentDidMount() {
        if (localStorage.jwtToken) {
            try {
                let decoded = jwt.decode(localStorage.jwtToken)
                this.setState({ user: decoded })
            } catch {}
        }
    }

    render() {
        return (
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot>
                <Router>
                    <Switch>
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path="/" Component={Welcome} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/settings`} Component={Settings} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/zapier`} Component={Zapier} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/admin`} Component={Admin} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/billing`} Component={Billing} />
                        {/* all plans 0-6 can access projects page now */}
                        <ProtectedRoute Refresh={true} Level={1} Paid={[0, 1, 2, 3, 4, 5, 6]} exact path={`/projects`} Component={Projects} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/nftmarketing`} Component={NFTMarketing} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/integratenfts`} Component={IntegrateNFTS} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/smartcontract`} Component={SmartContract} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/clients`} Component={Clients} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/addresses`} Component={Addresses} />
                        <ProtectedRoute Refresh={true} Level={1} Paid={[]} exact path={`/vesting`} Component={Vesting} />

                        <AppRoute exact path="/" Layout={NonAuthdLayout} Component={Login} />
                        <AppRoute exact path="/register" Layout={NonAuthdLayout} Component={Register} />
                        <Route exact path="/freetrial">
                            <RegisterNew />
                        </Route>
                        <AppRoute exact path="/logout" Layout={NonAuthdLayout} Component={Logout} />
                        <AppRoute path="/login" Layout={NonAuthdLayout} Component={Login} />
                        <AppRoute exact path="/forgot-password" Layout={NonAuthdLayout} Component={ForgotPassword} />
                        <AppRoute exact path="/invite" Layout={NonAuthdLayout} Component={Invite} />
                        <Route exact path="/pricing">
                            <Pricing />
                        </Route>

                        {/* redirect to "/" if url not found */}
                        <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </Router>
            </IntercomProvider>
        )
    }
}

export default App
