import React from "react"
import { Card, CardBody, CardTitle, CardText } from "reactstrap"
import LoadingCard from "../../Components/LoadingCard/LoadingOrEmptyCard"
import "./ProgressCard.scss"
import SpinnerWrap from "../SpinnerWrap"
import AccordionTable from "../AccordionTable/AccordionTable"

const ProgressCard = React.memo(
    ({
        // states/useStates from parent
        showProgressSpinner,
        showSpinner,
        campaignData,
        showDetailsButtons,
        setShowDetailsButtons,
        setImageModalMessage,
        imageData,
        showViewImageSpinner,
        progressAlertMessage,
        loadingErrorTxt = "campaign data", // part of the text that shows when this component is loading or there's an error

        // helper functions
        getImage,
        handleClickAddNFT
    }) => {
        const handleClickViewImage = async () => {
            setImageModalMessage("")
            if (!imageData) {
                await getImage()
            } else {
                setImageModalMessage(<img alt="imgdata" src={`data:image/jpeg;base64,${imageData}`} />)
            }
        }

        // this is what shows when table row is expanded/dropdown/action button is clicked on
        const renderViewDetail = (item, type) => {
            switch (type) {
                case "NFT Collection":
                    return (
                        <div className="details">
                            <div>
                                <span className="label">Chain:</span> <span className="value">{item.chain?.S}</span>{" "}
                            </div>
                            <div>
                                <span className="label">Description:</span> <span className="value">{item.description?.S}</span>
                            </div>
                            <div>
                                <span className="label">Cap:</span> <span className="value">{item.cap?.S}</span>
                            </div>
                            <div>
                                <span className="label">Symbol:</span> <span className="value">{item.symbol?.S}</span>
                            </div>
                            <div>
                                <span className="label">Image:</span>
                                <span className="value view-image" onClick={handleClickViewImage}>
                                    <SpinnerWrap showSpinner={showViewImageSpinner} txtTrue={"Loading"} txtFalse={"View Image"} />
                                </span>
                            </div>
                        </div>
                    )

                case "Twitter":
                    return (
                        <div className="details">
                            <span className="label">Twitter Launch Url:</span> <span className="value">{item.twitter_launch_url?.S}</span>
                        </div>
                    )

                case "Telegram":
                    return (
                        <div className="details">
                            <div>
                                <span className="label">Telegram Group Name:</span> <span className="value">{item?.telegram_group_name?.S}</span>
                            </div>
                            <div>
                                <span className="label">Telegram Bot Name:</span> <span className="value">{item?.telegram_bot_name?.S}</span>
                            </div>
                            <div>
                                <span className="label">Telegram Bot Command:</span> <span className="value">{item?.telegram_bot_command?.S}</span>
                            </div>
                        </div>
                    )

                case "Verification Site":
                    return (
                        <div className="details">
                            <span className="label">NFT Verification Subdomain:</span> <span className="value">{item.subdomain?.S}</span>
                        </div>
                    )
                default:
                    return <></>
            }
        }

        const toggleShowDetailButton = (idx) => {
            setShowDetailsButtons((prevState) => {
                return { ...prevState, [idx]: !prevState[idx] }
            })
        }

        return (
            <Card className="progress-card">
                <CardBody>
                    <CardTitle className="h3">Progress</CardTitle>
                    <CardText className="P font-size-12 text-uppercase">Your saved history</CardText>
                    <div className="overflow-auto">
                        <AccordionTable
                            tableHeaders={["TYPE", "STATUS", "ACTION"]}
                            headerClassNames={[]}
                            // if campaignData?.Items doesn't exist, that means we are rendering this from the ERC20Creator Page
                            tableRows={campaignData?.Items || (Object.keys(campaignData || {})?.length ? [campaignData] : [])}
                            tableRowClassNames={[]}
                            showDetailsButtons={showDetailsButtons}
                            setShowDetailsButtons={setShowDetailsButtons}
                            toggleShowDetailButton={toggleShowDetailButton}
                            handleClickAdd={handleClickAddNFT}
                            renderViewDetail={renderViewDetail}
                            showProgressSpinner={showProgressSpinner}
                            disableAllButtons={showSpinner}
                        />
                        {showProgressSpinner && !progressAlertMessage && <LoadingCard loadingTxt={loadingErrorTxt} />}
                        {progressAlertMessage && <LoadingCard errorTxt={loadingErrorTxt} alertMessage={progressAlertMessage} />}
                    </div>
                </CardBody>
            </Card>
        )
    }
)

export default ProgressCard
