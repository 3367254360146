import React from "react"
import "./PaginationArrows.scss"

const PaginationArrows = (props) => {
    const { currentPage, clickPreviousPage, clickNextPage, clickPageNum, pages, lastEvaluatedKey } = props
    // currentPage == index of pages array- number referring to what page the user is on
    // pages == [ [{}, {},...], [{}] ] array of arrays- each subarray has objects

    // const maxNumPages = 4
    // let slidingPages = pages
    // let currPageSliding = currentPage
    // if (pages.length > maxNumPages) {
    //     currPageSliding = currentPage - 1
    //     let l = currentPage - 1
    //     let r = currentPage
    //     slidingPages = []
    //     while (slidingPages.length < maxNumPages) {
    //         if (pages[r]) slidingPages.push(pages[r++]) // add current page and increment right pointer
    //         if (pages[l]) slidingPages.push(pages[l--]) // add previous page and decrement left pointer
    //     }
    //     console.log("slidingPages", slidingPages)
    //     console.log("L R", l, r)
    // }
    // console.log("currentPage", currentPage)
    // console.log("pages", pages.length, pages)
    // console.log("------------------------------------")

    return (
        <span className="pagination-wrap">
            <i
                className={"bx bx-chevron-left" + (currentPage + 1 > 1 ? " show-arrow" : " disable-arrow")}
                title="previous page"
                onClick={clickPreviousPage}
            />

            {pages.map((_page, idx) => {
                let pageStatus = ""
                if (idx === currentPage) pageStatus += " current-page"
                if (idx === pages.length - 1) pageStatus += " last-page"
                return (
                    <span className={"page" + pageStatus} key={idx} onClick={() => clickPageNum(idx)}>
                        {idx + 1}
                    </span>
                )
            })}
            {lastEvaluatedKey && <span className="etc">...</span>}

            <i
                className={"bx bx-chevron-right" + (currentPage < pages.length - 1 || lastEvaluatedKey ? " show-arrow" : " disable-arrow")}
                title="next page"
                onClick={clickNextPage}
            />
        </span>
    )
}

export default PaginationArrows
