import React, { useState } from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, TabContent, TabPane } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"

const cropOptions = {
    unit: "px",
    x: 0,
    y: 0,
    width: 100,
    height: 100
    // aspect: 1 // aspect of 1 means user can only upload square photos
}

const ImageCropModal = (props) => {
    const { isOpen, onClose, imageFile, onEditPhoto, sourceImage, onCancel, onlyOneImage, nftCollection = false, handleSaveNFT } = props
    // nftCollection = means this component is rendered from the NFTCollection page
    // sourceImage = FileObject (contains image blob data)
    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState(cropOptions)
    const [step, setStep] = useState("1")
    const [name, setName] = useState(sourceImage?.name || "")
    const [description, setDescription] = useState("")
    const [randomnessWeight, setRandomnessWeight] = useState(1)

    const onSave = async () => {
        sourceImage.base64 = await getCroppedImage()
        sourceImage.description = description
        sourceImage.randomnessWeight = randomnessWeight
        onEditPhoto(sourceImage)
        setCrop({ ...cropOptions })
        setStep("1")
    }

    const getCroppedImage = () => {
        const width = (image.naturalWidth * crop.width) / 100
        const height = (image.naturalHeight * crop.height) / 100
        const offsetX = (image.naturalWidth * crop.x) / 100
        const offsetY = (image.naturalHeight * crop.y) / 100

        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")
        canvas.width = width
        canvas.height = height
        context.drawImage(image, offsetX, offsetY, width, height, 0, 0, width, height)

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    blob.name = sourceImage.name
                    resolve(blob)
                },
                sourceImage.type,
                1
            )
        })
    }

    return (
        <Modal className="image-crop-modal" isOpen={isOpen} onClose={onClose} fade={true} centered={true}>
            <AvForm
                className="new-project-form"
                initialValues={{ name: "" }}
                onSubmit={async () => {
                    await onSave()
                    handleSaveNFT()
                }}
            >
                <ModalHeader toggle={onClose}>Crop Picture</ModalHeader>
                <ModalBody>
                    <TabContent activeTab={step}>
                        <TabPane className="show" tabId="1">
                            <div className="image-wrapper">
                                {imageFile ? (
                                    <ReactCrop
                                        src={imageFile}
                                        crop={crop}
                                        onChange={(newCrop, percentCrop) => setCrop(percentCrop)}
                                        onImageLoaded={(image) => setImage(image)}
                                        keepSelection
                                    />
                                ) : (
                                    <div className="no-content">
                                        <Spinner size="lg" color="primary" />
                                    </div>
                                )}
                            </div>
                        </TabPane>

                        <TabPane className="show" tabId="2">
                            <FormGroup className="mb-3">
                                <AvField
                                    label="Name"
                                    type="text"
                                    className="form-control"
                                    name="Name"
                                    id="name"
                                    placeholder="Enter Name"
                                    errorMessage="Required!"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Label htmlFor="full-name" className="form-label">
                                    Description
                                </Label>
                                <Input
                                    type="textarea"
                                    rows={5}
                                    className="form-control"
                                    id="description"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <AvField
                                    label="Randomness Weight"
                                    type="number"
                                    className="form-control"
                                    name="Randomness Weight"
                                    id="randomness-weight"
                                    placeholder="Enter Randomness weight"
                                    errorMessage={randomnessWeight < 0 ? "Cannot be negative" : "Required!"}
                                    onChange={(e) => setRandomnessWeight(e.target.value)}
                                    value={randomnessWeight} // TO DO: find out if this value can be a fraction?
                                    required
                                    min="0"
                                />
                            </FormGroup>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    {step === "1" && (
                        <>
                            <Button className="btn-light waves-effect cancel-btn" color="secondary" outline onClick={onCancel}>
                                CANCEL
                            </Button>
                            {onlyOneImage && !nftCollection ? (
                                <Button color="primary" className="waves-effect w-lg" onClick={onSave}>
                                    DONE
                                </Button>
                            ) : (
                                <Button color="primary" className="waves-effect w-lg" onClick={() => setStep("2")}>
                                    NEXT
                                </Button>
                            )}
                        </>
                    )}
                    {step === "2" && (
                        <>
                            <Button className="btn-light waves-effect cancel-btn" color="secondary" outline onClick={() => setStep("1")}>
                                BACK
                            </Button>
                            {nftCollection ? (
                                <Button type="submit" color="primary" className="waves-effect w-lg">
                                    SAVE NFT
                                </Button>
                            ) : (
                                <Button color="primary" className="waves-effect w-lg" onClick={onSave}>
                                    DONE
                                </Button>
                            )}
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    )
}

export default ImageCropModal
