import contractAbi from "../abi/AvalancheCommunityCompetitionNFT.json"

class AvalancheCommunityNFT {
    constructor(web3, address) {
        this.web3 = web3
        this.contract = new this.web3.eth.Contract(contractAbi.abi, address)
    }

    async getOwnerOfToken(tokenId) {
        return await this.contract.methods.ownerOf(tokenId).call()
    }

    async getTokenURI(tokenId) {
        return await this.contract.methods.getTokenURI(tokenId).call()
    }
}

export default AvalancheCommunityNFT
