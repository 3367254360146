import React from "react"
import { useCSVReader } from "react-papaparse"

const styles = {
    csvReader: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10
    },
    browseFile: {
        width: "20%",
        borderRadius: "4px",
        border: "none"
    },
    acceptedFile: {
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginLeft: "1rem",
        marginRight: "1rem",
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: "70%"
    },
    remove: {
        border: "none",
        borderRadius: "4px",
        padding: "0 20px"
    },
    progressBarBackgroundColor: {
        backgroundColor: "#556ee6"
    }
}

export default function CSVReader({ setCSVdata, disableAllButtons }) {
    const { CSVReader } = useCSVReader()

    const handleOnUploadAccepted = (results) => {
        console.log("---------------------------")
        // console.log("results.data", results.data) //=> ex. [ ['avatar_url', 'email', 'telegram_id'], ['', 'test5-csv1@gmail.com', 'test12-tele'], ... ]
        const csvData = results.data
        const containerArray = []
        const attributes = results.data[0] // ex. [ 'avatar_url', 'email', 'telegram_id', ... ]

        // NOTE*
        // - assumes CSV file being uploaded has a header row (1st row)
        // - assumes CSV file header attributes are all snake case (ex. 'telegram_id' instead of camel case 'telegramId')
        // starting at i = 1, since first row is header attribute columns
        for (let i = 1; i < csvData.length; i++) {
            const csvItem = csvData[i] // ex. ['', 'test5-csv1@gmail.com', 'test12-tele']
            const formattedItem = {}

            // loop through all attribute values in a row item
            for (let j = 0; j < csvItem.length; j++) {
                const attributeValue = csvItem[j] // ex. 'test5-csv1@gmail.com', or ''
                const attributeName = attributes[j] // ex. 'email'

                if (attributeValue) formattedItem[attributeName] = attributeValue
            }
            // only push if item is not empty (i.e. blank csv row)
            if (Object.keys(formattedItem).length) containerArray.push(formattedItem)
            // const telegramId = csvData[i][2]
            // const sbtId = csvData[i][7]
            // const wallet = csvData[i][10]
            // containerArray.push({ telegram_id: telegramId, sbt_id: sbtId, wallet: wallet })
        }
        console.log(containerArray) //=> ex. [ {email:'test5-csv1@gmail.com', telegram_id:'test1-tele'}, {}, ... ]
        setCSVdata(containerArray)
        console.log("---------------------------")
    }

    return (
        <CSVReader onUploadAccepted={handleOnUploadAccepted}>
            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                <>
                    <div style={styles.csvReader}>
                        <button type="button" {...getRootProps()} style={styles.browseFile} disabled={disableAllButtons}>
                            Browse file
                        </button>
                        <div style={styles.acceptedFile}>{acceptedFile && acceptedFile.name}</div>
                        <button {...getRemoveFileProps()} style={styles.remove} disabled={disableAllButtons}>
                            Remove
                        </button>
                    </div>
                    <ProgressBar style={styles.progressBarBackgroundColor} />
                </>
            )}
        </CSVReader>
    )
}
