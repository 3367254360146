import React, { useState } from "react"
import { Collapse } from "reactstrap"
import clsx from "clsx"
import JSONPretty from "react-json-pretty"

const methods = [
    {
        path: "/signup",
        method: "get",
        summary: "Sign Up in application",
        description: "Sign Up in application",
        produces: ["application/json"],
        parameters: [
            {
                in: "body",
                name: "body",
                description: "User object",
                required: true,
                schema: {
                    type: "object",
                    required: ["phone", "sms_code"],
                    properties: {
                        phone: {
                            type: "string"
                        },
                        sms_code: {
                            type: "string"
                        }
                    }
                }
            }
        ],
        responses: [
            {
                status: "201",
                description: "User created, email with confirmation link sent",
                schema: {
                    type: "object",
                    required: ["name", "email"],
                    properties: {
                        name: {
                            type: "string"
                        },
                        email: {
                            type: "string"
                        },
                        password: {
                            type: "string"
                        }
                    }
                }
            },
            {
                status: "422",
                description: "Issues with input parameters"
            },
            {
                status: "500",
                description: "Server issues"
            }
        ]
    },
    {
        path: "/login",
        method: "post",
        summary: "Login in application",
        description: "Login in application",
        produces: ["application/json"],
        parameters: [
            {
                in: "body",
                name: "body",
                description: "User credentials",
                required: true,
                schema: {
                    type: "object",
                    required: ["name", "email"],
                    properties: {
                        name: {
                            type: "string"
                        },
                        email: {
                            type: "string"
                        },
                        password: {
                            type: "string"
                        }
                    }
                }
            }
        ],
        responses: [
            {
                status: "201",
                description: "User created, email with confirmation link sent"
            },
            {
                status: "422",
                description: "Issues with input parameters"
            },
            {
                status: "500",
                description: "Server issues"
            }
        ]
    },
    {
        path: "/user",
        method: "delete",
        summary: "Delete user",
        description: "Delete user",
        produces: ["application/json"],
        parameters: [
            {
                in: "body",
                name: "body",
                description: "User credentials",
                required: true,
                schema: {
                    type: "object",
                    properties: {
                        user_id: {
                            type: "string"
                        }
                    }
                }
            }
        ],
        responses: [
            {
                status: "200",
                description: "User is removed successfully"
            }
        ]
    }
]

const MethodAccordion = ({ data }) => {
    const [isOpen, setOpen] = useState(false)

    const getJsonExample = (data) => {
        const result = {}
        Object.keys(data.properties).forEach((key) => {
            result[key] = data.properties[key].type
        })
        return result
    }

    return (
        <div className={clsx("method-block", isOpen ? "open" : "closed", data.method)}>
            <div className="method-block-summary" onClick={() => setOpen(!isOpen)}>
                <span className="block-summary-method">{data.method}</span>
                <span className="block-summary-path">{data.path}</span>
                <span className="block-summary-description">{data.summary}</span>
            </div>
            <Collapse isOpen={isOpen} className="method-block-body">
                {data.description && (
                    <div className="block-description-wrapper">
                        <div className="block-description">
                            <div className="markdown">
                                <p>{data.description}</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="block-section">
                    <div className="block-section-header">
                        <div className="tab-header">
                            <h4 className="block-title">Parameters</h4>
                        </div>
                        <div className="try-out">
                            <button className="try-out__btn">Try it out</button>
                        </div>
                    </div>
                    <div className="parameters-container">
                        {data.parameters.length > 0 ? (
                            <div className="table-container">
                                <table className="parameters">
                                    <thead>
                                        <tr>
                                            <th className="col_header parameters-col_name">Name</th>
                                            <th className="col_header parameters-col_description">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.parameters.map((parameter, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td className="parameters-col_name">
                                                        <div className={clsx("parameter__name", parameter.required ? "required" : "")}>
                                                            {parameter.name} {parameter.required && <span>&nbsp;*</span>}
                                                        </div>
                                                        <div className="parameter__type">{parameter.type || parameter.schema.type}</div>
                                                        <div className="parameter__in">({parameter.in})</div>
                                                    </td>
                                                    <td className="parameters-col_description">
                                                        <div className="markdown">
                                                            <p>{parameter.description}</p>
                                                        </div>
                                                        {parameter.schema && (
                                                            <div className="model-example">
                                                                <ul className="tab">
                                                                    <li className="tab-item active">Example value</li>
                                                                    <li className="tab-item">Model</li>
                                                                </ul>
                                                                <JSONPretty id="json-pretty" data={getJsonExample(parameter.schema)} />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="block-description-wrapper">
                                <p>No parameters</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="responses-wrapper">
                    <div className="block-section-header">
                        <div className="tab-header">
                            <h4 className="block-title">Responses</h4>
                        </div>
                        <div className="content-type-wrapper">
                            <span>Response content type</span>
                            <div className="content-type">{data.produces[0]}</div>
                        </div>
                    </div>
                    {data.responses.length > 0 ? (
                        <div className="responses-inner">
                            <table className="responses-table">
                                <thead>
                                    <tr className="responses-header">
                                        <th className="col_header response-col_status">Code</th>
                                        <th className="col_header response-col_description">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.responses.map((response, idx) => {
                                        return (
                                            <tr className="response" key={idx}>
                                                <td className="response-col_status">{response.status}</td>
                                                <td className="response-col_description">
                                                    <div className="response-col_description__inner">
                                                        <div className="markdown">
                                                            <p>{response.description}</p>
                                                        </div>
                                                        {response.schema && (
                                                            <div className="model-example">
                                                                <ul className="tab">
                                                                    <li className="tab-item active">Example value</li>
                                                                    <li className="tab-item">Model</li>
                                                                </ul>
                                                                <JSONPretty id="json-pretty" data={getJsonExample(response.schema)} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="block-description-wrapper">
                            <p>No responses</p>
                        </div>
                    )}
                </div>
            </Collapse>
        </div>
    )
}

const IntegrationMethods = () => {
    return (
        <React.Fragment>
            <div className="content integration-methods">
                {methods.map((method, idx) => (
                    <MethodAccordion data={method} key={idx} />
                ))}
            </div>
        </React.Fragment>
    )
}

export default IntegrationMethods
