import { getNetworkName } from "./utils"

export class ChainUnsupportedError extends Error {
    constructor(chainId, expectedChainIds, ...params) {
        super(...params)
        this.name = "ChainUnsupportedError"
        this.message = `Selected network is not supported! Required chain: ${getNetworkName(expectedChainIds)} (Chain IDs: ${expectedChainIds}).`
    }
}

export class ConnectorUnsupportedError extends Error {
    constructor(connectorId, ...params) {
        super(...params)
        this.name = "ConnectorUnsupportedError"
        this.message = `Unsupported connector: ${connectorId}.`
    }
}

export class ConnectionRejectedError extends Error {
    constructor(...params) {
        super(...params)
        this.name = "ConnectionRejectedError"
        this.message = `The activation has been rejected by the provider.`
    }
}

export class ConnectorConfigError extends Error {
    constructor(...params) {
        super(...params)
        this.name = "ConnectorConfigError"
    }
}
