import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"

import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import BreadCrumb from "../Components/BreadCrumb"
import AddClientModal from "../Components/Clients/AddClientModal"
import { convertDataToClientDetails } from "../utils"

const apiUrl = "https://clients.idexo.io/"

const Clients = () => {
    const [clients, setClients] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [openAddClientModal, setOpenAddClientModal] = useState(false)

    const handleAddClientModalClose = () => {
        setOpenAddClientModal(false)
    }

    const handleAddNewClient = () => {
        setTimeout(() => {
            fetchClients().then()
            handleAddClientModalClose()
        }, 2000)
    }

    const fetchClients = async () => {
        try {
            setShowLoading(true)

            const res = await fetch(apiUrl, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error") {
                setAlertMessage("Error: " + response.error)
                setShowLoading(false)
                setClients(null)
                return
            }

            const clientItems = response.data.Items.map((item) => convertDataToClientDetails(item))
            setClients(clientItems)
        } catch (err) {
            console.log(err)
            setClients(null)
            setAlertMessage("Error: " + String(err))
        }
        setShowLoading(false)
    }

    useEffect(() => {
        fetchClients().then()
    }, [])

    const renderClients = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"clients"} />
        }

        if (!clients) {
            return <LoadingCard errorTxt={"clients"} alertMessage={alertMessage} />
        }

        if (clients.length === 0) {
            return <LoadingCard emptyTxt={"clients"} icon={"bx dripicons-user-group"} />
        }

        return (
            <div className="overflow-auto">
                <Table className="align-middle mb-0 table-nowrap clients-table">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Email</th>
                            <th scope="col">Company</th>
                            <th scope="col">Domain</th>
                            <th scope="col">Slug</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client) => (
                            <tr key={client.id}>
                                <td>{client.email}</td>
                                <td>{client.company}</td>
                                <td>{client.domain}</td>
                                <td>{client.slug}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="content clients-page">
                <BreadCrumb items={["Clients"]} />
                <Card>
                    <CardBody>
                        <CardTitle className="h3">Clients</CardTitle>
                        <div className="d-flex justify-content-end mb-3">
                            <Button color="primary" className="add-contract-btn waves-effect w-xl" onClick={() => setOpenAddClientModal(true)}>
                                Add New Client
                            </Button>
                        </div>
                        {renderClients()}
                    </CardBody>
                </Card>
            </div>
            {openAddClientModal && <AddClientModal open={openAddClientModal} onClose={handleAddClientModalClose} onAddClient={handleAddNewClient} />}
        </React.Fragment>
    )
}

export default Clients
