import React from "react"
import { Link } from "react-router-dom"
import logoLightPng from "../assets/images/white-logo.png"

const Logo = () => {
    return (
        <React.Fragment>
            <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                    <img src={logoLightPng} alt="" height="35" />
                </Link>

                <Link to="/" className="logo logo-light">
                    <img src={logoLightPng} alt="" height="35" />
                </Link>
            </div>
        </React.Fragment>
    )
}

export default Logo
