import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Table, FormGroup, Input, Label, Form, Alert } from "reactstrap"
import jwt from "jsonwebtoken"
import { networkIcons } from "../../utils"
import OkDialog from "../OkDialog/OkDialog"
import LoadingCard from "../LoadingCard/LoadingOrEmptyCard"
import PurchaseModal from "./PurchaseModal"
import "./methodCredits.scss"

// TO DO: add fetchMethodCredits function to sdk utils
const apiUrl = "https://txcredits.idexo.io/"
const apiBalanceUrl = "https://balance.idexo.io/"

// returns object of user_credit method names (keys) and user credit balance (values). ex { NFTs_createCollectionCapped_fantom: "3", ... }
// userCredits == array of objects [ { credit_balance: { N: "3" }, method_name: { S: "NFTs_createCollectionCapped_fantom" } }, ... ]
const reformatUserCredits = (userCredits) => {
    let newUserCredits = {}
    for (let i = 0; i < userCredits.length; i++) {
        newUserCredits[userCredits[i].method_name.S] = userCredits[i].credit_balance.N
    }
    return newUserCredits
}

const defaultFilterState = {
    showPurchased: false,
    ethereum: false,
    arbitrum: false,
    arbitrumnova: false,
    polygon: false,
    zksync: false,
    zksynctest: false,
    common: false,
    marketplace: false,
    NFTs: false,
    multi: false,
    storage: false,
    tokens: false,
    vesting: false,
    staking: false
}

const chainFilters = {
    ethereum: true,
    arbitrum: true,
    arbitrumnova: true,
    base: true,
    polygon: true,
    zksync: true,
    zksynctest: true
}
const txGroupFilters = {
    common: true,
    marketplace: true,
    NFTs: true,
    SBTs: true,
    multi: true,
    storage: true,
    tokens: true,
    vesting: true,
    staking: true
}

// returns only filters that match filterType
const getFiltersByType = (filters, filterType) => {
    let results = {}
    for (let filter in filters) {
        if (filterType === "chain" && chainFilters[filter]) results[filter] = filters[filter]
        if (filterType === "group" && txGroupFilters[filter]) results[filter] = filters[filter]
    }
    return results
}

// returns true if all filters of a type (ex. "chain") are off
const isFilterTypeAllOff = (filters, filterType) => {
    let filtered = getFiltersByType(filters, filterType)
    for (let filter in filtered) {
        if (filtered[filter]) return false
    }
    return true
}

const MethodCredits = () => {
    // check if user is a tenant member
    let decoded = null
    if (localStorage.jwtTenant) decoded = jwt.decode(localStorage.jwtTenant)
    else decoded = jwt.decode(localStorage.jwtToken)
    //

    const [transactionCredits, setTransactionCredits] = useState(null) // number- user's transaction credit balance

    const [allMethodCredits, setAllMethodCredits] = useState(null) // array of objects- all method names
    const [selectedMethodCredits, setSelectedMethodCredits] = useState([]) // what to render in table after applying filters

    const [showLoading, setShowLoading] = useState(false) // loading spinner animation for fetching methodCredits
    const [alertMessage, setAlertMessage] = useState("") // string- error if fetching methodCredits
    const [alertColor, setAlertColor] = useState("") // "danger" or "success"
    const [filters, setFilters] = useState(defaultFilterState)
    const [searchKeyword, setSearchKeyword] = useState("") // for search bar

    const [selectedTxType, setSelectedTxType] = useState(null)

    // TO DO: (maintain DRY and move this to parent component)
    const fetchUserTransactionCredits = async () => {
        try {
            const res = await fetch(apiBalanceUrl, {
                method: "GET",
                headers: {
                    "x-api-key": decoded.api_key,
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                return
            }
            setTransactionCredits(response.data.balance)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!transactionCredits) {
            fetchUserTransactionCredits()
        }
        // eslint-disable-next-line
    }, [])

    const handlePurchase = async (purchased_method, amount, cost) => {
        handlePurchaseModalClose()
        try {
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "x-api-key": decoded.api_key,
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    action: "purchase",
                    method_name: "credits_from_tx",
                    purchased_method: purchased_method,
                    quantity: amount,
                    amount: cost
                })
            })
            const response = await res.json()

            // problem fetching method credits
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                console.log(response.error || response?.data?.error || response.message)
                setAlertMessage("Error purchase failed: " + (response.error || response?.data?.error || response.message))
                setAlertColor("danger")
                return
            }
            setAlertMessage(`You have successfully purchased ${amount} ${purchased_method}`)
            setAlertColor("success")
            setTimeout(() => {
                fetchMethodCredits()
                fetchUserTransactionCredits()
            }, 3000)
        } catch (error) {
            console.log(error)
            setAlertMessage("Error purchase failed: " + String(error))
            setAlertColor("danger")
        }
    }

    const handleClickReset = () => {
        setFilters(defaultFilterState)
        setSearchKeyword("")
    }

    const handleClickShowPurchased = () => {
        setFilters((oldFilters) => {
            return { ...oldFilters, showPurchased: !oldFilters.showPurchased }
        })
    }

    const handleClickFilterChainOrGroup = (e) => {
        let chainOrGroup = e.target.innerHTML || e.target.nextElementSibling.innerHTML // "ethereum", "common"
        setFilters((oldFilters) => {
            return { ...oldFilters, [chainOrGroup]: !oldFilters[chainOrGroup] }
        })
    }

    const handlePurchaseModalClose = () => {
        setSelectedTxType(null)
    }

    const handleAddCredit = (transactionType) => {
        setSelectedTxType(transactionType)
    }

    const fetchCosts = async (userCreds) => {
        const res = await fetch("https://costs.idexo.io/")
        const response = await res.json()
        if (response.error) {
            setAlertMessage("Error fetching costs: " + response.error)
            setAlertColor("danger")
            return
        }
        let allMethodNames = response.data.Items.map((x, i) => {
            const parts = x.item_name.S.split("_")
            return {
                txGroupSdk: parts[0],
                transactionType: parts[1],
                chain: parts[2],
                costPerTransaction: x.item_cost.N,
                userCreditsTableMethodName: x.item_name.S,
                availCredits: userCreds[x.item_name.S]
            }
        })
        allMethodNames.sort(function (a, b) {
            return a.chain < b.chain ? -1 : a.chain > b.chain ? 1 : 0
        })
        setSelectedMethodCredits(allMethodNames)
        setAllMethodCredits(allMethodNames)
    }

    // fetches all the methodCredits for a user
    const fetchMethodCredits = async () => {
        setShowLoading(true)
        setAlertMessage("")
        try {
            const res = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "x-api-key": decoded.api_key,
                    token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            const response = await res.json()

            // problem fetching method credits
            if (response.code !== 200 || response.error || response.message === "Internal server error" || response?.data?.error) {
                console.log(response.error || response?.data?.error || response.message)
                setAlertMessage("Error fetching your available credits: " + (response.error || response?.data?.error || response.message))
                setAlertColor("danger")
                setShowLoading(false)
                return
            }
            await fetchCosts(reformatUserCredits(response?.data?.Items))
        } catch (error) {
            console.log(error)
            setAlertMessage("Error fetching your available credits: " + String(error))
            setAlertColor("danger")
        }
        setShowLoading(false)
    }

    const filterShowPurchased = (methods) => {
        return methods.filter((method) => {
            // show only methods the user has purchased
            if (filters.showPurchased) return Number(method.availCredits > 0)

            // show all methods if "showPurchased" filter is off
            return true
        })
    }

    const filterShowChain = (methods) => {
        // if all filters are off EXCEPT for showPurchased
        const filtersCopy = { ...filters }
        delete filtersCopy.showPurchased
        if (filters.showPurchased && Object.values(filtersCopy).every((val) => val === false)) {
            return methods
        }

        // if all chain filters are off
        const isAllChainOff = isFilterTypeAllOff(filters, "chain")
        if (isAllChainOff) return methods

        return methods.filter((method) => {
            for (let filter in filters) {
                // "showPurchased", "ethereum", "NFTs"
                let isFilterOn = filters[filter]
                if (isFilterOn && method.chain === filter) return true
            }
            return false
        })
    }

    const filterShowGroup = (methods) => {
        // if all group filters are off
        const isAllGroupOff = isFilterTypeAllOff(filters, "group")
        if (isAllGroupOff) return methods

        return methods.filter((method) => {
            for (let filter in filters) {
                let isFilterOn = filters[filter]
                if (isFilterOn && method.txGroupSdk.toLowerCase() === filter.toLowerCase()) return true
            }
            return false
        })
    }

    const filterSearch = (methods) => {
        return methods.filter((method) => {
            return method?.userCreditsTableMethodName?.toLowerCase().includes(searchKeyword.toLowerCase())
        })
    }

    // anytime any of the filters change, update the selectedMethodCredits to render
    useEffect(() => {
        if (allMethodCredits) {
            let results = allMethodCredits
            results = filterSearch(results)

            // if all filters are off/default (ex. on first render), show all method names
            if (Object.values(filters).every((val) => val === false)) {
                setSelectedMethodCredits(results)
                return
            }

            results = filterShowPurchased(results)
            results = filterShowChain(results)
            results = filterShowGroup(results)
            setSelectedMethodCredits(results)
        }

        // eslint-disable-next-line
    }, [filters, allMethodCredits, searchKeyword, setSelectedMethodCredits])

    useEffect(() => {
        fetchMethodCredits()
        // eslint-disable-next-line
    }, [])

    const renderTableRows = () => {
        return selectedMethodCredits.map((item, idx) => {
            if (item.costPerTransaction > 0) {
                return (
                    <tr key={idx}>
                        <td>
                            {/* {item.chain} */}
                            <img src={networkIcons[item.chain]} alt={item.chain} className="methodcredits-chain-logo" />
                            <span>{(item?.chain || "").toLowerCase()}</span>
                        </td>
                        {/* similar to functionName / methodName */}
                        <td>{item.transactionType}</td>
                        {/* <td>{item.displayName}</td>  */}
                        <td>{item.costPerTransaction}</td>
                        <td>{item.availCredits}</td>
                        <td>
                            <Button className="add-credit" color="secondary" onClick={() => handleAddCredit(item)}>
                                Add Credit
                            </Button>
                        </td>
                    </tr>
                )
            } else {
                return null
            }
        })
    }

    return (
        <React.Fragment>
            <div className="method-credits-tab">
                <Card>
                    <CardBody>
                        <CardTitle className="h3">
                            <span>Method Credits</span>
                        </CardTitle>

                        <div className="method-credits-description">
                            Below are your available credits for each method name. Calling a method once will use 1 method credit.
                        </div>

                        <div className="method-credits-filter">
                            <div className="filters-label">FILTERS</div>

                            <Button className="reset-filters" type="button" onClick={handleClickReset}>
                                Reset
                            </Button>

                            <div className="additive-filters">
                                <Form className="show-purchased-wrap">
                                    <FormGroup className="form-switch" onClick={handleClickShowPurchased}>
                                        <Input type="checkbox" className="show-purchased-toggle" checked={filters.showPurchased} />
                                        <Label>Show Purchased</Label>
                                    </FormGroup>
                                </Form>

                                {/* Filter by Chain */}
                                <Form className="filters-chain-wrap">
                                    <Label className="d-block filterby-chain">CHAIN</Label>
                                    <div className="filter-chain-grid">
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.arbitrum} />
                                            <Label>arbitrum</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.base} />
                                            <Label>base</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.ethereum} />
                                            <Label>ethereum</Label>
                                        </FormGroup>

                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.polygon} />
                                            <Label>polygon</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.zksync} />
                                            <Label>zksync</Label>
                                        </FormGroup>
                                    </div>
                                </Form>

                                {/* Filter by Transaction Group */}
                                {/* Common, Marketplace, NFTs, Storage, Tokens, Vesting, Staking */}
                                <Form className="filters-group-wrap">
                                    <Label className="d-block filterby-group">GROUP</Label>
                                    <div className="filters-group-grid">
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.common} />
                                            <Label>common</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.marketplace} />
                                            <Label>marketplace</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.NFTs} />
                                            <Label>NFTs</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.SBTs} />
                                            <Label>SBTs</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.multi} />
                                            <Label>multi</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.storage} />
                                            <Label>storage</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.tokens} />
                                            <Label>tokens</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.vesting} />
                                            <Label>vesting</Label>
                                        </FormGroup>
                                        <FormGroup check onClick={handleClickFilterChainOrGroup}>
                                            <Input type="checkbox" checked={filters.staking} />
                                            <Label>staking</Label>
                                        </FormGroup>
                                    </div>
                                </Form>
                            </div>
                        </div>

                        {/* TO DO: Search */}
                        <div className="method-credits-search-wrap">
                            <Input
                                type="search"
                                placeholder="Search Methods..."
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                                className="method-credits-search"
                            />
                        </div>

                        <Alert color={alertColor} isOpen={!!alertMessage}>
                            {alertMessage}
                        </Alert>

                        <div className="overflow-auto">
                            <Table className="align-middle mb-0 table-nowrap method-credits-table">
                                <thead className="table-light">
                                    <tr className="table-head-tr">
                                        <th scope="col">Chain</th>
                                        <th scope="col">Method Name</th>
                                        <th scope="col">Transaction Credit Cost</th>
                                        <th scope="col">Available Credits</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                {showLoading ? <tbody></tbody> : <tbody>{renderTableRows()}</tbody>}
                            </Table>
                            {showLoading && <LoadingCard loadingTxt={"your available method credits"} />}
                        </div>
                    </CardBody>
                </Card>
            </div>

            {!!selectedTxType && transactionCredits && (
                <PurchaseModal
                    open={!!selectedTxType}
                    balance={transactionCredits}
                    selectedMethod={selectedTxType}
                    onClose={handlePurchaseModalClose}
                    onPurchase={handlePurchase}
                />
            )}

            <OkDialog
                open={!!selectedTxType && transactionCredits === 0}
                message={"You must first purchase transaction credits in order to add method credits. Please go to the Transaction Credits tab above"}
                okClick={handlePurchaseModalClose}
            />
        </React.Fragment>
    )
}

export default MethodCredits
