import React from "react"
import { Button } from "reactstrap"
import jwt from "jsonwebtoken"

import { isPlanAboveUser } from "../../utils"
import "./PricingDesign15.scss"

const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
}

const onClickUrl = (url) => {
    return () => openInNewTab(url)
}

// if isBillingTab == true, then this component will be rendered in the Billing Tab component
const PricingDesign15 = ({ isBillingTab }) => {
    const decoded = jwt.decode(localStorage.jwtToken)
    const userEmail = decoded?.email?.replace("@", "%40")
    const jwtPaid = Number(decoded?.paid) // change this for testing pricing UI
    // jwtPaid: 0 = apiKey, 1 = reserved (for admin), 2 = trial, 3 = starter plan, 4 = professional plan, 5 = enterprise plan, 6 = ultimate plan

    return (
        <section className={"main-section-2 design15" + (isBillingTab ? " billing-pricing" : "")}>
            <div className="pricing-card professional">
                <div>
                    <div className="pricing-plan-wrap">
                        <div className="plan-type">Growth</div>
                        <div className="pricing-plan">
                            <span className="plan-price">$149</span> <span className="plan-month">MO.</span>
                        </div>
                    </div>
                    <div className="pricing-description">
                        <div className="description-grey">Unlimited NFT Mints - Transaction Credits Extra</div>
                        <div className="description-white">Integration with Zapier and 5,000 Apps</div>
                        <div className="description-grey">API and SDK Integration</div>
                        <div className="description-white">NFT Templates</div>
                        <div className="description-grey">Smart Contract Library</div>
                        <div className="description-white">OpenAI</div>
                        <div className="description-grey">Mint by Email / Special ID</div>
                        <div className="description-white">Up to 30 NFT Templates</div>
                        <div className="description-grey">Collaborative NFT Creation</div>
                        <div className="description-white">Minting and Pre-Minting</div>
                        <div className="description-grey">Soulbound Tokens</div>
                        <div className="description-white">Utility Tokens</div>
                        <div className="description-grey">Newsletter Access</div>
                    </div>
                </div>
                <Button
                    className="btn-primary btn-font-bold btn-width-100"
                    onClick={onClickUrl(`https://buy.stripe.com/4gweVg17saL84Hm7sK?prefilled_email=${userEmail}`)}
                    color="primary"
                    type="button"
                    // disable button if plan is NOT above users plan or plan is users current plan
                    disabled={!isPlanAboveUser(4, jwtPaid) || 4 === jwtPaid}
                >
                    {/* if on billing page */}
                    {isBillingTab && (
                        <React.Fragment>
                            {isPlanAboveUser(4, jwtPaid) && "UPGRADE"} {/* show "UPGRADE" text if plan is above user's plan */}
                            {4 < jwtPaid && "UPGRADED"} {/* show "UPGRADED" text if plan is below user's plan */}
                            {4 === jwtPaid && "CURRENT PLAN"} {/* show "CURRENT PLAN" text if plan is user's plan */}
                        </React.Fragment>
                    )}

                    {/* if on pricing page, show text "GET STARTED" */}
                    {!isBillingTab && "GET STARTED"}
                </Button>
            </div>

            <div className="pricing-card enterprise">
                <div>
                    <div className="pricing-plan-wrap">
                        <div className="plan-type">BUSINESS</div>
                        <div className="pricing-plan">
                            <span className="plan-price">749</span> <span className="plan-month">MO.</span>
                        </div>
                    </div>
                    <div className="pricing-description">
                        <div className="description-grey">Unlimited NFT Mints - Transaction Credits Extra</div>
                        <div className="description-white">Everything in Growth ++</div>
                        <div className="description-grey">Hosted Token Gating</div>
                        <div className="description-white">NFT Marketing Software</div>
                        <div className="description-grey">Web3 CRM</div>
                        <div className="description-white">Web3 CRM</div>
                        <div className="description-grey">Integrated KYC Gating</div>
                        <div className="description-white">100+ NFT Templates</div>
                        <div className="description-grey">Unity and Premium Integrations</div>
                        <div className="description-white">NFT Surveys</div>
                        <div className="description-grey">Hosted NFT Sales</div>
                        <div className="description-white">Business Workflows Integration</div>
                        <div className="description-grey">Additional Modules</div>
                    </div>
                </div>
                <Button
                    className="btn-primary btn-font-bold btn-width-100"
                    onClick={onClickUrl(`https://buy.stripe.com/fZe28u9DY4mK8XCdR6?prefilled_email=${userEmail}`)}
                    color="primary"
                    type="button"
                    // disable button if plan is NOT above users plan or plan is users current plan
                    disabled={!isPlanAboveUser(5, jwtPaid) || 5 === jwtPaid}
                >
                    {/* if on billing page */}
                    {isBillingTab && (
                        <React.Fragment>
                            {isPlanAboveUser(5, jwtPaid) && "UPGRADE"} {/* show "UPGRADE" text if plan is above user's plan */}
                            {5 < jwtPaid && "UPGRADED"} {/* show "UPGRADED" text if plan is below user's plan */}
                            {5 === jwtPaid && "CURRENT PLAN"} {/* show "CURRENT PLAN" text if plan is user's plan */}
                        </React.Fragment>
                    )}

                    {/* if on pricing page, show text "GET STARTED" */}
                    {!isBillingTab && "GET STARTED"}
                </Button>
            </div>
        </section>
    )
}

export default PricingDesign15
