import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, Modal, ModalBody, ModalHeader, Input, Label, FormGroup } from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"

import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"

import { useWallet } from "../../libs/useMetaMaskWallet"
import { ChainUnsupportedError } from "../../libs/useMetaMaskWallet/error"
import EthereumIcon from "../../assets/images/crypto/icons/ethereum.webp"
import ZksyncIcon from "../../assets/images/crypto/icons/zksync.jpg"
import ArbitrumIcon from "../../assets/images/crypto/icons/arbitrum.png"
import ArbitrumNovaIcon from "../../assets/images/crypto/icons/arbitrumnova.png"
// import AvalancheIcon from "../../assets/images/crypto/icons/avalanche.webp"
import MaticIcon from "../../assets/images/crypto/icons/matic.webp"
// import OKCIcon from "../../assets/images/crypto/icons/okc.png"
// import BinanceIcon from "../../assets/images/crypto/icons/binance.png"
import MetamaskIcon from "../../assets/images/crypto/icons/metamask.png"
// import FantomIcon from "../../assets/images/crypto/icons/fantom.png"
// import WalletConnectIcon from "../../assets/images/crypto/icons/wallet-connect.svg"

import { setNetworkOption, setValidationStatus, setPointBalance, setLogin, setNetwork } from "../../store/common/commonActions"
import { setNFTItems } from "../../store/nft/nftActions"
import WalletInfo from "./WalletInfo"
import { useToast } from "../../hooks/toast"

const apiUrl = "https://validatenft.idexo.io"
const walletOptions = [
    {
        name: "Metamask",
        icon: MetamaskIcon,
        enabled: true
    } /*,
  {
    name: "WalletConnect",
    icon: WalletConnectIcon,
    enabled: true
  },
  {
    name: "Binance Chain Wallet",
    icon: BinanceChainWalletIcon,
    enabled: false
  },
  {
    name: "Arkane",
    icon: ArkaneIcon,
    enabled: false
  },
  {
    name: "WalletLink",
    icon: WalletLinkIcon,
    enabled: false
  },
  {
    name: "Ledger",
    icon: LedgerIcon,
    enabled: false
  },
  {
    name: "Portis",
    icon: PortisIcon,
    enabled: false
  },
  {
    name: "MEW",
    icon: MEWIcon,
    enabled: false
  },
  {
    name: "Fortmatic",
    icon: FortmaticIcon,
    enabled: false
  },
  {
    name: "Authereum",
    icon: AuthereumIcon,
    enabled: false
  },
  {
    name: "Torus",
    icon: TorusIcon,
    enabled: false
  },
  {
    name: "Bitski",
    icon: BitskiIcon,
    enabled: false
  }*/
]

const WalletOptions = (props) => {
    const {
        setNetworkOption,
        networkOptionStatus,
        setValidationStatus,
        setPointBalance,
        setNFTItems,
        loggedIn,
        setLogin,
        Selectednetwork,
        setNetwork
    } = props

    const [openNetworkChangeModal, setOpenNetworkChangeModal] = useState(false)
    const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false)
    const [isReadAndAccept, setIsReadAndAccept] = useState(false)
    const [openInstallMetaMaskDialog, setOpenInstallMetaMaskDialog] = useState(false)
    // const [openUnsupportNetworkDialog, setOpenUnsupportNetworkDialog] = useState(false)
    const [unsupportErrorMsg, setUnsupportErrorMsg] = useState("")
    const toast = useToast()
    const wallet = useWallet()
    const handleConnect = async (network = "Metamask") => {
        try {
            if (network === "Metamask") await wallet.connect()
            else await wallet.connect(network)
            setNetwork(network)
            setOpenConnectWalletModal(false)
            closeDialog()
            setIsReadAndAccept(false)
            if (wallet.error && wallet.error.name === "NoEthereumProviderError") {
                setOpenInstallMetaMaskDialog(true)
            }
        } catch (error) {
            if (error instanceof ChainUnsupportedError) {
                console.log(error.message)
                closeDialog()
                toast.addError(error.message)
                // setUnsupportErrorMsg(error.message)
            } else {
                console.error(error)
                setOpenInstallMetaMaskDialog(true)
            }
        }
    }

    const supportedChains = [
        {
            chainId: "1",
            chainName: "Ethereum Mainnet",
            nativeCurrency: {
                name: "Ether",
                symbol: "ETH",
                decimals: 18
            },
            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            blockExplorerUrls: ["https://snowtrace.io/"]
        },
        {
            chainId: "137",
            chainName: "Polygon Mainnet",
            nativeCurrency: {
                name: "Matic",
                symbol: "MATIC",
                decimals: 18
            },
            rpcUrls: ["https://polygon-rpc.com/"],
            blockExplorerUrls: ["https://polygonscan.com/"]
        },
        {
            chainId: "324",
            chainName: "zkSync",
            nativeCurrency: {
                name: "Ether",
                symbol: "ETH",
                decimals: 18
            },
            rpcUrls: ["https://mainnet.zksync.io"],
            blockExplorerUrls: ["https://explorer.zksync.io"]
        },
        {
            chainId: "42161",
            chainName: "Arbitrum Mainnet",
            nativeCurrency: {
                name: "Ether",
                symbol: "ETH",
                decimals: 18
            },
            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://arbiscan.io/"]
        },
        {
            chainId: "42170",
            chainName: "Arbitrum Nova",
            nativeCurrency: {
                name: "Ether",
                symbol: "ETH",
                decimals: 18
            },
            rpcUrls: ["https://nova.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://nova.arbiscan.io/"]
        }
        // {
        //     chainId: "250",
        //     chainName: "Fantom Opera Mainnet",
        //     nativeCurrency: {
        //         name: "Fantom",
        //         symbol: "FTM",
        //         decimals: 18
        //     },
        //     rpcUrls: ["https://rpc.ftm.tools"],
        //     blockExplorerUrls: ["https://ftmscan.com"]
        // }
    ]

    const handleSwitchNetwork = async (network) => {
        // console.log(chainId)
        let chainId = null
        switch (network) {
            case "Ethereum":
                chainId = "1"
                break
            // case "Binance":
            //     chainId = "56"
            //     break
            case "Matic":
                chainId = "137"
                break
            case "zkSync":
                chainId = "324"
                break
            // case "Avalanche":
            //     chainId = "43114"
            //     break
            // case "OKC":
            //     chainId = "66"
            //     break
            case "Arbitrum":
                chainId = "42161"
                break
            case "ArbitrumNova":
                chainId = "42170"
                break
            // case "Fantom":
            //     chainId = "250"
            //     break
            default:
                chainId = null
        }
        if (chainId) {
            let params = null
            supportedChains.forEach(function (el, id) {
                if (el.chainId === chainId) {
                    params = el
                }
            })
            // console.log(params)
            try {
                console.log(params.chainId.toString(16))
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${(+params.chainId).toString(16)}` }]
                })
            } catch (switchError) {
                console.log("Trying to add RPC network")
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: `0x${(+params.chainId).toString(16)}`,
                                    chainName: params.chainName,
                                    nativeCurrency: params.nativeCurrency,
                                    rpcUrls: params.rpcUrls,
                                    blockExplorerUrls: params.blockExplorerUrls
                                }
                            ]
                        })
                    } catch (addError) {
                        console.error(addError.toString())
                    }
                }
                // handle other "switch" errors
                console.error(switchError.toString())
            }
        }
    }

    const closeDialog = () => {
        setIsReadAndAccept(false)
        setOpenConnectWalletModal(false)
    }

    const goToInstallMetaMaskPage = () => {
        window.open("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn", "_blank")
        setOpenInstallMetaMaskDialog(false)
    }

    const onChangeNetworkOption = (value) => {
        if (!isReadAndAccept) return

        setNetworkOption(value)
    }

    const toggleConnectWalletModal = () => {
        setOpenConnectWalletModal(!openConnectWalletModal)
        setIsReadAndAccept(false)
    }

    const onClickWalletOption = (option) => {
        if (!isReadAndAccept && !loggedIn) return

        switch (option) {
            case "Metamask":
                handleConnect().then()
                break
            case "WalletConnect":
                handleConnect("walletconnect").then()
                break
            default:
                break
        }
    }

    const networkIcon = {
        Ethereum: EthereumIcon,
        Matic: MaticIcon,
        zkSync: ZksyncIcon,
        Arbitrum: ArbitrumIcon,
        ArbitrumNova: ArbitrumNovaIcon
    }

    useEffect(() => {
        if (wallet && wallet.account) {
            setLogin(true)
            let networkOption = ""
            switch (wallet.chainId) {
                case 1:
                    networkOption = "Ethereum"
                    break
                case 3:
                    networkOption = "Ethereum"
                    break
                case 137:
                    networkOption = "Matic"
                    break
                case 324:
                    networkOption = "zkSync"
                    break
                case 42161:
                    networkOption = "Arbitrum"
                    break
                case 42170:
                    networkOption = "ArbitrumNova"
                    break
                default:
                    break
            }
            if (networkOptionStatus && networkOption !== networkOptionStatus) {
                handleSwitchNetwork(networkOption)
            } else {
                axios
                    .post(apiUrl, { walletAddress: wallet.account }, { headers: { token: localStorage.jwtToken } })
                    .then(function (response) {
                        const { validationStatus, nft } = response["data"]["data"]
                        let _pointBalance = 0
                        if (nft) {
                            _pointBalance = 50
                            for (let i = 0; i < nft.length; i++) {
                                if ("point_balance" in nft[i]) {
                                    _pointBalance = nft[i]["point_balance"]["N"]
                                    break
                                }
                            }
                        }
                        setValidationStatus(validationStatus === "validated" || _pointBalance >= 200 ? "Validated" : "Not Validated")
                        setPointBalance(_pointBalance)
                        if (nft.length > 0) {
                            setNFTItems(nft)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        } else if (loggedIn) {
            onClickWalletOption(Selectednetwork)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet.account, setValidationStatus, setPointBalance])

    useEffect(() => {
        if (wallet?.status === "connected" && wallet?.chainId) {
            switch (wallet.ethereum?.network?.chainId || wallet.chainId) {
                case 1:
                    setNetworkOption("Ethereum")
                    break
                case 3:
                    setNetworkOption("Ethereum")
                    break
                case 137:
                    setNetworkOption("Matic")
                    break
                case 324:
                    setNetworkOption("zkSync")
                    break
                case 42161:
                    setNetworkOption("Arbitrum")
                    break
                case 42170:
                    setNetworkOption("ArbitrumNova")
                    break
                default:
                    break
            }
        }
    }, [setNetworkOption, wallet])

    const changeNetworkOption = (network) => {
        setNetworkOption(network)
        if (loggedIn) {
            handleSwitchNetwork(network)
            setOpenNetworkChangeModal(false)
            setNetworkOption(network)
        }
    }

    return (
        <div id="wallet-options">
            {networkOptionStatus && (
                <Button
                    type="submit"
                    color="primary"
                    outline
                    className="waves-effect waves-light network-option-btn"
                    onClick={() => setOpenNetworkChangeModal(!openNetworkChangeModal)}
                >
                    <img src={networkIcon[networkOptionStatus]} alt="" />
                    <span className="network-option-status">{networkOptionStatus}</span>
                </Button>
            )}

            {wallet.status === "connected" ? (
                <WalletInfo />
            ) : (
                <Button
                    type="submit"
                    color="primary"
                    outline
                    className="waves-effect waves-light connect-wallet-btn"
                    onClick={() => setOpenConnectWalletModal(!openConnectWalletModal)}
                >
                    <i className="bx bx-wallet" /> Connect Wallet
                </Button>
            )}
            <Modal
                isOpen={openNetworkChangeModal}
                fade={true}
                toggle={() => setOpenNetworkChangeModal(!openNetworkChangeModal)}
                className="network-change-modal"
            >
                <ModalHeader toggle={() => setOpenNetworkChangeModal(!openNetworkChangeModal)}>Switch Network</ModalHeader>
                <ModalBody>
                    <div className="option-group">
                        <div>
                            <div className="option-item" onClick={() => changeNetworkOption("Ethereum")}>
                                <div className="crypto-icon">
                                    <img src={EthereumIcon} alt="" />
                                    <span className={networkOptionStatus === "Ethereum" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Ethereum</span>
                            </div>
                            <div className="option-item" onClick={() => changeNetworkOption("zkSync")}>
                                <div className="crypto-icon">
                                    <img src={ZksyncIcon} alt="" />
                                    <span className={networkOptionStatus === "zkSync" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">zkSync</span>
                            </div>
                        </div>
                        <div>
                            <div className="option-item" onClick={() => changeNetworkOption("Matic")}>
                                <div className="crypto-icon">
                                    <img src={MaticIcon} alt="" />
                                    <span className={networkOptionStatus === "Matic" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Polygon</span>
                            </div>
                        </div>

                        <div>
                            <div className="option-item" onClick={() => changeNetworkOption("Arbitrum")}>
                                <div className="crypto-icon">
                                    <img src={ArbitrumIcon} alt="" />
                                    <span className={networkOptionStatus === "Arbitrum" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Arbitrum</span>
                            </div>
                            <div className="option-item" onClick={() => changeNetworkOption("ArbitrumNova")}>
                                <div className="crypto-icon">
                                    <img src={ArbitrumNovaIcon} alt="" />
                                    <span className={networkOptionStatus === "ArbitrumNova" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Arbitrum Nova</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={openConnectWalletModal} fade={true} centered={true} toggle={toggleConnectWalletModal} className="connect-wallet-modal">
                <ModalHeader toggle={toggleConnectWalletModal}>Connect Wallet</ModalHeader>
                <ModalBody>
                    <div className="wallet-section">
                        <h5>
                            1. Accept <Link to="#">Terms of Service, Legal Disclosure</Link> and <Link to="#">Privacy Policy</Link>
                        </h5>
                        <FormGroup className="mb-2 privacy-policy">
                            <Input type="checkbox" name="privacy-policy" id="privacy-policy" onClick={() => setIsReadAndAccept(!isReadAndAccept)} />
                            <Label htmlFor="privacy-policy">I have read and agree to the Terms.</Label>
                        </FormGroup>
                    </div>

                    <div className="wallet-section mt-2">
                        <h5>2. Choose Network</h5>
                        <div className="option-group">
                            <div
                                className={`option-item  ${isReadAndAccept ? "enabled" : "disabled"}`}
                                onClick={() => onChangeNetworkOption("Ethereum")}
                            >
                                <div className="crypto-icon">
                                    <img src={EthereumIcon} alt="" />
                                    <span className={networkOptionStatus === "Ethereum" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Ethereum</span>
                            </div>
                            <div
                                className={`option-item  ${isReadAndAccept ? "enabled" : "disabled"}`}
                                onClick={() => onChangeNetworkOption("Ethereum")}
                            >
                                <div className="crypto-icon">
                                    <img src={ZksyncIcon} alt="" />
                                    <span className={networkOptionStatus === "zkSync" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">zkSync</span>
                            </div>

                            <div
                                className={`option-item  ${isReadAndAccept ? "enabled" : "disabled"}`}
                                onClick={() => onChangeNetworkOption("Matic")}
                            >
                                <div className="crypto-icon">
                                    <img src={MaticIcon} alt="" />
                                    <span className={networkOptionStatus === "Matic" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Polygon</span>
                            </div>

                            <div
                                className={`option-item  ${isReadAndAccept ? "enabled" : "disabled"}`}
                                onClick={() => onChangeNetworkOption("Arbitrum")}
                            >
                                <div className="crypto-icon">
                                    <img src={ArbitrumIcon} alt="" />
                                    <span className={networkOptionStatus === "Arbitrum" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">Arbitrum</span>
                            </div>
                            <div
                                className={`option-item  ${isReadAndAccept ? "enabled" : "disabled"}`}
                                onClick={() => onChangeNetworkOption("ArbitrumNova")}
                            >
                                <div className="crypto-icon">
                                    <img src={ArbitrumNovaIcon} alt="" />
                                    <span className={networkOptionStatus === "ArbitrumNova" ? "show" : "hide"}>
                                        <i className="bx bx-check" />
                                    </span>
                                </div>
                                <span className="mt-1">ArbitrumNova</span>
                            </div>
                        </div>
                    </div>

                    <div className="wallet-section mt-2">
                        <h5>3. Choose Wallet</h5>
                        <div className="option-group">
                            {walletOptions.map((item, idx) => (
                                <div
                                    key={idx}
                                    className={`option-item  ${item.enabled && isReadAndAccept ? "enabled" : "disabled"}`}
                                    onClick={() => onClickWalletOption(item.name)}
                                >
                                    <div className="crypto-icon">
                                        <img src={item.icon} alt="" />
                                    </div>
                                    <span className="mt-1">{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Dialog
                open={openInstallMetaMaskDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                onClose={() => setOpenInstallMetaMaskDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">Install MetaMask</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Please install MetaMask wallet on your browser to connect</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={goToInstallMetaMaskPage}>
                        {" "}
                        Install MetaMask{" "}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={unsupportErrorMsg !== ""}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
                onClose={() => setUnsupportErrorMsg("")}
            >
                <DialogTitle id="alert-dialog-title">Unsupported Network</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{unsupportErrorMsg}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loggedIn: state.commonReducer.loggedIn,
    Selectednetwork: state.commonReducer.network,
    networkOptionStatus: state.commonReducer.networkOptionStatus,
    validationStatus: state.commonReducer.validationStatus
})

const mapDispatchToProps = {
    setLogin,
    setNetwork,
    setNetworkOption,
    setValidationStatus,
    setPointBalance,
    setNFTItems
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletOptions)
