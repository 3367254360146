import React, { Component } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import jwt from "jsonwebtoken"
import "./profileMenu.scss"

class ProfileMenu extends Component {
    constructor(props) {
        super(props)
        const decoded = jwt.decode(localStorage.jwtToken)
        this.state = {
            menu: false,
            name: "Admin",
            user_id: decoded?.user_id
        }
        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu
        }))
    }

    componentDidMount() {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const obj = JSON.parse(localStorage.getItem("authUser"))
                this.setState({ name: obj.displayName })
            } else if (process.env.REACT_APP_DEFAULTAUTH === "fake" || process.env.REACT_APP_DEFAULTAUTH === "jwt") {
                const obj = JSON.parse(localStorage.getItem("authUser"))
                this.setState({ name: obj.username })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-flex profile-menu-wrap">
                    <DropdownToggle className="btn header-item profile waves-effect" id="page-header-user-dropdown" tag="button">
                        {/* <span className="d-none d-lg-inline-block ml-2 mr-1">User</span> */}
                        <i className="bx bx-user font-size-18 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <NavLink to={`/settings`} className="dropdown-item-a">
                                <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                                <span className="badge badge-pill badge-info float-right">3</span>
                                <span>Profile</span>
                            </NavLink>
                        </DropdownItem>

                        {/* <DropdownItem tag="a" href="#">
                            <i className="bx bx-cog font-size-16 align-middle mr-1"></i>
                            Settings
                        </DropdownItem> */}
                        <div className="dropdown-divider"></div>
                        <DropdownItem>
                            <NavLink to="/logout" className="dropdown-item-a">
                                <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                                <span className="badge badge-pill badge-info float-right">3</span>
                                <span>Logout</span>
                            </NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
    }
}

export default ProfileMenu
