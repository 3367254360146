import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, FormGroup, Button, ModalFooter, Input } from "reactstrap"

import "./PurchaseModal.scss"

const PurchaseModal = (props) => {
    const { open, balance, onClose, onPurchase, selectedMethod } = props
    const [amount, setAmount] = useState(1)

    const handleSubmit = async () => {
        onPurchase(selectedMethod.userCreditsTableMethodName, amount, selectedMethod.costPerTransaction * amount)
        onClose()
    }

    return (
        <React.Fragment>
            <Modal className="purchase-modal" isOpen={open} onClose={onClose} fade={true} centered={true} toggle={onClose}>
                <ModalHeader toggle={onClose} tag={"div"}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>{"Purchase Method Credits"}</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <h3>Details:</h3>
                    <h5>Chain: {selectedMethod.chain}</h5>
                    <h5>Method: {selectedMethod.displayName}</h5>
                    <h5>Cost: {selectedMethod.costPerTransaction || 0} Tx Credits</h5>
                    <FormGroup className="mb-3">
                        <Input type="number" min={1} value={amount} onChange={(e) => setAmount(e.target.value)} />

                        <h3 className="mt-2">Available Tx Credits: {balance}</h3>
                        <h3 className="mt-2">Total Cost: {selectedMethod.costPerTransaction * amount}</h3>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="submit-cancel">
                    {/* TO DO: add custom success/error alert here */}
                    <Button
                        color="primary"
                        className="save-address-btn waves-effect"
                        onClick={handleSubmit}
                        disabled={balance < selectedMethod.costPerTransaction * amount}
                    >
                        {balance < selectedMethod.costPerTransaction * amount ? "Insufficient Balance" : "Purchase"}
                    </Button>
                    <Button color="secondary" outline className="btn-light waves-effect cancel-btn" onClick={onClose}>
                        CANCEL
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default PurchaseModal
