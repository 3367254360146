import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label } from "reactstrap"

import "./TableSettings.scss"

const TableSettings = (props) => {
    const { updatePageSize, itemsPerPage } = props

    const [showSettingsModal, setShowSettingsModal] = useState(false)

    const toggleModal = (e) => setShowSettingsModal((prev) => !prev)

    const clickPageSize = (e) => {
        const maxContactsPerPage = Number(e.target.value)
        updatePageSize(maxContactsPerPage)
    }

    return (
        <div className="table-settings-wrap">
            <i className="bx bxs-cog" title="table settings" onClick={toggleModal} type="button" />

            {showSettingsModal && (
                <Modal isOpen={showSettingsModal} centered={true} toggle={toggleModal} className="table-settings-modal">
                    <ModalHeader toggle={toggleModal}>Preferences</ModalHeader>

                    <ModalBody>
                        <span className="page-size-title">Page size</span>
                        <Form className="page-size-form">
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="10" onChange={clickPageSize} checked={itemsPerPage === 10} />
                                <Label check>10 items</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="25" onChange={clickPageSize} checked={itemsPerPage === 25} />
                                <Label check>25 items</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="50" onChange={clickPageSize} checked={itemsPerPage === 50} />
                                <Label check>50 items</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="100" onChange={clickPageSize} checked={itemsPerPage === 100} />
                                <Label check>100 items</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="200" onChange={clickPageSize} checked={itemsPerPage === 200} />
                                <Label check>200 items</Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input name="pagesize" type="radio" value="300" onChange={clickPageSize} checked={itemsPerPage === 300} />
                                <Label check>300 items</Label>
                            </FormGroup>
                            {/* <FormGroup check>
                                <Input name="pagesize" type="radio" value="10000" onChange={clickPageSize} checked={itemsPerPage === 10000} />
                                <Label check>All items</Label>
                            </FormGroup> */}
                        </Form>
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        <Button color="secondary" outline onClick={toggleModal} className="btn-light waves-effect cancel-btn">
                            CLOSE
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    )
}

export default TableSettings
