import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"

import BreadCrumb from "../Components/BreadCrumb"

const TrialOrPaid = () => {
    return (
        <React.Fragment>
            <div className="content projects">
                <BreadCrumb items={["Upgrade Your Account"]} />
                <Card>
                    <CardBody>
                        <CardTitle className="h3">Plan Options</CardTitle>
                        <div className="d-flex justify-content-center mb-3">
                            <CardTitle>To access this feature you need to upgrade your account</CardTitle>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default TrialOrPaid
