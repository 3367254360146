import React from "react"
import classnames from "classnames"
import "./tabs.scss"

// To use this component, just supply as props, when you call this function, "tabs" props with a value of an array of strings
// - each string in the array represents a different tab
const Tabs = ({ tabs, setActiveTab, activeTab }) => {
    return (
        <React.Fragment>
            <div className="tab-links">
                {tabs.map((tabName, idx) => {
                    return (
                        <span
                            className={classnames({
                                active: activeTab === String(idx + 1)
                            })}
                            onClick={() => {
                                setActiveTab(String(idx + 1))
                            }}
                            key={idx + 1}
                        >
                            {tabName}
                        </span>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default Tabs
