import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, FormGroup, Button, ModalFooter } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"
import clsx from "clsx"
import { QrReader } from "react-qr-reader"

import { allFormsValid } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import Select from "../CustomSelect"
import { chainOptions } from "../../common/chain"
import "./addAddressModal.scss"

const AddAddressModal = (props) => {
    const { open, onClose, onAddAddress, onUpdateAddress, data } = props
    const [name, setName] = useState(data?.name || "")
    const [email, setEmail] = useState(data?.email || "")
    const [nickname, setNickname] = useState(data?.nickname || "")
    const [address, setAddress] = useState(data?.address || "")
    const [chains, setChains] = useState([])
    const [showSpinner, setShowSpinner] = useState(false)
    const [openQRModal, setOpenQRModal] = useState(false)
    const [qrScanErr, setQrScanErr] = useState(false)

    if (data && data.chains && !chains.length) {
        setChains(
            data.chains
                .replace(/^_+/g, "")
                .split("_")
                .map((chain) => ({
                    value: chain,
                    name: chain
                }))
        )
    }

    const handleSubmit = () => {
        setShowSpinner(true)

        const newAddress = {
            id: new Date().getTime(),
            inserted: new Date().getTime(),
            name,
            email,
            nickname,
            address,
            chains: chains.reduce(function (a, o) {
                return a + "_" + o.value
            }, "")
        }

        setTimeout(() => {
            if (data) {
                newAddress.id = data.id
                onUpdateAddress(newAddress)
            } else {
                onAddAddress(newAddress)
            }
            setShowSpinner(false)
            onClose()
        }, 500)
    }

    const handleScan = (result, error) => {
        if (result) {
            const address = getAddressFromQr(result?.text)
            setAddress(address)
            setOpenQRModal(false)
        } else if (error) {
            setQrScanErr(true)
            console.error(error)
        }
    }

    const getAddressFromQr = (address = "") => {
        const str = address.split("?")[0]
        const str1 = str.split(":")

        return str1.length > 1 ? str1[1] : str1[0]
    }

    const handleOpenImageDialog = () => {
        const inputEl = document.querySelector(".qr-reader input")
        if (inputEl) {
            inputEl.click()
        }
    }

    const onCloseQrScanModal = () => {
        setOpenQRModal(false)
        setQrScanErr(false)
    }

    return (
        <React.Fragment>
            <Modal className="add-address-modal" isOpen={open} onClose={onClose} fade={true} centered={true} toggle={onClose}>
                <AvForm initialValues={{ name: "" }} onSubmit={() => handleSubmit()}>
                    <ModalHeader toggle={onClose} tag={"div"}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>{data ? "Edit Address" : "Add New Address"}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <AvField
                                name="name"
                                label="Name"
                                className="form-control"
                                placeholder="Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <AvField
                                name="nickname"
                                label="Nickname"
                                className="form-control"
                                placeholder="Nickname"
                                type="text"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <FormGroup className="address-input mb-3">
                            <AvField
                                name="address"
                                label="Address"
                                className="form-control"
                                placeholder="Address"
                                type="text"
                                value={address}
                                required
                                onChange={(e) => setAddress(e.target.value)}
                                disabled={showSpinner}
                            />
                            <span className="qr-btn" onClick={() => setOpenQRModal(true)}>
                                <i className="fa fa-qrcode" />
                            </span>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Select
                                label="Chains"
                                id="chain-select"
                                name="chain-select"
                                placeholder="Select Blockchain"
                                multiple
                                options={chainOptions}
                                selectedOption={chains}
                                handleChange={(value) => setChains(value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="submit-cancel">
                        {/* TO DO: add custom success/error alert here */}
                        <Button
                            color="primary"
                            type="submit"
                            className="save-address-btn waves-effect"
                            disabled={showSpinner || !allFormsValid([address])}
                        >
                            {!data ? (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Saving"} txtFalse={"Save"} />
                            ) : (
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Updating"} txtFalse={"Update"} />
                            )}
                        </Button>
                        <Button color="secondary" outline className="btn-light waves-effect cancel-btn" onClick={onClose} disabled={showSpinner}>
                            CANCEL
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>

            <Modal className="scan-qr-modal" isOpen={openQRModal} onClose={onCloseQrScanModal} fade={true} centered={true}>
                <ModalHeader toggle={onCloseQrScanModal}>Scan QR</ModalHeader>
                <ModalBody>
                    {qrScanErr && <p className="text-center">The QR could not be read</p>}
                    <div className={clsx("container", qrScanErr ? "invalid" : "")}>
                        <QrReader className="qr-reader" onResult={handleScan} />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button color="secondary" className="btn-light waves-effect me-3" onClick={onCloseQrScanModal} disabled={showSpinner}>
                            Close
                        </Button>
                        <Button color="primary" type="file" className="waves-effect" onClick={handleOpenImageDialog}>
                            Select an Image
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddAddressModal
