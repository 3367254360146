import { useState } from "react"

// custom hook- this emulates a constructor function from classical components
const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false)
    if (hasBeenCalled) return
    callBack()
    setHasBeenCalled(true)
}

export { useConstructor }
