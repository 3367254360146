export const KNOWN_CHAINS = {
    1: "Mainnet",
    2: "Expanse",
    3: "Ropsten",
    4: "Rinkeby",
    5: "Goerli",
    8: "Ubiq",
    42: "Kovan",
    100: "xDai",
    137: "Matic",
    300: "zkSync Sepolia",
    324: "zkSync Era",
    42161: "Arbitrum",
    42170: "ArbitrumNova",

    // This chainId is arbitrary and can be changed,
    // but by convention this is the number used
    // for local chains (ganache, buidler, etc) by default.
    1337: "Local",
    5777: "Ganache"
}

export const KNOWN_CHAIN_CURRENCY = {
    1: "ETH",
    3: "tETH",
    5: "tETH",
    56: "BNB",
    66: "OKT",
    137: "Matic",
    300: "ETH",
    324: "ETH",
    42161: "ETH",
    42170: "ETH",
}

export function getNetworkName(chainIds) {
    let res = ""
    chainIds.forEach((id, index) => {
        if (index === chainIds.length - 1) return (res += KNOWN_CHAINS[id])
        return (res += KNOWN_CHAINS[id] + ", ")
    })
    return res || "Unknown"
}

function isUnwrappedRpcResult(response) {
    return typeof response === "object" && response !== null && "jsonrpc" in response
}

export function rpcResult(response) {
    // Some providers don’t wrap the response
    if (isUnwrappedRpcResult(response)) {
        if (response.error) {
            throw new Error(response.error)
        }
        return response.result || null
    }

    return response || null
}

async function ethereumRequest(ethereum, method, params) {
    // If ethereum.request() exists, the provider is probably EIP-1193 compliant.
    if (ethereum.request) {
        return ethereum.request({ method, params }).then(rpcResult)
    }

    // This is specific to some older versions of MetaMask combined with Web3.js.
    if (ethereum.sendAsync && ethereum.selectedAddress) {
        return new Promise((resolve, reject) => {
            ethereum.sendAsync(
                {
                    method,
                    params,
                    from: ethereum.selectedAddress,
                    jsonrpc: "2.0",
                    id: 0
                },
                (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                }
            )
        }).then(rpcResult)
    }

    // If none of the previous two exist, we assume the provider is pre EIP-1193,
    // using .send() rather than .request().
    if (ethereum.send) {
        return ethereum.send(method, params).then(rpcResult)
    }

    throw new Error("The Ethereum provider doesn’t seem to provide a request method.")
}

export async function getAccountIsContract(ethereum, account) {
    try {
        const code = await ethereumRequest(ethereum, "eth_getCode", [account])
        return code !== "0x"
    } catch (err) {
        return false
    }
}

export async function getAccountBalance(ethereum, account) {
    return ethereumRequest(ethereum, "eth_getBalance", [account, "latest"])
}

export async function getBlockNumber(ethereum) {
    return ethereumRequest(ethereum, "eth_blockNumber", [])
}

export function pollEvery(fn, delay) {
    let timer: any // can be TimeOut (Node) or number (web)
    let stop = false
    const poll = async (request, onResult) => {
        const result = await request()
        if (!stop) {
            onResult(result)
            timer = setTimeout(poll.bind(null, request, onResult), delay)
        }
    }
    return (...params: T[]) => {
        const { request, onResult } = fn(...params)
        stop = false
        poll(request, onResult)
        return () => {
            stop = true
            clearTimeout(timer)
        }
    }
}
