import { SET_NFT_DATA_LOADING, SET_NFT_ITEMS, SET_SALE_TOKENS, SET_NFT_TOKENS } from "./nftActions"

const initialState = {
    items: [],
    sale_tokens: [],
    tokens: [],
    loading: true
}

const nftReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NFT_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_NFT_ITEMS:
            return {
                ...state,
                items: action.payload
            }
        case SET_SALE_TOKENS:
            return {
                ...state,
                loading: false,
                sale_tokens: action.payload
            }
        case SET_NFT_TOKENS:
            return {
                ...state,
                loading: false,
                tokens: action.payload
            }
        default:
            return { ...state }
    }
}

export default nftReducer
