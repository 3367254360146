export const chainOptions = [
    {
        value: "arbitrum",
        name: "arbitrum"
    },
    {
        value: "arbitrumnova",
        name: "arbitrumnova"
    },
    {
        value: "base", // this is what's displayed in dropdown item when selected, the value kept in react state, & sent to backend
        name: "base" // this is what's displayed in dropdown item (when not selected)
    },
    {
        value: "ethereum",
        name: "ethereum"
    },
    {
        value: "okc",
        name: "okc"
    },
    {
        value: "polygon",
        name: "polygon"
    },
    {
        value: "zksync",
        name: "zksync"
    },
    {
        value: "zksynctest",
        name: "zksynctest"
    }
]
