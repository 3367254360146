import React from "react"
import { Button, Card, CardBody, CardTitle, FormGroup } from "reactstrap"
import { Field as AvField, Form as AvForm } from "@availity/form"

import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg"
import LockIcon from "../../assets/icons/lock-icon.png"
import { allFormsValid } from "../../utils"
import SpinnerWrap from "../SpinnerWrap"
import "./TelegramCard.scss"

const TelegramCard = React.memo(
    ({
        // states/useStates from parent
        telegramData,
        telegram_group_name, // super group name
        setTelegramGroupName,
        telegram_bot_name,
        setBotName,
        telegram_bot_command,
        setBotCommand,
        paid,
        showSpinner,
        buttonId,
        handleSubmit
    }) => {
        // returns boolean, true if on editing form, the input form field data is the same as the old form data
        const noFormChange = () => {
            return (
                telegram_group_name === telegramData?.telegram_group_name?.S &&
                telegram_bot_name === telegramData?.telegram_bot_name?.S &&
                telegram_bot_command === telegramData?.telegram_bot_command?.S
            )
        }

        return (
            <Card className="telegram-card">
                <CardBody>
                    <CardTitle className="h3 with-icon">
                        <TelegramIcon /> Telegram
                    </CardTitle>
                    <div className="gray-box">
                        {paid > 0 ? (
                            <AvForm
                                className="telegram-group"
                                initialValues={{ "telegram-group": "" }}
                                onSubmit={(e) => {
                                    alert("Work In Progress...")
                                    // TO DO: backend for launching telegram bots, etc.
                                    // handleSubmit(
                                    //     e,
                                    //     { telegram_group_name, telegram_bot_name, telegram_bot_command },
                                    //     6,
                                    //     "launchTelegramMint",
                                    //     false,
                                    //     telegramData?.inserted?.N
                                    // )
                                }}
                            >
                                <FormGroup className="mb-4">
                                    <AvField
                                        label="Telegram Super group"
                                        type="text"
                                        className="form-control"
                                        id="telegram-group"
                                        name="telegram-group"
                                        placeholder="Enter Telegram Super Group Name"
                                        value={telegram_group_name}
                                        onChange={(e) => setTelegramGroupName(e.target.value)}
                                        required
                                        disabled={showSpinner}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    <AvField
                                        label="Custom Bot Name"
                                        type="text"
                                        className="form-control"
                                        id="custom-bot-name"
                                        name="custom-bot-name"
                                        placeholder="Give your minting bot a name"
                                        value={telegram_bot_name}
                                        onChange={(e) => setBotName(e.target.value)}
                                        required
                                        disabled={showSpinner}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <AvField
                                        label="Custom Bot Command"
                                        type="text"
                                        className="form-control"
                                        id="custom-bot-command"
                                        name="custom-bot-command"
                                        placeholder="Minting command ( all one word )"
                                        value={telegram_bot_command}
                                        onChange={(e) => setBotCommand(e.target.value)}
                                        required
                                        disabled={showSpinner}
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        color="primary"
                                        outline
                                        className="waves-effect w-sm save-btn"
                                        disabled={
                                            showSpinner ||
                                            !allFormsValid([telegram_group_name, telegram_bot_name, telegram_bot_command]) ||
                                            noFormChange()
                                        }
                                        onClick={(e) =>
                                            handleSubmit(
                                                e,
                                                { telegram_group_name, telegram_bot_name, telegram_bot_command },
                                                5,
                                                "createDraftTelegramMint",
                                                false,
                                                telegramData?.inserted?.N
                                            )
                                        }
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 5} txtTrue={"Saving"} txtFalse={"Save"} />
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="waves-effect w-xl submit-btn"
                                        disabled={showSpinner || !allFormsValid([telegram_group_name, telegram_bot_name, telegram_bot_command])}
                                    >
                                        <SpinnerWrap showSpinner={showSpinner && buttonId === 6} txtTrue={"LAUNCHING"} txtFalse={"LAUNCH LIVE"} />
                                    </Button>
                                </div>
                            </AvForm>
                        ) : (
                            <div className="lock">
                                <img src={LockIcon} alt="Lock" />
                                <p>You need to upgrade to a paid plan to access the Telegram Group</p>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        )
    }
)

export default TelegramCard
