import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"

import BreadCrumb from "../Components/BreadCrumb"
import AddAddressModal from "../Components/Address/AddAddressModal"
import { convertDataToAddressDetails, formatAddress } from "../utils"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"

const apiUrl = "https://wallet.idexo.io/"

const Addresses = () => {
    const [addresses, setAddresses] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [detailViewInfo, setDetailView] = useState(null)
    const [openAddAddressModal, setOpenAddAddressModal] = useState(false)

    const handleAddAddressModalClose = () => {
        if (detailViewInfo) {
            setDetailView(null)
        } else {
            setOpenAddAddressModal(false)
        }
    }

    const saveAddresses = async (method, address) => {
        const res = await fetch(apiUrl, {
            method: method,
            headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken },
            body: JSON.stringify(address)
        })
        const response = await res.json()
        console.log(response)
    }

    const handleAddNewAddress = (newAddress) => {
        saveAddresses("POST", newAddress)
        setAddresses([...addresses, newAddress])
    }

    const handleUpdateAddress = (updatedAddress) => {
        saveAddresses("PATCH", updatedAddress)
        const updatedAddresses = addresses.map((address) => {
            if (address.id === updatedAddress.id) {
                return updatedAddress
            } else {
                return address
            }
        })

        setAddresses([...updatedAddresses])
    }

    const fetchAddresses = async () => {
        try {
            setShowLoading(true)

            const res = await fetch(apiUrl, {
                method: "GET",
                headers: { token: localStorage.jwtTenant ? localStorage.jwtTenant : localStorage.jwtToken }
            })
            const response = await res.json()

            if (response.code !== 200 || response.error || response.message === "Internal server error") {
                setAlertMessage("Error: " + response.error)
                setShowLoading(false)
                setAddresses(null)
                return
            }

            const addressItems = response.data.Items.map((item) => convertDataToAddressDetails(item))
            setAddresses(addressItems)
        } catch (err) {
            console.log(err)
        }
        setShowLoading(false)
    }

    useEffect(() => {
        fetchAddresses().then()
    }, [])

    const renderAddresses = () => {
        if (showLoading) {
            return <LoadingCard loadingTxt={"Addresses"} />
        }

        if (!addresses) {
            return <LoadingCard errorTxt={"addresses"} alertMessage={alertMessage} />
        }

        if (addresses.length === 0) {
            return <LoadingCard emptyTxt={"addresses"} icon="bxs-contact" />
        }

        return (
            <div className="overflow-auto">
                <Table className="align-middle mb-0 table-nowrap addresses-table">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Address</th>
                            <th scope="col">Name</th>
                            <th scope="col">Nickname</th>
                            <th scope="col">Email</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addresses.map((address) => (
                            <tr key={address.id} onClick={() => setDetailView(address)}>
                                <td className="address">
                                    <tt>{formatAddress(address.address)}</tt>
                                </td>
                                <td>{address.name}</td>
                                <td>{address.nickname}</td>
                                <td>{address.email}</td>
                                <td>{formattedDateString(new Date(Number(address.inserted)))}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    const getTwoDigits = (val) => (val < 10 ? `0${val}` : val)

    const formattedDateString = (date) => {
        const year = date.getFullYear()
        const month = getTwoDigits(date.getMonth() + 1)
        const day = getTwoDigits(date.getDate())

        return `${day}.${month}.${year}`
    }

    return (
        <React.Fragment>
            <div className="content addresses-page">
                <BreadCrumb items={["Addresses"]} />
                <Card>
                    <CardBody>
                        <CardTitle className="h3">Addresses</CardTitle>
                        <div className="d-flex justify-content-end mb-3">
                            <Button color="primary" className="add-contract-btn waves-effect w-xl" onClick={() => setOpenAddAddressModal(true)}>
                                Add New Address
                            </Button>
                        </div>
                        {renderAddresses()}
                    </CardBody>
                </Card>
            </div>
            {(openAddAddressModal || detailViewInfo) && (
                <AddAddressModal
                    open={openAddAddressModal || detailViewInfo}
                    onClose={handleAddAddressModalClose}
                    onAddAddress={handleAddNewAddress}
                    onUpdateAddress={handleUpdateAddress}
                    type={detailViewInfo ? "edit" : "add"}
                    data={detailViewInfo}
                />
            )}
        </React.Fragment>
    )
}

export default Addresses
