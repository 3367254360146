import React, { Component } from "react"
import SimpleBar from "simplebar-react"
import { connect } from "react-redux"
import MetisMenu from "metismenujs"
import { NavLink } from "react-router-dom"
import jwt from "jsonwebtoken"

import { toggleSideMenu } from "../../store/common/commonActions"

class SidebarContent extends Component {
    constructor(props) {
        super(props)
        this.refDiv = React.createRef()
        this.state = {
            user: null
        }
    }

    componentDidMount() {
        this.initMenu()
    }

    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) {
            this.initMenu()
        }
    }

    initMenu() {
        new MetisMenu("#side-menu")
        if (localStorage.jwtToken) {
            try {
                let decoded = jwt.decode(localStorage.jwtToken)
                this.setState({ user: decoded })
            } catch {}
        }
    }

    scrollElement = (item) => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current) this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300
                    }
                }
            }
        }, 300)
    }

    activateParentDropdown = (item) => {
        item.classList.add("active")
        const parent = item.parentElement

        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            this.scrollElement(item)
            return false
        }
        this.scrollElement(item)
        return false
    }

    onClickMenuItem = () => {
        if (window.innerWidth < 992) {
            this.props.toggleSideMenu(false)
        }
    }

    getPath = (path) => {
        return `${path}`
    }

    render() {
        const { user } = this.state

        return (
            <React.Fragment>
                <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">Home</li>
                            <li>
                                <NavLink
                                    to="/"
                                    className="waves-effect welcome-page-link"
                                    onClick={this.onClickMenuItem}
                                    exact
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-home-circle"></i>
                                    <span>Welcome</span>
                                </NavLink>
                            </li>
                            <li className="menu-title">NFTs</li>
                            <li>
                                <NavLink
                                    to={this.getPath("/projects")}
                                    className=" waves-effect project-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-briefcase"></i>
                                    <span>Projects</span>
                                </NavLink>
                            </li>

                            {user && user?.account === 1 && (
                                <li>
                                    <NavLink
                                        to={this.getPath("/clients")}
                                        className=" waves-effect clients-page-link"
                                        onClick={this.onClickMenuItem}
                                        activeClassName="waves-active"
                                    >
                                        <i className="bx dripicons-user-group"></i>

                                        <span>Clients</span>
                                    </NavLink>
                                </li>
                            )}
                            <li>
                                <NavLink
                                    to={this.getPath("/addresses")}
                                    className=" waves-effect addresses-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="fa fa-address-book"></i>

                                    <span>Addresses</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={this.getPath("/smartcontract")}
                                    className=" waves-effect smart-contract-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-file"></i>

                                    <span>Contracts</span>
                                </NavLink>
                            </li>
                            <li className="menu-title">Settings</li>
                            <li>
                                <NavLink
                                    to={this.getPath("/settings")}
                                    className=" waves-effect settings-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-cog"></i>
                                    <span>Account</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={this.getPath("/billing")}
                                    className=" waves-effect billing-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-dollar"></i>
                                    {/* TO DO: only show if admin? */}
                                    <span>Billing</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={this.getPath("/zapier")}
                                    className=" waves-effect settings-page-link"
                                    onClick={this.onClickMenuItem}
                                    activeClassName="waves-active"
                                >
                                    <i className="bx bx-link"></i>
                                    <span>Zapier</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    toggleSideMenu
}

export default connect(null, mapDispatchToProps)(SidebarContent)
