import React, { Component } from "react"

class NonAuthdLayout extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="NonAuthdLayout">{this.props.children}</div>
            </React.Fragment>
        )
    }
}

export default NonAuthdLayout
