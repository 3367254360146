import React, { useState } from "react"
import { Col, Row, Button } from "reactstrap"
import OkDialog from "../Components/OkDialog/OkDialog"
import { updateModules } from "../utils"
import BreadCrumb from "../Components/BreadCrumb"
import SubdomainCard from "../Components/SubdomainCard/SubdomainCard"
import ProgressCard from "../Components/ProgressCard/ProgressCard"
import LoadingCard from "../Components/LoadingCard/LoadingOrEmptyCard"
import StakingCard from "../Components/StakingCard/StakingCard"
import WaitingModal from "../Components/WaitingModal/WaitingModal"

const apiUrl = "https://projects.idexo.io/" // backend

const Staking = ({ project, clickCancel, clickProjects, module, modules, setModules, stakingHasProject = false, ...rest }) => {
    // Staking Contract Card Component
    // stakingContract = {Items:[]} will contain info from all fields (from backend)
    const [showStakingLoading, setShowStakingLoading] = useState(false) // spinner animation for loading StakingCard data
    const [stakingAlertMessage, setStakingAlertMessage] = useState("") // message if error fetching staking data
    const [stakingAlertColor, setStakingAlertColor] = useState("")

    // Site SubdomainCard Component
    const [subdomain, setSubdomain] = useState(module?.subdomain?.S || "")
    const [subdomain_file, setSubdomainFile] = useState(module?.subdomain_file?.S || "")

    // Progress Table Card Component
    const [showDetailsButtons, setShowDetailsButtons] = useState({}) // keys = button indexes, values = booleans, where true = show button, false = don't show button
    const [showProgressLoading, setShowProgressLoading] = useState(false) // spinner animation of progress table
    const [progressAlertMessage, setProgressAlertMessage] = useState("") // if error fetching campaign data

    // MISC
    // const [paid, setPaid] = useState(decoded?.paid || 0) // 0 = not paid account
    const [paid, setPaid] = useState(2) // 2 = paid. FOR TESTING
    const [showTooltip, setShowTooltip] = useState(false) // for nft collection submit button
    const [showSpinner, setShowSpinner] = useState(false) // loading animation on buttons
    const [buttonId, setButtonId] = useState(null) // number refers to which button was clicked (for displaying spinner animation)
    const [alertMessage, setAlertMessage] = useState("")

    const updateProgressTableStatus = async (inserted, step, tx_hash, tx_id) => {
        // TO DO: maybe create new table for staking? instead of putting in nftmarketing table
        // inserted === time item (ex. twitter, telegram, nftcollection) in nftmarketing dynamo table was inserted
        try {
            setShowStakingLoading(false)
            setShowProgressLoading(false)
            setProgressAlertMessage("")
            setPaid(2)

            return await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "updateProgressStatus",
                    inserted: inserted, // inserted time of nftmarketing table item
                    step: step, // "pending", or "launched"
                    tx_hash,
                    tx_id
                    // Might not need below if we're not updating projects table:
                    // project_inserted: projectInserted, // refers to primary key in user_projects table
                    // module_inserted: moduleInserted // for identifying a module in a project
                })
            })
        } catch (err) {
            console.log(err)
        }
    }

    // Clicking Save Button
    const handleSubmit = async (newModule, buttonId, subdomain = "") => {
        setButtonId(buttonId) // refers to which button was clicked (for determining whether button will have animation)
        setShowSpinner(true)
        setStakingAlertMessage("")

        try {
            let oldModules = modules.slice() // shallow copy
            let newModules = updateModules(oldModules, newModule) // find modules in modules array (by inserted time) and replace that module
            let projectName = project?.project_name?.S
            let projectInserted = String(project?.inserted?.N)

            // TO DO: awsinfra- prevent unauthorized user (with valid jwt) from modifying another users project
            // Updating a project item in the projects table (we will replace entire attribute modules array with updated one)
            const res = await fetch(apiUrl, {
                method: "POST",
                headers: { token: localStorage.jwtToken },
                body: JSON.stringify({
                    action: "createNewModule", // this action creates or updates a module
                    project_name: projectName, // hash_key
                    inserted: projectInserted, // range_key = inserted time of item in user_projects table
                    modules: newModules, // updated modules to replace in backend
                    subdomain: subdomain,
                    subdomain_file: newModule?.M?.subdomain_file?.S || "",
                    flags: { staking: 1 }
                })
            })
            const response = await res.json()
            // problem saving/creating new module in backend
            if (response.error || response.message === "Internal server error" || response?.data.error) {
                setStakingAlertMessage("Error: " + (response.error || response?.data.error || response.message))
                setStakingAlertColor("danger")
            } else {
                modules = newModules
                setModules(modules)
                // TO DO:
                // setAlertMessage(`Success! Redirecting you back to the ${page} page...`)
                // setAlertColor("success")
                // setDisableModalButtons(true)
                // setTimeout(() => {
                //     onClose()
                //     onAddContract()
                // }, 3000)
            }
        } catch (err) {
            console.log(err)
        }
        setShowSpinner(false)
    }

    // const prefillFormData = (items) => {
    //     for (let i = 0; i < items.length; i++) {
    //         let item = items[i] // object- ex. nft collection, twitter, telegram, ...
    //         let type = determineType(item)

    //         // prefill form data (only do this once after mounting to prevent infinite rerenders)
    //         if (type === "Staking Contract" && !stakingName) {
    //             setStakingContract(item) // original data from backend (used to compare changes in form input so user doesn't submit same data)
    //             setStakingName(item.stakingName.S) //this probably should be staking_name but I used stakingName up to this point <- NB
    //             setSymbol(item.symbol.S)
    //             setChain(item.chain.S)
    //             // setDescription(item.description.S)
    //             setContractType(item.contract_type.S)
    //             setDepositToken(item.deposit_token.S)
    //             setRewardToken(item.reward_token.S)

    //             // if status is pending, check backend for updates (to see if status changed to deployed/done)
    //             if (item.source.S === "pending") {
    //                 checkTransactionStatus(item)
    //             }
    //         } else if (type === "Verification Site" && !subdomain) {
    //             setSubdomainData(item)
    //             setSubdomain(item.subdomain.S)
    //         }
    //     }
    // }

    return (
        <React.Fragment>
            {/* TO DO: change className below and css file */}
            <div className={"vesting-contracts module-" + !stakingHasProject}>
                {/* Only display breadcrumb component if staking module belongs to a project */}
                {/* TO DO: might not need this below && */}
                {stakingHasProject && (
                    <React.Fragment>
                        {/* breadcrumb will look like: Home > Projects > project name > Token Staking */}
                        <BreadCrumb
                            items={["projects", `${project?.project_name?.S}`, "Token Staking"]}
                            links={["projects", "projects"]}
                            linkFunctions={[clickProjects, clickCancel]}
                        />
                        <div className="back-to-projects">
                            {/* <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn" disabled={showDeleteLoading}> */}
                            <Button className="back-btn" color="secondary" onClick={clickCancel} id="back-btn">
                                Back to project
                            </Button>
                        </div>
                    </React.Fragment>
                )}

                <Row>
                    <OkDialog open={!!alertMessage} title={""} message={alertMessage} okClick={() => setAlertMessage("")} />
                    <Col xs={12} sm={12} md={6}>
                        <Row>
                            <Col>
                                {showStakingLoading ? (
                                    // TO DO: change className and css below
                                    <LoadingCard loadingTxt={"Token Staking"} backgroundColor="white" className="nft-collection-loading" />
                                ) : (
                                    <StakingCard
                                        module={module}
                                        handleSubmit={handleSubmit}
                                        // helper functions
                                        updateProgressTableStatus={updateProgressTableStatus}
                                        // TO DO: update props names in StakingCard component
                                        // states/state updaters
                                        stakingAlertMessage={stakingAlertMessage}
                                        setStakingAlertMessage={setStakingAlertMessage}
                                        stakingAlertColor={stakingAlertColor}
                                        showStakingLoading={showStakingLoading}
                                        showSpinner={showSpinner}
                                        setShowSpinner={setShowSpinner}
                                        buttonId={buttonId}
                                        setButtonId={setButtonId}
                                        showTooltip={showTooltip}
                                        paid={paid}
                                        setShowTooltip={setShowTooltip}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <ProgressCard
                            showProgressSpinner={showProgressLoading}
                            showSpinner={showSpinner} // spinner for sibling component buttons (ex. 'Deploy' in NFTCollectionCard)
                            showDetailsButtons={showDetailsButtons}
                            setShowDetailsButtons={setShowDetailsButtons}
                            progressAlertMessage={progressAlertMessage}
                        />
                        <SubdomainCard
                            module={module}
                            headerTitle="Staking Site Subdomain"
                            fieldLabel="Staking Sub Domain"
                            subdomain={subdomain}
                            setSubdomain={setSubdomain}
                            subdomain_file={subdomain_file}
                            setSubdomainFile={setSubdomainFile}
                            paid={paid}
                            showSpinner={showSpinner}
                            buttonId={buttonId}
                            handleSubmit={handleSubmit}
                        />
                    </Col>
                </Row>

                {/* triggers after user clicks "Deploy" or similar action */}
                <WaitingModal isOpen={showSpinner && buttonId === 2} modalType={"none"} text={"Contract is deploying. Kindly wait..."} />
            </div>
        </React.Fragment>
    )
}

export default Staking
