import React from "react"
import { Button, Collapse } from "reactstrap"
import { determineType } from "../../utils"
import "./AccordionTable.scss"

// table rows are collapsible on click
const AccordionTable = ({
    tableHeaders = [],
    headerClassNames = [], // custom classNames for table header columns
    tableRows = [],
    tableRowClassNames = [],
    tableRowLiClassName = "",
    addBtnClassName = "add-nfts",
    showDetailsButtons,
    setShowDetailsButtons,
    toggleShowDetailButton,
    handleClickAdd, // ex. handler for "add nft" or "add question"
    renderViewDetail,
    showProgressSpinner,
    disableAllButtons = false,
    addButtonTxt = "ADD NFTs",
    ...rest
}) => {
    const renderTableRows = () => {
        return tableRows.map((item, idx) => {
            let type = determineType(item)

            // this will execute only on first render
            if (showDetailsButtons[idx] === undefined) {
                setShowDetailsButtons((prevState) => {
                    return { ...prevState, [idx]: true }
                })
            }

            return (
                <div key={idx} className="table-row">
                    <li className={tableRowLiClassName + "progress-type"}>{type}</li>
                    <li className={tableRowLiClassName + "progress-status"}>{item?.source?.S || item?.status?.S}</li>
                    <li className={tableRowLiClassName + "progress-action"}>
                        {showDetailsButtons[idx] ? (
                            <React.Fragment>
                                {type === "NFT Collection" || type === "survey" ? (
                                    <Button className={addBtnClassName} onClick={() => handleClickAdd(item)} disabled={disableAllButtons}>
                                        {addButtonTxt}
                                    </Button>
                                ) : (
                                    <Button onClick={() => toggleShowDetailButton(idx)}>View</Button>
                                )}
                            </React.Fragment>
                        ) : (
                            <Button onClick={() => toggleShowDetailButton(idx)}>Hide</Button>
                        )}
                    </li>

                    <Collapse isOpen={!showDetailsButtons[idx]}>{renderViewDetail(item, type)}</Collapse>
                </div>
            )
        })
    }

    return (
        <div className="accordion-table">
            <div className="table-header">
                {tableHeaders.map((header, idx) => {
                    return (
                        <li key={idx} className={"col-" + headerClassNames[idx]}>
                            {header}
                        </li>
                    )
                })}
            </div>
            <div className="table-body">{!showProgressSpinner && renderTableRows()}</div>
        </div>
    )
}

export default AccordionTable
