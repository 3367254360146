import React, { useState } from "react"
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap"
import { Form as AvForm } from "@availity/form"

import SpinnerWrap from "../SpinnerWrap"
import Select from "../CustomSelect"

const ChangeOwnerModal = (props) => {
    const { open, onClose, onChangeOwner, addresses } = props

    const [showSpinner, setShowSpinner] = useState(false)
    const [owner, setOwner] = useState("")

    const handleSubmit = () => {
        setShowSpinner(true)

        setTimeout(() => {
            onChangeOwner(owner)
            setShowSpinner(false)
            onClose()
            setOwner("")
        }, 500)
    }

    return (
        <React.Fragment>
            <Modal className="change-owner-modal" isOpen={open} onClose={onClose} toggle={onClose} fade={true} centered={true}>
                <ModalHeader toggle={onClose} tag={"div"}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>Change owner</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <AvForm initialValues={{ "owner-select": "" }} onSubmit={() => handleSubmit()}>
                        <FormGroup className="mb-3">
                            <Select
                                label="Select new owner"
                                id="owner-select"
                                name="owner-select"
                                placeholder="Select Owner"
                                options={addresses}
                                selectedOption={owner}
                                errorMessage="Required!"
                                required
                                chainSelect={false}
                                handleChange={(value) => setOwner(value)}
                                disabled={showSpinner}
                            />
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-4">
                            <Button color="primary" type="submit" className="update-owner-btn waves-effect" disabled={showSpinner || !owner}>
                                <SpinnerWrap showSpinner={showSpinner} txtTrue={"Updating"} txtFalse={"Update"} />
                            </Button>
                            <Button color="secondary" outline className="btn-light waves-effect cancel-btn" onClick={onClose} disabled={showSpinner}>
                                CANCEL
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ChangeOwnerModal
