import React from "react"
// import { Link } from "react-router-dom"
import "./sidebarNew.scss"

// This sidebar is used in the new '/freetrial' page (new register page)
const SidebarNew = (props) => {
    let drawerClass = "side-drawer"

    if (props.show) drawerClass = "side-drawer open"

    let { navNames, navUrls } = props

    return (
        <nav className={drawerClass}>
            <div className="close-drawer-wrap">
                <button className="close-drawer" onClick={props.clickX}>
                    X
                </button>
            </div>
            <ul>
                {navNames.map((name, idx) => {
                    return (
                        <li key={idx}>
                            <a onClick={props.clickX} href={`${navUrls[idx]}`}>
                                {name}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default SidebarNew
